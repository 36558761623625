import * as React from "react";
import * as ReactDOMServer from "react-dom/server";
import classNames from "classnames/bind";
import marked from "marked";
import styles from "./styles.modules.css";

import { Image, Separator, Text } from "@ribit/components";

const cx = classNames.bind(styles);

marked.Renderer.prototype.paragraph = function(text) {
  return ReactDOMServer.renderToString(<Text dangerous={true}>{text}</Text>);
};

marked.Renderer.prototype.heading = function(text: string, level: number) {
  const tag = `h${level}`;
  return ReactDOMServer.renderToString(
    <Text tag={tag} dangerous={true}>
      {text}
    </Text>,
  );
};

marked.Renderer.prototype.link = function(
  href: string,
  title: string,
  text: string,
) {
  if (href.indexOf("@") > -1) {
    return ReactDOMServer.renderToString(<span>{text}</span>);
  }
  let target = "_blank";
  if (href.startsWith("/")) {
    target = "_self";
  }
  return ReactDOMServer.renderToString(
    <a href={href} target={target}>
      {text}
    </a>,
  );
};

marked.Renderer.prototype.image = function(
  href: string,
  title: string,
  text: string,
) {
  return ReactDOMServer.renderToString(<Image src={href} alt={text} />);
};

marked.Renderer.prototype.hr = function() {
  return ReactDOMServer.renderToString(<Separator text={" "} />);
};

const markdown = (str: string, className: string = null, breaks = true) => {
  if (!str) {
    return null;
  }
  return (
    <div
      className={cx({ [className]: className, markdown: true })}
      dangerouslySetInnerHTML={{
        __html: marked(str, {
          sanitize: true,
          breaks: breaks,
          gfm: true,
          silent: true,
        }),
      }}
    />
  );
};

export default markdown;
export { markdown };
