import * as React from "react";
import styles from "./styles.modules.css";

import { Container, Content, Loading } from "@ribit/components";
import { Operations, operations } from "@app/state/ducks/user/operations";
import { RouteComponentProps } from "@app/types";
import { withRouter } from "@app/helpers/hooks";

import { Title } from "@app/components/title";
import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";

type OwnProps = Record<string, never>;
type DispatchProps = {
  operations?: Operations;
};
type SSOProps = OwnProps & DispatchProps & RouteComponentProps;

class PureSSO extends React.Component<SSOProps> {
  private get token(): string {
    return this.props.match.params["token"];
  }

  componentDidMount() {
    this.props.operations.sso(this.token);
  }

  render(): React.ReactElement<any> {
    return (
      <Content className={styles.outer}>
        <Title label="Login" />
        <Container>
          <Loading label="Authenticating..." style="inverted" />
        </Container>
      </Content>
    );
  }
}

const ConnectedSSO = connect(
  null,
  mapOperationsToDispatchProps(operations),
)(PureSSO);
const SSO = withRouter(ConnectedSSO);

export { SSO };
export default SSO;
