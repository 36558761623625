import * as React from "react";

import { Container, Content } from "@ribit/components";
import { Conversation, Threads } from "./containers";

import { Title } from "@app/components/title";
import { ErrorGreyScreenOfDeath } from "@app/pages/errorGreyScreenOfDeath";

type OwnProps = Record<string, never>;
type OwnState = {
  error: Error | null;
};

class Messages extends React.Component<OwnProps, OwnState> {
  state = {
    error: null,
  };

  componentDidCatch(error: Error | null) {
    this.setState({ error: error });
  }

  render(): React.ReactElement<any> {
    if (this.state.error) {
      return <ErrorGreyScreenOfDeath />;
    }
    return (
      <Content>
        <Title label="Messages" />
        <Container grid="medium-span">
          <div>
            <Threads />
          </div>
          <Conversation />
        </Container>
      </Content>
    );
  }
}

export { Messages };
export default Messages;
