import * as React from "react";
import * as commonStyles from "../styles.modules.css";

import { ActionBar, Button, Heading, Image, Text } from "@ribit/components";

import Layout from "../layout";
import { history } from "@app/state/store";

const completeSvg = require("@app/assets/img/documents.svg");

type OwnProps = {
  onSubmit: (values, completed: boolean) => void;
};

const Complete: React.FunctionComponent<OwnProps> = (props: OwnProps) => {
  const { onSubmit } = props;
  return (
    <Layout className={commonStyles.centered}>
      <Image src={completeSvg} />
      <Heading text="You’ve completed profile setup" />
      <Text className={commonStyles.content}>
        Great work! Now you can either head to your dashboard or start looking
        for jobs.
      </Text>
      <ActionBar layout="center" className={commonStyles.actionBar}>
        <Button
          label="View job listings"
          action={() => {
            history.push("/jobs");
            onSubmit({}, true);
          }}
          style="tertiary"
          size="small"
        />
        <Button
          label="View my dashboard"
          action={() => {
            history.push("/dashboard");
            onSubmit({}, true);
          }}
          style="secondary"
          size="small"
        />
      </ActionBar>
    </Layout>
  );
};

export { Complete };
export default Complete;
