import * as React from "react";
import * as commonStyles from "../styles.modules.css";
import * as yup from "yup";

import { ActionBar, Button, Form, Text } from "@ribit/components";

import ArrayCount from "@app/components/array-count/array-count";
import Layout from "../layout";
import { ModalSelect } from "@app/components/modal-select";
import Skill from "@app/models/skill";
import Title from "../title";
import { lookup } from "@app/helpers/api";

type OwnProps = {
  step: number;
  stepTotal: number;
  onSubmit: (values: any) => void;
};

const Skills: React.FunctionComponent<OwnProps> = (props: OwnProps) => {
  const { onSubmit, step, stepTotal } = props;
  return (
    <Layout>
      <Title text="Add your skills" step={`${step}/${stepTotal}`} />
      <Text className={commonStyles.content}>
        Your skills are used to match you to the relevant jobs and recommend you
        to employers. You can select up to 6 skills for your profile - these
        skills will be viewable by employers.
      </Text>
      <Form
        validateSchema={yup.object().shape({
          skills: yup
            .array()
            .required("You must select some skills, but no more than 6")
            .min(1, "Must select at least one skill")
            .max(6, "Must not select more than 6 skills"),
        })}
        onSubmit={values => {
          const newValues = {
            ...values,
            skills: values.skills.map((x: Skill) => x.id),
          };
          return onSubmit(newValues);
        }}
      >
        {props => {
          const { values, valueChanged } = props;
          return (
            <>
              <ModalSelect
                itemsName="Skills"
                value={values.skills}
                onChange={valueChanged}
                options={[]}
                name="skills"
                modalTitle="Select the things you're good at..."
                label="I'm good at..."
                required
                help={<ArrayCount array={values.skills} max={6} />}
                modalCallback={lookup(
                  Skill,
                  "/skills",
                  (skills: Skill[]) => {
                    return skills.map(skill => {
                      return {
                        label: skill.name,
                        value: skill,
                      };
                    });
                  },
                  0,
                  100,
                )}
                async={{
                  loadingText: "Retrieving skills...",
                  callback: lookup(Skill, "/skills", (skills: Skill[]) => {
                    return skills.map(skill => {
                      return {
                        label: skill.name,
                        value: skill,
                      };
                    });
                  }),
                }}
                placeholder="Select some skills..."
                actionLabel="View all"
              />
              <ActionBar layout="center" className={commonStyles.actionBar}>
                <Button label="Next" type="submit" style="secondary" />
              </ActionBar>
            </>
          );
        }}
      </Form>
    </Layout>
  );
};

export { Skills };
export default Skills;
