import React from "react";
import { ColumnWithRibbonFooterData } from "@app/models/custom-footer-data";
import styles from "./styles.modules.css";
import { Container, Logo } from "@ribit/components";

const ribitLogo = require("@ribit/components/dist/assets/img/logos/ribit-logo-only.png");

type Props = ColumnWithRibbonFooterData;

const mainContentStyle = (props: Props) => ({
  color: props.mainContent.textColor,
  background: props.mainContent.backgroundColor,
});

const ribbonStyle = (props: Props) => ({
  color: props.ribbon.textColor,
  background: props.ribbon.backgroundColor,
});

export const ColumnsWithRibbonFooter = (props: Props) => (
  <footer
    className={styles.container}
    style={{ backgroundColor: props.mainContent.backgroundColor }}
  >
    <div
      className={styles.mainContentContainer}
      style={mainContentStyle(props)}
    >
      <Container>
        <div className={styles.mainContentContainerContent}>
          <div className={styles.ribitContent}>
            <a className={styles.heading} href="https://www.ribit.net">
              <h4>
                Powered by Ribit <img src={ribitLogo} height="24" />
              </h4>
            </a>
            <p>
              Connecting smart students with technical, engineering, analytical,
              business, marketing and research skills to innovative businesses.
            </p>
            <div>
              <div className={styles.ownerLogo}>
                <Logo brand="readygrad" />
              </div>
            </div>
          </div>
          <div className={styles.mainContentSpacer} />
          {props.mainContent.navigationColumns.map((column, index) => (
            <div className={styles.column} key={index}>
              <h4
                className={styles.heading}
                style={{
                  color: props.mainContent.accentColor,
                  borderBottom: `dotted 1px ${props.mainContent.accentColor}`,
                }}
              >
                {column.title}
              </h4>
              {column.links.map((link, index) => (
                <div key={index}>
                  &#8250;&nbsp;
                  <FooterLink
                    textColor={props.mainContent.textColor}
                    {...link}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </Container>
    </div>
    <div className={styles.ribbonContainer} style={ribbonStyle(props)}>
      <Container>
        <div className={styles.ribbonContainerContent}>
          <div className={styles.socialContainer}>
            {props.ribbon.secondaryLinks.length >= 1 && (
              <span>Follow us&nbsp;</span>
            )}
            {props.ribbon.socialLinks.map((socialLink, index) => (
              <SocialLink key={index} {...socialLink} />
            ))}
          </div>
          <div>
            {props.ribbon.secondaryLinks.map((link, index) => (
              <span key={index} className={styles.secondaryLink}>
                <FooterLink textColor={props.ribbon.textColor} {...link} />
              </span>
            ))}
          </div>
        </div>
      </Container>
    </div>
  </footer>
);

const FooterLink = (props: {
  text: string;
  url: string | null;
  textColor: string;
}) => {
  if (props.url === null) {
    return <span>{props.text}</span>;
  }

  return (
    <a style={{ color: props.textColor }} href={props.url}>
      {props.text}
    </a>
  );
};

const SocialLink = (props: {
  icon: string;
  linkUrl: string;
  title: string;
}) => (
  <a className={styles.socialLink} href={props.linkUrl}>
    <img src={props.icon} height="16" alt={props.title} />
  </a>
);
