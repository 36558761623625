import * as React from "react";
import * as styles from "../styles.modules.css";
import * as yup from "yup";

import {
  ActionBar,
  Container,
  Field,
  Form,
  Heading,
  Icons,
  Input,
  Loading,
  Panel,
  // RadioGroup, replaced to add data-cy
  RadioOption,
  Select,
  SelectOption,
  Size,
  Text,
  WordCount,
} from "@ribit/components";
import Job, { Budget, Day, Duration, Type } from "@app/models/job";
import { Location, addressLookup } from "@app/helpers/google";
import { isEqual, Munger, Schema } from "@ribit/lib";
import { get, lookup } from "@app/helpers/api";

import ArrayCount from "@app/components/array-count/array-count";
import Category from "@app/models/category";
import EditableJob, { ResidencyRequirement } from "../models";
import { Employer } from "@app/models/employer";
import { JobType } from "./job-type";
import { ModalSelect } from "@app/components/modal-select";
import Pagination from "@app/models/pagination";
import { PanelArrow } from "@app/components/panel-arrow";
import Skill from "@app/models/skill";
import { State } from "@app/state";
import { Group as RadioGroup } from "@app/components/radio/group";
import axios from "axios";
import { connect } from "react-redux";
import { Button } from "@app/components/button";
import { PhoneNumberInput } from "@app/components/phone-number-input";

type OwnProps = {
  onProgress: (data: object) => void;
  editing?: EditableJob;
};
type CategoryIcons = { [s: string]: string };
type OwnState = {
  loading: boolean;
  categories: RadioOption[];
  types: RadioOption[];
  budgets: RadioOption[];
  days: RadioOption[];
  durations: RadioOption[];
  courseCreditDurations: RadioOption[];
  categoryIcons: CategoryIcons;
  formValues: any;
};
type StateProps = {
  user?: Employer;
  job?: Job;
};

type JobFormValues = {
  title: string;
  description: string;
  courseCreditBudget: string;
  interviewQuestion: string;
  businessDescription: string;
  skills: number[];
  agreedFairwork: boolean;
  residencyRequirement: ResidencyRequirement;
};

const schema: Schema = {
  paths: {
    uuid: "uuid",
    category: "category",
    type: "type", // TODO job_type (DB name)
    budget: "budget", // TODO: pay
    courseCreditBudget: "courseCreditBudget", // COULD DO: remove
    days: "days",
    duration: "duration", // TODO: timing
    title: "title",
    skills: "skills",
    description: "description",
    interviewQuestion: "interviewQuestion",
    address: "address",
    residencyRequirement: "residencyRequirement",
    location: "location",
    agreedFairwork: "agreedFairwork",
    courseCreditDuration: "courseCreditDuration",
    workingOnsite: "workingOnsite",
    workplaceAddress: "workplaceAddress",
    hasDedicatedWorkingSpace: "hasDedicatedWorkingSpace",
    numberOfStudents: "numberOfStudents",
    supervisorJobTitle: "supervisorJobTitle",
    supervisorName: "supervisorName",
    supervisorPhoneNumber: "supervisorPhoneNumber",
    supervisorEmail: "supervisorEmail",
  },
};

type JobFormProps = StateProps & OwnProps;

class ExtendedForm extends Form {
  valueChanged = (
    name: string,
    value: any,
    multiple: boolean,
    isInitial: boolean,
  ) => {
    // This was copied out of @ribit/components so we can monkey-patch the method to add
    // external tracking of form values.
    // We can't access to the original method at runtime with super or by directly
    // calling the method via prototype chain. Not sure of the cause, could be
    // React clobbering prototypes, maybe the Form declaration (which defined the method
    // via assignment rather than the usual class declaration) is causing some mess up
    // in the chain.
    // ----------------------------------------------------------------
    const values: Values = Object.assign({}, this.state.values);
    if (multiple) {
      if (!Array.isArray(values[name])) {
        value = [value];
      } else {
        const tempValues: any[] = (values[name] || []).slice();
        const foundIndex: number = tempValues.findIndex(val =>
          isEqual(val, value),
        );
        if (foundIndex > -1) {
          tempValues.splice(foundIndex, 1);
        } else {
          tempValues.push(value);
        }
        value = tempValues;
      }
    }
    values[name] = value;
    const newState: any = { values: values };
    if (isInitial) {
      const initial: Values = Object.assign({}, this.state.initial);
      initial[name] = value;
      newState.initial = initial;
    }
    this.setState(newState);
    if (this.props.realtime) {
      this.submit(values);
    }
    // ----------------------------------------------------------------
    // /end copying
    //
    // Pass this and other values to any subscribers
    if (this.props.onValueChanged) {
      this.props.onValueChanged(values, name, value, multiple, isInitial);
    }
  };
}

const getIsCourseCreditJobTypeSelected = formValues => {
  if (formValues && formValues["type"]) {
    return formValues["type"].isCourseCredit;
  }
  return false;
};

class PureJobForm extends React.Component<JobFormProps, OwnState> {
  state: OwnState = {
    loading: true,
    categories: [],
    types: [],
    durations: [],
    courseCreditDurations: [],
    days: [],
    budgets: [],
    categoryIcons: {},
    formValues: null,
  };

  componentDidMount() {
    this._retrieveFormOptions();
  }

  private _retrieveFormOptions() {
    const { editing } = this.props;
    axios
      .all([
        get("/categories", Category),
        get("/jobs/types", Type),
        get("/jobs/durations", Duration),
        get("/jobs/course-credit-durations", Duration),
        get("/jobs/days", Day),
        get("/jobs/budgets", Budget),
      ])
      .then(
        axios.spread(
          (
            categories: any,
            types: Pagination<Type>,
            durations: Pagination<Duration>,
            courseCreditDurations: Pagination<Duration>,
            days: Pagination<Day>,
            budgets: Pagination<Budget>,
          ) => {
            this.setState({
              loading: false,
              categories: categories.results.map(category => {
                if (
                  editing.category &&
                  editing.category.name === category.name
                ) {
                  editing.category = category;
                }
                return { label: category.alternativeName, value: category };
              }),
              types: types.results.map(type => {
                if (editing.type && editing.type.name === type.name) {
                  editing.type = type;
                }
                return { label: type.name, value: type };
              }),
              durations: durations.results.map(duration => {
                if (
                  editing.duration &&
                  editing.duration.name === duration.name
                ) {
                  editing.duration = duration;
                }
                return { label: duration.name, value: duration };
              }),
              courseCreditDurations: courseCreditDurations.results.map(
                duration => {
                  if (
                    editing.courseCreditDuration &&
                    editing.courseCreditDuration.name === duration.name
                  ) {
                    editing.courseCreditDuration = duration;
                  }
                  return { label: duration.name, value: duration };
                },
              ),
              days: days.results.map(day => {
                if (editing.days && editing.days.name === day.name) {
                  editing.days = day;
                }
                return { label: day.name, value: day };
              }),
              budgets: budgets.results.map(budget => {
                if (editing.budget && editing.budget.name === budget.name) {
                  editing.budget = budget;
                }
                return { label: budget.name, value: budget };
              }),
              categoryIcons: categories.results.reduce(
                (previousValue: CategoryIcons, currentValue: Category) => {
                  previousValue[currentValue.name] = currentValue.iconName;
                  return previousValue;
                },
                {},
              ),
            });
          },
        ),
      );
  }

  private _renderRoleType = (
    option: RadioOption,
    selected: boolean,
  ): React.ReactElement<any> => {
    const category: string = (option.value as Category).name;
    const icon: string = this.state.categoryIcons[category];
    return (
      <JobType label={option.label} icon={Icons[icon]} active={selected} />
    );
  };

  render(): React.ReactElement<any> {
    const {
      categories,
      types,
      durations,
      courseCreditDurations,
      days,
      budgets,
      loading,
    } = this.state;
    const { editing } = this.props;
    let skills: SelectOption[] = [];
    if (editing.skills) {
      skills = editing.skills.map(skill => {
        return { label: skill.name, value: skill };
      });
    }
    const munger: Munger = new Munger(schema);
    if (loading) {
      return <Loading style="inverted" label="Retrieving form data..." />;
    }
    const initial = munger.flatten(editing);
    const heading: string = editing.uuid
      ? `${editing.canRepost ? "Reposting" : "Editing"} ${editing.title}`
      : "Post a new job";

    // address
    let formattedAddress: string = editing.address;
    let location: string = editing.location;
    const locationOptions: SelectOption[] = [
      {
        label: formattedAddress,
        value: formattedAddress,
      },
    ];

    // course credit address
    let workplaceFormattedAddress: string = editing.workplaceAddress;
    let workplaceLocation: string = editing.workplaceLocation;
    const workplaceLocationOptions: SelectOption[] = [
      {
        label: workplaceFormattedAddress,
        value: workplaceFormattedAddress,
      },
    ];

    const currentFormValues =
      this.state.formValues === null ? initial : this.state.formValues;
    const isCourseCreditJobTypeSelected = getIsCourseCreditJobTypeSelected(
      currentFormValues,
    );

    const fieldValidationSchema = {
      category: yup.mixed().keyValue("id", "You must select a job category"),
      type: yup.mixed().keyValue("id", "You must select a job role"),
      days: yup
        .mixed()
        .keyValue("id", "You must select how many days per week"),
      title: yup
        .string()
        .required("This field is required")
        .max(50, "Must not be more than 50 characters"),
      skills: yup
        .array()
        .required("You must select some skills, but no more than 6")
        .max(6, "Must not select more than 6 skills"),
      description: yup
        .string()
        .required("This field is required.")
        .max(3000, "Must not be more than 3000 characters"),
      interviewQuestion: yup
        .string()
        .max(150, "Must not be more than 150 characters")
        .required("This field is required"),
      agreedFairwork: yup
        .mixed()
        .oneOf([true], "You must agree to the fairwork terms"),
      address: yup
        .string()
        .nullable()
        .location("You must enter a valid address", (locations: Location[]) => {
          location = locations[0].region;
          formattedAddress = locations[0].address;
        }),
      residencyRequirement: yup.string().required("This field is required"),
    };
    if (isCourseCreditJobTypeSelected) {
      fieldValidationSchema.courseCreditBudget = yup
        .string()
        .required("You must indicate if there any additional allowance?");
      fieldValidationSchema.agreedCourseCredit = yup
        .mixed()
        .oneOf([true], "You must agree");
      fieldValidationSchema.numberOfStudents = yup
        .string()
        .required("You must enter the maximum number of students");
      fieldValidationSchema.courseCreditDuration = yup
        .mixed()
        .keyValue("id", "You must select the duration");
      fieldValidationSchema.workplaceAddress = yup
        .string()
        .nullable()
        .location("You must enter a valid address", (locations: Location[]) => {
          workplaceLocation = locations[0].region;
          workplaceFormattedAddress = locations[0].address;
        });
      fieldValidationSchema.workingOnsite = yup
        .string()
        .required("You must select one of the following options");
      fieldValidationSchema.hasDedicatedWorkingSpace = yup
        .string()
        .required("You must select one of the following options");
      fieldValidationSchema.supervisorJobTitle = yup
        .string()
        .required("You must enter the supervisor job title");
      fieldValidationSchema.supervisorName = yup
        .string()
        .required("You must enter the supervisor name");
      fieldValidationSchema.supervisorPhoneNumber = yup
        .string()
        .required("You must enter the supervisor phone number")
        .phoneNumber("You must enter a valid phone number");
      fieldValidationSchema.supervisorEmail = yup
        .string()
        .required("You must enter the supervisor email")
        .email("You must enter a valid email");
    } else {
      fieldValidationSchema.budget = yup
        .mixed()
        .keyValue("id", "You must select a range");
      fieldValidationSchema.duration = yup
        .mixed()
        .keyValue("id", "You must select how long the role will last for");
    }

    return (
      <ExtendedForm
        initial={initial}
        onValueChanged={values => {
          // When the job type changes to certain values (eg: course credit), the form
          // requires a different set of props (schema, etc). Unfortunately the
          // form's props can only be changed during a re-render and the form avoids
          // a re-render by tracking values internally. Hence, we're hooking into
          // the form class to detect when we need to force a re-render.
          // There's probably a better way of doing this, but we're attempting to change a
          // complicated and heavily abstracted set of form machinery, so we've gone with
          // the most obvious and least invasive approach.
          this.setState({ formValues: values });
        }}
        validateSchema={yup.object().shape(fieldValidationSchema)}
        onSubmit={values => {
          values.address = formattedAddress;
          values.location = location;
          values.workplaceAddress = workplaceFormattedAddress;
          values.workplaceLocation = workplaceLocation;
          const jobData: any = munger.expand(values);
          if (editing.canRepost) {
            jobData.canRepost = true;
          }
          this.props.onProgress(jobData);
        }}
        onError={errors => {
          window.scrollTo(0, 100);
        }}
      >
        {props => {
          const values: JobFormValues = props.values;
          const valueChanged = props.valueChanged;

          return (
            <>
              <Container>
                <ActionBar className={styles.actionBar}>
                  <Heading text={heading} />
                </ActionBar>
                <Panel
                  title="What role are you looking for?"
                  className={styles.roleType}
                >
                  <Field label={null} required>
                    <RadioGroup
                      name="category"
                      renderer={this._renderRoleType}
                      options={categories}
                    />
                  </Field>
                </Panel>

                <Container grid="span-small" className={styles.roleDetails}>
                  <Panel title="Type of role">
                    <Field required label="What type of job is this?">
                      <RadioGroup name="type" options={types} />
                    </Field>

                    {isCourseCreditJobTypeSelected ? (
                      <>
                        <Field
                          required
                          label="A University Course Credit Administrator may need to contact you for further information, click ‘I agree’ below to consent to the sharing of your information, company, contact and job details. Without your consent you will not be able to complete this job post and may need to change this to a standard role."
                        >
                          <RadioGroup
                            name="agreedCourseCredit"
                            options={[
                              { label: "I agree", value: true },
                              { label: "I do not agree", value: false },
                            ]}
                          />
                        </Field>
                        <Field
                          required
                          label="Is there any additional allowance provided to students for this opportunity?"
                        >
                          <Input
                            name="courseCreditBudget"
                            lines={3}
                            placeholder="e.g. a monthly stipend, travel allowance, meals, etc."
                          />
                        </Field>
                      </>
                    ) : (
                      <Field
                        required
                        label="What is the pay range for this role?"
                      >
                        <RadioGroup name="budget" options={budgets} />
                      </Field>
                    )}

                    <Field required label="How many days per week?">
                      <RadioGroup name="days" options={days} />
                    </Field>
                    {isCourseCreditJobTypeSelected ? (
                      <Field
                        required
                        label="How long do you expect the job to last?"
                      >
                        <RadioGroup
                          name="courseCreditDuration"
                          options={courseCreditDurations}
                        />
                      </Field>
                    ) : (
                      <Field
                        required
                        label="How long do you expect the job to last?"
                      >
                        <RadioGroup name="duration" options={durations} />
                      </Field>
                    )}
                  </Panel>
                  <div className={styles.helper}>
                    <Panel>
                      <PanelArrow />
                      <Heading size={Size.Small}>Types of roles</Heading>
                      {isCourseCreditJobTypeSelected ? (
                        <Text>
                          <b>Course credit</b> roles offer students a unique
                          opportunity to combine their studies with meaningful
                          work environments where they can apply the theory and
                          skills relevant to their degree. These roles will need
                          to be approved by the relevant university supervisor
                          before students can apply as certain requirements will
                          need to be met. Consequently, you may be contacted by
                          multiple university supervisors to provide additional
                          information. Before you proceed with a course credit
                          role please read{" "}
                          <Button
                            key="our FAQs"
                            className={styles.link}
                            type="text"
                            action="https://www.ribit.net/business-faqs/"
                            label="our FAQs"
                          />{" "}
                          and contact{" "}
                          <Button
                            key="Mailto link"
                            label="support@ribit.net"
                            action="mailto:support@ribit.net"
                            type="text"
                          />{" "}
                          if you have any questions not covered.
                        </Text>
                      ) : (
                        <>
                          <Text>
                            <b>Internships</b> via ribit are paid roles for
                            students and graduates looking to gain relevant
                            skills and experience in a particular field.
                          </Text>
                          <Text>
                            <b>Casual or project roles</b> can be temporary or
                            ongoing, with irregular or flexible hours.
                          </Text>
                          <Text>
                            <b>Full-time and part-time</b> roles are ongoing or
                            fixed-term contracts with regular hours each week.
                          </Text>
                          <Text>
                            For more info and pay rates, consult the{" "}
                            <Button
                              label="Fair Work Act"
                              action="https://www.fairwork.gov.au/ArticleDocuments/723/Minimum-wages.pdf.aspx"
                              type="text"
                            />
                          </Text>
                        </>
                      )}
                    </Panel>
                  </div>
                </Container>
                <Container grid="span-small" className={styles.jobDetails}>
                  <div>
                    <Panel title="Job details">
                      <Field
                        required
                        label="Job Title"
                        help={
                          <WordCount text={values.title} max={50} characters />
                        }
                      >
                        <Input name="title" />
                      </Field>
                      <div data-cy="modal-select__skills">
                        <ModalSelect
                          itemsName="Skills"
                          value={values.skills}
                          onChange={valueChanged}
                          name="skills"
                          modalTitle="Select the skills required"
                          label="Skills required"
                          required
                          help={<ArrayCount array={values.skills} max={6} />}
                          options={skills}
                          modalCallback={lookup(
                            Skill,
                            "/skills",
                            (skills: Skill[]) => {
                              return skills.map(skill => {
                                return {
                                  label: skill.name,
                                  value: skill,
                                };
                              });
                            },
                            0,
                            100,
                          )}
                          async={{
                            loadingText: "Retrieving skills...",
                            callback: lookup(
                              Skill,
                              "/skills",
                              (skills: Skill[]) => {
                                return skills.map(skill => {
                                  return {
                                    label: skill.name,
                                    value: skill,
                                  };
                                });
                              },
                            ),
                          }}
                          placeholder="Select some skills..."
                          actionLabel="View all"
                        />
                      </div>
                      <Field
                        required
                        label="Job description"
                        className={styles.description}
                        help={
                          <WordCount
                            text={values.description}
                            max={3000}
                            characters
                          />
                        }
                      >
                        <Input
                          name="description"
                          lines={5}
                          placeholder={
                            isCourseCreditJobTypeSelected
                              ? "Please provide a description of the role, including " +
                                "how the student will work as part of your team for the " +
                                "duration of the learning opportunity, and a list of the " +
                                "expected tasks students will complete in this placement."
                              : ""
                          }
                        />
                      </Field>
                      <Text className={styles.markdown}>
                        This field supports{" "}
                        <Button
                          label="Markdown"
                          action="http://daringfireball.net/projects/markdown/"
                          type="text"
                        />{" "}
                        syntax.
                      </Text>
                      <Field
                        required
                        label="Ask a screening question"
                        help={
                          <WordCount
                            text={values.interviewQuestion}
                            max={150}
                            characters
                          />
                        }
                      >
                        <Input name="interviewQuestion" />
                      </Field>
                      <div data-cy="address" style={{ paddingBottom: "20px" }}>
                        <Field
                          required
                          label="Address where the work will be performed"
                        >
                          <Select
                            name="address"
                            placeholder="Start typing to search for an address..."
                            options={locationOptions}
                            noResultsText="Address not found, perhaps try entering the address differently."
                            async={{
                              preventCallOnOpen: true,
                              threshold: 4,
                              callback: addressLookup(),
                            }}
                          />
                        </Field>
                      </div>
                      {isCourseCreditJobTypeSelected ? (
                        <Field
                          required
                          label="How many students are you able to place in this role?"
                        >
                          <Input name="numberOfStudents" />
                        </Field>
                      ) : null}
                      <Field
                        required
                        label="Australian Citizenship / Permanent Residency requirements"
                      >
                        <Select
                          name="residencyRequirement"
                          options={[
                            {
                              label: "Australian Citizenship",
                              value: "australian_citizenship",
                            },
                            {
                              label: "Permanent Resident",
                              value: "permanent_resident",
                            },
                            {
                              label: "Not required",
                              value: "not_required",
                            },
                          ]}
                        />
                      </Field>
                      <Field
                        required
                        label="I acknowledge that I am aware of the legal requirements in relation to payment of workers."
                      >
                        <RadioGroup
                          name="agreedFairwork"
                          options={[
                            { label: "I agree", value: true },
                            { label: "I do not agree", value: false },
                          ]}
                        />
                      </Field>
                      <Text className={styles.fairwork}>
                        See the{" "}
                        <Button
                          label="Fair Work Act"
                          action="https://www.fairwork.gov.au/ArticleDocuments/723/Minimum-wages.pdf.aspx"
                          type="text"
                        />
                      </Text>
                    </Panel>
                    {!isCourseCreditJobTypeSelected && (
                      <ActionBar>
                        <Button
                          type="submit"
                          label="Review"
                          style="secondary"
                          dataCy="button__manage-jobs__review"
                        />
                      </ActionBar>
                    )}
                  </div>
                  <div className={styles.helper}>
                    <Panel>
                      <PanelArrow />
                      <Heading size={Size.Small}>Job description</Heading>
                      <Text>Use something punchy, like:</Text>
                      <Text className={styles.small}>
                        We've had a web presence for about 3 years now, but
                        recently the majority of our site visits are coming from
                        mobile. We need an app with the same functionality as
                        our website. This is a short-term project for a student
                        with the right skills.
                      </Text>
                      <Text className={styles.small}>You will need to:</Text>
                      <ul className={styles.list}>
                        <li>
                          develop wireframes for the app based on the functions
                          we need
                        </li>
                        <li>
                          work with the designer to ensure the UI is beautiful
                        </li>
                        <li>build the app for iOS and Android</li>
                        <li>test the app across a range of devices</li>
                        <li>
                          get the app approved on the App store and Google Play
                        </li>
                      </ul>
                      <Text className={styles.small}>
                        You should have experience building apps on iOS and
                        Android as well as strong analytical and problem solving
                        skills.
                      </Text>
                    </Panel>
                  </div>
                </Container>

                {isCourseCreditJobTypeSelected ? (
                  <Container grid="span-small" className={styles.roleDetails}>
                    <div>
                      <Panel title="Workplace">
                        <div data-cy="office-address">
                          <Field
                            required
                            label="Please provide the business' office or mailing address"
                          >
                            <Select
                              name="workplaceAddress"
                              placeholder="Start typing to search for an address..."
                              options={workplaceLocationOptions}
                              noResultsText="Address not found, perhaps try entering the address differently."
                              async={{
                                preventCallOnOpen: true,
                                threshold: 4,
                                callback: addressLookup(),
                              }}
                            />
                          </Field>
                        </div>
                        <br />
                        <Field
                          required
                          label="Will the student usually be working onsite?"
                        >
                          <RadioGroup
                            name="workingOnsite"
                            options={[
                              { label: "Yes", value: "yes" },
                              { label: "No", value: "no" },
                              { label: "Remote", value: "remote" },
                            ]}
                          />
                        </Field>
                        <Field
                          required
                          label="Will the student be provided a dedicated working space?"
                        >
                          <RadioGroup
                            name="hasDedicatedWorkingSpace"
                            options={[
                              { label: "Yes", value: "yes" },
                              { label: "No", value: "no" },
                            ]}
                          />
                        </Field>
                      </Panel>
                      <Panel title="Student's Workplace Supervisor">
                        {/*<h2>*/}
                        {/*  Please provide the details of the student's supervisor*/}
                        {/*</h2>*/}
                        <Field required label="Name">
                          <Input name="supervisorName" />
                        </Field>
                        <Field required label="Job Title">
                          <Input name="supervisorJobTitle" />
                        </Field>
                        <Field required label="Phone number">
                          <PhoneNumberInput name="supervisorPhoneNumber" />
                        </Field>
                        <Field required label="Email">
                          <Input name="supervisorEmail" />
                        </Field>
                      </Panel>
                      <ActionBar>
                        <Button
                          type="submit"
                          label="Review"
                          style="secondary"
                          dataCy="button__manage-jobs__review"
                        />
                      </ActionBar>
                    </div>
                  </Container>
                ) : null}
              </Container>
            </>
          );
        }}
      </ExtendedForm>
    );
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    user: state.user as Employer,
  };
};

const JobForm = connect(mapStateToProps)(PureJobForm);

export { JobForm };
export default JobForm;
