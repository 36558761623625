import { Action, ApiData } from "@app/state/types";
import Types, { Payload } from "./types";

import Pagination from "@app/models/pagination";
import { Student } from "@app/models/student";

const initialState: ApiData<Pagination<Student>> = {
  loading: false,
  data: {
    count: 0,
    results: [],
  },
};

const reducer = (
  state = initialState,
  action: Action<Payload>,
): ApiData<Pagination<Student>> => {
  const { type } = action;
  const payload: Payload = action.payload;
  switch (type) {
    case Types.FETCH_SUCCESS: {
      const oldResults = state.data.results;
      const newState = {
        ...state,
        ...payload,
      };
      newState.data.results = oldResults.concat(newState.data.results);
      return newState;
    }
    case Types.FETCHING:
      if (!payload.data) {
        payload.data = state.data || initialState.data;
      }
      return payload;
    case Types.CLEAR:
      return payload;
    default:
      return state;
  }
};

export { reducer };

export default reducer;
