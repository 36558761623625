import * as React from "react";
import classNames from "classnames/bind";
import * as styles from "./styles.modules.css";

import Radio, { Box, Option, Standard } from "./radio";

import { isEqual } from "@ribit/lib";

const cx = classNames.bind(styles);

type OwnProps = {
  options: Option[];
  name: string;
  value?: any;
  className?: string;
  renderer?: (option: Option, selected: boolean) => React.ReactNode;
  onChange?: (name: string, value: string) => void;
  layout?: "vertical" | "horizontal";
  style?: "box" | "standard";
};

const defaultProps = {
  layout: "horizontal",
  style: "box",
};
type DefaultProps = Readonly<typeof defaultProps>;

class Group extends React.Component<OwnProps> {
  static defaultProps: DefaultProps = defaultProps;

  renderStyledOption = (
    option: Option,
    selected: boolean,
  ): React.ReactElement<any> => {
    const Type: any = this.props.style === "box" ? Box : Standard;
    return <Type label={option.label} selected={selected} />;
  };

  render(): React.ReactElement<any> {
    const {
      options,
      className,
      value,
      onChange,
      name,
      layout,
      style,
    } = this.props;
    let { renderer } = this.props;
    const className_: string = cx({
      outer: true,
      [className]: className,
      [layout]: true,
      [style]: true,
    });
    if (!renderer) {
      renderer = this.renderStyledOption;
    }
    return (
      <div className={className_} data-cy={`radio_group__${name}`}>
        {options.map((option: Option, index: number) => {
          const selected: boolean = isEqual(value, option.value);
          return (
            <Radio
              name={name}
              option={option}
              key={index}
              selected={selected}
              renderer={renderer}
              onChange={onChange}
              // datacy={`radio_group__${name}__${option}`}
            />
          );
        })}
      </div>
    );
  }
}

export { Group };
export default Group;
