import Job from "@app/models/job";
import Pagination from "@app/models/pagination";

const Types = {
  FETCHING: "@@manage/ccRoles/FETCHING",
  FETCH_SUCCESS: "@@manage/ccRoles/FETCH_SUCCESS",
  FETCH_ERROR: "@@manage/ccRoles/FETCH_ERROR",
  CLEAR: "@@manage/ccRoles/CLEAR",
  UPDATE: "@@manage/ccRoles/UPDATE",
};

type Payload = {
  loading: boolean;
  data: Pagination<Job>;
};

export { Payload, Types };

export default Types;
