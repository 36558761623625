import * as React from "react";

import { ActionBar, Loading, More, Table, Text } from "@ribit/components";
import {
  Operations,
  operations,
} from "@app/state/ducks/job-applications/operations";

import Application from "@app/models/application";
import { Button } from "@ribit/components/src/components/button";
import { EmptyTableView } from "@app/components/empty-table-view";
import { History } from "history";
import { JobApplication } from "@app/containers/job-application";
import Pagination from "@app/models/pagination";
import { ProfilePhoto } from "@app/components/profile-photo";
import { State } from "@app/state";
import { Student } from "@app/models/student";
import classNames from "classnames/bind";
import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";
import styles from "./styles.modules.css";

const cx = classNames.bind(styles);

type OwnProps = {
  data?: Pagination<Application>;
};
type OwnState = {
  offset: number;
  application?: Application;
};
type DispatchProps = {
  operations?: Operations;
};
type StateProps = {
  loading?: boolean;
  user?: Student;
  lastUpdatedApplication?: Application;
  history: History;
};
type JobApplicationProps = StateProps & DispatchProps & OwnProps;

class PureJobApplications extends React.Component<
  JobApplicationProps,
  OwnState
> {
  state: OwnState = {
    offset: 0,
  };

  fetch() {
    const { operations } = this.props;
    operations.clearApplications();
    operations.retrieveJobApplications();
  }

  componentDidMount() {
    this.fetch();
  }

  componentWillUnmount() {
    this.props.operations.clearApplications();
  }

  componentDidUpdate(prevProps: JobApplicationProps) {
    if (
      this.props.lastUpdatedApplication !== prevProps.lastUpdatedApplication
    ) {
      this.setState({ application: null });
    }
  }

  private _renderTitle(application: Application) {
    const viewUrl = `/jobs/${application.job.slug}`;
    return (
      <ProfilePhoto
        style="square"
        size="small"
        name={application.job.title}
        action={viewUrl}
        altName={application.job.owner.company.name}
        subtitle={application.job.owner.company.name}
        url={application.job.owner.company.logo}
      />
    );
  }

  private _renderCreated(application: Application) {
    return (
      <Text className={styles.count}>
        Submitted {application.humanCreatedAt}
      </Text>
    );
  }

  private _renderViewed(application: Application) {
    const viewed = application.viewedAt
      ? `Viewed ${application.humanViewedAt}`
      : application.humanViewedAt;
    return <Text className={styles.count}>{viewed}</Text>;
  }

  private _renderActions = (
    application: Application,
  ): React.ReactElement<any> => {
    const read = !!application.viewedAt || application.job.isClosed;
    const style: any = read ? "tertiary" : "secondary";
    return (
      <div className={styles.actionsContainer}>
        <Button
          label="View job"
          size="small"
          style="secondary"
          action={() => {
            this.props.history.push(`/jobs/${application.job.slug}`);
          }}
        ></Button>
        {!read && (
          <Button
            label="Edit"
            style={style}
            size="small"
            action={() => {
              this.setState({ application: application });
            }}
          />
        )}
      </div>
    );
  };

  private _renderLoadMore(): React.ReactElement<any> {
    const { data, operations, loading } = this.props;
    if (!data.results.length || !data.next) {
      return null;
    }

    return (
      <ActionBar layout="center">
        {loading && data.results.length ? (
          <Loading style="inverted" />
        ) : (
          <More
            label="View more"
            action={() => {
              operations.retrieveJobApplications(data.results.length);
            }}
          />
        )}
      </ActionBar>
    );
  }

  render(): React.ReactElement<any> {
    const { data, loading } = this.props;
    const { application } = this.state;
    return (
      <>
        <Table
          className={cx({ table: true, applications: true })}
          cellClassName={styles.cell}
          showColumns={false}
          showSeparator={false}
          columns={[
            { key: "Job Title", renderer: this._renderTitle, width: "40%" },
            { key: "Created", renderer: this._renderCreated },
            { key: "Viewed", renderer: this._renderViewed },
            { key: "Actions", renderer: this._renderActions },
          ]}
          data={data.results}
          loading={loading && !data.results.length}
          defaultLoadingLabel="Retrieving jobs..."
          emptyView={
            <EmptyTableView
              span={5}
              title="Apply for your first job"
              actionLabel="Search for jobs"
              message="Choose from flexible options including casual, part time and full time jobs, internships and projects."
              action="/jobs"
            />
          }
        />
        {this._renderLoadMore()}
        <JobApplication
          show={!!application}
          job={application ? application.job : null}
          application={application}
          onCancel={() => {
            this.setState({ application: null });
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    loading: state.jobApplications.loading,
    lastUpdatedApplication: state.jobApplication.data,
    history: state.history,
  };
};

const JobApplications = connect(
  mapStateToProps,
  mapOperationsToDispatchProps(operations),
)(PureJobApplications);

export { JobApplications };
export default JobApplications;
