import Application from "@app/models/application";

export enum LoadingType {
  MESSAGE = 1,
  SHORTLIST,
  UN_SHORTLIST,
  HIRE,
  DECLINE,
}

type Payload = {
  type?: LoadingType;
  shouldClose?: boolean;
  application?: Application;
};

const Types = {
  SELECT: "@@selectedApplication/SELECT",
  DESELECT: "@@selectedApplication/DESELECT",
  FETCHING: "@@selectedApplication/FETCHING",
  FETCH_SUCCESS: "@@selectedApplication/FETCH_SUCCESS",
  FETCH_FAILED: "@@selectedApplication/FETCH_FAILED",
};

export { Payload, Types };

export default Types;
