import FileUpload from "@app/models/file-upload";
import { User } from "@app/models/user";

const Types = {
  LOGIN_ATTEMPT: "@@user/LOGIN_ATTEMPT",
  LOGIN_SUCCESS: "@@user/LOGIN_SUCCESS",
  LOGIN_FAILED: "@@user/LOGIN_FAILED",
  LOGOUT: "@@user/LOGOUT",
  REGISTER_ATTEMPT: "@@user/REGISTER_ATTEMPT",
  REGISTER_SUCCESS: "@@user/REGISTER_SUCCESS",
  REGISTER_ERROR: "@@user/REGISTER_ERROR",
  REGISTER_KIOSK_ATTEMPT: "@@user/REGISTER_KIOSK_ATTEMPT",
  REGISTER_KIOSK_SUCCESS: "@@user/REGISTER_KIOSK_SUCCESS",
  REGISTER_KIOSK_ERROR: "@@user/REGISTER_KIOSK_ERROR",
  UPDATE_PROFILE_ATTEMPT: "@@user/UPDATE_PROFILE_ATTEMPT",
  UPDATE_PROFILE_SUCCESS: "@@user/UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_ERROR: "@@user/UPDATE_PROFILE_ERROR",
  FETCH_PROFILE_ATTEMPT: "@@user/FETCH_PROFILE_ATTEMPT",
  FETCH_PROFILE_SUCCESS: "@@user/FETCH_PROFILE_SUCCESS",
  FETCH_PROFILE_ERROR: "@@user/FETCH_PROFILE_ERROR",
  UPLOAD_PROFILE_PHOTO_ATTEMPT: "@@user/UPLOAD_PROFILE_PHOTO_ATTEMPT",
  UPLOAD_PROFILE_PHOTO_SUCCESS: "@@user/UPLOAD_PROFILE_PHOTO_SUCCESS",
  UPLOAD_PROFILE_PHOTO_ERROR: "@@user/UPLOAD_PROFILE_PHOTO_ERROR",
  UPLOAD_FILES_ATTEMPT: "@@user/UPLOAD_FILES_ATTEMPT",
  UPLOAD_FILES_ERROR: "@@user/UPLOAD_FILES_ERROR",
  UPLOAD_FILES_SUCCESS: "@@user/UPLOAD_FILES_SUCCESS",
  CHANGE_PASSWORD_ATTEMPT: "@@user/CHANGE_PASSWORD_ATTEMPT",
  CHANGE_PASSWORD_SUCCESS: "@@user/CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_ERROR: "@@user/CHANGE_PASSWORD_ERROR",
  INITIATE_RESET_PASSWORD: "@@user/INITIATE_RESET_PASSWORD",
  INITIATE_RESET_PASSWORD_SUCCESS: "@@user/INITIATE_RESET_PASSWORD_SUCCESS",
  INITIATE_RESET_PASSWORD_ERROR: "@@user/INITIATE_RESET_PASSWORD_ERROR",
};

type Payload = {
  user: User | null;
  message?: string;
};

type PhotoPayload = {
  url: string;
  message?: string;
};

type FilePayload = {
  files: FileUpload[];
  message?: string;
};

export { Payload, PhotoPayload, FilePayload, Types };

export default Types;
