import * as React from "react";
import classNames from "classnames/bind";
import styles from "./styles.modules.css";

const cx = classNames.bind(styles);
type OwnProps = {
  children: React.ReactNode;
  className?: string;
};

const Layout: React.FunctionComponent<OwnProps> = (props: OwnProps) => {
  const { children, className } = props;
  const className_: string = cx({
    outer: true,
    [className]: className,
  });
  return <div className={className_}>{children}</div>;
};

export { Layout };
export default Layout;
