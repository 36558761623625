import * as React from "react";
import styles from "./styles.modules.css";

import {
  Button,
  CheckList,
  Circular,
  Icons,
  Panel,
  Text,
  Title,
} from "@ribit/components";

import User from "@app/models/user";
import { getValue } from "@ribit/lib";

type Item = {
  field: string;
  validation?: (user: User) => boolean;
  label: string;
};

type OwnProps = {
  items: Item[];
  user: User;
  minCompleteMessage: string;
  completeMessage: string;
};

const buttonLabel = (percentComplete: number) =>
  percentComplete === 100 ? "Update your profile" : "Complete your profile";

const ProfileCompletion: React.FunctionComponent<OwnProps> = (
  props: OwnProps,
) => {
  const { user, items, completeMessage, minCompleteMessage } = props;
  const percentageComplete: number = user.percentageComplete;
  const complete = percentageComplete === 100;
  let minComplete = true;
  const checklistItems: any[] = items.map(item => {
    const isMinComplete: boolean = item.validation
      ? item.validation(user)
      : !!getValue(user, item.field);
    if (!isMinComplete) {
      minComplete = false;
    }
    return { label: item.label, completed: isMinComplete };
  });
  return (
    <>
      <div style={{ maxWidth: "280px", margin: "0 auto 40px auto" }}>
        <Circular percentage={percentageComplete} label="Profile Completion" />
      </div>

      <Panel title={<Title icon={Icons.CheckCircle} text="Important Info" />}>
        {!minComplete && !complete ? (
          <Text className={styles.message}>{minCompleteMessage}</Text>
        ) : null}
        {minComplete && !complete ? (
          <Text className={styles.message}>{completeMessage}</Text>
        ) : null}
        <br />
        <CheckList items={checklistItems} />
        <Button
          action="/account"
          label={buttonLabel(percentageComplete)}
          layout="full-width"
          style="tertiary"
        />
      </Panel>
    </>
  );
};

export { ProfileCompletion };
export default ProfileCompletion;
