import * as React from "react";
import * as yup from "yup";

import {
  ActionBar,
  Button,
  Container,
  Field,
  Form,
  Icons,
  Input,
  Loading,
  Panel,
  StickyFooterBar,
} from "@ribit/components";
import {
  Munger,
  Schema,
  deepMerge,
  isString,
  mapOperationsToDispatchProps,
} from "@ribit/lib";
import { Operations, operations } from "@app/state/ducks/user/operations";

import { Partner as PartnerType } from "@app/models/partner";
import { ModalPassword } from "@app/components/modal-password";
import { connect } from "react-redux";
import styles from "./styles.modules.css";

type OwnProps = {
  user: PartnerType;
};
type DispatchProps = {
  operations?: Operations;
};
type PartnerProps = OwnProps & DispatchProps;
type OwnState = {
  saving: boolean;
  passwordModalIsOpen: boolean;
};

const userSchema: Schema = {
  paths: {
    firstName: "",
    lastName: "",
    companyName: "company.name",
    companyWebsite: "company.url",
    linkedInUrl: "company.linkedin",
    companyDescription: "company.description",
    logo: "company.logo",
    abn: "company.abn",
    phoneNumber: "company.phoneNumber",
    industryCode: "company.industryCode.code|company.industryCode",
    companyValues: "company.values",
    companyVision: "company.vision",
    companySize: "company.size.id|company.size",
    studentAttributes: "company.studentAttributes",
  },
};

class PurePartner extends React.Component<PartnerProps, OwnState> {
  state: OwnState = {
    saving: false,
    passwordModalIsOpen: false,
  };

  triggerModalPassword = () => {
    this.setState({ passwordModalIsOpen: !this.state.passwordModalIsOpen });
  };

  render(): React.ReactElement<any> {
    const { saving } = this.state;
    const { user, operations } = this.props;
    const munger: Munger = new Munger(userSchema);
    const initial: any = munger.flatten(user);
    if (!initial.abn) {
      initial.abn = "";
    }
    return (
      <>
        <Form
          initial={initial}
          validateSchema={yup.object().shape({
            firstName: yup.string().required("First Name is a required field"),
            lastName: yup.string().required("Last Name is a required field"),
          })}
          // onFilterValues={values => {
          //   values.companyWebsite = prependHttpToUrl(values.companyWebsite);
          //   return values;
          // }}
          onError={() => window.scrollTo(0, 100)}
          onSubmit={values => {
            if (saving) {
              return;
            }
            this.setState({ saving: true });
            const updatedData: { [s: string]: any } = deepMerge(
              user,
              munger.expand<any>(values),
            );

            const promises: Promise<any>[] = [];
            if (!isString(values.logo)) {
              promises.push(operations.uploadProfilePhoto(values.logo));
            }
            return Promise.all(promises).then(() => {
              return operations
                .updateProfile(updatedData)
                .then(() => {
                  this.setState({ saving: false });
                })
                .catch(error => {
                  this.setState({ saving: false });
                  throw error;
                });
            });
          }}
        >
          {props => {
            const { isDirty } = props;
            return (
              <>
                <Container grid="medium-span-small">
                  <div>
                    <Panel title="Account" icon={Icons.Shield}>
                      <Field required>
                        <Input name="firstName" />
                      </Field>
                      <Field required>
                        <Input name="lastName" />
                      </Field>
                      <ActionBar layout="center" className={styles.actions}>
                        <Button
                          style="tertiary"
                          layout="full-width"
                          label="Change password"
                          action={this.triggerModalPassword}
                        />
                      </ActionBar>
                    </Panel>
                  </div>
                </Container>
                <StickyFooterBar className={styles.update}>
                  <Button
                    type="submit"
                    label={saving ? <Loading /> : "Update"}
                    style="secondary"
                    className={styles.updateButton}
                    disabled={!isDirty}
                  />
                </StickyFooterBar>
              </>
            );
          }}
        </Form>
        <ModalPassword
          name="change-password"
          modalTitle="Change Password"
          toggleModal={this.triggerModalPassword}
          modalIsOpen={this.state.passwordModalIsOpen}
        />
      </>
    );
  }
}

const Partner = connect(
  null,
  mapOperationsToDispatchProps(operations),
)(PurePartner);

export { Partner };
export default Partner;
