import * as React from "react";
import styles from "./styles.modules.css";

import {
  ABitAboutYourself,
  Abn,
  AddYourLogo,
  Complete as EmployerComplete,
  Setup as EmployerSetup,
} from "@app/components/onboarding/employer";
import {
  AboutYou,
  Contact,
  Photo,
  Skills,
  Complete as StudentComplete,
  Setup as StudentSetup,
  Studying,
  Work,
} from "@app/components/onboarding/student";
import { Horizontal, Modal } from "@ribit/components";
import { Operations, operations } from "@app/state/ducks/user/operations";
import User, { isEmployer, isStudent } from "@app/models/user";
import { clone, deepMerge, mapOperationsToDispatchProps } from "@ribit/lib";

import { State } from "@app/state";
import { connect } from "react-redux";

type OwnProps = Record<string, never>;
type OwnState = {
  step: number;
  data: object;
  show: boolean;
};
type StateProps = {
  user?: User;
};
type DispatchProps = {
  operations?: Operations;
};
type OnboardingProps = OwnProps & StateProps & DispatchProps;

class PureOnboarding extends React.Component<OnboardingProps> {
  state: OwnState = {
    step: 1,
    data: {},
    show: false,
  };

  componentDidMount() {
    const { user } = this.props;
    const onboardingCutover: number = Date.parse("2019-06-26T00:00:35.393419Z");
    if (
      user.isAuthenticated &&
      !user.doesNotRequireOnboarding &&
      user.createdAt.getTime() >= onboardingCutover
    ) {
      this.setState({ show: true });
    }
  }

  onProceedToNext = (values, hide: boolean) => {
    const { data, step } = this.state;
    const { user } = this.props;
    let updatedData: object = data;
    if (values) {
      updatedData = { ...data, ...values };
    }
    if (step === this.steps.length - 1) {
      const userData: { [s: string]: any } = clone(user);
      if (isEmployer(user)) {
        userData.company = deepMerge(userData.company, updatedData);
        if (
          userData.company.size &&
          typeof userData.company.size === "object"
        ) {
          userData.company.size = userData.company.size.id;
        }
        if (
          userData.company.industryCode &&
          typeof userData.company.industryCode === "object"
        ) {
          userData.company.industryCode = userData.company.industryCode.code;
        }
      } else {
        userData.profile = deepMerge(userData.profile, updatedData);
      }
      this.props.operations.updateProfile(userData);
    }
    let nextStep: number = this.state.step + 1;
    if (nextStep > this.steps.length) {
      nextStep = 1;
      updatedData = {};
    }
    this.setState({ step: nextStep, data: updatedData, show: !hide });
  };

  get steps(): any[] {
    const { user } = this.props;
    let steps: any[] = [];
    if (isEmployer(user)) {
      steps = [
        EmployerSetup,
        ABitAboutYourself,
        AddYourLogo,
        Abn,
        EmployerComplete,
      ];
    } else if (isStudent(user)) {
      steps = [
        StudentSetup,
        Studying,
        Skills,
        Contact,
        Work,
        AboutYou,
        Photo,
        StudentComplete,
      ];
    }
    return steps;
  }

  renderStep(): React.ReactElement<any> {
    const { step } = this.state;
    const steps: any[] = this.steps;
    if (step > steps.length) {
      return null;
    }
    const Step: any = steps[step - 1];
    return (
      <Step
        onSubmit={this.onProceedToNext}
        step={step - 1}
        stepTotal={steps.length - 2}
        user={this.props.user}
      />
    );
  }

  render(): React.ReactElement<any> {
    const { step, show } = this.state;
    const percentage: number = (step / this.steps.length) * 100;
    return (
      <Modal isOpen={show}>
        <div className={styles.outer} data-cy={`modal__onboarding__${show}`}>
          {this.renderStep()}
          <Horizontal percentage={percentage} />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    user: state.user,
  };
};

const Onboarding = connect(
  mapStateToProps,
  mapOperationsToDispatchProps(operations),
)(PureOnboarding);

export { Onboarding };
export default Onboarding;
