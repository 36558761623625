import Company from "./company";
import User from "./user";
import { hydrate } from "@ribit/lib";

class Employer extends User {
  @hydrate
  company: Company;

  get doesNotRequireOnboarding(): boolean {
    const hasCompleteness: boolean = ["description"].reduce(
      (previousValue: boolean, currentValue: string) => {
        const companyValue: any = this.company[currentValue];
        if (Array.isArray(companyValue) && !companyValue.length) {
          return false;
        }
        if (!companyValue) {
          return false;
        }
        return previousValue;
      },
      true,
    );
    return hasCompleteness;
  }

  get missingFields(): string[] {
    if (this.minimumProfileCompleteness) {
      return [];
    }

    const hasCompleteness: string[] = [
      "description",
      "industryCode",
      "size",
    ].reduce((previousValue: string[], currentValue: string) => {
      const companyValue: any = this.company[currentValue];
      if (Array.isArray(companyValue) && !companyValue.length) {
        previousValue.push(currentValue);
      } else if (!companyValue) {
        previousValue.push(currentValue);
      }
      return previousValue;
    }, []);

    return hasCompleteness;
  }

  get minimumProfileCompleteness(): boolean {
    const hasCompleteness: boolean = [
      "description",
      "industryCode",
      "size",
    ].reduce((previousValue: boolean, currentValue: string) => {
      const companyValue: any = this.company[currentValue];
      if (Array.isArray(companyValue) && !companyValue.length) {
        return false;
      }
      if (!companyValue) {
        return false;
      }
      return previousValue;
    }, true);
    return hasCompleteness;
  }

  get percentageComplete(): number {
    const fields: string[] = [
      "name",
      "description",
      "linkedin",
      "values",
      "vision",
      "studentAttributes",
      "url",
      "abn",
      "size",
      "industryCode",
      "logo",
      "phoneNumber",
    ];
    const completed: number = fields.reduce(
      (previousValue: number, currentValue: string) => {
        const companyValue: any = this.company[currentValue];
        if (Array.isArray(companyValue) && !companyValue.length) {
          return previousValue;
        }
        if (companyValue && companyValue !== undefined && companyValue !== "") {
          previousValue++;
        }
        return previousValue;
      },
      0,
    );
    return Math.round((completed / fields.length) * 100);
  }
}

export { Employer };

export default Employer;
