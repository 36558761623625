const Types = {
  FETCHING: "@@reset-forgotten-password/FETCHING",
  FETCH_SUCCESS: "@@reset-forgotten-password/FETCH_SUCCESS",
  FETCH_ERROR: "@@reset-forgotten-password/FETCH_ERROR",
  RESET_ATTEMPT: "@@reset-forgotten-password/RESET_ATTEMPT",
  RESET_SUCCESS: "@@reset-forgotten-password/RESET_SUCCESS",
  RESET_ERROR: "@@reset-forgotten-password/RESET_ERROR",
};

type ResetRequest = {
  name?: string;
  email?: string;
  valid?: boolean;
};

type ResetAttempt = {
  success?: boolean;
};

type Payload = {
  loading: boolean;
  data: ResetRequest | ResetAttempt;
};

export { ResetRequest, ResetAttempt, Payload, Types };

export default Types;
