import React from "react";
import { PendingCompanyPanelBase } from "./pending-company-panel-base";

interface Props {
  companyApproved: boolean;
}

export const PendingCompanyPanel = (props: Props) => {
  if (props.companyApproved === true) {
    return null;
  }

  return (
    <PendingCompanyPanelBase
      heading="Your new account will be reviewed and approved after you post your first job"
      points={[
        "All new companies are reviewed by Ribit to ensure high quality jobs and suitability for students",
        "During the review process any jobs you post will not be visible to students",
        "Most reviews are completed within 1-2 business days",
        "You will be notified when your review is complete and any jobs you have posted will become visible",
      ]}
    />
  );
};
