import * as React from "react";
import styles from "./styles.modules.css";

import { Heading, Text } from "@ribit/components";

import { Button } from "@ribit/components";
import { Container } from "@ribit/components";
import { Content } from "@ribit/components";
import { Image } from "@ribit/components";
import { Title } from "@app/components/title";

type OwnProps = Record<string, never>;

const NotFoundSVG = require("@app/assets/img/error404.svg");

const Error404: React.FunctionComponent<OwnProps> = () => {
  return (
    <>
      <Title label="Not Found" />
      <Content className={styles.outer}>
        <Container grid="span-medium">
          <Image src={NotFoundSVG} />
          <div>
            <Heading text="Uh-oh!" />
            <Text>
              There doesn't seem to be anything here... Sorry about that! In the
              meantime, try going back to the previous page you were on.
            </Text>
            <Button
              className={styles.back}
              style="secondary"
              label="Back to the previous page"
              action={() => {
                window.history.back();
              }}
            />
          </div>
        </Container>
      </Content>
    </>
  );
};

export { Error404 };
export default Error404;
