import * as React from "react";
import * as commonStyles from "../styles.modules.css";
import * as yup from "yup";

import { ActionBar, Button, Field, Form, Text } from "@ribit/components";
import { Operations, operations } from "@app/state/ducks/user/operations";

import Layout from "../layout";
import { PhotoUpload } from "@app/components/photo-upload";
import Title from "../title";
import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";

type OwnProps = {
  step: number;
  stepTotal: number;
  onSubmit: (values: any, completed: boolean) => void;
};
type DispatchProps = {
  operations: Operations;
};

type PhotoProps = OwnProps & DispatchProps;

const PurePhoto: React.FunctionComponent<PhotoProps> = (props: PhotoProps) => {
  const { onSubmit, step, stepTotal, operations } = props;
  return (
    <Layout>
      <Title text="Add a profile photo" step={`${step}/${stepTotal}`} />
      <Text className={commonStyles.content}>
        Evidence shows that employers are more likely to reach out to you if you
        have uploaded a photo. Find one that shows your face clearly, preferably
        with a smile!
      </Text>
      <Form
        validateSchema={yup.object().shape({
          photo: yup
            .mixed()
            .required("You must supply a photo or skip this step."),
        })}
        onSubmit={values => {
          return operations.uploadProfilePhoto(values.photo).then(() => {
            onSubmit({}, false);
          });
        }}
      >
        <Field label={null}>
          <PhotoUpload name="photo" />
        </Field>
        <ActionBar layout="center" className={commonStyles.actionBar}>
          <Button
            label="Skip"
            action={() => onSubmit(null, false)}
            style="tertiary"
          />
          <Button label="Next" type="submit" style="secondary" />
        </ActionBar>
      </Form>
    </Layout>
  );
};

const Photo = connect(
  null,
  mapOperationsToDispatchProps(operations),
)(PurePhoto);

export { Photo };
export default Photo;
