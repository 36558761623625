import * as React from "react";
import styles from "./styles.modules.css";

import { ActionBar, Loading, More, Table, Text } from "@ribit/components";
import {
  Operations,
  operations,
} from "@app/state/ducks/manage/closedCCRoles/operations";

import { EmptyTableView } from "@app/components/empty-table-view";
import Job from "@app/models/job";
import Pagination from "@app/models/pagination";
import { ProfilePhoto } from "@app/components/profile-photo";
import { State } from "@app/state";
import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";
import { Button } from "@app/components/button";

type OwnProps = {
  data?: Pagination<Job>;
  onJobClick?(job?: Job): void;
};

type DispatchProps = {
  operations?: Operations;
};

type StateProps = {
  loading?: boolean;
};

type PureCCRolesProps = StateProps & DispatchProps & OwnProps;

class PureClosedCCRoles extends React.Component<PureCCRolesProps> {
  renderTitle(job: Job) {
    return (
      <div className={styles.companyWrapper}>
        <ProfilePhoto
          url={job.owner.company.logo}
          altName={job.owner.company.name}
          name={job.title}
          action={`/jobs/${job.slug}`}
          subtitle={job.owner.company.name}
          size="small"
          style="square"
        />
      </div>
    );
  }

  private _renderDate(job: Job) {
    return <Text className={styles.count}>{job.posted}</Text>;
  }

  private _renderActions(job: Job) {
    return (
      <div className={styles.actions}>
        <Button
          label="View job"
          style="secondary"
          size="small"
          action={`/jobs/${job.slug}`}
          dataCy="button__view-closed-job"
        />
      </div>
    );
  }

  private _renderLoadMore(): React.ReactElement<any> {
    const { data, operations, loading } = this.props;
    if (!data.results.length || !data.next) {
      return null;
    }

    return (
      <ActionBar layout="center">
        {loading && data.results.length ? (
          <Loading style="inverted" />
        ) : (
          <More
            label="View more"
            action={() => {
              operations.fetch(data.results.length);
            }}
          />
        )}
      </ActionBar>
    );
  }

  render(): React.ReactElement<any> {
    const { data, loading } = this.props;
    return (
      <>
        <Table
          className={styles.table}
          cellClassName={styles.cell}
          showColumns={true}
          showSeparator={true}
          columns={[
            {
              key: "Public Link",
              renderer: this.renderTitle.bind(this),
              width: "40%",
            },
            { key: "Date Posted", renderer: this._renderDate },
            { key: "Role Details", renderer: this._renderActions.bind(this) },
          ]}
          data={data.results}
          loading={loading && !data.results.length}
          defaultLoadingLabel="Retrieving course credit roles..."
          emptyView={
            <EmptyTableView
              span={4}
              title="No results"
              message="No course credit roles to review."
            />
          }
        />
        {this._renderLoadMore()}
      </>
    );
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    loading: state.manage.closedCCRoles.loading,
  };
};

const ClosedCCRoles = connect(
  mapStateToProps,
  mapOperationsToDispatchProps(operations),
)(PureClosedCCRoles);

export { ClosedCCRoles };
export default ClosedCCRoles;
