import * as React from "react";

import {
  ActionBar,
  Button,
  Heading,
  Modal,
  Size,
  Text,
} from "@ribit/components";
import { Operations, operations } from "@app/state/ducks/user/operations";

import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";
import styles from "./styles.modules.css";

type OwnProps = {
  toggleModal: any;
  modalIsOpen: boolean;
};

type DispatchProps = {
  operations?: Operations;
};

type DeleteAccountProps = DispatchProps & OwnProps;

class PureDeleteAccount extends React.Component<DeleteAccountProps> {
  render(): React.ReactElement<any> {
    const { operations, modalIsOpen, toggleModal } = this.props;

    return (
      <>
        <Modal isOpen={modalIsOpen} onClose={this.props.toggleModal}>
          <div className={styles.inner}>
            <Heading
              size={Size.Medium}
              text="Deactivate account"
              className={styles.heading}
            />
            <Text>
              <b>WARNING</b> - you are about to deactivate your account.
            </Text>
            <Text>
              This action cannot be undone. If you require access to your
              account at a later stage you can contact Ribit administration.
            </Text>
            <ActionBar layout="center" className={styles.action}>
              <Button
                layout="full-width"
                label="Cancel"
                style="tertiary"
                action={() => toggleModal()}
              />
              <Button
                layout="full-width"
                label="Confirm Account Deletion"
                style="secondary"
                action={() => {
                  operations.closeAccount();
                }}
              />
            </ActionBar>
          </div>
        </Modal>
      </>
    );
  }
}

const DeleteAccount = connect(
  null,
  mapOperationsToDispatchProps(operations),
)(PureDeleteAccount);

export { DeleteAccount };
export default DeleteAccount;
