import Alert from "@app/models/alert";

const Types = {
  ADD: "@@site-alert/ADD",
  CLEAR: "@@site-alert/CLEAR",
};

type Payload = Alert;

export { Payload, Types };

export default Types;
