import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { deserialize, storage } from "@ribit/lib";

import Pagination from "@app/models/pagination";
import Tenant from "@app/models/tenant";
import Token from "@app/models/token";
import User from "@app/models/user";

let fetchingNewAccessToken = false;
const REFRESH_ENDPOINT = "/auth/token/refresh";
const TENANT_HEADER = "X-Ribit-Tenant";
const SOURCE_HEADER = "X-Ribit-Source";

const isRibit = (url: string): boolean => {
  return url.indexOf("ribit") > -1;
};

const isTokenExpired = (response: AxiosResponse, user: User): boolean => {
  if (!response) {
    return true;
  }
  return response.status === 401 && !!user;
};

const defaultConfig = (
  settings: AxiosRequestConfig = {},
): AxiosRequestConfig => {
  const configOptions: AxiosRequestConfig = Object.assign({}, settings);
  if (!configOptions.headers) {
    configOptions.headers = {};
  }
  const token: Token = storage.from("token", null);
  const tenant: Tenant = storage.from("tenant", null);
  if (tenant) {
    configOptions.headers[TENANT_HEADER] = tenant.uuid;
    configOptions.headers[SOURCE_HEADER] = "internal";
  }
  if (token && token.access) {
    configOptions.headers.Authorization = `Bearer ${token.access}`;
  }
  return configOptions;
};

const deserializeData = (
  response: AxiosResponse,
  deserializeMethodOrClass: any,
): any => {
  if (response.status === 200) {
    if (!deserializeMethodOrClass) {
      return response.data;
    }
    let data: any = null;
    let dataToDeserialize: any = response.data;
    if (response.data && response.data.results) {
      data = deserialize(Pagination, dataToDeserialize);
      dataToDeserialize = dataToDeserialize.results;
    }
    let deserializedData: any;
    try {
      deserializedData = deserializeMethodOrClass(dataToDeserialize);
    } catch (e) {} // eslint-disable-line
    if (!deserializedData) {
      deserializedData = deserialize(
        deserializeMethodOrClass,
        dataToDeserialize,
      );
    }
    if (data) {
      data.results = deserializedData;
    } else {
      data = deserializedData;
    }
    return data;
  }
  return null;
};

// Convenience methods

const endpoint = (path: string): string => {
  if (path.indexOf("http") === 0) {
    return path;
  }
  return process.env.API_V2_ENDPOINT + path;
};

const get = async (
  url: string,
  deserializeMethodOrClass: any = null,
  config: AxiosRequestConfig = null,
): Promise<any> => {
  url = endpoint(url);
  return axios.get(url, config).then((response: AxiosResponse) => {
    return deserializeData(response, deserializeMethodOrClass);
  });
};

const post = async (
  url: string,
  data: any = null,
  deserializeMethodOrClass: any = null,
  config: AxiosRequestConfig = null,
): Promise<any> => {
  url = endpoint(url);
  return axios.post(url, data, config).then((response: AxiosResponse) => {
    return deserializeData(response, deserializeMethodOrClass);
  });
};

const put = async (
  url: string,
  data: any = null,
  deserializeMethodOrClass: any = null,
  config: AxiosRequestConfig = null,
): Promise<any> => {
  url = endpoint(url);
  return axios.put(url, data, config).then((response: AxiosResponse) => {
    return deserializeData(response, deserializeMethodOrClass);
  });
};

const del = async (
  url: string,
  deserializeMethodOrClass: any = null,
  config: AxiosRequestConfig = null,
): Promise<any> => {
  url = endpoint(url);
  return axios.delete(url, config).then((response: AxiosResponse) => {
    return deserializeData(response, deserializeMethodOrClass);
  });
};

// Interceptors

const fetchNewAccessToken = async (error?: AxiosError) => {
  const token: Token = storage.from("token", null);
  if (!token) {
    return Promise.reject(error);
  }
  fetchingNewAccessToken = true;
  return post(REFRESH_ENDPOINT, { refresh: token.refresh }, Token)
    .then((token: Token) => {
      storage.to("token", token);
      fetchingNewAccessToken = false;
      return Promise.resolve(token);
    })
    .catch((error: AxiosError) => {
      fetchingNewAccessToken = null;
      return Promise.reject(error);
    });
};

const waitForResetToken = async (): Promise<any> => {
  // Wait until reset token has retrieved before allowing other requests to proceed
  // eslint-disable-next-line no-constant-condition
  while (true) {
    if (fetchingNewAccessToken !== true) {
      break;
    }
    await new Promise(resolve => setTimeout(resolve, 500));
  }
};

axios.interceptors.request.use(
  (axiosConfig: AxiosRequestConfig) => {
    if (!isRibit(axiosConfig.url)) {
      return axiosConfig;
    }
    const config: AxiosRequestConfig = defaultConfig(axiosConfig);
    if (config.url.indexOf("/tenants/") > -1) {
      delete config.headers[TENANT_HEADER];
      delete config.headers.Authorization;
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error: AxiosError) => {
    if (isRibit(error.config.url)) {
      // If we're on a Ribit request, check to see if the response is invalid
      // due to there being an invalid access token, if so request a new one.
      const user: User = storage.from("user", null);
      if (
        axios.isCancel(error) ||
        error.config.url.indexOf(REFRESH_ENDPOINT) > -1
      ) {
        return Promise.reject(error);
      }
      if (fetchingNewAccessToken) {
        await waitForResetToken();
        if (fetchingNewAccessToken === null) {
          return Promise.reject(error);
        }
        return Promise.resolve(axios(error.response.config));
      }
      if (isTokenExpired(error.response, user)) {
        if (!fetchingNewAccessToken) {
          await fetchNewAccessToken(error);
        }
        if (fetchingNewAccessToken === false) {
          // Successfully retrieved token, resolve the request
          return Promise.resolve(axios(error.response.config));
        }
      } else {
        return Promise.reject(error);
      }
    }
  },
);

const lookup = (
  Type: any,
  url: string,
  handler: (objs: any) => any,
  offset = 0,
  limit = 500,
): any => {
  return (query: string) => {
    return new Promise(resolve => {
      const limitOffset = `offset=${offset}&limit=${limit}`;
      if (query) {
        query = `?query=${query}&${limitOffset}`;
      } else {
        query = `?${limitOffset}`;
      }
      get(url + query, Type)
        .then((response: Pagination<any>) => {
          resolve(handler(response.results));
        })
        .catch(() => resolve([]));
    });
  };
};

export {
  get,
  post,
  put,
  del,
  lookup,
  endpoint,
  fetchNewAccessToken,
  defaultConfig,
};
