import * as React from "react";
import styles from "./styles.modules.css";

import { Button, Heading, Text } from "@ribit/components";

type OwnProps = {
  title: string;
  action?: any;
  actionLabel?: string;
  message: string;
  span: number;
};

const EmptyTableView: React.FunctionComponent<OwnProps> = (props: OwnProps) => {
  const { title, message, action, actionLabel, span } = props;

  return (
    <div className={styles.outer} style={{ gridColumn: `span ${span} / auto` }}>
      <Heading text={title} />
      <Text>{message}</Text>
      {action && (
        <Button
          className={styles.action}
          label={actionLabel}
          action={action}
          style="secondary"
          size="small"
        />
      )}
    </div>
  );
};

export { EmptyTableView };
export default EmptyTableView;
