import * as React from "react";
import styles from "./styles.modules.css";

import { Heading, Text } from "@ribit/components";

import { Button } from "@ribit/components";
import { Container } from "@ribit/components";
import { Content } from "@ribit/components";
import { Image } from "@ribit/components";
import { Title } from "@app/components/title";

type OwnProps = Record<string, never>;

const NotFoundSVG = require("@app/assets/img/error404.svg");

const ErrorGreyScreenOfDeath: React.FunctionComponent<OwnProps> = () => {
  return (
    <>
      <Title label="Error" />
      <Content className={styles.outer}>
        <Container grid="span-medium">
          <Image src={NotFoundSVG} />
          <div>
            <Heading text="Uh-oh!" />
            <Text>
              Something has broken on our end, don't worry this has been logged
              and someone will be looking at it shortly.
            </Text>
            <Button
              className={styles.back}
              style="secondary"
              label="Back to my dashboard"
              action={() => (document.location.pathname = "/")}
            />
          </div>
        </Container>
      </Content>
    </>
  );
};

export { ErrorGreyScreenOfDeath };
export default ErrorGreyScreenOfDeath;
