class Category {
  id: number;
  name: string;
  alternativeName: string;
  icon: string;

  get iconName(): string {
    return (
      this.icon || this.alternativeName.replace(/ /g, "").replace(/&/g, "")
    );
  }
}

export default Category;
