import Types, { Payload } from "./types";
import User, { Type } from "@app/models/user";
import { deserialize, storage } from "@ribit/lib";

import { Action } from "@app/state/types";
import { Employer } from "@app/models/employer";
import { Student } from "@app/models/student";

const userTypeFromJson = (user: object) => {
  const userType: Type = user["userType"];
  if (userType === Type.Employer) {
    return Employer;
  } else if (userType === Type.Student) {
    return Student;
  }

  return User;
};

const guestUser: object = { userType: Type.Guest };
const storedUser: any = storage.from("user", guestUser);

const initialUser: object = deserialize(
  userTypeFromJson(storedUser),
  storedUser,
);
const initialState = initialUser;

const reducer = (state = initialState, action: Action<Payload>) => {
  const { type } = action;
  const payload: Payload = action.payload;
  switch (type) {
    case Types.LOGIN_SUCCESS:
      return payload.user;
    case Types.LOGIN_FAILED:
      return guestUser;
    case Types.LOGIN_ATTEMPT:
      return guestUser;
    case Types.LOGOUT:
      return guestUser;
    case Types.REGISTER_SUCCESS:
      return payload.user;
    case Types.REGISTER_ERROR:
      return guestUser;
    case Types.REGISTER_ATTEMPT:
      return guestUser;
    case Types.UPDATE_PROFILE_ERROR:
      return state;
    case Types.UPDATE_PROFILE_SUCCESS:
      return payload.user;
    case Types.UPDATE_PROFILE_ATTEMPT:
      return state;
    case Types.FETCH_PROFILE_ATTEMPT:
      return state;
    case Types.FETCH_PROFILE_ERROR:
      return guestUser;
    case Types.FETCH_PROFILE_SUCCESS:
      return payload.user;
    case Types.INITIATE_RESET_PASSWORD_SUCCESS:
      return payload.user;
    default:
      return state;
  }
};

export { reducer, userTypeFromJson };

export default reducer;
