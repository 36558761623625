import * as React from "react";
import classNames from "classnames/bind";
import styles from "./styles.modules.css";

import { Attribute, Panel, Photo } from "@ribit/components";
import User, { isStudent } from "@app/models/user";

import { Badge } from "../badge";
import Employer from "@app/models/employer";
import Student from "@app/models/student";
import { Thread as ThreadType } from "@app/models/messages";
import { history } from "@app/state/store";

const cx = classNames.bind(styles);

type OwnProps = {
  message: ThreadType;
  user: User;
  active?: boolean;
};

const Thread: React.FunctionComponent<OwnProps> = (props: OwnProps) => {
  const { message, active, user } = props;
  const className: string = cx({
    outer: true,
    active: active,
  });
  const recipient: Student | Employer = message.recipient(user);
  const name: any = (
    <>
      <span>{recipient.fullName}</span>
      <time className={styles.time}>{message.humanLastCreatedAt}</time>
    </>
  );
  return (
    <div
      onClick={() => {
        if (active) {
          return false;
        }
        history.push(`/messages/${message.job.uuid}/${recipient.uuid}`);
      }}
    >
      <Panel className={className}>
        <div className={styles.who}>
          <Photo
            name={recipient.fullName}
            size="small"
            url={
              isStudent(recipient)
                ? recipient.profile.photo
                : recipient.company.logo
            }
            className={styles.photo}
          />
          <Attribute
            name={name}
            value={message.preview}
            altName={recipient.fullName}
            className={styles.message}
          />
          {message.unreadCount > 0 && (
            <Badge label={message.unreadCount} className={styles.unread} />
          )}
        </div>
      </Panel>
    </div>
  );
};

export { Thread };
export default Thread;
