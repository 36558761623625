import {
  ActionBar,
  Button,
  Heading,
  Modal,
  Size,
  Text,
} from "@ribit/components";
import { Operations, operations } from "@app/state/ducks/user/operations";

import React from "react";
import User from "@app/models/user";
import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";
import styles from "./styles.modules.css";

type OwnProps = {
  user: User;
};
type DispatchProps = {
  operations?: Operations;
};
type VerificationProps = OwnProps & DispatchProps;

const PureVerification = ({ user, operations }: VerificationProps) => {
  const [sent, setSent] = React.useState(false);

  const inner = sent ? (
    <>
      <Heading size={Size.Large}>Verification email is being resent...</Heading>
      <Text>
        Please check {user.email} for the verification link, remember to check
        your junk folder just in case!
      </Text>
    </>
  ) : (
    <>
      <Heading size={Size.Large}>Verification is needed...</Heading>
      <Text>
        Thanks for registering with Ribit, we'll need to verify your account in
        order to continue.
      </Text>
      <Text>
        Please check {user.email}, including your spam or promotions folders for
        the verification link. It may take up to 1 minute to receive this email.
        If you didn't receive it you can have it resent below.
      </Text>
      <ActionBar layout="right" className={styles.actions}>
        <Button
          label="Resend Verification Email"
          style="secondary"
          action={() => {
            operations.resendVerification();
            setSent(true);
          }}
        />
      </ActionBar>
    </>
  );

  return (
    <Modal isOpen className={styles.modal}>
      <div className={styles.outer}>{inner}</div>
    </Modal>
  );
};

const Verification = connect(
  null,
  mapOperationsToDispatchProps(operations),
)(PureVerification);

export { Verification };
export default Verification;
