import Types, { LoadingType, Payload } from "./types";

import { Action } from "@app/state/types";
import Application from "@app/models/application";

type SelectedApplicationStore = {
  loading: LoadingType[];
  shouldClose: boolean;
  application?: Application;
};

const initialState: SelectedApplicationStore = {
  loading: [],
  shouldClose: false,
  application: undefined,
};

const reducer = (
  state = initialState,
  action: Action<Payload>,
): SelectedApplicationStore => {
  const { type, payload } = action;

  let newState = state;

  switch (type) {
    case Types.FETCH_SUCCESS:
      if (!newState.application) {
        break;
      }
    case Types.SELECT:
      newState = {
        ...newState,
        application: {
          ...payload.application,
          ...newState.application,
          status: payload.application
            ? payload.application.status
            : newState.application.status,
        },
        loading: newState.loading.filter(type => type !== payload.type),
        shouldClose:
          payload.shouldClose !== undefined
            ? payload.shouldClose
            : state.shouldClose,
      };
      break;
    case Types.FETCH_FAILED:
      newState = {
        ...newState,
        loading: newState.loading.filter(type => type !== payload.type),
      };
      break;
    case Types.FETCHING:
      if (!newState.application) {
        break;
      }
      newState = {
        ...newState,
        loading: payload.type
          ? [...newState.loading, payload.type]
          : newState.loading,
      };
      break;
    case Types.DESELECT:
      newState = {
        ...initialState,
      };
  }

  return newState;
};

export { reducer, SelectedApplicationStore };

export default reducer;
