import * as React from "react";

import {
  Operations,
  operations,
} from "@app/state/ducks/messages/conversation/operations";
import { RouteComponentProps } from "@app/types";
import { withRouter } from "@app/helpers/hooks";

import { Conversation as ConversationComponent } from "../components";
import Message from "@app/models/messages";
import { State } from "@app/state";
import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";
import User from "@app/models/user";
import { GroupedPayload } from "@app/state/ducks/messages/threads/types";

type OwnProps = Record<string, never>;
type DispatchProps = {
  operations?: Operations;
};
type StateProps = {
  messages?: Message[];
  threads?: GroupedPayload;
  loading?: boolean;
  user?: User;
};
type ConversationProps = RouteComponentProps &
  DispatchProps &
  StateProps &
  OwnProps;

class PureConversation extends React.Component<ConversationProps> {
  private get jobUuid(): string {
    return this.props.match.params["jobuuid"];
  }

  private get userUuid(): string {
    return this.props.match.params["useruuid"];
  }

  private get isNew(): string {
    return this.props.match.params["new"];
  }

  componentDidUpdate(prevProps: ConversationProps) {
    const lastUserUuid: string = prevProps.match.params["useruuid"];
    const lastJobUuid: string = prevProps.match.params["jobuuid"];
    if (lastUserUuid !== this.userUuid || lastJobUuid !== this.jobUuid) {
      this.fetchThread();
    }
  }

  componentDidMount() {
    if (this.jobUuid && this.userUuid) {
      this.fetchThread();
    }
  }

  componentWillUnmount() {
    const { operations } = this.props;
    operations.clear();
  }

  private fetchThread = () => {
    const { operations } = this.props;
    operations.fetch(this.jobUuid, this.userUuid);
  };

  private createMessage = (message: string): Promise<any> => {
    const { operations } = this.props;
    if (!this.jobUuid || !this.userUuid) {
      return Promise.resolve();
    }
    return operations.create(this.jobUuid, this.userUuid, message);
  };

  render(): React.ReactElement<any> {
    const { messages, loading, user, threads } = this.props;
    return (
      <ConversationComponent
        onSubmit={this.createMessage}
        threads={threads}
        messages={messages}
        loading={loading}
        new={!!this.isNew}
        user={user}
      />
    );
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    threads: state.messages.threads.data,
    messages: state.messages.conversation.data,
    loading: state.messages.conversation.loading,
    user: state.user,
  };
};

const ConnectedConversation = connect(
  mapStateToProps,
  mapOperationsToDispatchProps(operations),
)(PureConversation);
const Conversation = withRouter(ConnectedConversation);

export { Conversation };
export default Conversation;
