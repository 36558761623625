import { clear, fetchError, fetchSuccess, fetching } from "./actions";

import Application from "@app/models/application";
import { Dispatch } from "redux";
import Pagination from "@app/models/pagination";
import { get } from "@app/helpers/api";

const retrieveJobApplications = (offset = 0, limit = 10): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(fetching());
    get(
      `/jobs/applications?offset=${offset}&limit=${limit}&omit=student`,
      Application,
    )
      .then((data: Pagination<Application>) => {
        dispatch(fetchSuccess(data));
      })
      .catch(() => {
        dispatch(fetchError());
      });
  };
};

const clearApplications = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch(clear());
  };
};

type Operations = {
  retrieveJobApplications: (offset?: number, limit?: number) => any;
  clearApplications: () => void;
};

const operations: Operations = {
  retrieveJobApplications,
  clearApplications,
};

export { retrieveJobApplications, operations, Operations };
