import React, { FunctionComponent } from "react";
import styles from "./styles.modules.css";

import { Container } from "@app/components/container";

interface Props {
  content?: React.ReactElement;
  actions?: React.ReactElement;
  sidebarContent?: React.ReactElement;
  dataCy?: string;
}

const ModalContent: FunctionComponent<Props> = ({
  content,
  actions,
  sidebarContent,
  dataCy,
}) => {
  return (
    <Container grid="span-medium" className={styles.container} dataCy={dataCy}>
      <div>
        <div className={styles.content}>{content}</div>
      </div>
      {(actions || sidebarContent) && (
        <div>
          {actions && <div className={styles.actions}>{actions}</div>}
          {sidebarContent && (
            <div className={styles.sidebarContent}>{sidebarContent}</div>
          )}
        </div>
      )}
    </Container>
  );
};

export { ModalContent };
export default ModalContent;
