import * as React from "react";
import styles from "./styles.modules.css";
import * as yup from "yup";

import {
  ActionBar,
  Field,
  Form,
  Heading,
  Input,
  Loading,
  Modal,
  SelectOption,
  Text,
} from "@ribit/components";
import {
  Operations,
  operations,
} from "@app/state/ducks/job-application/operations";

import Application from "@app/models/application";
import { Button } from "@app/components/button";
import { ArrayCount } from "@app/components/array-count";
import Job from "@app/models/job";
import { ModalSelect } from "@app/components/modal-select";
import Skill from "@app/models/skill";
import { State } from "@app/state";
import { connect } from "react-redux";
import { lookup } from "@app/helpers/api";
import { mapOperationsToDispatchProps } from "@ribit/lib";
import { Group as RadioGroup } from "@app/components/radio";

type OwnProps = {
  show?: boolean;
  job?: Job;
  application?: Application;
  onCancel: () => void;
};

type DispatchProps = {
  operations?: Operations;
};

type StateProps = {
  saving?: boolean;
};

type JobApplicationProps = DispatchProps & StateProps & OwnProps;

const PureJobApplication: React.FunctionComponent<JobApplicationProps> = (
  props: JobApplicationProps,
) => {
  const { show, job, onCancel, saving, application } = props;
  let initialData: object = {};
  let skills: SelectOption[] = [];
  if (application) {
    initialData = {
      coverLetter: application.coverLetter,
      skills: application.skills,
      interviewAnswer: application.interviewAnswer,
    };
    skills = application.skills.map(skill => {
      return { value: skill, label: skill.name };
    });
  }
  const sendLabel =
    application && application.uuid ? "Edit application" : "Send application";

  return (
    <Modal className={styles.outer} isOpen={show}>
      <Heading text="Apply for this role" />
      <Form
        initial={initialData}
        onSubmit={values => {
          const skillIds = (values.skills || []).map(skill => skill.id);
          const payload = {
            job: job.uuid,
            coverLetter: values.coverLetter,
            interviewAnswer: values.interviewAnswer,
            skills: skillIds,
          };
          const { apply, edit } = props.operations;
          if (application) {
            return edit(application, payload);
          } else {
            return apply(payload);
          }
        }}
        validateSchema={yup.object().shape({
          skills: yup
            .array()
            .required("You must select some skills, but no more than 6")
            .min(1, "Must select at least one skill")
            .max(6, "Must not select more than 6 skills"),
          coverLetter: yup
            .string()
            .required(
              "You must answer why you're the best candidate for the role",
            ),
          interviewAnswer: yup
            .string()
            .required("You must answer the interview question"),
        })}
      >
        {formProps => {
          const hasAgreed =
            formProps &&
            formProps.values &&
            formProps.values.agreedEligible === true;
          const canSubmit =
            (job && job.type.isCourseCredit && hasAgreed) ||
            (job && !job.type.isCourseCredit && formProps.isDirty);

          return (
            <>
              <ModalSelect
                itemsName="Skills"
                onChange={formProps.valueChanged}
                name="skills"
                value={formProps.values.skills}
                modalTitle="Which of your skills apply to this job?"
                label="Which of your skills apply to this job?"
                required
                help={<ArrayCount array={formProps.values.skills} max={6} />}
                options={skills}
                modalCallback={lookup(
                  Skill,
                  "/skills",
                  (skills: Skill[]) => {
                    return skills.map(skill => {
                      return {
                        label: skill.name,
                        value: skill,
                      };
                    });
                  },
                  0,
                  100,
                )}
                async={{
                  loadingText: "Retrieving skills...",
                  callback: lookup(Skill, "/skills", (skills: Skill[]) => {
                    return skills.map(skill => {
                      return {
                        label: skill.name,
                        value: skill,
                      };
                    });
                  }),
                }}
                placeholder="Select some skills..."
                actionLabel="View all"
              />
              <Field
                label="Why are you the best candidate for this job?"
                required
              >
                <Input
                  name="coverLetter"
                  lines={5}
                  type="text"
                  placeholder="I'm the best candidate for this job because..."
                />
              </Field>
              {job && job.interviewQuestion ? (
                <Field label={job.interviewQuestion} required>
                  <Input
                    name="interviewAnswer"
                    lines={5}
                    type="text"
                    placeholder="Answer interview question here..."
                  />
                </Field>
              ) : (
                <></>
              )}
              {job && job.type.isCourseCredit ? (
                <>
                  <Text>
                    Please do not apply for this role until you have received
                    confirmation from your university that this is a suitable
                    role for your program/degree and you are eligible to apply.
                  </Text>
                  <br />
                  <Field
                    required
                    label="I’m eligible to apply for this position"
                  >
                    <RadioGroup
                      name="agreedEligible"
                      options={[
                        { label: "I agree", value: true },
                        { label: "I do not agree", value: false },
                      ]}
                    />
                  </Field>
                </>
              ) : (
                <></>
              )}
              <ActionBar layout="center">
                <Button
                  label="Cancel"
                  action={onCancel}
                  style="tertiary"
                  disabled={saving}
                />
                <Button
                  type="submit"
                  label={saving ? <Loading /> : sendLabel}
                  style="secondary"
                  disabled={!canSubmit}
                />
              </ActionBar>
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state: State): StateProps => {
  return {
    saving: state.jobApplication.loading,
  };
};

const JobApplication = connect(
  mapStateToProps,
  mapOperationsToDispatchProps(operations),
)(PureJobApplication);

export { JobApplication };
export default JobApplication;
