import { Thread } from "@app/models/messages";

const Types = {
  FETCHING: "@@manage/messages/threads/FETCHING",
  FETCH_SUCCESS: "@@manage/messages/threads/FETCH_SUCCESS",
  FETCH_ERROR: "@@manage/messages/threads/FETCH_ERROR",
  CLEAR: "@@manage/messages/threads/CLEAR",
  CREATE: "@@manage/messages/threads/CREATE",
  CREATE_SUCCESS: "@@manage/messages/threads/CREATE_SUCCESS",
};

type Payload = {
  loading: boolean;
  data: Thread[];
};

type GroupedPayload = {
  [k: string]: Thread[];
};

export { Payload, GroupedPayload, Types };

export default Types;
