import * as React from "react";
import styles from "./styles.modules.css";

import {
  Operations,
  operations,
} from "@app/state/ducks/search-alerts/operations";

import { Alert } from "@app/components/search-alert";
import { Modal } from "@ribit/components";
import { SearchAlert as SearchAlertModel } from "@app/models/search-alert";
import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";

type OwnProps = {
  visible?: boolean;
  close: () => any;
  searchAlert: SearchAlertModel;
};
type DispatchProps = {
  operations?: Operations;
};
type SearchAlertProps = OwnProps & DispatchProps;

const PureSearchAlert: React.FunctionComponent<SearchAlertProps> = (
  props: SearchAlertProps,
) => {
  const { visible, close, searchAlert, operations } = props;
  if (!visible) {
    return null;
  }
  return (
    <Modal isOpen={visible} onClose={close}>
      <div className={styles.outer}>
        <Alert
          onSuccess={(searchAlert: SearchAlertModel) => {
            if (!operations) {
              return;
            }

            if (searchAlert.uuid) {
              operations.update(searchAlert).then(() => {
                close();
                operations.fetch();
              });
            } else {
              operations
                .create(
                  searchAlert.name,
                  searchAlert.interval,
                  searchAlert.criteria,
                )
                .then(() => {
                  close();
                  operations.fetch();
                });
            }
          }}
          onCancel={() => {
            if (!operations) {
              return;
            }
            operations.remove(searchAlert.uuid).then(() => {
              close();
              operations.fetch();
            });
          }}
          searchAlert={searchAlert}
        />
      </div>
    </Modal>
  );
};

const SearchAlert = connect(
  null,
  mapOperationsToDispatchProps(operations),
)(PureSearchAlert);

export { SearchAlert };
export default SearchAlert;
