import { CustomFooter } from "@app/components/custom-footer/custom-footer";
import { CustomFooterData } from "@app/models/custom-footer-data";
import React from "react";

/* This is a temporary component for the custom SAS footer. Eventually custom UI data will be sourced from the API
 * via the tenants endpoint */
const sasFooterData: CustomFooterData = {
  template: "columns-with-ribbon",
  mainContent: {
    accentColor: "#1D3D65",
    backgroundColor: "#fff",
    textColor: "#222",
    navigationColumns: [
      {
        title: "Connect",
        links: [
          {
            text: "Student Program",
            url:
              "https://www.sas.com/en_au/academic/overview.html#student-connection-program-tb",
          },
          { text: "Contact", url: "https://www.sas.com/en_au/contact.html" },
          { text: "Blogs", url: "http://blogs.sas.com/" },
          { text: "Events", url: "https://www.sas.com/en_au/events.html" },
          { text: "News", url: "https://www.sas.com/en_au/news.html" },
        ],
      },
      {
        title: "Insights On",
        links: [
          {
            text: "Analytics",
            url: "https://www.sas.com/en_au/insights/analytics.html",
          },
          {
            text: "Big Data",
            url: "https://www.sas.com/en_au/insights/big-data.html",
          },
          {
            text: "Data Management",
            url: "https://www.sas.com/en_au/insights/data-management.html",
          },
          {
            text: "Marketing",
            url: "https://www.sas.com/en_au/insights/marketing.html",
          },
          {
            text: "Risk & Fraud",
            url: "https://www.sas.com/en_au/insights/risk-fraud.html",
          },
        ],
      },
      {
        title: "Quick Links",
        links: [
          { text: "Careers", url: "https://www.sas.com/en_au/careers.html" },
          {
            text: "White Papers",
            url: "https://www.sas.com/en_au/whitepapers.html",
          },
          { text: "Webinars", url: "https://www.sas.com/en_au/webinars.html" },
          {
            text: "Resource Centre",
            url: "https://www.sas.com/en_au/resource-center.html",
          },
          {
            text: "E-Newsletter",
            url: "https://www.sas.com/en_au/news/e-newsletters.html",
          },
        ],
      },
    ],
  },
  ribbon: {
    backgroundColor: "#1D3D65",
    textColor: "white",
    secondaryLinks: [
      {
        text: "Privacy Statement",
        url: "https://www.sas.com/en_au/legal/privacy.html",
      },
      {
        text: "Terms of Use",
        url: "https://www.ribit.net/sas-terms-conditions/",
      },
      { text: "© 2020 SAS Institute Inc. All Rights Reserved", url: null },
    ],
    socialLinks: [
      {
        title: "Facebook",
        icon: "/sas/img/social/facebook.png",
        linkUrl: "https://www.facebook.com/SASAustNZ",
      },
      {
        title: "Twitter",
        icon: "/sas/img/social/twitter.png",
        linkUrl: "https://twitter.com/sas_anz",
      },
      {
        title: "LinkedIn",
        icon: "/sas/img/social/linkedin.png",
        linkUrl: "http://www.linkedin.com/company/sas",
      },
      {
        title: "YouTube",
        icon: "/sas/img/social/youtube.png",
        linkUrl: "https://www.youtube.com/user/SASAsiaPacific",
      },
      {
        title: "RSS",
        icon: "/sas/img/social/rss.png",
        linkUrl: "http://www.sas.com/en_au/resource-center/rss.html",
      },
      {
        title: "Instagram",
        icon: "/sas/img/social/instagram.png",
        linkUrl: "https://www.instagram.com/sas_ausnz/",
      },
    ],
  },
};

export const CustomSasFooter = () => <CustomFooter {...sasFooterData} />;
