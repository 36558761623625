import Application from "@app/models/application";
import Pagination from "@app/models/pagination";

const Types = {
  FETCHING: "@@jobApplications/FETCHING",
  FETCH_SUCCESS: "@@jobApplications/FETCH_SUCCESS",
  FETCH_ERROR: "@@jobApplications/FETCH_ERROR",
  CLEAR: "@@jobApplications/CLEAR",
};

type Payload = {
  loading: boolean;
  data: Pagination<Application>;
};

export { Payload, Types };

export default Types;
