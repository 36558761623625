import * as React from "react";
import classNames from "classnames/bind";
import styles from "./styles.modules.css";

const cx = classNames.bind(styles);

type OwnProps = {
  array: any[];
  max: number;
  className?: string;
};

const ArrayCount: React.FunctionComponent<OwnProps> = (props: OwnProps) => {
  const { array, max, className } = props;
  const count: number = array ? array.length : 0;
  const className_: string = cx({
    count: true,
    error: count > max,
    [className]: className,
  });
  return (
    <span className={className_}>
      {count}/{max}
    </span>
  );
};

export { ArrayCount };
export default ArrayCount;
