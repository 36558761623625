import { Payload, Types } from "./types";

import { Action } from "@app/state/types";
import Alert from "@app/models/alert";
import { siteAlertExists } from "./selectors";

const initialState: Alert[] = [];

const reducer = (state = initialState, action: Action<Payload>) => {
  const { type } = action;
  const payload: Payload = action.payload;
  switch (type) {
    case Types.ADD:
      if (!siteAlertExists(state, payload.message)) {
        return [...state, payload];
      }
      return state;
    case Types.CLEAR:
      return initialState;
    default:
      return state;
  }
};

export { reducer };

export default reducer;
