import * as React from "react";
import styles from "./styles.modules.css";

import {
  Operations as AlertOperations,
  operations as alertOperations,
} from "@app/state/ducks/site-alerts/operations";
import { Container, Content, Step } from "@ribit/components";
import { JobForm, Review, View } from "./steps";
import { Operations, operations } from "@app/state/ducks/job/operations";
import { RouteComponentProps } from "@app/types";
import { withRouter } from "@app/helpers/hooks";

import EditableJob from "./models";
import { Employer } from "@app/models/employer";
import Job from "@app/models/job";
import { State } from "@app/state";
import { Title } from "@app/components/title";
import { connect } from "react-redux";
import { history } from "@app/state/store";
import { mapOperationsToDispatchProps } from "@ribit/lib";
import { Route, Routes } from "react-router-dom";

type OwnProps = RouteComponentProps & Record<string, never>;
type OwnState = {
  data: EditableJob;
  loading: boolean;
  reviewing: boolean;
};
type StateProps = {
  loading?: boolean;
  job?: Job;
  user?: Employer;
};
type DispatchProps = {
  operations?: {
    job: Operations;
    alerts: AlertOperations;
  };
};

type ManageProps = DispatchProps & StateProps & OwnProps;

class PureManage extends React.Component<ManageProps, OwnState> {
  state: OwnState = {
    reviewing: false,
    data: null,
    loading: true,
  };

  private get uuid(): string {
    const uuid = this.props.match.params["*"].split("/")[0];

    return uuid.indexOf("-") > -1 ? uuid : undefined;
  }

  private get action(): "create" | "edit" | "review" | "manage" {
    const parts = this.props.match.params["*"].split("/");

    if (parts.length > 1) {
      return parts[1];
    }

    if (parts[0] === "create") {
      return "create";
    }

    return "manage";
  }

  private get isNew(): boolean {
    return this.action === "create";
  }

  private get step(): number {
    if (this.state.reviewing) {
      return 2;
    }
    if (this.action === "create") {
      return 0;
    } else if (this.action === "edit") {
      return 1;
    } else if (this.action === "manage") {
      return 3;
    }

    return 0;
  }

  componentDidMount() {
    const { user } = this.props;
    let loading = false;
    let data = null;
    if (!this.isNew) {
      loading = true;
      this._retrieveJob();
    } else {
      data = EditableJob.fromData({
        owner: { company: user.company },
        residencyRequirement: "not_required",
      });
    }
    this.setState({ loading, data });
  }

  private _retrieveJob = () => {
    const { job, alerts } = this.props.operations;
    job
      .retrieveJobByUuid(this.uuid)
      .then((job: Job) => {
        const state = {
          loading: false,
          data: EditableJob.fromData(job),
        };
        this.setState(state);
      })
      .catch(() => {
        alerts.addAlert(
          "Job does not exist, please select a correct job",
          null,
          "Close",
          "error",
        );
        history.push("/dashboard");
      });
  };

  private get stepLabels(): Step[] {
    const { job } = this.props;
    if (job && job.canRepost) {
      return [
        { label: "Modify job details" },
        { label: "Review job post" },
        { label: "Share & hire!" },
      ];
    } else {
      return [
        { label: "Enter job details" },
        { label: "Review job post" },
        { label: "Share & hire!" },
      ];
    }
  }

  renderCreateEditReviewProcess() {
    return (
      <>
        {!this.state.reviewing ? (
          <JobForm
            editing={this.state.data}
            onProgress={(data: any = null) => {
              this.setState({ data, reviewing: true });
              window.scrollTo(0, 0);
            }}
          />
        ) : (
          // @ts-ignore temporary ignore
          <Review
            editing={this.state.data}
            onBack={() => {
              this.setState({ reviewing: false });
            }}
            onProgress={job => {
              this.setState({ reviewing: false });
              history.push(`/manage/jobs/${job.uuid}`);
            }}
          />
        )}
      </>
    );
  }

  render(): React.ReactElement<any> {
    const { job } = this.props;
    let title = "Manage job";
    if (job) {
      title = job.title;
    }
    const employer = job ? job.owner : null;
    if (!this.state.data) {
      return null;
    }
    return (
      <Content>
        <Title label={title} />
        <Container>
          <Step
            className={styles.steps}
            current={this.step}
            steps={this.stepLabels}
          />
          <Routes>
            <Route
              path="/:id/edit"
              element={this.renderCreateEditReviewProcess()}
            />
            <Route
              path="create"
              element={this.renderCreateEditReviewProcess()}
            />
            <Route
              path="*"
              element={
                <View
                  job={job}
                  applicants={null}
                  applicantsLoading={null}
                  shortlist={null}
                  shortlistLoading={null}
                  operations={null}
                  tenant={null}
                  invites={null}
                  invitesLoading={null}
                  currentEmployer={employer}
                />
              }
            />
          </Routes>
        </Container>
      </Content>
    );
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    loading: state.job.loading,
    job: state.job.data,
    user: state.user as Employer,
  };
};

const ConnectedManage = connect(
  mapStateToProps,
  mapOperationsToDispatchProps({ job: operations, alerts: alertOperations }),
)(PureManage);
const Manage = withRouter(ConnectedManage);

export { Manage };
export default Manage;
