import * as React from "react";

import { Button, Icon, Icons, Input } from "@ribit/components";

import { last } from "@ribit/lib";
import styles from "./styles.modules.css";

type OwnProps = {
  name: string;
  addLabel: string;
  value?: any;
  placeholder?: any;
  onChange?: (name: string, value: string[]) => void;
};

const defaultProps = {};
type DefaultProps = Readonly<typeof defaultProps>;

class InputList extends React.Component<OwnProps> {
  static defaultProps: DefaultProps = defaultProps;

  onChange = (inputName: string, inputValue: string) => {
    const { value, name, onChange } = this.props;
    value[inputName] = inputValue;
    onChange(name, value);
  };

  render(): React.ReactElement<any> {
    const { placeholder, addLabel, onChange, name } = this.props;
    let { value } = this.props;
    if (value.length === 0) {
      value = [""];
    }
    const inputs: Input[] = value.map((val, index: number) => {
      return (
        <div key={index} className={styles.item}>
          <Input
            className={styles.input}
            name={index.toString()}
            value={val}
            placeholder={placeholder}
            onChange={this.onChange}
          />
          <Button
            className={styles.remove}
            size="small"
            label={<Icon icon={Icons.Cross} />}
            action={() => {
              const { onChange } = this.props;
              value.splice(index, 1);
              onChange(name, value);
            }}
          />
        </div>
      );
    });

    return (
      <>
        {inputs}
        <Button
          className={styles.add}
          label={addLabel}
          action={() => {
            if (last(value).length === 0) {
              return;
            }
            onChange(name, [...value, ...[""]]);
          }}
          type="text"
        />
      </>
    );
  }
}

export { InputList };
export default InputList;
