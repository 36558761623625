import { StoreEnhancer, applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { createBrowserHistory } from "history";
import reducer from "./ducks";
import { createReduxHistoryContext } from "redux-first-history";
import thunk from "redux-thunk";
import { analyticsMiddleware } from "./middleware/analytics-middleware";

const IS_DEVELOPMENT = true;

const history = createBrowserHistory();

const { routerMiddleware, routerReducer } = createReduxHistoryContext({
  history,
});

const configureStore = () => {
  let middleware: StoreEnhancer = applyMiddleware(
    routerMiddleware,
    thunk,
    analyticsMiddleware,
  );

  if (IS_DEVELOPMENT) {
    const compose = composeWithDevTools({
      trace: true,
      traceLimit: 40,
    });
    middleware = compose(middleware);
  }
  return createStore(reducer(history, routerReducer), middleware);
};

export { history, configureStore };
