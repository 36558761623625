import Invitation from "@app/models/invitation";
import Pagination from "@app/models/pagination";

const Types = {
  FETCHING: "@@jobInvitations/FETCHING",
  FETCH_SUCCESS: "@@jobInvitations/FETCH_SUCCESS",
  FETCH_ERROR: "@@jobInvitations/FETCH_ERROR",
  CLEAR: "@@jobInvitations/CLEAR",
};

type Payload = {
  loading: boolean;
  data: Pagination<Invitation>;
};

export { Payload, Types };

export default Types;
