import { Dispatch } from "redux";
import {
  clear as clearActive,
  fetchError,
  fetchSuccess,
  fetching,
} from "./actions";
import { Course } from "@app/models/job";
import Pagination from "@app/models/pagination";
import { get } from "@app/helpers/api";

const fetch = (): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(fetching());
    get("/partners/courses?limit=200&offset=0", Course)
      .then((data: Pagination<Course>) => {
        dispatch(fetchSuccess(data));
      })
      .catch(() => {
        dispatch(fetchError());
      });
  };
};

const clear = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch(clearActive());
  };
};

type Operations = {
  fetch: () => any;
  clear: () => void;
};

const operations: Operations = {
  fetch,
  clear,
};

export { fetch, operations, Operations };
