import { get } from "./api";

class Location {
  address: string;
  region: string;
}

const addressLookup = (): any => {
  return (query: string) => {
    return new Promise(resolve => {
      const url = `/address?q=${encodeURI(query)}`;
      return get(url, Location)
        .then((response: Location[]) => {
          resolve(
            response.map(result => {
              return { label: result.address, value: result.address };
            }),
          );
        })
        .catch(() => resolve([]));
    });
  };
};

export { Location, addressLookup };
