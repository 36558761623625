import { alertsAdd, alertsClear } from "../site-alerts/actions";
import {
  create,
  createSuccess,
  fetchError,
  fetchSuccess,
  fetching,
  update,
  updateError,
  updateSuccess,
} from "./actions";
import { get, post, put } from "@app/helpers/api";

import { Dispatch } from "redux";
import { createError } from "../messages/conversation/actions";
import CourseCreditApprovalRequest from "@app/models/course-credit-approval-request";
import User from "@app/models/user";

const fetch = (uuid: string): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(fetching());
    return get(
      `/jobs/course-credit-approval-request/${uuid}`,
      CourseCreditApprovalRequest,
    )
      .then(request => {
        dispatch(fetchSuccess(request));
      })
      .catch(() => {
        dispatch(fetchError());
      });
  };
};

type CourseCreditApprovalRequestPayload = {
  job?: string;
  requestedBy?: User;
  message: string;
  supervisorEmail: string;
  university: string;
};

const apply = (data: CourseCreditApprovalRequestPayload): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(create());
    dispatch(alertsClear());
    return post(
      "/jobs/course-credit-approval-request",
      data,
      CourseCreditApprovalRequest,
    )
      .then(request => {
        dispatch(
          alertsAdd(
            "Successfully request approval for the role.",
            null,
            "Close",
            "success",
          ),
        );
        dispatch(createSuccess(request));
      })
      .then(() => {
        dispatch(createError());
      });
  };
};

const edit = (
  request: CourseCreditApprovalRequest,
  data: CourseCreditApprovalRequestPayload,
): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(update());
    dispatch(alertsClear());
    return put(
      `/jobs/course-credit-approval-request/${request.job.uuid}`,
      data,
      CourseCreditApprovalRequest,
    )
      .then((request: CourseCreditApprovalRequest) => {
        // request.coverLetter = app.coverLetter;
        // request.interviewAnswer = app.interviewAnswer;
        // request.skills = app.skills;z
        dispatch(
          alertsAdd(
            "Successfully updated your request.",
            null,
            "Close",
            "success",
          ),
        );
        dispatch(updateSuccess(request));
      })
      .catch(() => {
        dispatch(updateError());
      });
  };
};

type Operations = {
  fetch(uuid: string): void;
  apply(data: CourseCreditApprovalRequestPayload): void;
  edit(
    request: CourseCreditApprovalRequest,
    data: CourseCreditApprovalRequestPayload,
  ): void;
};

const operations: Operations = {
  fetch,
  apply,
  edit,
};

export { operations, Operations };
