import * as React from "react";

import { Account, Confirm } from "@app/pages/account";
import { View as JobView } from "@app/pages/jobs";
import FindJobs from "@app/pages/jobs/find-jobs";
import {
  ForgottenPassword,
  Login,
  Logout,
  ResetPassword,
  SSO,
  Signup,
  Unauthorized,
} from "@app/pages/auth";
import User, { isEmployer, isStudent } from "@app/models/user";

import { View as CompanyView } from "@app/pages/companies";
import { Dashboard } from "@app/pages/dashboard";
import { Error404 } from "@app/pages/error404";
import { FindStudents } from "@app/pages/students";
import { Home } from "@app/pages/home";
import { Kiosk } from "@app/pages/kiosk";
import { Manage } from "@app/pages/manage-jobs";
import { Messages } from "@app/pages/messages";
import { Navigate } from "react-router-dom";

const DEFAULT_FAILED_REDIRECT = "/dashboard";

type Route = {
  name?: string;
  altName?: string;
  path: string;
  exact?: boolean;
  component: any;
  showInNavigation?: boolean;
  primaryOnlyTenant?: boolean;
  validation?: (user: User) => boolean;
  failedRedirect?: string;
  children?: Route[];
};

const isEmployerWithMinimum = (user: User): boolean => {
  return isEmployer(user) && user.minimumProfileCompleteness;
};

const isAuthenticated = (user: User): boolean => {
  return user.isAuthenticated;
};

const isAuthenticatedStudentOrEmployer = (user: User): boolean => {
  return user.isAuthenticated && (isEmployer(user) || isStudent(user));
};

const notVerified = (user: User): boolean =>
  user.isAuthenticated && !user.confirmed;

const isGuest = (user: User): boolean => {
  return !user.isAuthenticated;
};

const shouldIncludeRoute = (user: User) => (route: Route): boolean =>
  route.validation ? route.validation(user) : true;

const getRouteForUser = (user: User) => (route: Route): Route => ({
  ...route,
  component: route.validation
    ? route.validation(user)
      ? route.component
      : () => {
          const url = new URL(window.location.href);
          const redirect = url.searchParams.get("redirect");
          const path = redirect
            ? redirect
            : route.failedRedirect || DEFAULT_FAILED_REDIRECT;
          return <Navigate to={path} />;
        }
    : route.component,
});

// Routes that need to have priority over other authenticated routes
const priorityRoutes: Route[] = [
  {
    name: "jobView",
    path: "/jobs/:slug",
    component: () => <JobView />,
  },
];

// Shared authenticated routes
const authenticatedRoutes: Route[] = [
  {
    name: "dashboard",
    path: "/dashboard",
    showInNavigation: true,
    failedRedirect: "/auth/login?blah",
    validation: isAuthenticated,
    component: () => <Dashboard />,
  },
  {
    name: "confirm",
    path: "/account/confirm/:uuid",
    component: () => <Confirm />,
    exact: true,
    validation: notVerified,
  },
  {
    name: "account",
    path: "/account",
    showInNavigation: true,
    validation: isAuthenticated,
    component: () => <Account />,
  },
  {
    name: "messagesConversation",
    path: "/messages/:jobuuid/:useruuid",
    validation: isAuthenticatedStudentOrEmployer,
    exact: true,
    component: () => <Messages />,
  },
  {
    name: "messagesConversation",
    path: "/messages/:jobuuid/:useruuid/new",
    exact: true,
    validation: isAuthenticatedStudentOrEmployer,
    component: () => <Messages />,
  },
  {
    name: "messages",
    path: "/messages",
    showInNavigation: true,
    validation: isAuthenticatedStudentOrEmployer,
    component: () => <Messages />,
  },
];

const authenticatedAuthenticationRoutes: Route[] = [
  {
    name: "signout",
    altName: "Sign Out",
    path: "/auth/logout",
    showInNavigation: true,
    validation: isAuthenticated,
    component: () => <Logout />,
  },
];

// Student specific routes
const studentRoutes: Route[] = [
  {
    name: "jobs",
    path: "/jobs",
    validation: isStudent,
    showInNavigation: true,
    component: () => <FindJobs />,
  },
];

// Employer routes
const employerRoutes: Route[] = [
  {
    name: "jobs",
    path: "/manage/jobs/*",
    exact: true,
    validation: isEmployerWithMinimum,
    component: () => <Manage />,
  },
  {
    name: "students",
    path: "/students/search",
    showInNavigation: true,
    validation: isEmployer,
    component: () => <FindStudents />,
  },
];

const unauthenticatedRoutes: Route[] = [
  {
    name: "signin",
    altName: "Sign In",
    path: "/auth/login",
    component: () => <Login />,
    showInNavigation: true,
    validation: isGuest,
  },
  {
    name: "forgotten-password",
    path: "/auth/forgotten-password",
    component: () => <ForgottenPassword />,
    validation: isGuest,
  },
  {
    name: "reset-password",
    path: "/auth/reset-password/:uuid",
    component: () => <ResetPassword />,
    validation: isGuest,
  },
  {
    name: "signup",
    altName: "Sign Up",
    path: "/auth/signup",
    component: () => <Navigate to="/auth/signup/employer" />,
    exact: true,
    showInNavigation: true,
    validation: isGuest,
  },
  {
    name: "signup-student",
    path: "/auth/signup/*",
    exact: true,
    component: () => <Signup />,
    validation: isGuest,
  },
  {
    name: "signup-employer",
    path: "/auth/signup/*",
    exact: true,
    component: () => <Signup />,
    validation: isGuest,
  },
  {
    name: "sso",
    path: "/auth/sso/:token",
    component: () => <SSO />,
    validation: isGuest,
  },
];

// Public / guest routes
const publicRoutes: Route[] = [
  {
    name: "companyView",
    path: "/companies/:uuid",
    component: () => <CompanyView />,
  },
  {
    name: "kiosk-register",
    path: "/kiosk/:type",
    component: () => <Kiosk />,
  },
  {
    name: "kiosk",
    path: "/kiosk",
    component: () => <Navigate to="/kiosk/student" />,
  },
  {
    name: "events",
    path: "https://www.eventbrite.com.au/o/csiro-data-61-ribitnet-16792121758",
    showInNavigation: true,
    primaryOnlyTenant: true,
    component: null,
  },
  {
    name: "blog",
    path: "https://www.ribit.net/category/blog/",
    component: null,
    primaryOnlyTenant: true,
    showInNavigation: true,
  },
  {
    name: "unauthorized",
    path: "/unauthorized",
    component: () => <Unauthorized />,
  },
  {
    name: "home",
    path: "/",
    exact: true,
    component: () => <Home />,
  },
];

const fallbackRoutes: Route[] = [
  {
    path: "*",
    component: () => <Error404 />,
  },
];

const userRoutes = (
  user?: User,
  excludeInvalid?: boolean,
  primaryOnlyTenant?: boolean,
): Route[] => {
  if (!user) {
    return [];
  } else {
    let routes = priorityRoutes
      .concat(
        authenticatedRoutes,

        isEmployer(user)
          ? employerRoutes
          : isStudent(user)
          ? studentRoutes
          : employerRoutes.concat(studentRoutes),

        publicRoutes,

        authenticatedAuthenticationRoutes,
        unauthenticatedRoutes,

        fallbackRoutes,
      )
      .map(getRouteForUser(user));

    if (excludeInvalid) {
      routes = routes.filter(shouldIncludeRoute(user));
    }
    return routes.filter(route => {
      if (!primaryOnlyTenant && route.primaryOnlyTenant) {
        return false;
      }
      return true;
    });
  }
};

export {
  userRoutes,
  authenticatedRoutes,
  studentRoutes,
  employerRoutes,
  publicRoutes,
  Route,
};
