import * as React from "react";
import { FunctionComponent } from "react";
import { Heading, Size, Text, Button, Loading } from "@ribit/components";
import styles from "./styles.modules.css";

interface Props {
  onDecline: () => void;
  onHome: () => void;
  loading: boolean;
}

export const ViewApplicationDecline: FunctionComponent<Props> = ({
  onDecline,
  onHome,
  loading,
}) => (
  <div className={styles.applicationStep}>
    <Heading size={Size.Large} text={"Decline application?"} />
    <Text>
      This will remove the applicant from your shortlist and send an email
      notification.
    </Text>
    <div className={styles.buttons}>
      <Button style={"tertiary"} action={onHome} label={"Back"} />
      <Button
        style={"secondary"}
        action={loading ? null : onDecline}
        label={!loading ? "Decline Application" : <Loading />}
      />
    </div>
  </div>
);
