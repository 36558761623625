import React from "react";
import * as styles from "./styles.modules.css";
import { Heading, Size } from "@ribit/components";

interface Props {
  heading: string;
  points: string[];
  children?: React.ReactNode;
}

export const PendingCompanyPanelBase = ({
  heading,
  points,
  children,
}: Props) => {
  return (
    <div className={styles.panel}>
      <Heading size={Size.Smaller} className={styles.heading}>
        {heading}
      </Heading>
      <ul>
        {points.map((point, i) => (
          <li key={i}>{point}</li>
        ))}
      </ul>
      {children}
    </div>
  );
};
