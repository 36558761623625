import * as React from "react";
import styles from "./styles.modules.css";
import * as yup from "yup";

import {
  ActionBar,
  Field,
  Form,
  Heading,
  Input,
  Loading,
  Modal,
  Text,
} from "@ribit/components";
import {
  Operations,
  operations,
} from "@app/state/ducks/course-credit-approval-request/operations";

import Job from "@app/models/job";
import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";
import { Button } from "@app/components/button";
import User from "@app/models/user";

type OwnProps = {
  show: boolean;
  job: Job;
  user: User;
  onCancel: () => void;
  onSubmit: () => void;
};

type DispatchProps = {
  operations?: Operations;
};

type StateProps = {
  saving?: boolean;
};

type CourseCreditApprovalRequestProps = DispatchProps & StateProps & OwnProps;

const PureCourseCreditApprovalRequestPopup: React.FunctionComponent<CourseCreditApprovalRequestProps> = (
  props: CourseCreditApprovalRequestProps,
) => {
  const { show, job, onCancel, onSubmit, saving, user } = props;

  return (
    <Modal className={styles.outer} isOpen={show}>
      <Heading text="Send to my supervisor" />
      <Text>
        This is an unpaid role that provides a placement opportunity for
        work-integrated-learning (WIL) course or program credit, and may or may
        not provide a stipend as additional compensation. Before applying for a
        course-credited role, you may need to seek approval to apply from your
        university’s course credit or work-integrated-learning supervisor to
        ensure the role is suitable for your program. Please refer to your
        university’s website and staff before applying for this role. If you
        know your course credit supervisor’s email, fill in this form and we’ll
        send them a message with this role’s details, including the employer’s
        contact details to streamline this process. For more information please
        consult{" "}
        <Button
          key="our FAQs"
          className={styles.link}
          type="text"
          action="https://www.ribit.net/student-faqs/"
          label="our FAQs"
        />{" "}
        and the{" "}
        <Button
          key="unpaid"
          className={styles.link}
          type="text"
          action="https://www.fairwork.gov.au/ArticleDocuments/723/Unpaid-work.pdf.aspx"
          label="Unpaid Work Fact Sheet"
        />
        .
      </Text>
      <br />
      <Form
        initial={{}}
        onSubmit={values => {
          const payload = {
            job: job.uuid,
            requestedBy: user.uuid,
            supervisorEmail: values.supervisorEmail,
            message: values.message,
            university: values.university,
          };
          const { apply } = props.operations;
          return apply(payload).then(onSubmit);
        }}
        validateSchema={yup.object().shape({
          supervisorEmail: yup
            .string()
            .email("Must enter a valid email address")
            .required("Enter supervisor email"),
          university: yup
            .string()
            .required("Enter university")
            .max(100, "Name is too long"),
          message: yup
            .string()
            .required("Enter message")
            .max(2000, "Message is too long"),
          agreedEligible: yup.mixed().oneOf([true], "You must agree"),
        })}
      >
        {formProps => (
          <div data-cy="popup__send_request">
            <Field label="Supervisor email" required>
              <Input name="supervisorEmail" type="email" />
            </Field>
            <Field label="University" required>
              <Input name="university" />
            </Field>
            <Field label="Message" required>
              <Input
                name="message"
                lines={5}
                type="text"
                placeholder="Hello, could you check if this job is an appropriate WIL/Industrial Training/Placement opportunity for my program/degree…. My student number is A12345."
              />
            </Field>
            <ActionBar layout="center">
              <Button
                label="Cancel"
                action={onCancel}
                style="tertiary"
                disabled={saving}
              />
              <Button
                type="submit"
                label={saving ? <Loading /> : "Send this"}
                style="secondary"
                disabled={!formProps.isDirty}
                dataCy="button__send-to-supervisor__confirm"
              />
            </ActionBar>
          </div>
        )}
      </Form>
    </Modal>
  );
};

const mapStateToProps = (): StateProps => {
  // state: State
  // return {
  //   saving: state.requestCourseCreditApproval.loading,
  // };
  return {};
};

const CourseCreditApprovalRequestPopup = connect(
  mapStateToProps,
  mapOperationsToDispatchProps(operations),
)(PureCourseCreditApprovalRequestPopup);

export { CourseCreditApprovalRequestPopup };
export default CourseCreditApprovalRequestPopup;
