import React from "react";
import { CustomFooterData } from "@app/models/custom-footer-data";
import { ColumnsWithRibbonFooter } from "./columns-with-ribbon-footer/columns-with-ribbon-footer";
import { Footer } from "@ribit/components";

type Props = CustomFooterData;

export const CustomFooter = (props: Props) => {
  switch (props.template) {
    case "columns-with-ribbon":
      return <ColumnsWithRibbonFooter {...props} />;

    case "standard":
      return <Footer disclaimer={props.disclaimer} />;
  }
};
