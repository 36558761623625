import * as React from "react";
import * as styles from "./styles.modules.css";
import {
  Container,
  Heading,
  Icons,
  Size,
  Text,
  Logo,
  Button,
  Icon,
} from "@ribit/components";

type Social = {
  link: string;
  icon: Icons;
};

type Link = {
  name: string;
  href: string;
};

type Section = {
  title?: string;
  links?: Link[];
};

type OwnProps = {
  socials?: Social[];
  terms?: string;
  privacy?: string;
  sections?: Section[];
  disclaimer?: string;
};

const defaultProps: OwnProps = {
  socials: [
    { link: "https://www.facebook.com/ribitdotnet/", icon: Icons.Facebook },
    { link: "https://twitter.com/ribitdotnet", icon: Icons.Twitter },
    { link: "https://au.linkedin.com/company/ribit.net", icon: Icons.LinkedIn },
    { link: "https://www.instagram.com/ribitdotnet/", icon: Icons.Instagram },
    {
      link: "https://www.youtube.com/channel/UC2StYiqddNIgTk8b-vbPptg",
      icon: Icons.YouTube,
    },
  ],
  sections: [
    {},
    {
      title: "Platform",
      links: [
        { name: "Students", href: "https://www.ribit.net/student-advice/" },
        {
          name: "Employers",
          href: "https://www.ribit.net/category/blog/business-advice/",
        },
        {
          name: "Case Studies",
          href: "https://www.ribit.net/category/blog/success-stories/",
        },
        {
          name: "Employer FAQ",
          href: "https://www.ribit.net/business-faqs/",
        },
        {
          name: "Student FAQ",
          href: "https://www.ribit.net/student-faqs/",
        },
      ],
    },
    {
      title: "Events",
      links: [
        {
          name: "Our events",
          href:
            "https://www.eventbrite.com.au/o/csiro-data-61-ribitnet-16792121758",
        },
      ],
    },
    {
      title: "Company",
      links: [
        { name: "About", href: "https://www.ribit.net/about-us/" },
        { name: "Blog", href: "https://www.ribit.net/category/blog/" },
        { name: "Media", href: "https://www.ribit.net/media/" },
        { name: "Contact", href: "https://www.ribit.net/contact/" },
      ],
    },
  ],
  terms: "https://www.ribit.net/terms-conditions/",
  privacy: "https://www.ribit.net/privacy-policy/",
  disclaimer: null,
};
type DefaultProps = Readonly<typeof defaultProps>;

const Footer: React.FunctionComponent<OwnProps> = (props: OwnProps) => {
  const currentYear: number = new Date().getFullYear();
  const { socials, terms, privacy, sections } = props;
  return (
    <footer className={styles.background}>
      <Container>
        <div className={styles.footerLinks}>
          <div className={styles.info}>
            <Logo brand="ribit" className={styles.ribitLogo} />
            <Text className={styles.grow}>
              Connecting smart students with technical, engineering, analytical,
              business, marketing and research skills to innovative businesses.
            </Text>
            <div className={styles.social}>
              {socials.map(social => (
                <Button
                  key={social.icon}
                  className={styles.link}
                  type="text"
                  action={social.link}
                  label={<Icon icon={social.icon} />}
                />
              ))}
            </div>
          </div>

          {sections.map((section, index) => (
            <div key={index} className={styles.linksSection}>
              {!!section.title && (
                <Heading
                  size={Size.Smallest}
                  text={section.title}
                  className={styles.title}
                />
              )}
              {!!section.links &&
                section.links.map(link => (
                  <Button
                    key={link.href}
                    className={styles.link}
                    type="text"
                    action={link.href}
                    label={link.name}
                  />
                ))}
            </div>
          ))}
        </div>
        <hr className={styles.separator} />
        <div className={styles.secondary}>
          <Logo brand="readygrad" className={styles.ownerLogo} />
          <div className={styles.terms}>
            <Button
              type="text"
              action={terms}
              label="Terms and Conditions"
              className={styles.link}
            />
            <Button
              type="text"
              action={privacy}
              label="Privacy Policy"
              className={styles.link}
            />
          </div>
          <Text>Copyright &copy; {currentYear} ReadyGrad</Text>
        </div>
        {props.disclaimer && (
          <div className={styles.disclaimer}>
            <Text>{props.disclaimer}</Text>
          </div>
        )}
      </Container>
    </footer>
  );
};

(Footer.defaultProps as DefaultProps) = defaultProps;

export { Footer };
export default Footer;
