import { ActionObserverFunc } from "../types";
import Types from "@app/state/ducks/job/types";

export const observeJobPosted: ActionObserverFunc = (action, state) => {
  if (action.type !== Types.CREATE_SUCCESS) return;
  const jobId = action.payload.data.uuid;

  window.analytics.track("JOB_POSTED", {
    jobUuid: jobId,
    tenantUuid: state.tenant.uuid,
    userUuid: state.user.uuid,
  });
};
