import * as React from "react";
import styles from "./styles.modules.css";

import { Attribute, Heading, Photo, Size } from "@ribit/components";

import { FunctionComponent } from "react";
import { Student } from "@app/models/student";

interface Props {
  student: Student;
  blur?: boolean;
}

const Header: FunctionComponent<Props> = ({ student, blur, ...rest }) => (
  <div {...rest} className={styles.container}>
    <div className={styles.picture}>
      <Photo
        blur={blur}
        style="circle"
        size={"large"}
        name={student.fullName}
        url={student.profile.photo}
      />
    </div>
    <div className={styles.who}>
      <Heading size={Size.Medium} text={student.fullName} />
      {student.profile.courseDegree &&
      student.profile.institution &&
      student.profile.institution.name ? (
        <Attribute
          name={student.profile.courseDegree.name}
          value={student.profile.institution.name}
        />
      ) : (
        <Attribute name={"Student"} value={""} />
      )}
    </div>
  </div>
);

export { Header };
export default Header;
