import { Job } from "./job";
import { hydrate } from "@ribit/lib";

class Invitation {
  readonly id: number;
  title: string;
  posted: string;
  slug: string;
  uuid: string;
  location: string;
  employer: string;
  pay: string;
  skills: string;
  hasApplied: boolean;
  createdAt: Date;
  humanCreatedAt: string;
  @hydrate
  job: Job;
}

export { Invitation };
export default Invitation;
