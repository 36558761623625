import "@app/helpers/yup";

import * as React from "react";
import styles from "./styles.modules.css";
import * as yup from "yup";

import {
  ActionBar,
  Button,
  Field,
  Form,
  Heading,
  Input,
  RadioGroup,
  Select,
  Text,
} from "@ribit/components";

import Country from "@app/models/country";
import Layout from "../layout";
import Title from "../title";
import { lookup } from "@app/helpers/api";

type OwnProps = {
  step: number;
  stepTotal: number;
  onSubmit: (values: any, completed: boolean) => void;
};
type OwnState = {
  abnType: number;
  abn: string;
};

class Abn extends React.Component<OwnProps, OwnState> {
  state: OwnState = {
    abnType: null,
    abn: "",
  };

  private _goBack = () => {
    this.setState({ abnType: null });
  };

  private _renderTypeSelection = (): React.ReactElement<any> => {
    const { step, stepTotal } = this.props;
    if (this.state.abnType !== null) {
      return null;
    }
    return (
      <>
        <Title
          text="Confirm your business setup"
          step={`${step}/${stepTotal}`}
        />
        <Text>Please select the option that applies to you.</Text>
        <Field label={null} className={styles.options}>
          <RadioGroup
            name="abnType"
            layout="vertical"
            style="standard"
            options={[
              { value: 0, label: "I am a registered business" },
              { value: 1, label: "I'm not a registered business yet" },
            ]}
          />
        </Field>
        <ActionBar layout="center">
          <Button label="Next" type="submit" style="secondary" />
        </ActionBar>
      </>
    );
  };

  private _renderRegisteredBusiness = (): React.ReactElement<any> => {
    if (this.state.abnType !== 0) {
      return null;
    }
    return (
      <>
        <Heading text="Registered business" className={styles.centered} />
        <Text className={styles.centered}>
          Enter your ABN (Australian Business Number). You can find your ABN
          using the{" "}
          <Button
            type="text"
            action="https://abr.business.gov.au/"
            label="ABR's lookup tool"
          />
        </Text>
        <Field label={null} required className={styles.options}>
          <Input
            name="abn"
            placeholder="Enter your 11-digit ABN"
            format={{
              str: "XX XXX XXX XXX",
              separator: " ",
              characters: "[0-9]",
            }}
          />
        </Field>
        <ActionBar layout="center">
          <Button label="Back" action={this._goBack} style="tertiary" />
          <Button label="Next" type="submit" style="secondary" />
        </ActionBar>
      </>
    );
  };

  private _renderInternationalBusiness = (): React.ReactElement<any> => {
    if (this.state.abnType !== 2) {
      return null;
    }
    return (
      <>
        <Heading text="International business" className={styles.centered} />
        <Text className={styles.centered}>
          Please select your country from the dropdown below and enter your
          company ID.
        </Text>
        <Field label={null} required className={styles.options}>
          <Select
            name="abnData"
            async={{
              loadingText: "Retrieving countries...",
              callback: lookup(
                Country,
                "/countries",
                (countries: Country[]) => {
                  return countries.map(country => {
                    return {
                      label: country.name,
                      value: country.id,
                    };
                  });
                },
              ),
            }}
            placeholder="Select country"
          />
        </Field>
        <Field label={null} required>
          <Input name="abn" placeholder="Your country-relevant company ID" />
        </Field>
        <ActionBar layout="center">
          <Button label="Back" action={this._goBack} style="tertiary" />
          <Button label="Next" type="submit" style="secondary" />
        </ActionBar>
      </>
    );
  };

  private _renderNotRegisteredBusiness = (): React.ReactElement<any> => {
    if (this.state.abnType !== 1) {
      return null;
    }
    return (
      <>
        <Heading text="Unregistered business" className={styles.centered} />
        <Text className={styles.centered}>
          Please explain why you are currently not a registered business
        </Text>
        <Field label={null} required className={styles.options}>
          <Input
            name="abnData"
            lines={4}
            placeholder="I'm not a registered business because..."
          />
        </Field>
        <ActionBar layout="center">
          <Button label="Back" action={this._goBack} style="tertiary" />
          <Button label="Next" type="submit" style="secondary" />
        </ActionBar>
      </>
    );
  };

  private _renderOther = (): React.ReactElement<any> => {
    if (this.state.abnType !== 3) {
      return null;
    }
    return (
      <>
        <Heading text="Other" className={styles.centered} />
        <Text className={styles.centered}>
          Please explain why you are currently not a registered business
        </Text>
        <Field label={null} required className={styles.options}>
          <Input
            name="abnData"
            lines={4}
            placeholder="I'm not a registered business because..."
          />
        </Field>
        <ActionBar layout="center">
          <Button label="Back" action={this._goBack} style="tertiary" />
          <Button label="Next" type="submit" style="secondary" />
        </ActionBar>
      </>
    );
  };

  private _renderForm = (schema: yup.Schema<any>, innerComponent: any) => {
    const { abnType } = this.state;
    return (
      <Form
        initial={this.state}
        validateSchema={schema}
        onSubmit={values => {
          this.setState({ abnType: values.abnType });
          if (abnType !== null) {
            if (values.abn) {
              values.abn = values.abn.replace(/ /g, "");
            }
            if (values.abn && values.abnData) {
              values.abnData = JSON.stringify({
                country: values.abnData,
                code: values.abn,
              });
              values.abn = null;
            }
            if (values.abn === "") {
              values.abn = null;
            }
            this.props.onSubmit(values, false);
          }
        }}
      >
        {props => {
          const { values } = props;
          return <>{innerComponent(values)}</>;
        }}
      </Form>
    );
  };

  render(): React.ReactElement<any> {
    return (
      <Layout>
        {this._renderForm(
          yup.object().shape({
            abnType: yup.mixed().required("You must make a selection"),
          }),
          this._renderTypeSelection,
        )}
        {this._renderForm(
          yup.object().shape({
            abn: yup
              .string()
              .required("This field is required")
              .abn("This ABN is not in the correct format"),
          }),
          this._renderRegisteredBusiness,
        )}
        {this._renderForm(null, this._renderNotRegisteredBusiness)}
        {this._renderForm(null, this._renderInternationalBusiness)}
        {this._renderForm(null, this._renderOther)}
      </Layout>
    );
  }
}

export { Abn };
export default Abn;
