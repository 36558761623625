import { State } from "@app/state";
import { KeyValueObject } from "@app/types/global";
import * as R from "ramda";
import { sanitizePageTitle } from "@app/utils/page-title-util";

type UserType = "STUDENT" | "EMPLOYER";

export const identifyUserFromAppState = (state: State) => {
  const userUuid: string = R.view(R.lensPath(["user", "uuid"]), state);
  const firstName: string = R.view(R.lensPath(["user", "firstName"]), state);
  const lastName: string = R.view(R.lensPath(["user", "lastName"]), state);
  const name = `${firstName} ${lastName}`;
  const gender: string = R.view(
    R.lensPath(["user", "profile", "gender"]),
    state,
  );
  const userTypeNumber = R.view(R.lensPath(["user", "userType"]), state);
  const userType = userTypeNumber === 1 ? "STUDENT" : "EMPLOYER";
  const afqLevel: string = R.view(
    R.lensPath(["user", "profile", "afqLevel", "name"]),
    state,
  );
  const companyName: string = R.view(
    R.lensPath(["user", "company", "name"]),
    state,
  );
  const courseDegree: string = R.view(
    R.lensPath(["user", "profile", "courseDegree", "name"]),
    state,
  );
  const email: string = R.view(R.lensPath(["user", "email"]), state);
  const institutionName: string = R.view(
    R.lensPath(["user", "profile", "institution", "name"]),
    state,
  );
  const isInternationalStudent: boolean = R.view(
    R.lensPath(["user", "profile", "internationalStudent"]),
    state,
  );
  const postcode: string = R.view(
    R.lensPath(["user", "profile", "postcode"]),
    state,
  );
  const profilePercentageComplete: number = R.view(
    R.lensPath(["user", "profile", "percentageComplete"]),
    state,
  );
  const tenantName: string = R.view(
    R.lensPath(["user", "tenant", "name"]),
    state,
  );
  const tenantUuid: string = R.view(
    R.lensPath(["user", "tenant", "uuid"]),
    state,
  );

  const metadata = {
    user_id: userUuid, // eslint-disable-line
    name,
    first_name: firstName, // eslint-disable-line
    last_name: lastName, // eslint-disable-line
    type: userType,
    afq_level: afqLevel, // eslint-disable-line
    company: companyName,
    degree: courseDegree,
    email,
    gender,
    institution: institutionName,
    international: isInternationalStudent,
    postcode,
    profile_percentage_complete: profilePercentageComplete, // eslint-disable-line
    tenant: tenantName,
    tenant_uuid: tenantUuid, // eslint-disable-line
  };

  window.analytics.identify(userUuid, metadata);
};

export const identifyUser = (
  userUuid: string,
  userType: UserType,
  tenantUuid: string,
) => {
  window.analytics.identify(userUuid, { type: userType, tenantUuid });
};

export const resetIdentification = () => {
  window.analytics.reset();
};

export const trackEvent = (eventName: string, metadata: KeyValueObject) => {
  window.analytics.track(eventName, metadata);
};

export const trackPageEvent = (title: string, path: string, state: State) => {
  title = sanitizePageTitle(title);
  const userUuid: string = R.view(R.lensPath(["user", "uuid"]), state);
  const tenantUuid: string = R.view(
    R.lensPath(["user", "tenant", "uuid"]),
    state,
  );
  const userTypeId: number = R.view(R.lensPath(["user", "userType"]), state);
  const userType = userTypeId === 1 ? "STUDENT" : "EMPLOYER";

  window.analytics.page(title, { path, tenantUuid, userUuid, userType });
};
