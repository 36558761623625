const extractNonNull = (data: object): object => {
  return Object.keys(data)
    .filter((value: string) => {
      return data[value];
    })
    .reduce((previousValue: object, currentValue: string) => {
      previousValue[currentValue] = data[currentValue];
      return previousValue;
    }, {});
};

export { extractNonNull };
