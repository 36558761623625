import * as React from "react";

import { GroupedPayload } from "@app/state/ducks/messages/threads/types";
import { Separator } from "@ribit/components";
import { Thread } from "../thread";
import { Thread as ThreadType } from "@app/models/messages";
import User from "@app/models/user";
import { Link } from "react-router-dom";
import * as styles from "./styles.modules.css";

type OwnProps = {
  threads: GroupedPayload;
  user: User;
  activeUuid: string;
  activeJobUuid: string;
};

class Threads extends React.Component<OwnProps> {
  render(): React.ReactElement<any> {
    const { threads } = this.props;
    return (
      <>
        {Object.keys(threads)
          .sort()
          .map(jobTitle => {
            const job = threads[jobTitle][0].job;
            return (
              <React.Fragment key={job.uuid}>
                <Link className={styles.jobTitleLink} to={`/jobs/${job.slug}`}>
                  <Separator text={jobTitle} />
                </Link>

                {this.renderThreads(threads[jobTitle])}
              </React.Fragment>
            );
          })}
      </>
    );
  }

  renderThreads = (threads: ThreadType[]) => {
    const { user, activeUuid, activeJobUuid } = this.props;
    return threads.map(thread => {
      const key = `${thread.toUser.uuid}-${thread.fromUser.uuid}-${thread.job.uuid}`;
      return (
        <Thread
          active={thread.matches(activeUuid, activeJobUuid)}
          message={thread}
          user={user}
          key={key}
        />
      );
    });
  };
}

export { Threads };
export default Threads;
