import { clear, fetchError, fetchSuccess, fetching } from "./actions";
import { get } from "@app/helpers/api";

import { Dispatch } from "redux";
import Pagination from "@app/models/pagination";
import CourseCreditApprovalRequest from "@app/models/course-credit-approval-request";

const retrieveCourseCreditApprovalRequests = (offset = 0, limit = 10): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(fetching());
    get(
      `/jobs/course-credit-approval-request?offset=${offset}&limit=${limit}`,
      CourseCreditApprovalRequest,
    )
      .then((data: Pagination<CourseCreditApprovalRequest>) => {
        dispatch(fetchSuccess(data));
      })
      .catch(() => {
        dispatch(fetchError());
      });
  };
};

const clearCourseCreditApprovalRequests = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch(clear());
  };
};

type Operations = {
  retrieveCourseCreditApprovalRequests: (
    offset?: number,
    limit?: number,
  ) => any;
  clearCourseCreditApprovalRequests: () => void;
};

const operations: Operations = {
  retrieveCourseCreditApprovalRequests,
  clearCourseCreditApprovalRequests,
};

export {
  retrieveCourseCreditApprovalRequests,
  clearCourseCreditApprovalRequests,
  operations,
  Operations,
};
