import { History } from "history";
import active from "./manage/active";
import closed from "./manage/closed";
import ccRoles from "./manage/ccRoles";
import closedCCRoles from "./manage/closedCCRoles";
import courses from "./manage/courses";
import { combineReducers } from "redux";
import company from "./company";
import conversation from "./messages/conversation";
import findJobFilters from "./find-jobs-filters";
import history from "./history";
import invites from "./manage/invites";
import job from "./job";
import jobApplication from "./job-application";
import jobApplications from "./job-applications";
import courseCreditApprovalRequests from "./course-credit-approval-requests";
import jobInvitations from "./job-invitations";
import jobs from "./jobs";
import manageApplications from "./manage/applications";
import matches from "./students/matches";
import resetForgottenPassword from "./reset-forgotten-password";
import selectedApplication from "./selected-application";
import shortlist from "./manage/shortlist";
import similar from "./students/similar";
import siteAlerts from "./site-alerts";
import searchAlerts from "./search-alerts";
import tenant from "./tenant";
import threads from "./messages/threads";
import user from "./user";

const reducer = (_history: History, routerReducer) =>
  combineReducers({
    history: history(_history),
    router: routerReducer,
    user,
    jobs,
    findJobFilters,
    jobInvitations,
    courseCreditApprovalRequests,
    selectedApplication,
    jobApplications,
    siteAlerts,
    tenant,
    job,
    company,
    resetForgottenPassword,
    messages: combineReducers({
      threads,
      conversation,
    }),
    jobApplication,
    manage: combineReducers({
      ccRoles: ccRoles,
      closedCCRoles: closedCCRoles,
      courses: courses,
      active: active,
      closed: closed,
      applications: manageApplications,
      invites,
      shortlist,
    }),
    students: combineReducers({
      matches,
      similar,
    }),
    searchAlerts,
  });

export { reducer };

export default reducer;
