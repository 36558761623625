import Types, { Payload } from "./types";

import { Action } from "@app/state/types";
import Pagination from "@app/models/pagination";
import { Student } from "@app/models/student";

const fetchSuccess = (data: Pagination<Student>): Action<Payload> => {
  return {
    type: Types.FETCH_SUCCESS,
    payload: {
      loading: false,
      data: data,
    },
  };
};

const fetching = (): Action<Payload> => {
  return {
    type: Types.FETCHING,
    payload: {
      loading: true,
      data: null,
    },
  };
};

const clear = (): Action<Payload> => {
  return {
    type: Types.CLEAR,
    payload: {
      loading: false,
      data: { count: 0, results: [] },
    },
  };
};

export { fetchSuccess, fetching, clear };
