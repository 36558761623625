import * as React from "react";

import User, { isEmployer, isPartner, isStudent } from "@app/models/user";

import { Content } from "@ribit/components";
import { Employer } from "./employer";
import { State } from "@app/state";
import { Student } from "./student";
import { Title } from "@app/components/title";
import { connect } from "react-redux";
import { History } from "history";
import { Partner } from "./partner";

type OwnProps = Record<string, never>;
type StateProps = {
  user: User;
  history: History;
};
type DashboardProps = OwnProps & StateProps;

const PureDashboard: React.FunctionComponent<DashboardProps> = (
  props: DashboardProps,
) => {
  const { user } = props;
  let dashboardElement: any = null;
  if (isEmployer(user)) {
    dashboardElement = <Employer />;
  } else if (isStudent(user)) {
    dashboardElement = <Student history={props.history} />;
  } else if (isPartner(user)) {
    dashboardElement = <Partner />;
  }
  return (
    <Content>
      <Title label="Your Dashboard" />
      {dashboardElement}
    </Content>
  );
};

const mapStateToProps = (state: State): StateProps => {
  return {
    user: state.user,
    history: state.history,
  };
};

const Dashboard = connect(mapStateToProps, null)(PureDashboard);

export { Dashboard };
export default Dashboard;
