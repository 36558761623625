import * as React from "react";
import * as commonStyles from "../styles.modules.css";
import * as yup from "yup";

import { ActionBar, Button, Field, Form, Text } from "@ribit/components";
import { Operations, operations } from "@app/state/ducks/user/operations";

import Layout from "../layout";
import { PhotoUpload } from "@app/components/photo-upload";
import Title from "../title";
import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";

type OwnProps = {
  step: number;
  stepTotal: number;
  onSubmit: (values: any, completed: boolean) => void;
};
type DispatchProps = {
  operations: Operations;
};
type AddYourLogoProps = OwnProps & DispatchProps;

const PureAddYourLogo: React.FunctionComponent<AddYourLogoProps> = (
  props: AddYourLogoProps,
) => {
  const { onSubmit, step, stepTotal, operations } = props;
  return (
    <Layout>
      <Form
        validateSchema={yup.object().shape({
          logo: yup
            .mixed()
            .required("You must supply a logo or skip this step."),
        })}
        onSubmit={values => {
          return operations.uploadProfilePhoto(values.logo).then(() => {
            onSubmit({}, false);
          });
        }}
      >
        <Title text="Add your logo" step={`${step}/${stepTotal}`} />
        <Text className={commonStyles.content}>
          Upload your business logo to help applicants identify your brand.
          Recommended logo size: 350x350 pixels. Required file type: JPG or PNG.
        </Text>
        <Field label={null}>
          <PhotoUpload
            name="logo"
            message="Click or drag-and-drop your image to upload"
          />
        </Field>
        <ActionBar layout="center" className={commonStyles.actionBar}>
          <Button
            label="Skip"
            action={() => onSubmit(null, false)}
            style="tertiary"
          />
          <Button label="Next" action={null} type="submit" style="secondary" />
        </ActionBar>
      </Form>
    </Layout>
  );
};

const AddYourLogo = connect(
  null,
  mapOperationsToDispatchProps(operations),
)(PureAddYourLogo);

export { AddYourLogo };
export default AddYourLogo;
