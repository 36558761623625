import { Middleware } from "redux";
import { State, Action } from "../types";
import {
  observeEmployerSignUp,
  observeStudentSignUp,
  observeProfileUpdate,
} from "../observers/analytics/onboarding-observers";
import { pageViewObserver } from "../observers/analytics/page-observers";
import {
  observeUserLogin,
  observeUserLogout,
} from "../observers/analytics/login-observers";
import { ActionObserverFunc } from "../observers/types";
import { observeJobPosted } from "../observers/analytics/job-observers";

const observers: ActionObserverFunc[] = [
  observeEmployerSignUp,
  observeStudentSignUp,
  pageViewObserver,
  observeUserLogin,
  observeProfileUpdate,
  observeJobPosted,
  observeUserLogout,
];

export const analyticsMiddleware: Middleware<unknown, State, any> = store => {
  return next => (action: Action<any>) => {
    const appliedAction = next(action);

    const newState = store.getState();
    observers.forEach(observe => {
      observe(action, newState);
    });

    return appliedAction;
  };
};
