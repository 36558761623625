import { clone, deserialize, isString } from "@ribit/lib";

import EditableJob from "./models";
import { Employer } from "@app/models/employer";
import Job from "@app/models/job";

const toNameObject = (data: object, key: string, attr: string): void => {
  if (!data[key]) {
    data[key] = {};
    data[key][attr] = null;
  }
  if (isString(data[key])) {
    const value: any = data[key];
    data[key] = {};
    data[key][attr] = value;
  }
};

const createTemporaryJob = (editing: EditableJob, user: Employer): Job => {
  const owner: Employer = deserialize(Employer, clone(user) as any);
  const job: Job = deserialize(Job, editing);
  const isCourseCredit = job.type.isCourseCredit;

  job.canRepost = true;
  job.owner = owner;
  job.budget = isCourseCredit
    ? editing.courseCreditBudget
    : editing.budget.name;
  job.days = editing.days.name;
  job.duration = isCourseCredit
    ? editing.courseCreditDuration.name
    : editing.duration.name;
  job.category = editing.category.name;

  return job;
};

const createEditableJob = (job: Job): EditableJob => {
  const editableJob: EditableJob = job as any;
  editableJob.category = {
    id: null,
    name: job.category,
    icon: null,
    iconName: null,
    alternativeName: null,
  };
  editableJob.budget = { id: null, name: job.budget };
  editableJob.days = { id: null, name: job.days };
  editableJob.duration = { id: null, name: job.duration };
  editableJob.canRepost = true;
  return editableJob;
};

export { toNameObject, createTemporaryJob, createEditableJob };
