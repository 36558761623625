import Types, { Payload } from "./types";

import { Action } from "@app/state/types";
import Pagination from "@app/models/pagination";
import SearchAlert from "@app/models/search-alert";

const fetching = (): Action<Payload> => ({
  type: Types.FETCHING,
  payload: {
    loading: true,
    data: null,
  },
});

const fetchSuccess = (data: Pagination<SearchAlert>): Action<Payload> => ({
  type: Types.FETCH_SUCCESS,
  payload: {
    loading: false,
    data: data,
  },
});

const fetchError = (): Action<Payload> => ({
  type: Types.FETCH_ERROR,
  payload: {
    loading: false,
    data: null,
  },
});

const create = (): Action<Payload> => ({
  type: Types.CREATE,
  payload: {
    loading: true,
    data: null,
  },
});

const createSuccess = (): Action<Payload> => ({
  type: Types.CREATE_SUCCESS,
  payload: {
    loading: false,
    data: null,
  },
});

const createError = (): Action<Payload> => ({
  type: Types.CREATE_ERROR,
  payload: {
    loading: false,
    data: null,
  },
});

const del = (): Action<Payload> => ({
  type: Types.DELETE,
  payload: {
    loading: true,
    data: null,
  },
});

const deleteSuccess = (): Action<Payload> => ({
  type: Types.DELETE_SUCCESS,
  payload: {
    loading: false,
    data: null,
  },
});

const deleteError = (): Action<Payload> => ({
  type: Types.DELETE_ERROR,
  payload: {
    loading: false,
    data: null,
  },
});

export {
  fetching,
  fetchSuccess,
  fetchError,
  create,
  createSuccess,
  createError,
  del,
  deleteSuccess,
  deleteError,
};
