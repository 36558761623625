import * as React from "react";
import styles from "./styles.modules.css";

import { Container, Content, Heading, Size } from "@ribit/components";

import Job from "@app/models/job";
import { Jobs } from "./jobs";
import markdown from "@app/helpers/markdown";

type OwnProps = {
  copy: string;
  subtitle: string;
  jobs: Job[];
};

const MINIMUM_JOBS_ON_HOMEPAGE = 2;

const Body: React.FunctionComponent<OwnProps> = (props: OwnProps) => {
  const { copy, subtitle, jobs } = props;
  return (
    <Content>
      <Container>
        <div className={styles.body}>
          {jobs.length >= MINIMUM_JOBS_ON_HOMEPAGE && <Jobs items={jobs} />}
          <div className={styles.copy}>
            <Heading
              size={Size.Medium}
              text={subtitle}
              className={styles.subtitle}
            />
            {markdown(copy)}
          </div>
        </div>
      </Container>
    </Content>
  );
};

export { Body };
export default Body;
