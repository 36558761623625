import * as React from "react";

import {
  Button,
  Heading,
  Icon,
  Icons,
  Loading,
  Modal,
  Select,
  Size,
  Text,
} from "@ribit/components";
import { FunctionComponent, useEffect, useState } from "react";

import Job from "@app/models/job";
import { State } from "@app/state";
import Student from "@app/models/student";
import { StudentProfile } from "@app/components/student-profile";
import { operations as activeOperations } from "@app/state/ducks/manage/active/operations";
import { connect } from "react-redux";
import { operations as jobOperations } from "@app/state/ducks/job/operations";
import { mapOperationsToDispatchProps } from "@ribit/lib";
import styles from "./styles.modules.css";
import Employer from "@app/models/employer";

interface OwnProps {
  student?: Student;
  currentEmployer: Employer;
  job?: Job;
  blur?: boolean;
  dismissed: (dirty?: boolean) => void;
}

const operations = {
  job: jobOperations,
  active: activeOperations,
};

enum ViewState {
  HOME = "home",
  INVITE = "invite",
}

interface DispatchProps {
  operations?: typeof operations;
}

const mapStateToProps = (state: State) => ({
  active: state.manage.active.data.results,
});

type StateProps = {
  active?: Job[];
};

export const PureViewStudent: FunctionComponent<OwnProps &
  DispatchProps &
  StateProps> = ({
  student: propsStudent,
  blur,
  job,
  dismissed,
  currentEmployer,
  ...rest
}) => {
  // eslint-disable-next-line
  let [student, setStudent] = useState(propsStudent);
  const [viewState, setViewState] = useState<ViewState>(ViewState.HOME);

  const {
    operations: {
      job: { sendInvitation },
      active: { fetch, clear },
    },
    active,
  } = (rest as unknown) as DispatchProps & StateProps;

  const [selectedJob, setSelectedJob] = useState("");

  const [shouldSendInvite, setShouldSendInvite] = useState(false);
  const [sendingInvite, setSendingInvite] = useState(false);

  const goHome = () => setViewState(ViewState.HOME);
  const goInvite = () => setViewState(ViewState.INVITE);

  const sendInvite = () => setShouldSendInvite(true);

  const closeButtonClicked = () => {
    dismissed();
  };

  let jobs = active;
  if (job) {
    jobs = [job];
  }

  useEffect(() => {
    setSelectedJob(selectedJob || (jobs[0] || { uuid: undefined }).uuid);
  }, [jobs]);

  useEffect(() => {
    let requestClosed = false;
    let shouldClose = true;
    if (shouldSendInvite) {
      setSendingInvite(true);
      sendInvitation(selectedJob, student.uuid)
        .catch(() => {
          shouldClose = false;
        })
        .then(() => {
          if (!requestClosed) {
            setSendingInvite(false);
            if (shouldClose) {
              dismissed(true);
            }
          }
          setShouldSendInvite(false);
        });
    }
    return () => (requestClosed = true);
  }, [shouldSendInvite]);

  const selectJob = (_name: string, value: string) => {
    setSelectedJob(value);
  };

  useEffect(() => {
    goHome();
    setSendingInvite(false);
    if (propsStudent) {
      if (!job) {
        fetch();
      }

      // This is like debouncing.
      // In the future, if the dialog animates open and
      // closed, the previous version of the application
      // has to be visible during the exit animation.
      setStudent(propsStudent);
    }

    return clear;
  }, [propsStudent]);

  let content = undefined;

  student = propsStudent || student;
  if (student) {
    if (viewState === ViewState.HOME) {
      const url = new URL(window.location.href);
      url.searchParams.set("uuid", student.uuid);

      content = (
        <StudentProfile
          blur={blur}
          sidebarContent={
            <div>
              <input
                type="text"
                id="copyTarget"
                value={url.toString()}
                style={{ position: "absolute", top: "-99999px" }}
              />
              <Button
                disabled={!currentEmployer.company.approved}
                action={job ? sendInvite : goInvite}
                label={sendingInvite ? <Loading /> : "Invite to apply"}
                style="secondary"
                size="small"
                layout="full-width"
              />
              <Button
                disabled={!currentEmployer.company.approved}
                action={() => {
                  const copyText = document.querySelector("#copyTarget");
                  copyText.select();
                  document.execCommand("copy");
                }}
                label="Copy student link"
                style="tertiary"
                size="small"
                layout="full-width"
              />
              {!currentEmployer.company.approved && (
                <p className={styles.pendingApprovalText}>
                  You can invite students to apply for jobs once your account
                  has been reviewed
                </p>
              )}
            </div>
          }
          student={student}
        />
      );
    } else {
      content = (
        <div className={styles.invite}>
          <Heading size={Size.Large} text={"Invite to apply"} />
          <Text>
            {active.length > 2 ? "What" : "Which"} role would you like to invite{" "}
            {student.firstName} to apply for?
          </Text>
          <Select
            value={selectedJob}
            options={active.map(x => ({ label: x.title, value: x.uuid }))}
            onChange={selectJob}
            name="role"
            placeholder="Choose a role..."
          />
          <div className={styles.buttons}>
            <Button style={"tertiary"} action={goHome} label={"Back"} />
            <Button
              style={selectedJob ? "secondary" : "tertiary"}
              action={selectedJob ? sendInvite : null}
              label={sendingInvite ? <Loading /> : "Send invite"}
            />
          </div>
        </div>
      );
    }
  }

  return (
    <Modal isOpen={!!propsStudent} onClose={closeButtonClicked}>
      <div className={styles.content}>
        <button
          type="button"
          onClick={closeButtonClicked}
          className={styles.close}
        >
          <Icon icon={Icons.Cross} />
        </button>
        {content}
      </div>
    </Modal>
  );
};

export const ViewStudent = connect(
  mapStateToProps,
  mapOperationsToDispatchProps(operations),
)(PureViewStudent);
