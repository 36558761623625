import Types, { Payload } from "./types";

import { Action } from "@app/state/types";

const alertsAdd = (
  message: string,
  action: string,
  actionLabel: string,
  type: any = "error",
): Action<Payload> => {
  return {
    type: Types.ADD,
    payload: {
      message,
      type,
      action,
      actionLabel,
    },
  };
};

const alertsClear = (): Action<Payload> => {
  return {
    type: Types.CLEAR,
    payload: null,
  };
};

export { alertsAdd, alertsClear };
