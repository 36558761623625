import * as React from "react";

import {
  ActionBar,
  Button,
  Loading,
  More,
  Table,
  Tag,
} from "@ribit/components";
import {
  Operations,
  operations,
} from "@app/state/ducks/manage/applications/operations";

import Application from "@app/models/application";
import { EmptyTableView } from "@app/components/empty-table-view";
import Job from "@app/models/job";
import Pagination from "@app/models/pagination";
import { ProfilePhoto } from "@app/components/profile-photo";
import Skill from "@app/models/skill";
import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";
import styles from "./styles.modules.css";

type OwnProps = {
  applications: Pagination<Application>;
  job: Job;
  onSelect: (application: Application) => void;
  loading: boolean;
};

type DispatchProps = {
  operations?: Operations;
};

type ApplicantProps = DispatchProps & OwnProps;

class PureShortlist extends React.Component<ApplicantProps> {
  private _renderName = (application: Application) => {
    const { onSelect } = this.props;
    return (
      <ProfilePhoto
        name={application.student.fullName}
        url={application.student.profile.photo}
        action={() => {
          onSelect(application);
        }}
        subtitle={
          application.student.profile.institution
            ? application.student.profile.institution.name
            : "Unknown"
        }
        style="square"
        size="small"
      />
    );
  };

  private _renderActions = (application: Application) => {
    const { onSelect } = this.props;
    return (
      <Button
        label="View applicant"
        style="tertiary"
        size="small"
        action={() => {
          onSelect(application);
        }}
      />
    );
  };

  private _renderSkills(application: Application) {
    const skills: Skill[] = application.student.profile.skills;
    return (
      <div>
        {skills.map(skill => (
          <Tag label={skill.name} key={skill.id} />
        ))}
      </div>
    );
  }

  private _renderLoadMore(): React.ReactElement<any> {
    const { applications, operations, loading, job } = this.props;
    if (!applications.results.length || !applications.next) {
      return null;
    }

    return (
      <ActionBar layout="center">
        {loading && applications.results.length ? (
          <Loading style="inverted" />
        ) : (
          <More
            label="View more"
            action={() => {
              operations.fetch(job.uuid, applications.results.length);
            }}
          />
        )}
      </ActionBar>
    );
  }

  render(): React.ReactElement<any> {
    const { applications, loading } = this.props;
    return (
      <>
        <Table
          className={styles.table}
          cellClassName={styles.cell}
          showColumns={false}
          columns={[
            { key: "uuid", renderer: this._renderName, width: "35%" },
            { key: "skills", renderer: this._renderSkills },
            { key: "actions", renderer: this._renderActions, width: "15%" },
          ]}
          data={applications.results}
          loading={loading && !applications.results.length}
          defaultLoadingLabel="Retrieving applicants for this job..."
          emptyView={
            <EmptyTableView
              span={3}
              title="Shortlist some students"
              message="You haven't shortlisted any students for this job, try viewing a student who has applied and shortlist them and check your profile is complete."
              action="/students/search"
              actionLabel="Find more students"
            />
          }
        />
        {this._renderLoadMore()}
      </>
    );
  }
}

const Shortlist = connect(
  null,
  mapOperationsToDispatchProps(operations),
)(PureShortlist);

export { Shortlist };
export default Shortlist;
