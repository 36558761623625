import { Action } from "@app/state/types";
import User from "@app/models/user";

export const Types = {
  EMPLOYER_SIGN_UP_SUCCESS: "@@analytics/EMPLOYER_SIGN_UP_SUCCESS",
  EMPLOYER_ONBOARDING_COMPLETED: "@@analytics/EMPLOYER_ONBOARDING_COMPLETED",
  STUDENT_ONBOARDING_COMPLETED: "@@analytics/STUDENT_ONBOARDING_COMPLETED",
  STUDENT_SIGN_UP_SUCCESS: "@@analytics/STUDENT_SIGN_UP_SUCCESS",
};

export const makeEmployerSignUpSuccessAction = (
  user: User,
): Action<{ user: User }> => {
  return {
    type: Types.EMPLOYER_SIGN_UP_SUCCESS,
    payload: {
      user,
    },
  };
};

export const makeStudentSignUpSuccessAction = (
  user: User,
): Action<{ user: User }> => ({
  type: Types.STUDENT_SIGN_UP_SUCCESS,
  payload: {
    user,
  },
});

export const makeEmployerOnboardingCompletedAction = (
  user: User,
): Action<{ user: User }> => ({
  type: Types.EMPLOYER_ONBOARDING_COMPLETED,
  payload: {
    user,
  },
});

export const makeStudentOnboardingCompletedAction = (
  user: User,
): Action<{ user: User }> => ({
  type: Types.STUDENT_ONBOARDING_COMPLETED,
  payload: {
    user,
  },
});
