import { Admin } from "./admin";
import Employer from "./employer";
import Student from "./student";
import { Tenant } from "./tenant";
import { hydrate } from "@ribit/lib";

enum Type {
  Guest,
  Student,
  Employer,
  Admin,
  ContentMgr,
  Recruiter,
  Partner,
}

class User {
  uuid: string;
  email: string;
  password: string;
  userType: Type;
  @hydrate
  tenant: Tenant;
  firstName: string;
  lastName: string;
  token: string;
  @hydrate
  createdAt: Date;
  confirmed?: boolean;

  get fullName(): string {
    if (!this.lastName) {
      return this.firstName;
    }
    return `${this.firstName} ${this.lastName}`;
  }

  get isAuthenticated(): boolean {
    return this.userType !== Type.Guest;
  }

  get percentageComplete(): number {
    return 0;
  }

  get doesNotRequireOnboarding(): boolean {
    return this.minimumProfileCompleteness;
  }

  get minimumProfileCompleteness(): boolean {
    if (!this.isAuthenticated) {
      return true;
    }
    if (this.userType === Type.Partner) {
      return true;
    }
    return false;
  }
}

function isEmployer(user: User): user is Employer {
  return user.userType === Type.Employer;
}

function isStudent(user: User): user is Student {
  return user.userType === Type.Student;
}

function isPartner(user: User): user is Student {
  return user.userType === Type.Partner;
}

function isAdmin(user: User): user is Admin {
  return user.userType === Type.Admin;
}

export { User, Type, isEmployer, isStudent, isAdmin, isPartner };

export default User;
