import SearchAlert, { Criteria } from "@app/models/search-alert";
import { alertsAdd, alertsClear } from "../site-alerts/actions";
import {
  create as createAction,
  createError,
  createSuccess,
  del as delAction,
  deleteError,
  deleteSuccess,
  fetchError,
  fetchSuccess,
  fetching,
} from "./actions";
import { del, get, post, put } from "@app/helpers/api";

import { Dispatch } from "react";
import Pagination from "@app/models/pagination";

const fetch = (): any => (dispatch: Dispatch<any>) => {
  dispatch(fetching());
  return get("/jobs/search-alerts", SearchAlert)
    .then((data: Pagination<SearchAlert>) => {
      dispatch(fetchSuccess(data));
    })
    .catch(() => {
      dispatch(fetchError());
    });
};

const create = (name: string, interval: number, criteria: Criteria): any => (
  dispatch: Dispatch<any>,
) => {
  dispatch(createAction());
  dispatch(alertsClear());
  return post(
    "/jobs/search-alerts",
    { name, interval, criteria: criteria.munge() },
    SearchAlert,
  )
    .then(() => {
      dispatch(
        alertsAdd(
          `Successfully created search alert ${name}`,
          null,
          "Close",
          "success",
        ),
      );
      dispatch(createSuccess());
    })
    .catch(() => {
      dispatch(
        alertsAdd("Unable to create search alert", null, "Close", "error"),
      );
      dispatch(createError());
    });
};

const update = (searchAlert: SearchAlert): any => (dispatch: Dispatch<any>) => {
  dispatch(createAction());
  dispatch(alertsClear());
  const { name, interval, uuid, criteria } = searchAlert;
  return put(
    `/jobs/search-alerts/${uuid}`,
    { name, interval, criteria: criteria.munge() },
    SearchAlert,
  )
    .then(() => {
      dispatch(
        alertsAdd(
          `Successfully updated search alert ${name}`,
          null,
          "Close",
          "success",
        ),
      );
      dispatch(createSuccess());
    })
    .catch(() => {
      dispatch(
        alertsAdd("Unable to update search alert", null, "Close", "error"),
      );
      dispatch(createError());
    });
};

const remove = (uuid: string): any => (dispatch: Dispatch<any>) => {
  dispatch(delAction());
  dispatch(alertsClear());
  return del(`/jobs/search-alerts/${uuid}`)
    .then(() => {
      dispatch(
        alertsAdd(
          "Successfully deleted search alert",
          null,
          "Close",
          "success",
        ),
      );
      dispatch(deleteSuccess());
      return fetch()(dispatch);
    })
    .catch(() => {
      dispatch(
        alertsAdd("Unable to delete search alert", null, "Close", "error"),
      );
      dispatch(deleteError());
    });
};

type Operations = {
  fetch: () => any;
  create: (name: string, interval: number, criteria: Criteria) => any;
  remove: (uuid: string) => any;
  update: (searchAlert: SearchAlert) => any;
};

const operations: Operations = {
  fetch,
  create,
  remove,
  update,
};

export { fetch, operations, Operations };
