import Employer from "./employer";
import Skill from "./skill";
import { hydrate } from "@ribit/lib";
import { ResidencyRequirement } from "@app/pages/manage-jobs/models";

class Course {
  facultyName: string;
  id: string;
  code: string;
  name: string;
  link?: string;
}

class JobReview {
  approvedAt: string;
  createdAt: string;
  rejectedAt: string;
  status: string;
  suitableCourses?: string[];
}

class Type {
  id: number;
  name: string;
  isCourseCredit: boolean;
}

class Budget {
  id: number;
  name: string;
}

class Duration {
  id: number;
  name: string;
}

class Day {
  id: number;
  name: string;
}

class PreApproval {
  tenant: { name: string };
  courses?: Course[];
}

class Job {
  readonly uuid: string;
  address: string;
  days: string;
  description: string;
  interviewQuestion?: string;
  category: string;
  title: string;
  location: string;
  budget: string;
  posted: string;
  jobCategory?: string;
  requirements: string;
  agreedFairwork: boolean;
  @hydrate(Skill)
  skills: Skill[];
  duration: string;
  @hydrate
  type: Type;
  @hydrate
  owner: Employer;
  numberOfStudents?: bigint;
  workingOnsite: string;
  hasDedicatedWorkingSpace: string;
  workplaceAddress: string;
  supervisorJobTitle: string;
  supervisorName: string;
  supervisorPhoneNumber: string;
  supervisorEmail: string;
  readonly isPaid: boolean;
  readonly status: string;
  readonly slug: string;
  readonly firstCreatedAt: Date;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly invites: number;
  readonly views: number;
  readonly applicants: number;
  readonly postedOn: string | null | undefined;
  canRepost: boolean; // Should "technically" be reonly, but required for reposting
  readonly residencyRequirement: ResidencyRequirement;
  jobReview?: JobReview; // Optional review for the logged-in partner (empty for employer & students)
  preApprovals?: PreApproval[]; // Optional partner approved-reviews to list suitable courses to students

  get isClosed(): boolean {
    return this.status === "closed";
  }

  get isOpen(): boolean {
    return this.status === "open";
  }

  get isFilled(): boolean {
    return this.status === "filled";
  }

  get isReopened(): boolean {
    return this.status === "reopened";
  }
}

export { Course, Job, Type, Duration, Day, Budget };
export default Job;
