import User, { isStudent } from "@app/models/user";
import { deserialize, hydrate } from "@ribit/lib";

import Employer from "./employer";
import Job from "@app/models/job";
import Student from "./student";
import { format } from "date-fns";

const typedUser = (user: User): Student | Employer => {
  if (isStudent(user)) {
    return deserialize(Student, user);
  } else {
    return deserialize(Employer, user);
  }
};

class Base {
  @hydrate
  fromUser: User;
  @hydrate
  toUser: User;
  @hydrate
  job: Job;

  public get typedToUser(): Student | Employer {
    return typedUser(this.toUser);
  }

  public get typedFromUser(): Student | Employer {
    return typedUser(this.fromUser);
  }

  public matches(uuid: string, jobUuid: string): boolean {
    return (
      (this.toUser.uuid === uuid || this.fromUser.uuid === uuid) &&
      this.job.uuid === jobUuid
    );
  }

  public recipient(user: User): Student | Employer {
    const recipient: User =
      user.uuid !== this.fromUser.uuid ? this.fromUser : this.toUser;
    return typedUser(recipient);
  }
}

class Message extends Base {
  @hydrate
  createdAt: Date;
  humanCreatedAt: string;
  preview: string;
  body?: string;
  read?: boolean;

  public get formattedCreatedAt(): string {
    return format(new Date(this.createdAt), "EEEE, d MMMM yyyy 'at 'H:mmb");
  }
}

class Thread extends Base {
  unreadCount: number;
  @hydrate
  lastCreatedAt: Date;
  humanLastCreatedAt: string;
  preview: string;
}

export { Message, Thread };

export default Message;
