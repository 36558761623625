import * as React from "react";
import styles from "./styles.modules.css";

import {
  ActionBar,
  Button,
  Heading,
  Loading,
  Select,
  Size,
  Text,
} from "@ribit/components";

import Application from "@app/models/application";
import { FunctionComponent } from "react";
import { Option } from "@ribit/components/src/components/select";
import { Student } from "@app/models/student";
import { lookup } from "@app/helpers/api";

interface Props {
  onClose: (application?: Application, reason?: number) => void;
  onBack: () => void;
  loading: boolean;
  selected?: Student;
  applications: Application[];
}

const email = "admin@ribit.net";
const HIRED_STUDENT_REASON = 2;

export const CloseJob: FunctionComponent<Props> = ({
  onClose,
  onBack,
  loading,
  applications,
  selected,
}) => {
  const options: Option[] = applications.map(x => ({
    label: x.student.fullName,
    value: x,
  }));

  const [selectedApplication, setSelected] = React.useState(
    selected
      ? applications.find(x => x.student.uuid === selected.uuid)
      : undefined,
  );
  const [selectedReason, setReason] = React.useState();

  const select = (_name: string, value: any) => setSelected(value);
  const selectReason = (_name: string, value: any) => setReason(value);

  const closeJob = () => {
    if (selectedApplication && selectedReason === HIRED_STUDENT_REASON) {
      onClose(selectedApplication, selectedReason);
    } else {
      onClose(undefined, selectedReason);
    }
  };

  return (
    <div className={styles.applicationStep}>
      <Heading size={Size.Large} text={"Close job post"} />
      <Text>Reason for closing?</Text>

      <Select
        className={styles.select}
        value={selectedReason}
        onChange={selectReason}
        name="reason"
        placeholder="Choose a reason..."
        async={{
          loadingText: "Retrieving reasons...",
          callback: lookup(
            undefined,
            "/jobs/reasons",
            (reasons: { id: string; reason: string }[]) => {
              return reasons.map(({ id, reason }) => ({
                value: id,
                label: reason,
              }));
            },
          ),
        }}
      />

      {selectedReason === HIRED_STUDENT_REASON && (
        <div className={styles.hireContainer}>
          <span>We hired</span>
          <Select
            value={selectedApplication}
            onChange={select}
            name="Student"
            options={options}
          />
        </div>
      )}

      <ActionBar layout="center">
        <Button style={"tertiary"} action={onBack} label={"Back"} />
        <Button
          style={selectedReason ? "secondary" : "tertiary"}
          action={loading || selectedReason === undefined ? null : closeJob}
          label={!loading ? "Close Job" : <Loading />}
        />
      </ActionBar>

      <Text>
        Thanks for using Ribit. If you have any feedback, contact us at{" "}
        <a className={styles.adminLink} href={"mailto:" + email}>
          {email}
        </a>
        .
      </Text>
    </div>
  );
};
