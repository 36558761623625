import * as React from "react";
import * as yup from "yup";

import {
  ActionBar,
  Button,
  Container,
  Field,
  Form,
  Heading,
  Icons,
  Input,
  Loading,
  Panel,
  Select,
  Size,
  StickyFooterBar,
  Text,
} from "@ribit/components";
import {
  Munger,
  Schema,
  deepMerge,
  isString,
  mapOperationsToDispatchProps,
} from "@ribit/lib";
import { Operations, operations } from "@app/state/ducks/user/operations";

import { Size as CompanySize } from "@app/models/company";
import { DeleteAccount } from "@app/components/delete-account";
import { Employer as EmployerType } from "@app/models/employer";
import Industry from "@app/models/industry";
import { ModalPassword } from "@app/components/modal-password";
import { Option } from "@ribit/components/src/components/select";
import { PanelArrow } from "@app/components/panel-arrow";
import { PhoneNumberInput } from "@app/components/phone-number-input";
import { PhotoUpload } from "@app/components/photo-upload";
import { connect } from "react-redux";
import { lookup } from "@app/helpers/api";
import { prependHttpToUrl } from "@app/helpers/urls";
import styles from "./styles.modules.css";

type OwnProps = {
  user: EmployerType;
};
type DispatchProps = {
  operations?: Operations;
};
type EmployerProps = OwnProps & DispatchProps;
type OwnState = {
  saving: boolean;
  passwordModalIsOpen: boolean;
  deleteAccountModalIsOpen: boolean;
};

const userSchema: Schema = {
  paths: {
    firstName: "",
    lastName: "",
    companyName: "company.name",
    companyWebsite: "company.url",
    linkedInUrl: "company.linkedin",
    companyDescription: "company.description",
    logo: "company.logo",
    abn: "company.abn",
    phoneNumber: "company.phoneNumber",
    industryCode: "company.industryCode.code|company.industryCode",
    companyValues: "company.values",
    companyVision: "company.vision",
    companySize: "company.size.id|company.size",
    studentAttributes: "company.studentAttributes",
  },
};

const extractSizeOption = (user: EmployerType): Option[] => {
  if (user.company.size) {
    const { size } = user.company;
    return [{ label: size.name, value: size.id }];
  }
  return [];
};

const extractIndustryCodeOption = (user: EmployerType): Option[] => {
  if (user.company.industryCode) {
    const { industryCode } = user.company;
    return [{ label: industryCode.name, value: industryCode.code }];
  }
  return [];
};

class PureEmployer extends React.Component<EmployerProps, OwnState> {
  state: OwnState = {
    saving: false,
    passwordModalIsOpen: false,
    deleteAccountModalIsOpen: false,
  };

  triggerModalPassword = () => {
    this.setState({ passwordModalIsOpen: !this.state.passwordModalIsOpen });
  };

  triggerDeleteAccount = () => {
    this.setState({
      deleteAccountModalIsOpen: !this.state.deleteAccountModalIsOpen,
    });
  };

  render(): React.ReactElement<any> {
    const { saving } = this.state;
    const { user, operations } = this.props;
    const munger: Munger = new Munger(userSchema);
    const initial: any = munger.flatten(user);
    if (!initial.abn) {
      initial.abn = "";
    }
    const sizeOptions: Option[] = extractSizeOption(user);
    const industryCodeOptions: Option[] = extractIndustryCodeOption(user);
    return (
      <>
        <Form
          initial={initial}
          validateSchema={yup.object().shape({
            firstName: yup.string().required("First Name is a required field"),
            companyName: yup
              .string()
              .required("Company name is a required field"),
            companyDescription: yup
              .string()
              .required("Company description is a required field"),
            companyWebsite: yup
              .string()
              .required("Company website is a required field")
              .url("The url must be in the correct format"),
            lastName: yup.string().required("Last Name is a required field"),
            phoneNumber: yup
              .string()
              .phoneNumber("Must enter a valid phone number")
              .required("Must enter a phone number"),
            abn: yup.string().abn("This ABN is not in the correct format."),
            industryCode: yup
              .string()
              .nullable()
              .required("Industry code is a required field"),
            companySize: yup
              .string()
              .nullable()
              .required("Company size is a required field"),
          })}
          onFilterValues={values => {
            values.companyWebsite = prependHttpToUrl(values.companyWebsite);
            return values;
          }}
          onError={() => window.scrollTo(0, 100)}
          onSubmit={values => {
            if (saving) {
              return;
            }
            this.setState({ saving: true });
            const updatedData: { [s: string]: any } = deepMerge(
              user,
              munger.expand<any>(values),
            );

            const promises: Promise<any>[] = [];
            if (!isString(values.logo)) {
              promises.push(operations.uploadProfilePhoto(values.logo));
            }
            return Promise.all(promises).then(() => {
              return operations
                .updateProfile(updatedData)
                .then(() => {
                  this.setState({ saving: false });
                })
                .catch(error => {
                  this.setState({ saving: false });
                  throw error;
                });
            });
          }}
        >
          {props => {
            const { isDirty } = props;
            return (
              <>
                <Container grid="medium-span-small">
                  <div>
                    <Field label={null}>
                      <PhotoUpload name="logo" />
                    </Field>
                    <Panel title="Account" icon={Icons.Shield}>
                      <Field required>
                        <Input name="firstName" />
                      </Field>
                      <Field required>
                        <Input name="lastName" />
                      </Field>
                      <Field required>
                        <PhoneNumberInput name="phoneNumber" />
                      </Field>
                      <ActionBar layout="center" className={styles.actions}>
                        <Button
                          style="tertiary"
                          layout="full-width"
                          label="Change password"
                          action={this.triggerModalPassword}
                        />
                        <Button
                          style="secondary"
                          layout="full-width"
                          label="Deactivate Account"
                          action={this.triggerDeleteAccount}
                        />
                      </ActionBar>
                    </Panel>
                  </div>
                  <div>
                    <Panel title="Business Profile" icon={Icons.User}>
                      <Field required label="Business name">
                        <Input name="companyName" placeholder="Acme, Inc." />
                      </Field>
                      <Field required label="Business website">
                        <Input
                          name="companyWebsite"
                          placeholder="https://yourcompany.com"
                        />
                      </Field>
                      <Field label="Business LinkedIn URL">
                        <Input
                          name="linkedInUrl"
                          prefix="linkedin.com/company/"
                          className={styles.linkedin}
                        />
                      </Field>
                      <Field required label="Business Description">
                        <Input
                          name="companyDescription"
                          placeholder="Add a brief overview of your company."
                          lines={8}
                        />
                      </Field>
                      <Field label="Australian Business Number (ABN)">
                        <Input
                          name="abn"
                          placeholder="XX XXX XXX XXX"
                          format={{
                            str: "XX XXX XXX XXX",
                            separator: " ",
                            characters: "[0-9]",
                          }}
                        />
                      </Field>
                      <Field label="Industry" required>
                        <Select
                          name="industryCode"
                          options={industryCodeOptions}
                          async={{
                            loadingText: "Retrieving industries...",
                            threshold: 3,
                            callback: lookup(
                              Industry,
                              "/industries",
                              (industries: Industry[]) => {
                                return industries.map(industry => {
                                  return {
                                    label: industry.name,
                                    value: industry.code,
                                  };
                                });
                              },
                            ),
                          }}
                          placeholder="Select an industry..."
                        />
                      </Field>
                      <Field label="Business size" required>
                        <Select
                          name="companySize"
                          options={sizeOptions}
                          async={{
                            loadingText: "Retrieving sizes...",
                            threshold: 3,
                            callback: lookup(
                              CompanySize,
                              "/companies/sizes",
                              (sizes: CompanySize[]) => {
                                return sizes.map(size => {
                                  return {
                                    label: size.name,
                                    value: size.id,
                                  };
                                });
                              },
                            ),
                          }}
                          placeholder="Select a size..."
                        />
                      </Field>
                    </Panel>
                    <Panel title="Culture" icon={Icons.Suitcase}>
                      <Field label="Business vision">
                        <Input
                          name="companyVision"
                          placeholder="Add your business vision"
                          lines={8}
                        />
                      </Field>
                      <Field label="Business values">
                        <Input
                          name="companyValues"
                          placeholder="Add your business values and culture"
                          lines={8}
                        />
                      </Field>
                      <Field label="I'm looking for students who are...">
                        <Input
                          name="studentAttributes"
                          placeholder="Start typing attributes..."
                        />
                      </Field>
                    </Panel>
                  </div>
                  <div className={styles.helper}>
                    <Panel>
                      <PanelArrow />
                      <Heading size={Size.Small}>Your business profile</Heading>
                      <Text>
                        The info about your organisation will appear alongside
                        your job post.
                      </Text>
                      <Text>
                        We find that including detail about your business
                        mission, values and culture attracts more interest from
                        candidates.
                      </Text>
                      <Text>Your phone number won’t be displayed.</Text>
                    </Panel>
                  </div>
                </Container>
                <StickyFooterBar className={styles.update}>
                  <Button
                    type="submit"
                    label={saving ? <Loading /> : "Update"}
                    style="secondary"
                    className={styles.updateButton}
                    disabled={!isDirty}
                  />
                </StickyFooterBar>
              </>
            );
          }}
        </Form>
        <DeleteAccount
          toggleModal={this.triggerDeleteAccount}
          modalIsOpen={this.state.deleteAccountModalIsOpen}
        />
        <ModalPassword
          name="change-password"
          modalTitle="Change Password"
          toggleModal={this.triggerModalPassword}
          modalIsOpen={this.state.passwordModalIsOpen}
        />
      </>
    );
  }
}

const Employer = connect(
  null,
  mapOperationsToDispatchProps(operations),
)(PureEmployer);

export { Employer };
export default Employer;
