import {
  trackPageEvent,
  identifyUserFromAppState,
  resetIdentification,
} from "@app/analytics/segment";
import { ActionObserverFunc } from "../types";
import * as R from "ramda";

export const pageViewObserver: ActionObserverFunc = (action, state) => {
  if (action.type !== "@@router/LOCATION_CHANGE") return;

  setTimeout(() => {
    const path: string = action.payload.location.pathname;
    trackPageEvent(document.title, path, state);
    const userUuid = R.view(R.lensPath(["user", "uuid"]), state);
    if (userUuid) {
      identifyUserFromAppState(state);
    } else {
      resetIdentification();
    }
  }, 0);
};
