import * as React from "react";
import styles from "./styles.modules.css";

import { ActionBar, Button, Container, Heading } from "@ribit/components";

type OwnProps = {
  title: string;
  backgroundImage: string;
};

const Banner: React.FunctionComponent<OwnProps> = (props: OwnProps) => {
  const { title, backgroundImage } = props;
  return (
    <div
      className={styles.banner}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <Container>
        <Heading text={title} className={styles.tagline} />
        <ActionBar layout="center" className={styles.actions}>
          <Button label="Find Talent" action="/auth/signup/employer" /> or{" "}
          <Button label="Find Opportunities" action="/auth/signup/student" />
        </ActionBar>
      </Container>
    </div>
  );
};

export { Banner };
export default Banner;
