import * as React from "react";
import styles from "./styles.modules.css";

import {
  ActionBar,
  Button,
  CheckboxGroup,
  Field,
  Heading,
  Icon,
  Icons,
  Loading,
  Modal,
  Select,
  Size,
} from "@ribit/components";

import { Option as CheckboxOption } from "@ribit/components/src/components/checkbox";
import { Option as SelectOption } from "@ribit/components/src/components/select";

type OwnProps = {
  name: string;
  label?: string;
  itemsName: string;
  required?: boolean;
  modalTitle: string;
  layout?: "horizontal" | "vertical";
  actionLabel: string;
  value?: any;
  help?: React.ReactElement<any>;
  placeholder?: any;
  options: SelectOption[];
  modalCallback: any;
  async: any;
  loadImmediate?: boolean;
  onChange?: (name: string, value: any, multiple: boolean) => void;
};

type OwnState = {
  modalIsOpen: boolean;
  availableOptions: { [s: string]: CheckboxOption[] };
  cachedOptions: SelectOption[];
};

const defaultProps = {
  layout: "vertical",
};
type DefaultProps = Readonly<typeof defaultProps>;

class ModalSelect extends React.Component<OwnProps, OwnState> {
  static defaultProps: DefaultProps = defaultProps;
  state: OwnState = {
    modalIsOpen: false,
    availableOptions: {},
    cachedOptions: [],
  };

  toggleModal = () => {
    if (!this.state.modalIsOpen) {
      const { modalCallback } = this.props;
      const key = "category";
      modalCallback().then((options: CheckboxOption[]) => {
        const orderedKeys: Set<string>[] = [];
        const groupedOptions: any = options.reduce((previous, current) => {
          const value: any = current.value;
          const group: any = value[key];
          if (!orderedKeys[value.order]) {
            orderedKeys[value.order] = new Set();
          }
          orderedKeys[value.order].add(group);
          if (!previous[group]) {
            previous[group] = [];
          }
          previous[group].push(current);
          return previous;
        }, {});
        const sortedOptions: any = {};
        [].concat(...orderedKeys.map((keys: any) => [...keys])).forEach(key => {
          if (!key) {
            return;
          }
          sortedOptions[key] = groupedOptions[key];
        });
        this.setState({
          availableOptions: sortedOptions,
          cachedOptions: options as any,
        });
      });
    }
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  };

  render(): React.ReactElement<any> {
    const {
      value,
      loadImmediate,
      placeholder,
      actionLabel,
      name,
      label,
      required,
      options,
      async,
      modalTitle,
      itemsName,
      onChange,
      help,
      layout,
    } = this.props;
    const { modalIsOpen, availableOptions, cachedOptions } = this.state;
    const optionValues: SelectOption[] = cachedOptions.length
      ? cachedOptions
      : options;
    const loading: boolean = Object.keys(availableOptions).length === 0;
    const helpText: React.ReactElement<any> = (
      <>
        {help}{" "}
        <Button label={actionLabel} type="text" action={this.toggleModal} />
      </>
    );
    return (
      <>
        <Field
          label={label}
          required={required}
          help={helpText}
          layout={layout}
          className={styles.field}
        >
          <Select
            loadImmediate={loadImmediate}
            name={name}
            options={optionValues}
            placeholder={placeholder}
            async={async}
            multiple
          />
        </Field>
        <Modal isOpen={modalIsOpen} onClose={this.toggleModal}>
          <div className={styles.inner}>
            <Button
              label={<Icon icon={Icons.Cross} />}
              action={this.toggleModal}
              className={styles.close}
            />
            <Heading size={Size.Medium} text={modalTitle} />
            {loading && (
              <Loading style="inverted" label={`Loading ${itemsName}...`} />
            )}
            {!loading && (
              <CheckboxGroup
                name={name}
                value={value}
                options={availableOptions as any}
                className={styles.options}
                onChange={onChange}
              />
            )}
            <ActionBar layout="center" className={styles.action}>
              <Button
                label={`Add ${value ? value.length : 0} ${itemsName}`}
                action={this.toggleModal}
                style="secondary"
              />
            </ActionBar>
          </div>
        </Modal>
      </>
    );
  }
}

export { ModalSelect };
export default ModalSelect;
