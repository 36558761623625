import {
  clear as clearAction,
  createError,
  createSuccess,
  creating,
  fetchError,
  fetchSuccess,
  fetching,
} from "./actions";
import { get, post } from "@app/helpers/api";

import { Dispatch } from "redux";
import Message from "@app/models/messages";

const fetch = (jobUuid: string, userUuid: string): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(fetching());
    return get(`/messages/${jobUuid}/${userUuid}`, Message)
      .then((data: Message[]) => {
        dispatch(fetchSuccess(data));
      })
      .catch(() => {
        dispatch(fetchError());
      });
  };
};

const clear = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch(clearAction());
  };
};

const create = (jobUuid: string, userUuid: string, body: string): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(creating());
    return post(
      "/messages",
      { job: jobUuid, toUser: userUuid, body: body },
      Message,
    )
      .then((data: Message) => {
        dispatch(createSuccess(data));
      })
      .catch(() => {
        dispatch(createError());
      });
  };
};

type Operations = {
  fetch: (jobUuid: string, userUuid: string) => any;
  clear: () => void;
  create: (jobUuid: string, userUuid: string, body: string) => any;
};

const operations: Operations = {
  create,
  fetch,
  clear,
};

export { fetch, clear, create, operations, Operations };
