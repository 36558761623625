import * as React from "react";
import * as commonStyles from "../styles.modules.css";

import {
  ActionBar,
  Button,
  Field,
  Form,
  Input,
  Select,
  Text,
} from "@ribit/components";

import Layout from "../layout";
import Title from "../title";

type OwnProps = {
  step: number;
  stepTotal: number;
  onSubmit: (values: any) => void;
};

const AboutYou: React.FunctionComponent<OwnProps> = (props: OwnProps) => {
  const { onSubmit, step, stepTotal } = props;
  return (
    <Layout>
      <Form
        onSubmit={values => {
          return onSubmit(values);
        }}
      >
        <Title text="A bit more info" step={`${step}/${stepTotal}`} />
        <Text className={commonStyles.content}>
          These details are kept private and are not shown to employers, nor do
          they influence your application success.
        </Text>
        <Text className={commonStyles.content}>
          Your postcode helps us provide a better match for jobs and events near
          you and your gender helps us keep track of diversity on our platform.
        </Text>
        <Field required>
          <Select
            name="gender"
            options={[
              { label: "Male", value: "Male" },
              { label: "Female", value: "Female" },
              { label: "I'd rather not say", value: "N" },
            ]}
          />
        </Field>
        <Field label="Your postcode" required>
          <Input
            name="postcode"
            placeholder="2000"
            format={{ str: "XXXX", separator: " ", characters: "^[0-9]$" }}
          />
        </Field>
        <ActionBar layout="center" className={commonStyles.actionBar}>
          <Button label="Next" action={null} type="submit" style="secondary" />
        </ActionBar>
      </Form>
    </Layout>
  );
};

export { AboutYou };
export default AboutYou;
