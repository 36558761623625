import * as React from "react";

import { Button, Loading } from "@ribit/components";

import Application from "@app/models/application";
import { FunctionComponent } from "react";
import Job from "@app/models/job";
import { StudentProfile } from "@app/components/student-profile";

interface Props {
  job: Job;
  application: Application;
  onToggleShortlist: () => void;
  onSendMessage: () => void;
  onDecline: () => void;
  onHire: () => void;
  shortlisted: boolean;
  shortlistLoading: boolean;
}

export const ViewApplicationHome: FunctionComponent<Props> = ({
  job,
  application,
  shortlisted,
  onSendMessage,
  onToggleShortlist,
  onDecline,
  shortlistLoading,
}) => (
  <StudentProfile
    student={application.student}
    matchSkills={job.skills}
    sidebarContent={
      <>
        <Button
          action={onSendMessage}
          label="Send message"
          style="secondary"
          size="small"
          layout="full-width"
        />
        <Button
          action={onToggleShortlist}
          size="small"
          layout="full-width"
          style={shortlisted ? "primary" : "secondary"}
          label={
            shortlistLoading ? (
              <Loading />
            ) : shortlisted ? (
              "Shortlisted"
            ) : (
              "Shortlist"
            )
          }
        />
        <Button
          action={onDecline}
          style="tertiary"
          size="small"
          layout="full-width"
          label="Decline application"
        />
      </>
    }
    applicationDetails={[
      {
        name: "Why are you interested in this role?",
        value: application.coverLetter,
      },
      {
        name: job.interviewQuestion || "Interview Answer",
        value: application.interviewAnswer,
      },
    ]}
  />
);
