import Types, { Payload } from "./types";

import { Action } from "@app/state/types";
import Tenant from "@app/models/tenant";

const fetching = (): Action<Payload> => {
  return {
    type: Types.FETCHING,
    payload: {
      tenant: null,
    },
  };
};

const fetchedSuccess = (tenant: Tenant): Action<Payload> => {
  return {
    type: Types.FETCH_SUCCESS,
    payload: {
      tenant,
    },
  };
};

export { fetching, fetchedSuccess };
