import * as React from "react";
import classNames from "classnames/bind";
import styles from "./styles.modules.css";
import * as yup from "yup";

import {
  ActionBar,
  Button,
  Field,
  Form,
  Image,
  Input,
  Loading,
  Panel,
  Photo,
  Text,
} from "@ribit/components";
import User, { isStudent } from "@app/models/user";

import Employer from "@app/models/employer";
import { GroupedPayload } from "@app/state/ducks/messages/threads/types";
import { Message } from "../message";
import { Message as MessageType } from "@app/models/messages";
import Student from "@app/models/student";

const cx = classNames.bind(styles);

const empty = require("@app/assets/img/undrawFilingSystem.svg");

type OwnProps = {
  loading: boolean;
  messages: MessageType[];
  threads: GroupedPayload;
  user: User;
  new?: boolean;
  onSubmit: (message: string) => Promise<any>;
};

class Conversation extends React.Component<OwnProps> {
  private conversationListRef: React.RefObject<HTMLDivElement>;

  constructor(props: OwnProps) {
    super(props);
    this.conversationListRef = React.createRef();
  }

  private get isEmpty(): boolean {
    const { threads, loading, messages } = this.props;
    return !loading && !messages.length && !Object.keys(threads).length;
  }

  componentDidUpdate(oldProps: OwnProps) {
    if (this.props.messages.length > oldProps.messages.length) {
      const el = this.conversationListRef.current;
      el.scrollTop = el.scrollHeight;
    }
  }

  renderLoading = (): React.ReactElement<any> => {
    const { loading, messages } = this.props;
    if (loading) {
      return (
        <Loading
          style="inverted"
          label={
            messages.length === 0 ? "Retrieving messages..." : "Sending..."
          }
          className={styles.loading}
        />
      );
    }
    return null;
  };

  renderMessages = (): React.ReactElement<any> => {
    const { messages, user } = this.props;
    if (messages.length === 0) {
      return null;
    }

    return (
      <div ref={this.conversationListRef} className={styles.conversationList}>
        {messages.map((message, index) => {
          const key = `${index}-${message.job.uuid}-${message.fromUser.uuid}`;
          return (
            <Message
              message={message}
              key={key}
              mine={message.fromUser.uuid === user.uuid}
            />
          );
        })}
      </div>
    );
  };

  renderEmpty = (): React.ReactElement<any> => {
    if (!this.isEmpty) {
      return null;
    }

    const { user } = this.props;
    let instructions =
      "First step is to post a job, wait for applicants to roll in, and then get the conversation started.";
    let action = "/manage/jobs/create";
    let label = "Post a Job";
    if (isStudent(user)) {
      instructions =
        "First step is to apply for some roles and let the employers get the conversation started.";
      action = "/jobs";
      label = "View Jobs";
    }

    return (
      <>
        <Image src={empty} className={styles.image} />
        <Text>You haven't sent or received any messages.</Text>
        <Text className={styles.instructions}>{instructions}</Text>
        <ActionBar layout="center" className={styles.actions}>
          <Button label={label} action={action} style="secondary" />
        </ActionBar>
      </>
    );
  };

  renderForm = (): React.ReactElement<any> => {
    if (this.isEmpty) {
      return null;
    }
    const { user } = this.props;
    let url: string = null;
    if (isStudent(user)) {
      url = (user as Student).profile.photo;
    } else {
      url = (user as Employer).company.logo;
    }
    return (
      <Form
        className={styles.form}
        validateSchema={yup.object().shape({
          message: yup.string().required(),
        })}
        onSubmit={(values, internals) => {
          internals.reset();
          const { onSubmit } = this.props;
          onSubmit(values.message).then(() => {
            internals.reset();
          });
        }}
      >
        {props => {
          const { values } = props;
          return (
            <>
              <Photo name={user.fullName} size="small" url={url} />
              <Field label={null} className={styles.field} required>
                <Input
                  name="message"
                  placeholder="Start typing a message..."
                  className={styles.input}
                />
              </Field>
              <Button
                style="secondary"
                label="Send message"
                disabled={!values.message}
                type="submit"
              />
            </>
          );
        }}
      </Form>
    );
  };

  render(): React.ReactElement<any> {
    const className: string = cx({
      outer: true,
      empty: this.isEmpty,
    });
    return (
      <Panel className={className}>
        {this.renderEmpty()}
        {this.renderMessages()}
        {this.renderLoading()}
        {this.renderForm()}
      </Panel>
    );
  }
}

export { Conversation };
export default Conversation;
