import Message from "@app/models/messages";

const Types = {
  FETCHING: "@@manage/messages/conversation/FETCHING",
  FETCH_SUCCESS: "@@manage/messages/conversation/FETCH_SUCCESS",
  FETCH_ERROR: "@@manage/messages/conversation/FETCH_ERROR",
  CLEAR: "@@manage/messages/conversation/CLEAR",
  CREATE: "@@manage/messages/conversation/CREATE",
  CREATE_SUCCESS: "@@manage/messages/conversation/CREATE_SUCCESS",
  CREATE_ERROR: "@@manage/messages/conversation/CREATE_ERROR",
};

type Payload = {
  loading: boolean;
  sending?: boolean;
  data: Message[];
};

export { Payload, Types };

export default Types;
