import * as React from "react";
import styles from "./styles.modules.css";

import { ActionBar, Button, Container, Icons, Panel } from "@ribit/components";
import {
  Operations,
  operations,
} from "@app/state/ducks/manage/closed/operations";

import { ActiveJobs } from "./containers";
import ClosedJobs from "./containers/closed-jobs";
import { Employer as EmployerType } from "@app/models/employer";
import Job from "@app/models/job";
import Pagination from "@app/models/pagination";
import { ProfileCompletion } from "@app/components/profile-completion";
import { ProfilePhoto } from "@app/components/profile-photo";
import { State } from "@app/state";
import { connect } from "react-redux";
import { mapOperationsToDispatchProps, toTitleCase } from "@ribit/lib";
import {
  PendingCompanyPanel,
  PendingCompanyPanelBase,
} from "@app/components/pending-company-panel";

type OwnProps = Record<string, never>;

type DispatchProps = {
  operations?: Operations;
};
type StateProps = {
  user?: EmployerType;
  active?: Pagination<Job>;
  closed?: Pagination<Job>;
};
type EmployerProps = StateProps & DispatchProps & OwnProps;

const PureEmployer: React.FunctionComponent<EmployerProps> = (
  props: EmployerProps,
) => {
  const { user, active, closed, operations } = props;

  React.useEffect(() => {
    operations.fetch();
    return () => (operations ? operations.clear() : null);
  }, []);
  const activeCount: string =
    active.count > active.results.length
      ? `${active.results.length}/${active.count}`
      : active.count > 0
      ? `${active.count}`
      : null;
  const closedCount: string =
    closed.count > closed.results.length
      ? `${closed.results.length}/${closed.count}`
      : closed.count > 0
      ? `${closed.count}`
      : null;

  return (
    <Container grid="medium-span">
      <div>
        <ProfilePhoto
          className={styles.photo}
          name={user.fullName}
          url={user.company.logo}
          subtitle={`Employer at ${user.company.name}`}
        />
        <ProfileCompletion
          items={[
            { label: "Contact person", field: "firstName" },
            { label: "Business name", field: "company.name" },
            { label: "Business description", field: "company.description" },
            { label: "Business website", field: "company.url" },
            { label: "Business Industry", field: "company.industryCode" },
            { label: "Business size", field: "company.size" },
          ]}
          user={user}
          minCompleteMessage="Complete the following important details to post a job"
          completeMessage="Get to 100% profile completion to increase your chances of attracting the best talent"
        />
      </div>
      <div className={styles.jobs}>
        {user.minimumProfileCompleteness && (
          <ActionBar className={styles.actionBar}>
            <Button
              label="Post a job"
              action="/manage/jobs/create"
              style="secondary"
            />
          </ActionBar>
        )}
        {!user.minimumProfileCompleteness && (
          <PendingCompanyPanelBase
            heading="To post a job please complete the following mandatory fields in your Account profile"
            points={user.missingFields.map(toTitleCase)}
          >
            <br />
            <Button
              label="Complete your profile"
              action="/account"
              type="text"
            />
          </PendingCompanyPanelBase>
        )}
        <PendingCompanyPanel companyApproved={user.company.approved} />
        <Panel
          title="Active jobs"
          icon={Icons.Suitcase}
          help={<div className={styles.help}>{activeCount}</div>}
        >
          <ActiveJobs data={active} />
        </Panel>
        {closed.count > 0 && (
          <Panel
            title="Closed jobs"
            icon={Icons.Suitcase}
            help={<div className={styles.help}>{closedCount}</div>}
          >
            <ClosedJobs data={closed} />
          </Panel>
        )}
      </div>
    </Container>
  );
};

const mapStateToProps = (state: State): StateProps => {
  return {
    active: state.manage.active.data,
    closed: state.manage.closed.data,
    user: state.user as EmployerType,
  };
};

const Employer = connect(
  mapStateToProps,
  mapOperationsToDispatchProps(operations),
)(PureEmployer);

export { Employer };
export default Employer;
