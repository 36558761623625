import * as React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function withRouter(component: any) {
  const Component: any = component;

  function ComponentWithRouterProp(props: any) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return <Component {...props} match={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

export { withRouter };
