import * as React from "react";
import classNames from "classnames/bind";
import styles from "./styles.modules.css";

const cx = classNames.bind(styles);

type OwnProps = {
  label: string | number;
  className?: string;
};

const Badge: React.FunctionComponent<OwnProps> = (props: OwnProps) => {
  const { label, className } = props;
  const className_: string = cx({
    badge: true,
    [className]: className,
  });
  return <div className={className_}>{label}</div>;
};

export { Badge };
export default Badge;
