import { Dispatch } from "redux";
import {
  clear as clearActive,
  fetchError,
  fetchSuccess,
  fetching,
  update,
} from "./actions";
import { Job } from "@app/models/job";
import Pagination from "@app/models/pagination";
import { get, post } from "@app/helpers/api";

const fetch = (): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(fetching());
    get("/jobs/partner-jobs/?closed=0", Job)
      .then((data: Pagination<Job>) => {
        dispatch(fetchSuccess(data));
      })
      .catch(() => {
        dispatch(fetchError());
      });
  };
};

const clear = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch(clearActive());
  };
};

const seen = (uuid: string): any => {
  return (dispatch: Dispatch<any>) => {
    return post(`/jobs/partner-jobs/${uuid}/mark-as-seen`)
      .then(data => {
        dispatch(update(data));
      })
      .catch(e => {
        throw e;
      });
  };
};

const reject = (uuid: string): any => {
  return (dispatch: Dispatch<any>) => {
    return post(`/jobs/partner-jobs/${uuid}/reject`)
      .then(data => {
        dispatch(update(data));
      })
      .catch(e => {
        throw e;
      });
  };
};

const approve = (uuid: string, courses: string[]): any => {
  return (dispatch: Dispatch<any>) => {
    return post(`/jobs/partner-jobs/${uuid}/approve`, {
      suitableCourses: courses,
    })
      .then(data => {
        dispatch(update(data));
      })
      .catch(e => {
        throw e;
      });
  };
};

type Operations = {
  fetch: () => any;
  clear: () => void;
  seen(uuid: string): void;
  reject(uuid: string): void;
  approve(uuid: string, courses: string[]): void;
};

const operations: Operations = {
  fetch,
  clear,
  seen,
  reject,
  approve,
};

export { fetch, operations, Operations };
