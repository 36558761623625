import Job from "@app/models/job";

const Types = {
  FETCHING: "@@job/FETCHING",
  FETCH_SUCCESS: "@@job/FETCH_SUCCESS",
  FETCH_ERROR: "@@job/FETCH_ERROR",
  CREATE: "@@job/CREATE",
  CREATE_ERROR: "@@job/CREATE_ERROR",
  CREATE_SUCCESS: "@@job/CREATE_SUCCESS",
  UPDATE: "@@job/UPDATE",
  UPDATE_ERROR: "@@job/UPDATE_ERROR",
  UPDATE_SUCCESS: "@@job/UPDATE_SUCCESS",
};

type Payload = {
  loading: boolean;
  data: Job;
};

export { Payload, Types };

export default Types;
