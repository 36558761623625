import * as React from "react";
import * as commonStyles from "../styles.modules.css";
import * as yup from "yup";

import { ActionBar, Button, Field, Form, Input, Text } from "@ribit/components";

import Layout from "../layout";
import { PhoneNumberInput } from "@app/components/phone-number-input";
import Title from "../title";
import styles from "./styles.modules.css";

type OwnProps = {
  step: number;
  stepTotal: number;
  onSubmit: (values) => void;
};

const Contact: React.FunctionComponent<OwnProps> = (props: OwnProps) => {
  const { onSubmit, step, stepTotal } = props;
  return (
    <Layout>
      <Title text="Contact details" step={`${step}/${stepTotal}`} />
      <Text className={commonStyles.content}>
        We ask for your mobile number so employers can contact you about the
        jobs you have applied for! We may also contact you occasionally about
        networking events relevant to you. We won't share your mobile number
        with anyone else, and we won't spam you!
      </Text>
      <Text className={commonStyles.content}>
        We encourage you to share your social media profiles to provide a more
        complete picture of you to employers. You can remove your contact
        details at any time.
      </Text>
      <Form
        validateSchema={yup.object().shape({
          mobilePhoneNumber: yup
            .string()
            .required("You must enter a phone number")
            .phoneNumber("Must enter a valid phone number"),
        })}
        onSubmit={values => {
          return onSubmit(values);
        }}
      >
        <Field required>
          <PhoneNumberInput name="mobilePhoneNumber" />
        </Field>
        <Field label="My social media profiles">
          <Input
            name="facebook"
            prefix="facebook.com/"
            prefixClassName={styles.social}
          />
        </Field>
        <Field label={null}>
          <Input
            name="linkedin"
            prefix="linkedin.com/in/"
            prefixClassName={styles.social}
          />
        </Field>
        <Field label={null}>
          <Input
            name="twitter"
            prefix="twitter.com/"
            prefixClassName={styles.social}
          />
        </Field>
        <ActionBar layout="center" className={commonStyles.actionBar}>
          <Button label="Next" type="submit" style="secondary" />
        </ActionBar>
      </Form>
    </Layout>
  );
};

export { Contact };
export default Contact;
