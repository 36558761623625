import { del, post } from "@app/helpers/api";
import {
  deselect,
  fetchFailed,
  fetchSuccess,
  fetching,
  select,
} from "./actions";

import Application from "@app/models/application";
import { Dispatch } from "redux";
import { LoadingType } from "./types";
import { alertsAdd } from "../site-alerts/actions";

export const selectApplication = (application: Application) => (
  dispatch: Dispatch<any>,
) => {
  dispatch(select(application));
};

export const deselectApplication = () => (dispatch: Dispatch<any>) => {
  dispatch(deselect());
};

export const shortlistApplication = (jobId: string, applicationId: string) => (
  dispatch: Dispatch<any>,
) => {
  dispatch(fetching(LoadingType.SHORTLIST));
  post(`/jobs/manage/${jobId}/applications/${applicationId}/shortlist`)
    .then(application =>
      dispatch(fetchSuccess(application, LoadingType.SHORTLIST, false)),
    )
    .catch(() => {
      dispatch(
        alertsAdd("Failed to shortlist application.", null, "Close", "error"),
      );
      dispatch(fetchFailed(LoadingType.SHORTLIST));
    });
};

export const unShortlistApplication = (
  jobId: string,
  applicationId: string,
) => (dispatch: Dispatch<any>) => {
  dispatch(fetching(LoadingType.UN_SHORTLIST));
  del(`/jobs/manage/${jobId}/applications/${applicationId}/shortlist`)
    .then(application =>
      dispatch(fetchSuccess(application, LoadingType.UN_SHORTLIST, false)),
    )
    .catch(() => {
      dispatch(
        alertsAdd(
          "Failed to un-shortlist application.",
          null,
          "Close",
          "error",
        ),
      );
      dispatch(fetchFailed(LoadingType.UN_SHORTLIST));
    });
};

export const declineApplication = (jobId: string, applicationId: string) => (
  dispatch: Dispatch<any>,
) => {
  dispatch(fetching(LoadingType.DECLINE));
  del(`/jobs/manage/${jobId}/applications/${applicationId}`)
    .then(application =>
      dispatch(fetchSuccess(application, LoadingType.DECLINE, true)),
    )
    .catch(() => {
      dispatch(
        alertsAdd("Failed to decline application.", null, "Close", "error"),
      );
      dispatch(fetchFailed(LoadingType.DECLINE));
    });
};

export const acceptApplication = (
  jobId: string,
  applicationId: string,
  reason: number,
) => (dispatch: Dispatch<any>) => {
  dispatch(fetching(LoadingType.HIRE));
  post(`/jobs/manage/${jobId}/applications/${applicationId}/hire`, { reason })
    .then(application =>
      dispatch(fetchSuccess(application, LoadingType.HIRE, true)),
    )
    .catch(() => dispatch(fetchFailed(LoadingType.HIRE)));
};
