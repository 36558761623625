import {
  clear as clearAction,
  createSuccess,
  fetchError,
  fetchSuccess,
  fetching,
} from "./actions";

import { Dispatch } from "redux";
import Job from "@app/models/job";
import { Thread } from "@app/models/messages";
import User from "@app/models/user";
import { get } from "@app/helpers/api";
import { history } from "@app/state/store";

const fetch = (): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(fetching());
    return get("/messages", Thread)
      .then((data: Thread[]) => {
        dispatch(fetchSuccess(data));
      })
      .catch(() => {
        dispatch(fetchError());
      });
  };
};

const clear = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch(clearAction());
  };
};

const create = (job: Job, toUser: User, fromUser: User) => {
  return (dispatch: Dispatch<any>) => {
    const thread: Thread = new Thread();
    thread.fromUser = fromUser;
    thread.toUser = toUser;
    thread.job = job;
    thread.unreadCount = 0;
    thread.humanLastCreatedAt = "now";
    thread.lastCreatedAt = new Date();
    thread.preview = "New...";
    dispatch(createSuccess(thread));
    history.push(`/messages/${job.uuid}/${toUser.uuid}/new`);
  };
};

type Operations = {
  fetch: () => any;
  clear: () => void;
  create: (job: Job, toUser: User, fromUser: User) => void;
};

const operations: Operations = {
  fetch,
  clear,
  create,
};

export { fetch, clear, create, operations, Operations };
