import Application from "@app/models/application";

const Types = {
  FETCHING: "@@jobApplication/FETCHING",
  FETCH_SUCCESS: "@@jobApplication/FETCH_SUCCESS",
  FETCH_ERROR: "@@jobApplication/FETCH_ERROR",
  CREATE: "@@jobApplication/CREATE",
  CREATE_ERROR: "@@jobApplication/CREATE_ERROR",
  CREATE_SUCCESS: "@@jobApplication/CREATE_SUCCESS",
  UPDATE: "@@jobApplication/UPDATE",
  UPDATE_ERROR: "@@jobApplication/UPDATE_ERROR",
  UPDATE_SUCCESS: "@@jobApplication/UPDATE_SUCCESS",
};

type Payload = {
  loading: boolean;
  data: Application;
};

export { Payload, Types };

export default Types;
