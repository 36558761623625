import AfqLevel from "@app/models/afq-level";
import Degree from "@app/models/degree";
import FileUpload from "./file-upload";
import Institution from "@app/models/institution";
import Language from "./language";
import Location from "./location";
import Portfolio from "./portfolio";
import Skill from "./skill";
import { Type } from "./job";
import { hydrate } from "@ribit/lib";

class Profile {
  @hydrate
  afqLevel?: AfqLevel;
  category: string;
  @hydrate
  courseDegree?: Degree;
  @hydrate(FileUpload)
  fileUploads?: FileUpload[];
  gender?: string;
  graduateYear?: number;
  @hydrate
  institution?: Institution;
  internationalStudent?: boolean;
  @hydrate(Location)
  locations?: Location[];
  @hydrate(Language)
  languages?: Language[];
  mobilePhoneNumber?: string;
  photo?: string;
  postcode?: number;
  biography?: string;
  @hydrate(Skill)
  skills: Skill[];
  twitter?: string;
  facebook?: string;
  linkedin?: string;
  @hydrate(Type)
  workTypes?: Type[];
  @hydrate(Portfolio)
  portfolios?: Portfolio[];
  availabilityRemote?: boolean;
  availabilityAnywhere?: boolean;
  workEligibility: "permanent_resident" | "working_visa" | "sponsorship";

  readonly percentageComplete?: number;
  readonly humanWorkEligibility: string;
  readonly humanLocations: string;
  readonly humanWorkTypes: string;

  public isLocationSet(): boolean {
    return (
      (this.locations || []).length > 0 ||
      this.availabilityRemote === true ||
      this.availabilityAnywhere === true
    );
  }
}

export default Profile;
