import { hydrate } from "@ribit/lib";
import { CustomFooterData } from "./custom-footer-data";

class Assets {
  [s: string]: string;
}

class Colors {
  [s: string]: string;
}

class Theme {
  @hydrate
  colors: Colors;
  @hydrate
  assets: Assets;
}

class Content {
  header: string;
  title: string;
  body: string;
}

class Links {
  terms?: string;
  privacy?: string;
}

type CustomUi = {
  footer?: CustomFooterData;
};

class Tenant {
  readonly uuid: string;
  readonly name: string;
  readonly domain: string;
  readonly isPrimary: boolean;
  @hydrate
  readonly theme?: Theme;
  @hydrate
  readonly content?: Content;
  @hydrate
  readonly links?: Links;
  readonly features?: string[];
  readonly segmentKey?: string;

  customUi?: CustomUi;

  public hasFeature = (flag: string): boolean => {
    return this.features && this.features.indexOf(flag) > -1;
  };
}

export { Tenant };

export default Tenant;
