import React, { FunctionComponent } from "react";
import { Attribute, Heading, Photo, Size } from "@ribit/components";
import { Job } from "@app/models/job";
import { format } from "date-fns";
import styles from "./styles.modules.css";

interface Props {
  job: Job;
}

const JobHeader: FunctionComponent<Props> = ({ job, ...rest }) => (
  <div {...rest} className={styles.container}>
    <div className={styles.picture}>
      <Photo
        style="circle"
        size={"large"}
        name={job.owner.company.name}
        url={job.owner.company.logo}
      />
    </div>
    <div className={styles.who}>
      <Heading size={Size.Medium} text={job.title} />
      {job.owner.company.name && job.createdAt ? (
        <Attribute
          name={job.owner.company.name}
          value={format(
            new Date(job.createdAt),
            "EEEE, d MMMM yyyy 'at 'H:mmb",
          )}
        />
      ) : (
        <Attribute name={"Job"} value={""} />
      )}
    </div>
  </div>
);

export { JobHeader };
export default JobHeader;
