import { Job } from "./job";
import Skill from "./skill";
import { Student } from "./student";
import { hydrate } from "@ribit/lib";

class Application {
  readonly uuid: string;
  interviewAnswer: string;
  coverLetter: string;
  @hydrate(Skill)
  skills: Skill[];
  @hydrate
  student: Student;
  status: string;
  @hydrate
  job: Job;
  viewedAt: Date; // FIX: switch this to being hydrated once Date hydration no longer defaults to 1970 if null
  @hydrate
  createdAt: Date;
  readonly humanCreatedAt: string;
  readonly humanViewedAt: string;
}

export default Application;
