import * as React from "react";
import styles from "./styles.modules.css";

import { ActionBar, Loading, More, Table, Tag } from "@ribit/components";

import { EmptyTableView } from "@app/components/empty-table-view";
import Pagination from "@app/models/pagination";
import { ProfilePhoto } from "@app/components/profile-photo";
import Student from "@app/models/student";

type OwnProps = {
  data?: Pagination<Student>;
  loading: boolean;
  viewMoreAction: () => void;
  searchAction: () => void;
  viewStudentAction: (student: Student) => void;
};

class Students extends React.Component<OwnProps> {
  private _renderName = (student: Student): React.ReactElement<any> => {
    return (
      <ProfilePhoto
        blur
        className={styles.profile}
        name={student.fullName}
        altName={student.fullName}
        url={student.profile.photo}
        action={() => {
          this.props.viewStudentAction(student);
        }}
        subtitle={
          student.profile.institution
            ? student.profile.institution.name
            : "Unknown university"
        }
        size="small"
        style="square"
      />
    );
  };

  private _renderSkills(student: Student): React.ReactElement<any> {
    if (student.profile.skills.length === 0) {
      return <>No matching skills</>;
    }
    return (
      <>
        {student.profile.skills.map(skill => (
          <Tag key={skill.id} label={skill.name} />
        ))}
      </>
    );
  }

  private _renderLocation(student: Student): React.ReactElement<any> {
    const { humanLocations } = student.profile;
    let { locations } = student.profile;
    if (humanLocations.length === 0) {
      return <>N/A</>;
    }
    const override = [];
    if (student.profile.availabilityAnywhere) {
      override.push("Anywhere");
      locations = locations.slice(
        locations.findIndex(location => location.name === "Anywhere"),
        1,
      );
    }
    if (student.profile.availabilityRemote) {
      override.push("Remote");
    }
    if (override.indexOf("Anywhere") > -1) {
      return <>{override.join(", ")}</>;
    }
    if (locations.length > 3) {
      const visibleLocations = locations
        .slice(0, 3)
        .map(location => location.name)
        .join(", ");
      const more = locations.length - 3;
      return (
        <>
          {visibleLocations} + {more} more
        </>
      );
    }
    return <>{humanLocations}</>;
  }

  private _renderLoadMore = (): React.ReactElement<any> => {
    const { data, loading, viewMoreAction } = this.props;
    if (!data.results.length || !data.next) {
      return null;
    }

    return (
      <ActionBar layout="center">
        {loading && data.results.length ? (
          <Loading style="inverted" />
        ) : (
          <More label="View more" action={viewMoreAction} />
        )}
      </ActionBar>
    );
  };

  render(): React.ReactElement<any> {
    const { loading, data } = this.props;
    return (
      <>
        <Table
          className={styles.table}
          cellClassName={styles.cell}
          columns={[
            {
              label: "Student Name",
              key: "studentName",
              renderer: this._renderName,
              width: "30%",
            },
            {
              label: "Skills",
              key: "skills",
              renderer: this._renderSkills,
              width: "50%",
            },
            {
              label: "Location",
              key: "profile.humanLocations",
              renderer: this._renderLocation,
            },
          ]}
          data={data.results}
          loading={loading && !data.results.length}
          emptyView={
            <EmptyTableView
              span={4}
              title="No results found"
              message="Unfortunately we weren't able to find any matching students for your filters, widen your criteria and search again."
            />
          }
        />
        {this._renderLoadMore()}
      </>
    );
  }
}

export { Students };
export default Students;
