import Types, { Payload, ResetRequest } from "./types";

import { Action } from "@app/state/types";

const fetching = (): Action<Payload> => {
  return {
    type: Types.FETCHING,
    payload: {
      loading: true,
      data: {
        valid: false,
      },
    },
  };
};

const fetchedSuccess = (data: ResetRequest): Action<Payload> => {
  data.valid = true;
  return {
    type: Types.FETCH_SUCCESS,
    payload: {
      loading: false,
      data: data,
    },
  };
};

const fetchedError = (): Action<Payload> => {
  return {
    type: Types.FETCH_ERROR,
    payload: {
      loading: false,
      data: {
        valid: false,
      },
    },
  };
};

const resetAttempt = (): Action<Payload> => {
  return {
    type: Types.RESET_ATTEMPT,
    payload: {
      loading: true,
      data: {
        valid: true,
      },
    },
  };
};

const resetAttemptSuccess = (): Action<Payload> => {
  return {
    type: Types.RESET_SUCCESS,
    payload: {
      loading: false,
      data: {
        valid: true,
        success: true,
      },
    },
  };
};

const resetAttemptError = (): Action<Payload> => {
  return {
    type: Types.RESET_ERROR,
    payload: {
      loading: false,
      data: {
        valid: true,
        success: false,
      },
    },
  };
};

export {
  fetching,
  fetchedSuccess,
  fetchedError,
  resetAttempt,
  resetAttemptSuccess,
  resetAttemptError,
};
