import * as React from "react";
import classNames from "classnames/bind";
import styles from "./styles.modules.css";

import { Icon, Icons } from "@ribit/components";

const cx = classNames.bind(styles);

type OwnProps = {
  label: string;
  icon: Icons;
  active?: boolean;
  onKeyUp?: (event) => void;
  onMouseDown?: () => void;
};

const JobType: React.FunctionComponent<OwnProps> = (props: OwnProps) => {
  const { label, icon, active, onKeyUp, onMouseDown } = props;
  const className: string = cx({
    radio: true,
    active: active,
  });
  return (
    <div
      className={className}
      tabIndex={0}
      onKeyUp={onKeyUp}
      onMouseDown={onMouseDown}
    >
      <Icon icon={icon} className={styles.icon} />
      {label}
    </div>
  );
};

export { JobType };
export default JobType;
