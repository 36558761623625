import * as React from "react";

import { Banner, Body } from "../components";
import { Operations, operations } from "@app/state/ducks/jobs/operations";

import Job from "@app/models/job";
import { State } from "@app/state";
import { Tenant as TenantType } from "@app/models/tenant";
import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";

type OwnProps = {
  tenant: TenantType;
};

type DispatchProps = {
  operations?: Operations;
};

type StateProps = {
  jobs?: Job[];
};

type TenantProps = DispatchProps & StateProps & OwnProps;

class PureTenant extends React.Component<TenantProps> {
  componentDidMount() {
    const { tenant } = this.props;
    if (tenant.hasFeature("display_jobs")) {
      this.props.operations.recent();
    }
  }

  render(): React.ReactElement<any> {
    const { tenant, jobs } = this.props;
    let banner = null;
    if (tenant && tenant.theme) {
      banner = tenant.theme.assets["header"];
    }
    let copy: string = null;
    let title: string = null;
    let subtitle: string = null;
    if (tenant && tenant.content) {
      copy = tenant.content.body;
      title = tenant.content.header;
      subtitle = tenant.content.title;
    }
    return (
      <>
        <Banner backgroundImage={banner} title={title} />
        <Body copy={copy} subtitle={subtitle} jobs={jobs} />
      </>
    );
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    jobs: state.jobs.data ? state.jobs.data.results : [],
  };
};

const Tenant = connect(
  mapStateToProps,
  mapOperationsToDispatchProps(operations),
)(PureTenant);

export { Tenant };
export default Tenant;
