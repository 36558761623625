import * as React from "react";
import styles from "./styles.modules.css";

import { ActionBar, Loading, More, Table, Tag, Text } from "@ribit/components";

import { EmptyTableView } from "@app/components/empty-table-view";
import Job from "@app/models/job";
import Pagination from "@app/models/pagination";
import { ProfilePhoto } from "@app/components/profile-photo";

type OwnProps = {
  data?: Pagination<Job>;
  loading: boolean;
  viewMoreAction: () => void;
  searchAction: () => void;
};

class Jobs extends React.Component<OwnProps> {
  private _renderTitle(job: Job) {
    return (
      <ProfilePhoto
        className={styles.logo}
        url={job.owner.company.logo}
        altName={job.owner.company.name}
        name={job.title}
        action={`/jobs/${job.slug}`}
        subtitle={job.owner.company.name}
        size="small"
        style="square"
      />
    );
  }

  private _renderType(job: Job) {
    return <Text className={styles.count}>{job.type.name}</Text>;
  }

  private _renderLocation(job: Job) {
    return <Text className={styles.count}>{job.location}</Text>;
  }

  private _renderLoadMore(): React.ReactElement<any> {
    const { data, loading, viewMoreAction } = this.props;
    if (!data.results.length || !data.next) {
      return null;
    }

    return (
      <ActionBar layout="center">
        {loading && data.results.length ? (
          <Loading style="inverted" />
        ) : (
          <More label="View more" action={viewMoreAction} />
        )}
      </ActionBar>
    );
  }

  private _renderSkills(job: Job): React.ReactElement<any> {
    return (
      <>
        {job.skills.map(skill => (
          <Tag key={skill.id} label={skill.name} />
        ))}
      </>
    );
  }

  private _renderEmptyTableView = (): React.ReactElement<any> => {
    return (
      <EmptyTableView
        span={5}
        title="No results found"
        message="Unfortunately we weren't able to find any matching jobs for your filters, widen your criteria and search again."
      />
    );
  };

  render(): React.ReactElement<any> {
    const { data, loading } = this.props;
    return (
      <div data-cy="job-listing">
        <Table
          className={styles.table}
          cellClassName={styles.cell}
          showColumns={true}
          columns={[
            { key: "Job Title", renderer: this._renderTitle, width: "40%" },
            { key: "Skills", renderer: this._renderSkills, width: "35%" },
            { key: "Type", renderer: this._renderType },
            { key: "Location", renderer: this._renderLocation },
          ]}
          data={data.results}
          loading={loading && !data.results.length}
          defaultLoadingLabel="Retrieving jobs..."
          emptyView={this._renderEmptyTableView()}
          data-cy="job-listing"
        />
        {this._renderLoadMore()}
      </div>
    );
  }
}

export { Jobs };
export default Jobs;
