import { AxiosError, AxiosResponse } from "axios";
import {
  fetchedError,
  fetchedSuccess,
  fetching,
  resetAttempt,
  resetAttemptError,
  resetAttemptSuccess,
} from "./actions";
import { get, post } from "@app/helpers/api";

import { Dispatch } from "redux";

const validate = (uuid: string): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(fetching());
    return get(`/users/reset-password/${uuid}`)
      .then((response: AxiosResponse) => {
        dispatch(fetchedSuccess(response as any));
      })
      .catch(() => {
        dispatch(fetchedError());
      });
  };
};

const reset = (uuid: string, password: string, confirmPassword: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(resetAttempt());
    return post(`/users/reset-password/${uuid}`, {
      new: password,
      confirm: confirmPassword,
    })
      .then(() => {
        dispatch(resetAttemptSuccess());
      })
      .catch((error: AxiosError) => {
        dispatch(resetAttemptError());
        throw error.response;
      });
  };
};

type Operations = {
  validate: (uuid: string) => any;
  reset: (uuid: string, password: string, confirmPassword: string) => any;
};

const operations: Operations = {
  validate,
  reset,
};

export { validate, reset, operations, Operations };
