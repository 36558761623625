import * as React from "react";
import * as commonStyles from "../styles.modules.css";

import { ActionBar, Button, Heading, Image, Text } from "@ribit/components";

import Layout from "../layout";
import { history } from "@app/state/store";

const completeSvg = require("@app/assets/img/documents.svg");

type OwnProps = {
  onSubmit: (values, completed: boolean) => void;
};

const Complete: React.FunctionComponent<OwnProps> = (props: OwnProps) => {
  const { onSubmit } = props;
  return (
    <Layout className={commonStyles.centered}>
      <Image src={completeSvg} />
      <Heading text="Congratulations your profile setup is complete!" />
      <Text className={commonStyles.content}>
        Post a job or visit your dashboard to see a summary of your jobs posted
        and applications received.
      </Text>
      <ActionBar layout="center" className={commonStyles.actionBar}>
        <Button
          label="Create a new job"
          action={() => {
            history.push("/manage/jobs/create");
            onSubmit({}, true);
          }}
          style="tertiary"
          size="small"
        />
        <Button
          label="View my dashboard"
          action={() => {
            history.push("/dashboard");
            onSubmit({}, true);
          }}
          style="secondary"
          size="small"
        />
      </ActionBar>
    </Layout>
  );
};

export { Complete };
export default Complete;
