import * as React from "react";

import {
  ActionBar,
  Button,
  Field,
  Form,
  Heading,
  Icons,
  Input,
  Select,
  Tooltip,
} from "@ribit/components";
import { Operations, operations } from "@app/state/ducks/user/operations";

import Category from "@app/models/category";
import { PrivacyPolicyLabel } from "@app/components/privacy-policy-label/privacy-policy-label";
import { State } from "@app/state";
import Tenant from "@app/models/tenant";
import { Title } from "@app/components/title";
import { connect } from "react-redux";
import { lookup } from "@app/helpers/api";
import { mapOperationsToDispatchProps } from "@ribit/lib";
import styles from "./styles.modules.css";

type OwnProps = Record<string, never>;
type DispatchProps = {
  operations?: Operations;
};
type StateProps = {
  tenant?: Tenant;
};
type StudentProps = StateProps & DispatchProps & OwnProps;

class PureStudent extends React.Component<StudentProps> {
  render(): React.ReactElement<any> {
    const { operations, tenant } = this.props;
    return (
      <>
        <Title label="Student Registration" />
        <Heading className={styles.title} text="Get started - it's free" />
        <Form
          onSubmit={values => {
            const mungedData: object = {
              email: values.email,
              password: values.password,
              firstName: values.firstName,
              lastName: values.lastName,
              profile: {
                category: values.category,
              },
              tenant: tenant.uuid,
            };
            return operations.register(mungedData);
          }}
        >
          <Field required>
            <Input name="firstName" placeholder="Jane" />
          </Field>
          <Field required>
            <Input name="lastName" placeholder="Smith" />
          </Field>
          <Field required>
            <Input
              type="email"
              name="email"
              placeholder="janesmith@example.com"
            />
          </Field>
          <Field required>
            <Input
              type="password"
              name="password"
              suffix={
                <Tooltip
                  icon={Icons.Question}
                  label="Must be at least 8 characters"
                />
              }
            />
          </Field>
          <Field required label="I'm interested in...">
            <Select
              name="category"
              async={{
                loadingText: "Retrieving categories...",
                callback: lookup(
                  Category,
                  "/categories",
                  (categories: Category[]) => {
                    return categories.map(category => {
                      return {
                        label: category.name,
                        value: category.name,
                      };
                    });
                  },
                ),
              }}
            />
          </Field>
          <PrivacyPolicyLabel />
          <ActionBar>
            <Button
              type="submit"
              label="Sign up as a student"
              style="secondary"
              layout="full-width"
            />
          </ActionBar>
        </Form>
      </>
    );
  }
}

const mapDispatchToProps = mapOperationsToDispatchProps(operations);
const mapStateToProps = (state: State) => {
  return {
    tenant: state.tenant,
  };
};

const Student = connect(mapStateToProps, mapDispatchToProps)(PureStudent);

export { Student };
export default Student;
