import * as React from "react";

import User, { isEmployer, isPartner, isStudent } from "@app/models/user";

import { Content } from "@ribit/components";
import { Employer } from "./employer";
import { Partner } from "./partner";
import { State } from "@app/state";
import { Student } from "./student";
import { Title } from "@app/components/title";
import { connect } from "react-redux";

type OwnProps = Record<string, never>;
type StateProps = {
  user: User;
};
type AccountProps = OwnProps & StateProps;

const PureAccount: React.FunctionComponent<AccountProps> = (
  props: AccountProps,
) => {
  const { user } = props;
  let accountElement: any = null;
  if (isEmployer(user)) {
    accountElement = <Employer user={user} />;
  } else if (isStudent(user)) {
    accountElement = <Student user={user} />;
  } else if (isPartner(user)) {
    accountElement = <Partner user={user} />;
  }
  return (
    <Content>
      <Title label="Your account" />
      {accountElement}
    </Content>
  );
};

const mapStateToProps = (state: State): StateProps => {
  return {
    user: state.user,
  };
};

const Account = connect(mapStateToProps, null)(PureAccount);

export { Account };
export default Account;
