import * as React from "react";

import {
  ActionBar,
  Button,
  Heading,
  Size,
  StickyFooterBar,
} from "@ribit/components";
import { Munger, mapOperationsToDispatchProps } from "@ribit/lib";
import { Operations, operations } from "@app/state/ducks/job/operations";
import { createEditableJob, createTemporaryJob } from "../helpers";

import EditableJob from "../models";
import { Employer } from "@app/models/employer";
import Job from "@app/models/job";
import { JobDetails } from "@app/pages/jobs";
import { State } from "@app/state";
import { connect } from "react-redux";
import { history } from "@app/state/store";

type OwnProps = {
  editing: EditableJob;
  onProgress: (data: any) => void;
  onBack: (data: object) => void;
};
type DispatchProps = {
  operations: Operations;
};
type StateProps = {
  user: Employer;
};
type ReviewProps = OwnProps & StateProps & DispatchProps;

const PureReview: React.FunctionComponent<ReviewProps> = (
  props: ReviewProps,
) => {
  const { onBack, operations, editing, onProgress, user } = props;
  const temporaryJob: Job = createTemporaryJob(editing, user);
  const label: string = editing.uuid
    ? editing.canRepost
      ? "Repost job"
      : "Update job"
    : "Post job";
  return (
    <>
      <Heading text={`Preview job post - ${editing.title}`} />
      <JobDetails job={temporaryJob} review user={user} />
      <StickyFooterBar grid="span-small">
        <Heading
          size={Size.Small}
          text="This is a preview of your job, does this look correct?"
        />
        <ActionBar>
          <Button
            size="small"
            style="tertiary"
            label="Edit"
            action={() => {
              onBack(createEditableJob(temporaryJob));
            }}
          />
          <div data-cy="button__manage-jobs__post">
            <Button
              size="small"
              style="secondary"
              label={label}
              action={() => {
                const munger: Munger = new Munger({
                  paths: {
                    jobCategory: "category",
                    jobType: "type.id",
                    pay: "budget", // pay is the field name in the backend
                    days: "days",
                    timing: "duration",
                    title: "title",
                    description: "description",
                    interviewQuestion: "interviewQuestion",
                    address: "address",
                    location: "location",
                    agreedFairwork: "agreedFairwork",
                    residencyRequirement: "residencyRequirement",
                    skills: "skills.id",
                    courseCreditBudget: "courseCreditBudget",
                    courseCreditDuration: "courseCreditDuration",
                    numberOfStudents: "numberOfStudents",
                    workingOnsite: "workingOnsite",
                    hasDedicatedWorkingSpace: "hasDedicatedWorkingSpace",
                    workplaceAddress: "workplaceAddress",
                    supervisorJobTitle: "supervisorJobTitle",
                    supervisorName: "supervisorName",
                    supervisorPhoneNumber: "supervisorPhoneNumber",
                    supervisorEmail: "supervisorEmail",
                  },
                });
                const mungedJob: object = munger.flatten(temporaryJob);
                mungedJob.supervisorDetails = {
                  jobTitle: temporaryJob.supervisorJobTitle,
                  name: temporaryJob.supervisorName,
                  phoneNumber: temporaryJob.supervisorPhoneNumber,
                  email: temporaryJob.supervisorEmail,
                };

                let action: Promise<any>;
                if (editing.uuid) {
                  action = operations.update(editing.uuid, mungedJob);
                } else {
                  action = operations.create(mungedJob);
                }
                action.then((job: Job) => {
                  if (!job) {
                    return;
                  }
                  onProgress(job);
                  // history.push(`/manage/jobs/${job.uuid}`);
                  // if (!editing.uuid) {
                  //   onProgress(job);
                  // }
                });
              }}
            />
          </div>
        </ActionBar>
      </StickyFooterBar>
    </>
  );
};

const mapStateToProps = (state: State): StateProps => {
  return {
    user: state.user as Employer,
  };
};

const Review = connect(
  mapStateToProps,
  mapOperationsToDispatchProps(operations),
)(PureReview);

export { Review };
export default Review;
