import * as React from "react";
import classNames from "classnames/bind";
import * as styles from "./styles.modules.css";
import { Icon, Icons } from "@ribit/components";

const cx = classNames.bind(styles);

type Option = {
  label: any;
  value?: any;
};

type OwnProps = {
  name: string;
  option: Option;
  selected?: boolean;
  renderer?: (
    option: Option,
    selected: boolean,
  ) => React.ReactNode | React.ReactElement;
  onChange?: (name: string, value: string) => void;
  // dataCy?: string;
};

class Radio extends React.Component<OwnProps> {
  onKeyUp = (event: React.KeyboardEvent) => {
    if (event.key.toLowerCase() === "enter") {
      this.select();
    }
  };

  select = () => {
    const { name, onChange, option } = this.props;
    onChange(name, option.value);
  };

  render(): React.ReactElement<any> {
    const { option, selected, renderer } = this.props;
    const element: any = renderer(option, selected);
    return React.cloneElement(element, {
      onKeyUp: this.onKeyUp,
      onMouseDown: this.select,
      tabIndex: 0,
      ...element.props,
    });
  }
}

type ButtonProps = {
  className: string;
  onKeyUp: (event) => void;
  onMouseDown: () => void;
  label: string;
  selected: boolean;
  dataCy: string;
};

const Box: React.FunctionComponent<ButtonProps> = (props: ButtonProps) => {
  const { onKeyUp, onMouseDown, label, selected } = props;
  const className: string = cx({
    box: true,
    option: true,
    selected: selected,
  });
  return (
    <div
      className={className}
      tabIndex={0}
      onKeyUp={onKeyUp}
      onMouseDown={onMouseDown}
    >
      {label}
    </div>
  );
};

const Standard: React.FunctionComponent<ButtonProps> = (props: ButtonProps) => {
  const { onKeyUp, onMouseDown, label, selected } = props;
  const className: string = cx({
    standard: true,
    option: true,
    selected: selected,
  });
  const icon: Icons = selected ? Icons.CheckCircle : Icons.Circle;
  return (
    <div
      className={className}
      tabIndex={0}
      onKeyUp={onKeyUp}
      onMouseDown={onMouseDown}
    >
      <Icon icon={icon} className={styles.icon} />{" "}
      <span className={styles.label}>{label}</span>
    </div>
  );
};

export { Radio, Option, Box, Standard };
export default Radio;
