import Tenant from "@app/models/tenant";

const Types = {
  FETCHING: "@@tenant/FETCHING",
  FETCH_SUCCESS: "@@tenant/FETCH_SUCCESS",
};

type Payload = {
  tenant: Tenant | null;
};

export { Payload, Types };

export default Types;
