import Types, { LoadingType, Payload } from "./types";
import { Action } from "@app/state/types";
import Application from "@app/models/application";

export const fetchSuccess = (
  application: Application,
  type: LoadingType,
  shouldClose: boolean,
): Action<Payload> => ({
  type: Types.FETCH_SUCCESS,
  payload: {
    type,
    application,
    shouldClose,
  },
});

export const select = (application: Application): Action<Payload> => ({
  type: Types.SELECT,
  payload: { application },
});

export const deselect = (): Action<Payload> => ({
  type: Types.DESELECT,
  payload: {},
});

export const fetchFailed = (type: LoadingType): Action<Payload> => ({
  type: Types.FETCH_FAILED,
  payload: {
    type,
    shouldClose: false,
  },
});

export const fetching = (type: LoadingType): Action<Payload> => ({
  type: Types.FETCHING,
  payload: {
    type,
  },
});
