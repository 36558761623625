import * as React from "react";
import classNames from "classnames/bind";
import styles from "./styles.modules.css";

import { Icons, Upload } from "@ribit/components";

import { isString } from "@ribit/lib";

const cx = classNames.bind(styles);

type Preview = File & {
  preview: any;
};

type OwnProps = {
  name: string;
  message?: string;
  value?: string | Preview;
};

const defaultProps = {
  message: "Click or drag-and-drop your photo to upload",
};
type DefaultProps = Readonly<typeof defaultProps>;

const PhotoUpload: React.SFC<OwnProps> = (props: OwnProps) => {
  const { name, message, ...rest } = props;
  const value: string | Preview = props.value;
  let photoUrl: string;
  if (isString(value)) {
    photoUrl = value;
  } else if (value) {
    photoUrl = value.preview;
  }
  return (
    <div className={cx({ outer: true, existing: photoUrl })}>
      {photoUrl && (
        <div
          className={styles.photo}
          style={{ backgroundImage: `url(${photoUrl})` }}
        />
      )}
      <Upload
        showPreview={false}
        message={message}
        multiple={false}
        name={name}
        className={styles.upload}
        icon={Icons.Image}
        {...rest}
      />
    </div>
  );
};

(PhotoUpload.defaultProps as DefaultProps) = defaultProps;

export { PhotoUpload };
export default PhotoUpload;
