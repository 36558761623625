import Types, { Payload } from "./types";

import { Action } from "@app/state/types";
import Application from "@app/models/application";

const fetching = (): Action<Payload> => {
  return {
    type: Types.FETCHING,
    payload: {
      loading: true,
      data: null,
    },
  };
};

const fetchSuccess = (application: Application): Action<Payload> => {
  return {
    type: Types.FETCH_SUCCESS,
    payload: {
      loading: false,
      data: application,
    },
  };
};

const fetchError = (): Action<Payload> => {
  return {
    type: Types.FETCH_ERROR,
    payload: {
      loading: false,
      data: null,
    },
  };
};

const create = (): Action<Payload> => {
  return {
    type: Types.CREATE,
    payload: {
      loading: true,
      data: null,
    },
  };
};

const createSuccess = (application: Application): Action<Payload> => {
  return {
    type: Types.CREATE_SUCCESS,
    payload: {
      loading: false,
      data: application,
    },
  };
};

const createError = (): Action<Payload> => {
  return {
    type: Types.CREATE_ERROR,
    payload: {
      loading: false,
      data: null,
    },
  };
};

const update = (): Action<Payload> => {
  return {
    type: Types.UPDATE,
    payload: {
      loading: true,
      data: null,
    },
  };
};

const updateSuccess = (application: Application): Action<Payload> => {
  return {
    type: Types.UPDATE_SUCCESS,
    payload: {
      loading: false,
      data: application,
    },
  };
};

const updateError = (): Action<Payload> => {
  return {
    type: Types.UPDATE_ERROR,
    payload: {
      loading: false,
      data: null,
    },
  };
};

export {
  fetching,
  fetchSuccess,
  fetchError,
  create,
  createSuccess,
  createError,
  update,
  updateSuccess,
  updateError,
};
