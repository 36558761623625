import { Budget, Day, Duration, Type } from "@app/models/job";
import { clone, deepMerge } from "@ribit/lib";

import Category from "@app/models/category";
import Skill from "@app/models/skill";
import { toNameObject } from "./helpers";

export type ResidencyRequirement =
  | "not_required"
  | "australian_citizenship"
  | "permanent_resident"
  | "unknown";

class EditableJob {
  uuid: string;
  skills: Skill[];
  category: Category;
  type: Type;
  days: Day;
  duration: Duration;
  budget: Budget;
  courseCreditBudget: string;
  courseCreditDuration: Duration;
  agreedFairwork: boolean;
  residencyRequirement: ResidencyRequirement;
  address: string;
  location: string;
  workplaceAddress: string;
  workplaceLocation: string;
  owner: any;
  title: string;
  canRepost: boolean;
  supervisorJobTitle: string;
  supervisorName: string;
  supervisorPhoneNumber: string;
  supervisorEmail: string;

  static fromData(data: any): EditableJob {
    const job: EditableJob = new EditableJob();
    const clonedData: any = clone(data);
    toNameObject(clonedData, "type", "name");
    toNameObject(clonedData, "budget", "name");
    toNameObject(clonedData, "category", "name");
    toNameObject(clonedData, "days", "name");
    toNameObject(clonedData, "duration", "name");
    toNameObject(clonedData, "courseCreditDuration", "name");
    job.owner = {};
    job.agreedFairwork = false;
    const editableJob: EditableJob = deepMerge(job, clonedData);
    return editableJob;
  }

  merge = (data: any): EditableJob => {
    return EditableJob.fromData(deepMerge(this, data));
  };
}

export function humanNameForResidencyRequirement(
  residencyRequirement: ResidencyRequirement,
): string {
  switch (residencyRequirement) {
    case "australian_citizenship":
      return "Australian Citizenship";
    case "permanent_resident":
      return "Permanent Resident";
    case "not_required":
      return "Not required";
    case "unknown":
      return "Unknown";
  }
}

export default EditableJob;
