import Pagination from "@app/models/pagination";
import { Student } from "@app/models/student";

const Types = {
  FETCHING: "@@students/similar/FETCHING",
  FETCH_SUCCESS: "@@students/similar/FETCH_SUCCESS",
  CLEAR: "@@students/similar/CLEAR",
};

type Payload = {
  loading: boolean;
  data: Pagination<Student>;
};

export { Payload, Types };

export default Types;
