import * as React from "react";
import classNames from "classnames/bind";
import * as styles from "./styles.modules.css";

const cx = classNames.bind(styles);

type OwnProps = {
  children: React.ReactNode;
  grid?:
    | "one"
    | "two"
    | "three"
    | "four"
    | "medium-span"
    | "medium-span-small"
    | "small-span"
    | "span-small"
    | "span-medium";
  className?: string;
  dataCy?: string;
};

const Container: React.FunctionComponent<OwnProps> = (props: OwnProps) => {
  const { children, grid, className, dataCy } = props;
  const className_: string = cx({
    container: true,
    grid: grid,
    [grid]: grid,
    [className]: className,
  });
  if (dataCy) {
    return (
      <div className={className_} data-cy={dataCy}>
        {children}
      </div>
    );
  }
  return <div className={className_}>{children}</div>;
};

export { Container };
export default Container;
