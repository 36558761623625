import {
  clear as clearAction,
  fetchError,
  fetchSuccess,
  fetching,
} from "./actions";

import { Dispatch } from "redux";
import Pagination from "@app/models/pagination";
import { Student } from "@app/models/student";
import { get } from "@app/helpers/api";

const fetch = (uuid: string, offset = 0, limit = 5): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(fetching());
    get(
      `/jobs/manage/${uuid}/recommended?offset=${offset}&limit=${limit}`,
      Student,
    )
      .then((data: Pagination<Student>) => {
        dispatch(fetchSuccess(data));
      })
      .catch(() => {
        dispatch(fetchError());
      });
  };
};

const clear = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch(clearAction());
  };
};

type Operations = {
  fetch: (uuid: string, offset?: number, limit?: number) => any;
  clear: () => void;
};

const operations: Operations = {
  fetch,
  clear,
};

export { fetch, clear, operations, Operations };
