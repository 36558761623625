import Types, { Payload } from "./types";

import { Action } from "@app/state/types";
import Company from "@app/models/company";

const fetching = (): Action<Payload> => {
  return {
    type: Types.FETCHING,
    payload: {
      loading: true,
      data: null,
    },
  };
};

const fetchSuccess = (company: Company): Action<Payload> => {
  return {
    type: Types.FETCH_SUCCESS,
    payload: {
      loading: false,
      data: company,
    },
  };
};

const fetchError = (): Action<Payload> => {
  return {
    type: Types.FETCH_ERROR,
    payload: {
      loading: false,
      data: null,
    },
  };
};

export { fetching, fetchSuccess, fetchError };
