import { fetchError, fetchSuccess, fetching } from "./actions";

import Company from "@app/models/company";
import { Dispatch } from "redux";
import { get } from "@app/helpers/api";

const fetch = (uuid: string): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(fetching());
    return get(`/companies/${uuid}`, Company)
      .then((company: Company) => {
        dispatch(fetchSuccess(company));
      })
      .catch(() => {
        dispatch(fetchError());
      });
  };
};

type Operations = {
  fetch: (uuid: string) => any;
};

const operations: Operations = {
  fetch,
};

export { operations, Operations };
