import React from "react";
import styles from "./styles.modules.css";
import { Button } from "@ribit/components";

export const PrivacyPolicyLabel = () => (
  <div className={styles.container}>
    By registering you agree to Ribit's&nbsp;
    <Button
      action="https://www.ribit.net/privacy-policy/"
      label="Privacy Policy"
      type="text"
    />
  </div>
);
