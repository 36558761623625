import { Container, Content, Loading } from "@ribit/components";
import { Operations, operations } from "@app/state/ducks/user/operations";

import React from "react";
import { Title } from "@app/components/title";
import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";

type OwnProps = Record<string, never>;
type DispatchProps = {
  operations?: Operations;
};

const PureConfirm = ({ operations }: OwnProps & DispatchProps) => {
  React.useEffect(() => {
    operations.confirmAccount();
  }, []);

  return (
    <Content>
      <Title label="Your account" />
      <Container>
        <Loading
          label="We're now confirming your account..."
          style="inverted"
        />
      </Container>
    </Content>
  );
};

const Confirm = connect(
  null,
  mapOperationsToDispatchProps(operations),
)(PureConfirm);

export { Confirm };
export default Confirm;
