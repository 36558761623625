import { clearFilters, setFilters } from "./actions";

import { Criteria } from "@app/models/search-alert";
import { Dispatch } from "redux";

const set = (filters: Criteria) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(setFilters(filters));
  };
};

const clear = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch(clearFilters());
  };
};

type Operations = {
  set: (filters: Criteria) => any;
  clear: () => void;
};

const operations: Operations = {
  set,
  clear,
};

export { clear, set, operations, Operations };
