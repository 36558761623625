import Types, { Payload } from "./types";
import { deserialize, storage } from "@ribit/lib";

import { Action } from "@app/state/types";
import Tenant from "@app/models/tenant";

const storedTenant: any = storage.from("tenant", {});

const initialState = deserialize(Tenant, storedTenant);

const reducer = (state = initialState, action: Action<Payload>) => {
  const { type } = action;
  const payload: Payload = action.payload;
  switch (type) {
    case Types.FETCH_SUCCESS:
      return payload.tenant;
    default:
      return state;
  }
};

export { reducer };

export default reducer;
