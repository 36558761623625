import * as React from "react";
import styles from "./styles.modules.css";

import { Attribute, Button, Icon, Icons } from "@ribit/components";

import SearchAlert from "@app/models/search-alert";

type OwnProps = {
  searchAlert: SearchAlert;
  onSelect: (searchAlert: SearchAlert) => void;
  onEdit: (searchAlert: SearchAlert) => void;
};

const Alert: React.FunctionComponent<OwnProps> = (props: OwnProps) => {
  const { onEdit, onSelect, searchAlert } = props;
  return (
    <div className={styles.outer}>
      <Attribute
        className={styles.info}
        name={
          (
            <Button
              className={styles.alert}
              type="text"
              label={searchAlert.name}
              action={() => onSelect(searchAlert)}
            />
          ) as any
        }
        altName={searchAlert.name}
        value={searchAlert.humanInterval}
      />
      <div className={styles.actions}>
        <Button
          style="tertiary"
          label={<Icon icon={Icons.Edit} />}
          className={styles.button}
          action={() => onEdit(searchAlert)}
        />
      </div>
    </div>
  );
};

export { Alert };
export default Alert;
