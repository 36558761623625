import * as React from "react";
import styles from "./styles.modules.css";

import { Heading } from "@ribit/components";

type OwnProps = {
  step: string;
  text: string;
};

const Title: React.FunctionComponent<OwnProps> = (props: OwnProps) => {
  const { step, text } = props;
  return (
    <div className={styles.title}>
      <Heading text={step} className={styles.step} />
      <Heading text={text} />
    </div>
  );
};

export { Title };
export default Title;
