import * as React from "react";
import classNames from "classnames/bind";
import styles from "./styles.modules.css";

import { Attribute, Button, Photo } from "@ribit/components";

const cx = classNames.bind(styles);

type OwnProps = {
  url?: string;
  name: string;
  subtitle: string;
  style?: "circle" | "square";
  size?: "small" | "medium" | "large";
  blur?: boolean;
  action?: any;
  altName?: string;
  className?: string;
};

const defaultProps = {
  style: "circle",
  size: "medium",
};
type DefaultProps = Readonly<typeof defaultProps>;

const ProfilePhoto: React.FunctionComponent<OwnProps> = (props: OwnProps) => {
  const {
    url,
    name,
    subtitle,
    style,
    size,
    altName,
    className,
    action,
    blur,
  } = props;
  const className_: string = cx({
    outer: true,
    [className]: className,
  });
  let actualName: any = name;
  let photo: any = (
    <Photo
      url={url}
      name={altName ? altName : name}
      style={style}
      size={size}
      blur={blur}
    />
  );
  if (action) {
    actualName = (
      <Button
        type="text"
        label={name}
        action={action}
        className={styles.name}
      />
    );
    photo = <Button type="text" label={photo} action={action} />;
  }
  return (
    <div className={className_}>
      {photo}
      <Attribute
        name={actualName}
        altName={name}
        value={subtitle}
        className={styles.who}
      />
    </div>
  );
};

(ProfilePhoto.defaultProps as DefaultProps) = defaultProps;

export { ProfilePhoto };
export default ProfilePhoto;
