import { Action } from "@app/state/types";
import Types from "./types";

export const clearFilters = (): Action<any> => ({
  type: Types.CLEAR,
  payload: undefined,
});

export const setFilters = filters => ({
  type: Types.SET,
  payload: filters,
});
