import { History } from "history";

const reducer = (history: History) => {
  const init = history;
  return (state = init) => {
    return state;
  };
};

export { reducer };

export default reducer;
