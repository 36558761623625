import * as React from "react";
import styles from "./styles.modules.css";

import { ActionBar, Container, Icons, Panel } from "@ribit/components";
import { Operations, operations } from "@app/state/ducks/user/operations";

import Application from "@app/models/application";
import Invitation from "@app/models/invitation";
import Job from "@app/models/job";
import { JobApplications } from "./containers";
import { JobInvitations } from "./containers";
import Pagination from "@app/models/pagination";
import { ProfileCompletion } from "@app/components/profile-completion";
import { ProfilePhoto } from "@app/components/profile-photo";
import { State } from "@app/state";
import { Student as StudentType } from "@app/models/student";
import User from "@app/models/user";
import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";
import { History } from "history";
import { Button } from "@app/components/button";
import CourseCreditApprovalRequest from "@app/models/course-credit-approval-request";
import CourseCreditRoles from "@app/pages/dashboard/student/containers/course-credit-roles";

type OwnProps = Record<string, never>;

type DispatchProps = {
  operations?: Operations;
};
type StateProps = {
  user?: StudentType;
  jobs?: Pagination<Job>;
  invitations?: Pagination<Invitation>;
  applications?: Pagination<Application>;
  courseCreditApprovalRequests?: Pagination<CourseCreditApprovalRequest>;
  history: History;
};
type StudentProps = StateProps & DispatchProps & OwnProps;

const PureStudent: React.FunctionComponent<StudentProps> = (
  props: StudentProps,
) => {
  const {
    user,
    invitations,
    applications,
    courseCreditApprovalRequests,
  } = props;
  const { photo, institution } = user.profile;

  return (
    <Container grid="medium-span">
      <div>
        <ProfilePhoto
          className={styles.photo}
          name={user.fullName}
          url={photo}
          subtitle={`Student${institution ? ` at ${institution.name}` : ""}`}
        />
        <ProfileCompletion
          items={[
            { label: "Set your bio", field: "profile.biography" },
            {
              label: "Add your skills",
              field: "profile.skills",
              validation: (user: User): boolean => {
                const student: StudentType = user as StudentType;
                return student.profile.skills.length > 0;
              },
            },
            {
              label: "Set work locations",
              field: "profile.locations",
              validation: (user: User): boolean => {
                const student: StudentType = user as StudentType;
                return student.profile.isLocationSet();
              },
            },
            { label: "Set your postcode", field: "profile.postcode" },
            {
              label: "Set your study information",
              field: "profile.courseDegree",
            },
            {
              label: "Set your mobile number",
              field: "profile.mobilePhoneNumber",
            },
          ]}
          user={user}
          minCompleteMessage="Complete the following important details to start applying for jobs"
          completeMessage="Get to 100% profile completion to increase your chances of being hired"
        />
      </div>
      <div>
        <ActionBar className={styles.actionBar}>
          <Button
            label="Search for jobs"
            action="/jobs"
            style="secondary"
            dataCy="button__search-job"
          />
        </ActionBar>
        <Panel title="Job applications" icon={Icons.Suitcase}>
          <JobApplications
            data={applications}
            user={user}
            history={props.history}
          />
        </Panel>
        <CourseCreditRoles
          data={courseCreditApprovalRequests}
          user={user}
          history={props.history}
        />
        <Panel title="Job Invitations" icon={Icons.Lightbulb}>
          <JobInvitations data={invitations} />
        </Panel>
      </div>
    </Container>
  );
};

const mapStateToProps = (state: State): StateProps => {
  return {
    user: state.user as StudentType,
    invitations: state.jobInvitations.data,
    applications: state.jobApplications.data,
    courseCreditApprovalRequests: state.courseCreditApprovalRequests.data,
    history: state.history,
  };
};

const Student = connect(
  mapStateToProps,
  mapOperationsToDispatchProps(operations),
)(PureStudent);

export { Student };
export default Student;
