import * as React from "react";
import styles from "./styles.modules.css";

import {
  ActionBar,
  Button,
  Container,
  Content,
  Field,
  Form,
  Icons,
  Input,
  PageBar,
  Panel,
  Select,
  Separator,
  Text,
} from "@ribit/components";
import {
  Operations as MatchesOperations,
  operations as matchesOperations,
} from "@app/state/ducks/students/matches/operations";

import Location from "@app/models/location";
import { ModalSelect } from "@app/components/modal-select";
import Pagination from "@app/models/pagination";
import { QueryStringFilter } from "@app/types";
import Skill from "@app/models/skill";
import { State } from "@app/state";
import { Student } from "@app/models/student";
import { Students } from "./components";
import { Title } from "@app/components/title";
import { Type } from "@app/models/job";
import { ViewStudent } from "@app/containers/view-student";
import { connect } from "react-redux";
import { lookup } from "@app/helpers/api";
import { mapOperationsToDispatchProps } from "@ribit/lib";
import Employer from "@app/models/employer";

const initialFormValues = {
  sort: "match",
};

type OwnProps = Record<string, never>;
type DispatchProps = {
  operations?: {
    matches: MatchesOperations;
  };
};
type StateProps = {
  matches?: Pagination<Student>;
  similar?: Pagination<Student>;
  matchesLoading?: boolean;
  similarLoading?: boolean;
  currentEmployer?: Employer;
};
type StudentProps = StateProps & DispatchProps & OwnProps;
type OwnState = {
  selected?: Student;
  filters: QueryStringFilter;
};

class PureFindStudents extends React.Component<StudentProps, OwnState> {
  constructor(props, context) {
    super(props, context);
    this.state = {
      selected: undefined,
      filters: initialFormValues,
    };
  }

  private dismiss = () => this.setState({ ...this.state, selected: undefined });

  clear = (reset = false) => {
    const { operations } = this.props;
    operations.matches.clear();
    if (reset) {
      this.setState({
        filters: initialFormValues,
      });
    }
  };

  fetch = () => {
    const { operations, matches } = this.props;
    const { filters } = this.state;
    const url = new URL(window.location.href);
    const newFilters = { ...filters };
    const uuid = url.searchParams.get("uuid");
    if (uuid) {
      newFilters.uuid = uuid;
    }
    operations.matches.fetch(matches.results.length, 10, newFilters);
  };

  componentDidMount() {
    this.fetch();
  }

  componentWillUnmount() {
    this.clear();
  }

  private _renderStudentProfile(): React.ReactElement {
    return (
      <ViewStudent
        blur
        student={this.state.selected}
        dismissed={this.dismiss}
        currentEmployer={this.props.currentEmployer}
      />
    );
  }

  viewStudentProfile = (student: Student) => {
    this.setState({ selected: student });
  };

  render(): React.ReactElement<any> {
    const { matches, similar, matchesLoading } = this.props;

    return (
      <Form
        initial={initialFormValues}
        onSubmit={values => {
          const filterValues = Object.assign({}, values);
          if (filterValues.skills) {
            filterValues.skills = filterValues.skills.map(skill => skill.id);
          }
          this.clear();
          this.setState({ filters: filterValues });
          this.fetch();
        }}
      >
        {props => {
          const { reset, submit, values, valueChanged } = props;
          return (
            <>
              <Title label="Find students" />
              <PageBar
                grid="span-small"
                left={
                  <Field className={styles.keywords} label={null}>
                    <Input
                      icon={Icons.Search}
                      name="keywords"
                      placeholder="Search for a student..."
                      style="transparent"
                    />
                  </Field>
                }
                right={
                  <Text>
                    {matches.count} results found
                    {similar.count > 0
                      ? ` (${similar.count} similar found)`
                      : null}
                  </Text>
                }
              />

              <Content>
                <Container grid="medium-span">
                  <div>
                    <Panel title="Filter Students" icon={Icons.Sliders}>
                      <Field layout="horizontal" className={styles.field}>
                        <Select
                          name="locations"
                          async={{
                            callback: lookup(
                              Location,
                              "/locations",
                              (locations: Location[]) => {
                                return locations.map(location => {
                                  return {
                                    label: location.name,
                                    value: location.id,
                                  };
                                });
                              },
                            ),
                          }}
                          multiple
                          placeholder="Select locations..."
                        />
                      </Field>
                      <ModalSelect
                        itemsName="Skills"
                        value={values.skills}
                        onChange={valueChanged}
                        name="skills"
                        modalTitle="Select some skills..."
                        layout="horizontal"
                        options={[]}
                        modalCallback={lookup(
                          Skill,
                          "/skills",
                          (skills: Skill[]) => {
                            return skills.map(skill => {
                              return {
                                label: skill.name,
                                value: skill,
                              };
                            });
                          },
                          0,
                          100,
                        )}
                        async={{
                          loadingText: "Retrieving skills...",
                          callback: lookup(
                            Skill,
                            "/skills",
                            (skills: Skill[]) => {
                              return skills.map(skill => {
                                return {
                                  label: skill.name,
                                  value: skill,
                                };
                              });
                            },
                          ),
                        }}
                        placeholder="Select some skills..."
                        actionLabel="View all"
                      />
                      <Field
                        layout="horizontal"
                        label="Role types"
                        className={styles.field}
                      >
                        <Select
                          name="types"
                          multiple
                          async={{
                            callback: lookup(
                              Type,
                              "/jobs/types",
                              (types: Type[]) => {
                                return types.map(type => {
                                  return {
                                    label: type.name,
                                    value: type.id,
                                  };
                                });
                              },
                            ),
                          }}
                          placeholder="Select a role type..."
                        />
                      </Field>
                      <Field layout="horizontal" className={styles.field}>
                        <Select
                          name="workEligibility"
                          multiple
                          options={[
                            {
                              value: "permanent_resident",
                              label: "Australian citizen or permanent resident",
                            },
                            {
                              value: "working_visa",
                              label: "Valid working visa",
                            },
                            { value: "sponsorship", label: "Visa sponsorship" },
                          ]}
                          placeholder="Select eligibility..."
                        />
                      </Field>
                      <Field
                        label="International or Domestic"
                        layout="horizontal"
                        className={styles.field}
                      >
                        <Select
                          name="international"
                          options={[
                            { label: "Domestic", value: false },
                            { label: "International", value: true },
                          ]}
                          placeholder="Choose..."
                        />
                      </Field>
                      <Field layout="horizontal" className={styles.field}>
                        <Select
                          name="sort"
                          options={[
                            { label: "Best Match", value: "match" },
                            { label: "Alphabetical", value: "alphabetical" },
                            { label: "Latest", value: "latest" },
                          ]}
                          placeholder="Select a sort method"
                        />
                      </Field>
                      <ActionBar layout="center" className={styles.actions}>
                        <Button
                          label="Filter students"
                          style="tertiary"
                          type="submit"
                          layout="full-width"
                        />
                        <Separator text="or" />
                        <Button
                          label="Reset"
                          type="text"
                          layout="full-width"
                          action={() => {
                            reset();
                            submit();
                          }}
                        />
                      </ActionBar>
                    </Panel>
                  </div>
                  <div>
                    <Panel>
                      <Students
                        data={matches}
                        loading={matchesLoading}
                        searchAction={() => {
                          this.clear();
                          this.fetch();
                        }}
                        viewMoreAction={this.fetch}
                        viewStudentAction={this.viewStudentProfile}
                      />
                    </Panel>
                    {this._renderStudentProfile()}
                  </div>
                </Container>
              </Content>
            </>
          );
        }}
      </Form>
    );
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    matches: state.students.matches.data,
    similar: state.students.similar.data,
    matchesLoading: state.students.matches.loading,
    similarLoading: state.students.similar.loading,
    currentEmployer: state.user as Employer,
  };
};

const FindStudents = connect(
  mapStateToProps,
  mapOperationsToDispatchProps({
    // search (API call) happens under "matchesOperations"
    matches: matchesOperations,
  }),
)(PureFindStudents);

export { FindStudents };
export default FindStudents;
