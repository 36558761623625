import * as React from "react";
import * as queryString from "query-string";

import {
  Operations as FilterOperations,
  operations as filterOperations,
} from "@app/state/ducks/find-jobs-filters/operations";
import {
  Operations,
  operations,
} from "@app/state/ducks/search-alerts/operations";
import { RouteComponentProps } from "@app/types";
import { withRouter } from "@app/helpers/hooks";

import { List } from "../components/search-alerts";
import Pagination from "@app/models/pagination";
import SearchAlert from "@app/models/search-alert";
import { State } from "@app/state";
import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";

type OwnProps = {
  onEdit: (searchAlert: SearchAlert) => void;
};
type DispatchProps = {
  operations?: {
    searchAlerts: Operations;
    filters: FilterOperations;
  };
};
type StateProps = {
  searchAlerts?: Pagination<SearchAlert>;
  loading?: boolean;
};
type SearchAlertsProps = OwnProps &
  DispatchProps &
  StateProps &
  RouteComponentProps;

class PureSearchAlerts extends React.Component<SearchAlertsProps> {
  private get alertUuid(): string {
    const params = queryString.parse(this.props.match.location.search);
    return params["alert"] as any;
  }

  componentDidUpdate(prevProps: SearchAlertsProps) {
    if (
      this.props.searchAlerts &&
      !prevProps.searchAlerts.count &&
      this.alertUuid
    ) {
      const alert: SearchAlert = this.props.searchAlerts.results.find(
        alert => alert.uuid === this.alertUuid,
      );
      if (alert) {
        this.props.onEdit(alert);
      }
    }
  }

  componentDidMount() {
    this.props.operations.searchAlerts.fetch();
  }

  onSelect = (searchAlert: SearchAlert) => {
    this.props.operations.filters.set(searchAlert.criteria);
  };

  onDelete = (searchAlert: SearchAlert) => {
    this.props.operations.searchAlerts.remove(searchAlert.uuid);
  };

  render(): React.ReactElement<any> {
    const { searchAlerts, loading } = this.props;

    return (
      <>
        <List
          alerts={searchAlerts}
          loading={loading}
          onSelect={this.onSelect}
          onEdit={this.props.onEdit}
        />
      </>
    );
  }
}

const mapStateToProps = (state: State): StateProps => {
  const { loading, data } = state.searchAlerts;
  return {
    searchAlerts: data,
    loading: loading,
  };
};

const ConnectedSearchAlerts = connect(
  mapStateToProps,
  mapOperationsToDispatchProps({
    searchAlerts: operations,
    filters: filterOperations,
  }),
)(PureSearchAlerts);
const SearchAlerts = withRouter(ConnectedSearchAlerts);

export { SearchAlerts };
export default SearchAlerts;
