import Pagination from "@app/models/pagination";
import { Student } from "@app/models/student";

const Types = {
  FETCHING: "@@manage/invites/FETCHING",
  FETCH_SUCCESS: "@@manage/invites/FETCH_SUCCESS",
  FETCH_ERROR: "@@manage/invites/FETCH_ERROR",
  CLEAR: "@@manage/invites/CLEAR",
};

type Payload = {
  loading: boolean;
  data: Pagination<Student>;
};

export { Payload, Types };

export default Types;
