import Job from "@app/models/job";
import Pagination from "@app/models/pagination";

const Types = {
  FETCHING: "@@jobs/FETCHING",
  FETCH_SUCCESS: "@@jobs/FETCH_SUCCESS",
  FETCH_ERROR: "@@jobs/FETCH_ERROR",
  CLEAR: "@@jobs/CLEAR",
};

type Payload = {
  loading: boolean;
  data: Pagination<Job>;
};

export { Payload, Types };

export default Types;
