import * as React from "react";
import styles from "./styles.modules.css";

import {
  AttributeList,
  Container,
  Heading,
  Panel,
  Size,
  Tag,
} from "@ribit/components";
import {
  Operations,
  operations,
} from "@app/state/ducks/job-application/operations";
import User, { isEmployer, isPartner, isStudent } from "@app/models/user";

import Application from "@app/models/application";
import Job from "@app/models/job";
import { JobApplication } from "@app/containers/job-application";
import { ProfilePhoto } from "@app/components/profile-photo";
import { State } from "@app/state";
import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";
import markdown from "@app/helpers/markdown";
import { PendingCompanyPanel } from "@app/components/pending-company-panel/pending-company-panel";
import { humanNameForResidencyRequirement } from "../manage-jobs/models";
import Student from "@app/models/student";
import { CourseCreditApprovalRequestPopup } from "@app/containers/course-credit-approval-request";
import { Button } from "@app/components/button";
import { get } from "@app/helpers/api";
import CourseCreditApprovalRequest from "@app/models/course-credit-approval-request";

type DispatchProps = {
  operations?: Operations;
};

type StateProps = {
  application?: Application;
};

type OwnProps = {
  job: Job;
  user: User;
  review?: boolean;
};

type OwnState = {
  showApplication: boolean;
  showRequestForApproval: boolean;
  hasRequest?: boolean;
};

type JobDetailsProps = DispatchProps & StateProps & OwnProps;

class PureJobDetails extends React.Component<JobDetailsProps, OwnState> {
  state: OwnState = {
    showApplication: false,
    showRequestForApproval: false,
    hasRequest: false,
  };

  componentDidMount() {
    const { operations, job } = this.props;
    operations.fetch(job.uuid);
    this._retrieveJobRequest();
  }

  private _retrieveJobRequest() {
    const { job } = this.props;

    // requests are only for course credit jobs
    if (!job.type.isCourseCredit) {
      this.setState({ hasRequest: false });
    }

    get(
      `/jobs/course-credit-approval-request?job_uuid=${job.uuid}`,
      CourseCreditApprovalRequest,
    )
      .then(result => {
        this.setState({ hasRequest: result["count"] >= 1 });
      })
      .catch(() => {
        this.setState({ hasRequest: false });
      });
  }

  renderAboutCompany(): React.ReactElement<any> {
    const { job } = this.props;
    return (
      <Panel
        className={styles.company}
        title={`About ${job.owner.company.name}`}
      >
        {markdown(job.owner.company.description, styles.area)}
        {job.owner.company.vision && (
          <>
            <Heading text="Our vision" size={Size.Medium} />{" "}
            {markdown(job.owner.company.vision, styles.area)}
          </>
        )}
        {job.owner.company.values && (
          <>
            <Heading text="Our values" size={Size.Medium} />{" "}
            {markdown(job.owner.company.values, styles.area)}
          </>
        )}
        {job.owner.company.studentAttributes && (
          <>
            <Heading
              text="We're looking for students with these attributes..."
              size={Size.Small}
            />{" "}
            {job.owner.company.studentAttributes}
          </>
        )}
      </Panel>
    );
  }

  renderJobDescription(): React.ReactElement<any> {
    const { job } = this.props;

    return (
      <div className={styles.description}>
        <Panel title="Job description">{markdown(job.description)}</Panel>
        {this.renderAboutCompany()}
      </div>
    );
  }

  renderJobDetails(): React.ReactElement<any> {
    const { job } = this.props;
    const isCourseCredit = job.type.isCourseCredit;
    let aboutDetails: any[] = [
      { name: "Area", value: job.category },
      { name: "Job type", value: job.type.name },
      { name: isCourseCredit ? "Allowance" : "Pay rate", value: job.budget },
      { name: "Days per week", value: job.days },
      { name: "Role duration", value: job.duration },
      { name: "Location", value: job.address },
      isCourseCredit
        ? {
            name: "Number of available placements",
            value: job.numberOfStudents,
          }
        : null,
      {
        name: "Skills required",
        value: job.skills.map(skill => (
          <Tag label={skill.name} key={skill.id} />
        )),
      },
      {
        name: "Residency requirements",
        value: humanNameForResidencyRequirement(job.residencyRequirement),
      },
    ];

    // remove null values if any
    aboutDetails = aboutDetails.filter(Boolean);

    return (
      <Panel title="About the job">
        <AttributeList items={aboutDetails} />
        {this.renderWorkStatusWarning()}
      </Panel>
    );
  }

  renderWorkplaceDetails(): React.ReactElement<any> {
    const { job, user } = this.props;

    if (!isEmployer(user)) {
      return null;
    }

    const workplaceDetails = [
      { name: "Working onsite", value: job.workingOnsite },
      {
        name: "Dedicated working space",
        value: job.hasDedicatedWorkingSpace ? "Yes" : "No",
      },
      { name: "Business / mailing address", value: job.workplaceAddress },
    ];

    return (
      <Panel title="Workplace">
        <AttributeList items={workplaceDetails} />
      </Panel>
    );
  }

  renderSupervisorDetails(): React.ReactElement<any> {
    const { job, user } = this.props;

    if (!isEmployer(user)) {
      return null;
    }

    const supervisorDetails = [
      { name: "Name", value: job.supervisorName },
      { name: "Title", value: job.supervisorJobTitle },
      { name: "Email (not visible to students)", value: job.supervisorEmail },
      {
        name: "Phone number (not visible to students)",
        value: job.supervisorPhoneNumber,
      },
    ];

    return (
      <Panel title="Supervisor">
        <AttributeList items={supervisorDetails} />
      </Panel>
    );
  }

  renderWorkStatusWarning() {
    const { user, job } = this.props;
    if (
      user.userType !== 1 ||
      (user as Student).profile.workEligibility === "permanent_resident"
    ) {
      return null;
    }
    if (
      job.residencyRequirement === "australian_citizenship" ||
      job.residencyRequirement === "permanent_resident"
    ) {
      return (
        <div className={styles.warningPanel}>
          Based on your work eligibility set in your profile, you might not be
          able to apply for this job.
        </div>
      );
    }

    return null;
  }

  renderEmployerDetails(): React.ReactElement<any> {
    const { job } = this.props;

    const employerDetails: any[] = [];
    if (job.owner.company.url) {
      employerDetails.push({
        name: "Website",
        value: (
          <Button
            type="text"
            label={job.owner.company.url}
            action={job.owner.company.url}
          />
        ),
      });
    }
    if (job.owner.company.industryCode) {
      employerDetails.push({
        name: "Industry",
        value: job.owner.company.industryCode.name,
      });
    }
    if (job.owner.company.linkedin && job.owner.company.linkedin.length) {
      employerDetails.push({
        name: "Business LinkedIn",
        value: (
          <Button
            type="text"
            label={job.owner.company.fullLinkedInUrl}
            action={job.owner.company.fullLinkedInUrl}
          />
        ),
      });
    }
    if (job.owner.company.abn) {
      employerDetails.push({ name: "ABN", value: job.owner.company.abn });
    }
    return (
      <Panel title="Employer details">
        <ProfilePhoto
          className={styles.employer}
          name={job.owner.company.name}
          altName={job.owner.company.name}
          action={`/companies/${job.owner.company.uuid}`}
          url={job.owner.company.logo}
          subtitle={`Has posted ${job.owner.company.postCount} roles on Ribit`}
        />
        <AttributeList items={employerDetails} />
      </Panel>
    );
  }

  renderPreApprovals(): React.ReactElement<any> {
    const { job } = this.props;
    const preApprovals = job.preApprovals;

    if (
      job.preApprovals === undefined ||
      job.preApprovals === null ||
      job.preApprovals.length === 0
    ) {
      return null;
    }

    const htmlItems = preApprovals.map((preApproval, approvalIndex) => {
      const courseNames = preApproval.courses.map(course => {
        if (course.link.length > 5) {
          return (
            <a
              href={course.link}
              key={course.id}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Tag label={`${course.code} ${course.name}`} />
            </a>
          );
        }
        return <Tag label={`${course.code} ${course.name}`} key={course.id} />;
      });

      return (
        <>
          {approvalIndex > 0 ? (
            <>
              <br />
              <br />
            </>
          ) : null}
          <p key={`approval-${preApproval.tenant.name}`}>
            Students from <strong>{preApproval.tenant.name}</strong> enrolled or
            enrolling in the following courses are pre-approved to apply for
            this role. Please refer to{" "}
            <Button
              key="our FAQs"
              className="link"
              type="text"
              action="https://www.ribit.net/student-faqs/"
              label="our FAQs"
            />{" "}
            for more information.
          </p>
          <br />
          {courseNames}
        </>
      );
    });

    return (
      <Panel title="University Pre-approval" key="university-approval">
        {htmlItems}
      </Panel>
    );
  }

  renderCta(): React.ReactElement<any> {
    const { review, user, job, application } = this.props;
    let ctaLabel = "Sign in or sign up to apply for this role!";
    let ctaAction: any = `/auth/login?redirect=/jobs/${job.slug}&type=student`;
    let ctaVisible = !job.isClosed && !job.isFilled;
    let ctaDisabled = false;
    if (isEmployer(user) && user.uuid === job.owner.uuid) {
      ctaLabel = "Manage job";
      ctaAction = `/manage/jobs/${job.uuid}`;
    } else if (isEmployer(user) || isPartner(user)) {
      ctaVisible = false;
    } else if (isStudent(user) && (job.isClosed || job.isFilled)) {
      ctaVisible = true;
      ctaAction = null;
      ctaDisabled = true;
      ctaLabel = "This job has been closed";
    } else if (isStudent(user) && user.canApplyForJobs) {
      ctaLabel = "Apply for this job";
      if (application) {
        ctaLabel = "Applied for this job";
        ctaDisabled = true;
      } else {
        ctaAction = () => {
          this.setState({ showApplication: true });
        };
      }
    } else if (isStudent(user)) {
      ctaLabel = "Complete your profile before applying";
      ctaAction = "/account";
    } else if (job.isClosed || job.isFilled) {
      ctaVisible = true;
      ctaAction = null;
      ctaDisabled = true;
      ctaLabel = "This job has been closed";
    }
    if (review || !ctaVisible) {
      return null;
    }
    return (
      <Button
        className={styles.jobAction}
        style="secondary"
        label={ctaLabel}
        action={ctaAction}
        disabled={ctaDisabled}
        layout="full-width"
      />
    );
  }

  renderRequestApprovalButton(): React.ReactElement<any> {
    const { user, job } = this.props;
    const { hasRequest } = this.state;

    if (
      job.type.isCourseCredit &&
      isStudent(user) &&
      !job.isClosed &&
      !job.isFilled
    ) {
      return (
        <Button
          className={styles.jobAction}
          style="secondary"
          label={
            hasRequest ? "Request sent to supervisor" : "Send to my supervisor"
          }
          disabled={hasRequest}
          action={() => {
            this.setState({ showRequestForApproval: true });
          }}
          layout="full-width"
          dataCy="button__send-to-supervisor"
        />
      );
    }
    return null;
  }

  renderPendingApprovalPanel() {
    const user = this.props.user;
    const job = this.props.job;
    if (!isEmployer(user)) {
      return null;
    }

    if (job.owner.company.uuid !== user.company.uuid) {
      return null;
    }

    return (
      <Container>
        <PendingCompanyPanel companyApproved={user.company.approved} />
      </Container>
    );
  }

  render(): React.ReactElement<any> {
    const { job, application, user } = this.props;
    const { showApplication, showRequestForApproval, hasRequest } = this.state;

    return (
      <div>
        {this.renderPendingApprovalPanel()}
        <Container grid="span-medium">
          <JobApplication
            show={showApplication && !application}
            job={job}
            application={application}
            onCancel={() => {
              this.setState({ showApplication: false });
            }}
          />
          <CourseCreditApprovalRequestPopup
            show={showRequestForApproval && !hasRequest}
            job={job}
            onSubmit={() => {
              this.setState({ hasRequest: true });
            }}
            onCancel={() => {
              this.setState({ showRequestForApproval: false });
            }}
            user={user}
          />
          {this.renderJobDescription()}
          <div className={styles.details}>
            {this.renderRequestApprovalButton()}
            {this.renderCta()}
            {this.renderJobDetails()}
            {job.type.isCourseCredit && this.renderSupervisorDetails()}
            {job.type.isCourseCredit && this.renderWorkplaceDetails()}
            {this.renderPreApprovals()}
            {this.renderAboutCompany()}
            {this.renderEmployerDetails()}
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    application: state.jobApplication.data,
  };
};

const JobDetails = connect(
  mapStateToProps,
  mapOperationsToDispatchProps(operations),
)(PureJobDetails);

export { JobDetails };

export default JobDetails;
