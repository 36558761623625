import * as React from "react";

import {
  AttributeList,
  Banner,
  Button,
  Container,
  Content,
  Heading,
  Loading,
  Panel,
  Size,
} from "@ribit/components";
import { Operations, operations } from "@app/state/ducks/company/operations";
import { RouteComponentProps } from "@app/types";
import { withRouter } from "@app/helpers/hooks";

import Company from "@app/models/company";
import { Error404 } from "../error404";
import { ProfilePhoto } from "@app/components/profile-photo";
import { State } from "@app/state";
import { Title } from "@app/components/title";
import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";
import markdown from "@app/helpers/markdown";
// import { parsePhoneNumberFromString } from "libphonenumber-js";
import styles from "./styles.modules.css";

type OwnProps = Record<string, never>;
type StateProps = {
  company: Company;
  loading?: boolean;
};
type DispatchProps = {
  operations: Operations;
};
type ViewProps = RouteComponentProps & OwnProps & StateProps & DispatchProps;

const headerImage =
  "https://images.unsplash.com/photo-1556761175-4b46a572b786?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=80";

class PureView extends React.Component<ViewProps> {
  private get uuid(): string {
    return this.props.match.params["uuid"];
  }

  componentDidMount() {
    this.props.operations.fetch(this.uuid);
  }

  private _renderNotFound(): React.ReactElement<any> {
    return <Error404 />;
  }

  private _renderLoading(): React.ReactElement<any> {
    return (
      <>
        <Title label="Loading..." />
        <Banner
          title="..."
          subtitle={null}
          attributes={null}
          action={null}
          image={headerImage}
        />
        <Content>
          <Container grid="span-medium">
            <div>
              <Panel title="Business overview">
                <Loading style="inverted" />
              </Panel>
            </div>
            <div>
              <Panel title="Details">
                <Loading style="inverted" />
              </Panel>
            </div>
          </Container>
        </Content>
      </>
    );
  }

  private _renderLoaded(): React.ReactElement<any> {
    const { company } = this.props;
    // let phoneNumber = "Unknown";
    // const parsedNumber = parsePhoneNumberFromString(company.phoneNumber);
    // if (parsedNumber && parsedNumber.isValid()) {
    //   phoneNumber = parsedNumber.formatInternational();
    // }
    const attributes: object[] = [
      {
        name: "Website",
        value: <Button type="text" label={company.url} action={company.url} />,
      },
      // { name: "Phone Number", value: phoneNumber },
    ];
    if (company.linkedin) {
      attributes.push({
        name: "LinkedIn",
        value: (
          <Button
            type="text"
            action={company.fullLinkedInUrl}
            label={company.fullLinkedInUrl}
          />
        ),
      });
    }
    return (
      <>
        <Title label={company.name} />
        <Banner
          title={company.name}
          subtitle={null}
          attributes={[
            { name: "Verified", value: company.approved ? "Yes" : "No" },
            {
              name: "Size",
              value: company.size ? company.size.name : "Unknown",
            },
            {
              name: "Industry",
              value: company.industryCode
                ? company.industryCode.name
                : "Unknown",
            },
          ]}
          action={null}
          image={headerImage}
        />
        <Content>
          <Container grid="span-medium">
            <div>
              <Panel title="Business overview">
                {markdown(company.description, styles.area)}
                {!!company.vision && (
                  <>
                    <Heading text="Our vision" size={Size.Medium} />
                    {markdown(company.vision, styles.area)}
                  </>
                )}
                {!!company.values && (
                  <>
                    <Heading text="Our values" size={Size.Medium} />
                    {markdown(company.values, styles.area)}
                  </>
                )}
                {!!company.studentAttributes && (
                  <>
                    <Heading
                      text="We're looking for students with these attributes..."
                      size={Size.Small}
                    />
                    {company.studentAttributes}
                  </>
                )}
              </Panel>
            </div>
            <div>
              <Panel title="Details">
                <ProfilePhoto
                  className={styles.who}
                  name={company.name}
                  url={company.logo}
                  subtitle={`Has posted ${company.postCount} roles on Ribit`}
                />
                <AttributeList items={attributes as any} />
              </Panel>
            </div>
          </Container>
        </Content>
      </>
    );
  }

  render(): React.ReactElement<any> {
    const { company, loading } = this.props;
    if (loading === false && !company) {
      return this._renderNotFound();
    }
    if (loading || loading === null) {
      return this._renderLoading();
    }
    return this._renderLoaded();
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    company: state.company.data,
    loading: state.company.loading,
  };
};

const ConnectedView = connect(
  mapStateToProps,
  mapOperationsToDispatchProps(operations),
)(PureView);
const View = withRouter(ConnectedView);

export { View };
export default View;
