import * as queryString from "query-string";

import {
  clear as clearAction,
  fetchError,
  fetchSuccess,
  fetching,
} from "./actions";

import { Dispatch } from "redux";
import Pagination from "@app/models/pagination";
import { QueryStringFilter } from "@app/types";
import { Student } from "@app/models/student";
import { get } from "@app/helpers/api";

const fetch = (
  offset = 0,
  limit = 10,
  filters: QueryStringFilter = {},
): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(fetching());
    return get(
      `/students?${queryString.stringify(filters, {
        arrayFormat: "comma",
      })}&offset=${offset}&limit=${limit}`,
      Student,
    )
      .then((data: Pagination<Student>) => {
        dispatch(fetchSuccess(data));
        return data;
      })
      .catch(() => {
        dispatch(fetchError());
      });
  };
};

const clear = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch(clearAction());
  };
};

type Operations = {
  fetch: (offset?: number, limit?: number, filters?: QueryStringFilter) => any;
  clear: () => void;
};

const operations: Operations = {
  fetch,
  clear,
};

export { fetch, clear, operations, Operations };
