import * as React from "react";

import { Banner, Content, Loading } from "@ribit/components";
import { Operations, operations } from "@app/state/ducks/job/operations";
import { RouteComponentProps } from "@app/types";
import { Navigate } from "react-router-dom";
import { withRouter } from "@app/helpers/hooks";

import Job from "@app/models/job";
import JobDetails from "./job-details";
import { State } from "@app/state";
import { Title } from "@app/components/title";
import User from "@app/models/user";
import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";

type OwnProps = Record<string, never>;
type OwnState = {
  bannerImage: string;
};
type StateProps = {
  job: Job;
  loading: boolean;
  user: User;
};
type DispatchProps = {
  operations: Operations;
};
type ViewProps = RouteComponentProps & StateProps & DispatchProps & OwnProps;

const randomBannerImage = (): string => {
  const banners: string[] = [
    "1503428593586-e225b39bddfe",
    "1454165804606-c3d57bc86b40",
    "1517245386807-bb43f82c33c4",
    "1556559322-b5071efadc88",
    "1517048676732-d65bc937f952",
    "1522071820081-009f0129c71c",
    "1521737852567-6949f3f9f2b5",
  ];

  const randomBannerIndex: number =
    Math.floor(Math.random() * banners.length - 1) + 1;
  return `https://images.unsplash.com/photo-${banners[randomBannerIndex]}?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=36c64b55cbc13bba1e00133b3fcbb8fd&auto=format&fit=crop&w=1350&q=80`;
};

class PureView extends React.Component<ViewProps, OwnState> {
  constructor(props: ViewProps) {
    super(props);
    this.state = {
      bannerImage: randomBannerImage(),
    };
  }

  private get slug(): string {
    return this.props.match.params["slug"];
  }

  componentDidMount() {
    const { operations } = this.props;
    operations.retrieveJobBySlug(this.slug);
  }

  render(): React.ReactElement<any> {
    const { loading, job, user } = this.props;
    const { bannerImage } = this.state;
    if (!job) {
      if (loading === false) {
        return <Navigate to="/404" />;
      }
      return (
        <>
          <Title label="Retrieving job..." />
          <Banner
            title={null}
            subtitle={null}
            attributes={[]}
            action={null}
            image={bannerImage}
          />
          <Content>
            <Loading style="inverted" label="Retrieving job..." />
          </Content>
        </>
      );
    }
    const attributes = [
      {
        name: "Business",
        value: job.owner ? job.owner.company.name : "",
      },
      { name: "Type", value: job.type.name },
      { name: "Location", value: job.address },
    ];
    if (job.postedOn && job.postedOn.indexOf("Ribit") < 0) {
      attributes.push({ name: "Posted On", value: job.postedOn });
    }
    return (
      <>
        <Title label={job.title} />
        <Banner
          title={job.title}
          subtitle={null}
          attributes={attributes}
          action={null}
          image={bannerImage}
        />
        <Content>
          <JobDetails job={job} user={user} />
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    job: state.job.data,
    loading: state.job.loading,
    user: state.user,
  };
};

const ConnectedView = connect(
  mapStateToProps,
  mapOperationsToDispatchProps(operations),
)(PureView);
const View = withRouter(ConnectedView);

export { View };
export default View;
