import Types, { FilePayload, Payload, PhotoPayload } from "./types";

import { Action } from "@app/state/types";
import FileUpload from "@app/models/file-upload";
import User from "@app/models/user";

const loginAttempt = (username: string): Action<Payload> => {
  return {
    type: Types.LOGIN_ATTEMPT,
    payload: {
      user: null,
      message: `Attempting to login as ${username}..."`,
    },
  };
};

const loginSuccess = (user: User): Action<Payload> => {
  return {
    type: Types.LOGIN_SUCCESS,
    payload: {
      user,
    },
  };
};

const loginError = (): Action<Payload> => {
  return {
    type: Types.LOGIN_FAILED,
    payload: {
      user: null,
      message: "Failed to login, are you sure your credentials are correct?",
    },
  };
};

const logoutSuccess = (): Action<Payload> => {
  return {
    type: Types.LOGOUT,
    payload: {
      user: null,
      message: "Logged out successfully.",
    },
  };
};

const registerError = (): Action<Payload> => {
  return {
    type: Types.REGISTER_ERROR,
    payload: {
      user: null,
      message: "Failed to register, please correct any errors and try again.",
    },
  };
};

const registerSuccess = (user: User): Action<Payload> => {
  return {
    type: Types.REGISTER_SUCCESS,
    payload: {
      user,
      message: "Successfully created your account, logging in...",
    },
  };
};

const updateProfileAttempt = (): Action<Payload> => {
  return {
    type: Types.UPDATE_PROFILE_ATTEMPT,
    payload: {
      user: null,
    },
  };
};

const updateProfileSuccess = (user: User): Action<Payload> => {
  return {
    type: Types.UPDATE_PROFILE_SUCCESS,
    payload: {
      user: user,
      message: "Successfully updated your profile...",
    },
  };
};

const updateProfileError = (): Action<Payload> => {
  return {
    type: Types.UPDATE_PROFILE_ERROR,
    payload: {
      user: null,
      message:
        "Unable to update your profile. Please <a href='mailto:support@ribit.net'>email</a> us so we can help!",
    },
  };
};

const fetchProfileAttempt = (): Action<Payload> => {
  return {
    type: Types.FETCH_PROFILE_ATTEMPT,
    payload: {
      user: null,
      message: "Fetching user profile...",
    },
  };
};

const fetchProfileSuccess = (user: User): Action<Payload> => {
  return {
    type: Types.FETCH_PROFILE_SUCCESS,
    payload: {
      user: user,
    },
  };
};

const fetchProfileError = (): Action<Payload> => {
  return {
    type: Types.FETCH_PROFILE_ERROR,
    payload: {
      user: null,
    },
  };
};

const uploadProfilePhotoAttempt = (): Action<PhotoPayload> => {
  return {
    type: Types.UPLOAD_PROFILE_PHOTO_ATTEMPT,
    payload: {
      url: null,
    },
  };
};

const uploadProfilePhotoSuccess = (url: string): Action<PhotoPayload> => {
  return {
    type: Types.UPLOAD_PROFILE_PHOTO_SUCCESS,
    payload: {
      url: url,
    },
  };
};

const uploadProfilePhotoError = (message: string): Action<PhotoPayload> => {
  return {
    type: Types.UPLOAD_PROFILE_PHOTO_ATTEMPT,
    payload: {
      url: null,
      message: message,
    },
  };
};

const uploadFilesAttempt = (): Action<FilePayload> => {
  return {
    type: Types.UPLOAD_FILES_ATTEMPT,
    payload: {
      files: [],
    },
  };
};

const uploadFilesSuccess = (files: FileUpload[]): Action<FilePayload> => {
  return {
    type: Types.UPLOAD_FILES_SUCCESS,
    payload: {
      files: files,
    },
  };
};

const uploadFilesError = (message: string): Action<FilePayload> => {
  return {
    type: Types.UPLOAD_FILES_ATTEMPT,
    payload: {
      files: [],
      message: message,
    },
  };
};

const changePasswordAttempt = (): Action<Payload> => {
  return {
    type: Types.CHANGE_PASSWORD_ATTEMPT,
    payload: {
      user: null,
    },
  };
};

const changePasswordSuccess = (): Action<Payload> => {
  return {
    type: Types.CHANGE_PASSWORD_SUCCESS,
    payload: {
      user: null,
      message: "Successfully changed your password...",
    },
  };
};

const changePasswordError = (): Action<Payload> => {
  return {
    type: Types.CHANGE_PASSWORD_ERROR,
    payload: {
      user: null,
      message: "An error occured changing your password...",
    },
  };
};

const initiatePasswordReset = (): Action<any> => {
  return {
    type: Types.INITIATE_RESET_PASSWORD,
    payload: {},
  };
};

const initiatePasswordResetSuccess = (email: string): Action<any> => {
  return {
    type: Types.INITIATE_RESET_PASSWORD_SUCCESS,
    payload: {
      user: {
        email: email,
      },
    },
  };
};

const registerKioskError = (): Action<Payload> => {
  return {
    type: Types.REGISTER_KIOSK_ERROR,
    payload: {
      user: null,
      message: "Failed to register, please correct any errors and try again.",
    },
  };
};

const registerKioskSuccess = (user: User): Action<Payload> => {
  return {
    type: Types.REGISTER_KIOSK_SUCCESS,
    payload: {
      user,
      message:
        "Successfully created your account, you will receive an email shortly...",
    },
  };
};

const initiatePasswordResetError = (): Action<any> => {
  return {
    type: Types.INITIATE_RESET_PASSWORD_ERROR,
    payload: {},
  };
};

export {
  loginAttempt,
  loginSuccess,
  loginError,
  logoutSuccess,
  registerError,
  registerSuccess,
  updateProfileAttempt,
  updateProfileError,
  updateProfileSuccess,
  fetchProfileAttempt,
  fetchProfileSuccess,
  fetchProfileError,
  uploadProfilePhotoAttempt,
  uploadProfilePhotoSuccess,
  uploadProfilePhotoError,
  uploadFilesAttempt,
  uploadFilesSuccess,
  uploadFilesError,
  changePasswordAttempt,
  changePasswordSuccess,
  changePasswordError,
  initiatePasswordReset,
  initiatePasswordResetError,
  initiatePasswordResetSuccess,
  registerKioskError,
  registerKioskSuccess,
};
