import * as React from "react";
import "./segment-init";

import { App } from "@app/app";
import { Provider } from "react-redux";
import { configureStore } from "@app/state";
import { render } from "react-dom";
import { storage, deserialize } from "@ribit/lib";
import Tenant from "./models/tenant";

window["APPLICATION_ENVIRONMENT"] = process.env.APPLICATION_ENVIRONMENT;
window["SENTRY_DSN"] = process.env.SENTRY_DSN;
window["GIT_HASH"] = process.env.GIT_HASH || process.env.CI_COMMIT_SHORT_SHA;

const store = configureStore();

setTimeout(() => {
  const storedTenant: any = storage.from("tenant", {});
  const tenant = deserialize(Tenant, storedTenant) as Tenant | null | undefined;

  if (tenant && tenant.segmentKey && window.analytics.load !== undefined) {
    window.analytics.load(tenant.segmentKey);
  }
}, 0);

const rootElement = document.getElementById("root");
document.addEventListener("DOMContentLoaded", () => {
  render(
    <Provider store={store}>
      <App />
    </Provider>,
    rootElement,
  );
});
