import CourseCreditApprovalRequest from "@app/models/course-credit-approval-request";
import Pagination from "@app/models/pagination";

const Types = {
  FETCHING: "@@courseCreditApprovalRequest/FETCHING",
  FETCH_SUCCESS: "@@courseCreditApprovalRequest/FETCH_SUCCESS",
  FETCH_ERROR: "@@courseCreditApprovalRequest/FETCH_ERROR",
  CLEAR: "@@courseCreditApprovalRequest/CLEAR",
};

type Payload = {
  loading: boolean;
  data: Pagination<CourseCreditApprovalRequest>;
};

export { Payload, Types };

export default Types;
