import * as React from "react";
import classNames from "classnames/bind";
import * as styles from "./styles.modules.css";

import { NavLink } from "react-router-dom";
import { isString } from "@ribit/lib";

const cx = classNames.bind(styles);

type OwnProps = {
  style?: "primary" | "secondary" | "tertiary";
  type?: "button" | "submit" | "text";
  size?: "small" | "regular" | "large";
  layout?: "content" | "full-width";
  tabIndex?: number;
  label: any;
  action?: any;
  disabled?: boolean;
  className?: string;
  activeClassName?: string;
  tag?: any;
  dataCy?: string;
};

const defaultProps = {
  style: "primary",
  type: "button",
  size: "regular",
  layout: "content",
  tabIndex: 0,
  disabled: false,
};
type DefaultProps = Readonly<typeof defaultProps>;

type ElementProps = {
  className: string;
  tabIndex: number;
  "data-cy"?: string;
  disabled: boolean;
};

interface NavLinkProps extends ElementProps {
  activeClassName: string;
  to: string;
}

interface HtmlElementProps extends ElementProps {
  onClick: any;
  href: any;
  type: string;
  target?: "_blank" | null;
}

const isInternal = (action: string): boolean => {
  return action.substr(0, 4) !== "http";
};

const Button: React.FunctionComponent<OwnProps> = (props: OwnProps) => {
  const {
    style,
    label,
    size,
    action,
    disabled,
    className,
    tabIndex,
    activeClassName,
    layout,
    dataCy,
  } = props;

  let { tag, type } = props;
  let elementProps: ElementProps = {
    className: null,
    tabIndex: tabIndex,
    "data-cy": dataCy,
  };

  const actionIsString: boolean = isString(action);
  if (type === "button" || type === "submit") {
    (elementProps as HtmlElementProps).type = type;
    tag = type = "button";
    if (!disabled) {
      if (actionIsString) {
        if (!isInternal(action as string)) {
          tag = "a";
          elementProps = {
            ...elementProps,
            target: "_blank",
            href: action,
          } as HtmlElementProps;
        } else {
          elementProps = {
            ...elementProps,
            activeClassName,
            to: action,
          } as NavLinkProps;
          tag = NavLink;
        }
      } else {
        (elementProps as HtmlElementProps).onClick = action;
      }
    }
  } else {
    if (actionIsString && isInternal(action as string)) {
      tag = NavLink;
      elementProps = {
        ...elementProps,
        activeClassName: activeClassName,
        to: action,
      } as NavLinkProps;
    } else {
      tag = "a";
      if (actionIsString && !isInternal(action as string)) {
        elementProps = {
          ...elementProps,
          target: "_blank",
        } as HtmlElementProps;
      }
    }
    if (!disabled) {
      if (!actionIsString) {
        (elementProps as HtmlElementProps).onClick = action;
      } else {
        (elementProps as HtmlElementProps).href = action;
      }
    }
  }

  const layoutName = layout === "full-width" ? "fullWidth" : layout;
  const className_: string = cx({
    [type]: true,
    [style]: true,
    [size]: true,
    disabled: disabled,
    [layoutName]: true,
    [className]: className,
  });
  elementProps.className = className_;

  // Disable the button
  // style was changed and action is ignored in certain cases in the above code
  // however this is necessary for regular Submit buttons
  if (disabled) {
    elementProps.disabled = true;
  }

  const element: React.ReactElement = React.createElement(
    tag,
    elementProps,
    label,
  );
  return element;
};

(Button.defaultProps as DefaultProps) = defaultProps;

export { Button };
export default Button;
