import Profile from "./profile";
import User from "./user";
import { hydrate } from "@ribit/lib";

class Student extends User {
  @hydrate
  profile: Profile;

  get minimumProfileCompleteness(): boolean {
    const hasCompleteness: boolean = [
      "institution",
      "courseDegree",
      "afqLevel",
      "graduateYear",
      "skills",
    ].reduce((previousValue: boolean, currentValue: string) => {
      const profileValue: any = this.profile[currentValue];
      if (Array.isArray(profileValue) && !profileValue.length) {
        return false;
      }
      if (!profileValue) {
        return false;
      }
      return previousValue;
    }, true);
    return hasCompleteness;
  }

  get doesNotRequireOnboarding(): boolean {
    return this.minimumProfileCompleteness;
  }

  get canApplyForJobs(): boolean {
    return (
      this.minimumProfileCompleteness &&
      this.profile.mobilePhoneNumber &&
      this.profile.isLocationSet() &&
      !!this.profile.biography
    );
  }

  get percentageComplete(): number {
    const fields: string[] = [
      "afqLevel",
      "biography",
      "category",
      "courseDegree",
      "facebook",
      "gender",
      "graduateYear",
      "institution",
      "internationalStudent",
      "linkedin",
      "locations",
      "mobilePhoneNumber",
      "photo",
      "portfolios",
      "postcode",
      "skills",
      "twitter",
      "workEligibility",
      "workTypes",
    ];
    const completed: number = fields.reduce(
      (previousValue: number, currentValue: string) => {
        const profileValue: any = this.profile[currentValue];
        if (currentValue === "locations") {
          if (
            profileValue.length ||
            this.profile.availabilityAnywhere ||
            this.profile.availabilityRemote
          ) {
            previousValue++;
            return previousValue;
          }
        }
        if (Array.isArray(profileValue) && !profileValue.length) {
          return previousValue;
        }
        if (
          profileValue !== undefined &&
          profileValue !== null &&
          profileValue !== ""
        ) {
          previousValue++;
        }
        return previousValue;
      },
      0,
    );
    return Math.round((completed / fields.length) * 100);
  }
}

export { Student };

export default Student;
