import Company from "@app/models/company";

const Types = {
  FETCHING: "@@company/FETCHING",
  FETCH_SUCCESS: "@@company/FETCH_SUCCESS",
  FETCH_ERROR: "@@company/FETCH_ERROR",
};

type Payload = {
  loading: boolean;
  data: Company;
};

export { Payload, Types };

export default Types;
