import Category from "./category";
import Location from "./location";
import Skill from "./skill";
import { Type } from "./job";
import { hydrate } from "@ribit/lib";

class Criteria {
  keywords: string;
  skills: Skill[];
  locations: Location[];
  types: Type[];
  category: Category[];
  sort: string;

  public munge(): any {
    const { category, skills, types, keywords, sort, locations } = this;
    return {
      keywords: keywords,
      skills: skills ? skills.map(skill => skill.id) : [],
      category: category ? category.map(category => category.id) : [],
      types: types ? types.map(type => type.id) : [],
      locations: locations ? locations.map(location => location.id) : [],
      sort: sort ? sort : "created_at",
    };
  }

  static fromFilters(filters): Criteria {
    const criteria = new Criteria();
    criteria.keywords = filters.keywords;
    criteria.skills = filters.skills;
    criteria.locations = filters.locations;
    criteria.sort = filters.sort;
    criteria.types = filters.types;
    criteria.category = filters.category;
    return criteria;
  }
}

enum Interval {
  NEVER,
  DAILY,
  WEEKLY,
}

class SearchAlert {
  readonly uuid: string;
  name: string;
  interval: Interval;
  @hydrate
  criteria: Criteria;
  readonly lastSentAt: Date;
  readonly createdAt: Date;

  public get humanInterval(): string {
    switch (this.interval) {
      case Interval.DAILY:
        return "Daily";
      case Interval.NEVER:
        return "Disabled";
      case Interval.WEEKLY:
        return "Weekly";
    }
    return "Unknown";
  }
}

export default SearchAlert;
export { Criteria, SearchAlert, Interval };
