import * as React from "react";

import { EmptyTableView } from "@app/components/empty-table-view";
import { Table } from "@ribit/components";

type OwnProps = Record<string, never>;

const Updates: React.FunctionComponent<OwnProps> = () => {
  return (
    <Table
      showColumns={false}
      columns={[{ label: "Test", key: "asd" }]}
      emptyView={
        <EmptyTableView
          span={1}
          title="No new updates"
          message="Any new updates from Ribit will be displayed here including event recommendations etc."
        />
      }
    />
  );
};

export { Updates };
export default Updates;
