import * as React from "react";
import styles from "./styles.modules.css";

import { Arrow } from "@ribit/components";

type OwnProps = Record<string, never>;

const PanelArrow: React.FunctionComponent<OwnProps> = () => {
  return <Arrow className={styles.arrow} />;
};

export { PanelArrow };
export default PanelArrow;
