import { Action, ApiData } from "@app/state/types";
import Types, { Payload } from "./types";

import Pagination from "@app/models/pagination";
import SearchAlert from "@app/models/search-alert";

const initialState: ApiData<Pagination<SearchAlert>> = {
  loading: false,
  data: {
    count: 0,
    results: [],
  },
};

const reducer = (
  state = initialState,
  action: Action<Payload>,
): ApiData<Pagination<SearchAlert>> => {
  const { type, payload } = action;
  switch (type) {
    case Types.FETCH_SUCCESS:
      return payload;
    case Types.FETCHING:
      if (!payload.data) {
        payload.data = state.data || initialState.data;
      }
      return payload;
    case Types.FETCH_ERROR:
      return payload;
    default:
      return state;
  }
};

export { reducer };

export default reducer;
