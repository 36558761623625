import * as React from "react";
import styles from "./styles.modules.css";
import * as yup from "yup";

import {
  ActionBar,
  Button,
  Field,
  Form,
  Heading,
  Icon,
  Icons,
  Input,
  Modal,
  Size,
  FormContext,
} from "@ribit/components";

import { connect } from "react-redux";

import { Operations, operations } from "@app/state/ducks/user/operations";
import { mapOperationsToDispatchProps } from "@ribit/lib";
import { alertsAdd } from "@app/state/ducks/site-alerts/actions";
import { changePasswordError } from "@app/state/ducks/user/actions";

type OwnProps = {
  name: string;
  toggleModal: any;
  modalTitle: string;
  modalIsOpen: boolean;
};

type DispatchProps = {
  operations?: Operations;
};

type OwnState = {
  saving: boolean;
  errors?: { [key: string]: string[] };
};

type ModalPasswordProps = DispatchProps & OwnProps;
const defaultProps = {};
type DefaultProps = Readonly<typeof defaultProps>;

class PureModalPassword extends React.Component<ModalPasswordProps, OwnState> {
  static defaultProps: DefaultProps = defaultProps;
  state: OwnState = {
    saving: false,
  };

  componentDidMount(): void {
    this.setState({
      saving: false,
    });
  }

  private resetInvalidError = () => {
    if (this.state.errors) {
      this.setState({
        ...this.state,
        errors: undefined,
      });
    }
  };

  render(): React.ReactElement<any> {
    const { modalTitle, operations, modalIsOpen } = this.props;
    const { saving, errors } = this.state;
    return (
      <>
        <Modal isOpen={modalIsOpen} onClose={this.props.toggleModal}>
          <div className={styles.inner}>
            <Button
              label={<Icon icon={Icons.Cross} />}
              action={this.props.toggleModal}
              className={styles.close}
            />
            <Heading
              size={Size.Medium}
              text={modalTitle}
              className={styles.heading}
            />

            <Form
              validateSchema={yup.object().shape({
                old: yup.string().required("Old Password is a required field"),
                new: yup
                  .string()
                  .min(8, "Password must be at least eight characters")
                  .required("New Password is a required field"),
                confirm: yup
                  .string()
                  .oneOf([yup.ref("new")], "Passwords must match")
                  .required("Confirm Password is a required field"),
              })}
              onSubmit={values => {
                if (saving) {
                  return;
                }

                this.resetInvalidError();
                this.setState({ ...this.state, saving: true });

                return operations
                  .changePassword(values)
                  .then(() => {
                    this.setState({ saving: false });
                    this.props.toggleModal();
                  })
                  .catch((e: { data?: any }) => {
                    if (e && e.data && e.data.errors) {
                      const { data } = e;
                      if (data.errors.old) {
                        this.setState({
                          ...this.state,
                          errors: { old: data.errors.old },
                        });
                      }
                    } else {
                      alertsAdd(
                        changePasswordError().payload.message,
                        null,
                        "Close",
                      );
                    }

                    this.setState({ ...this.state, saving: false });
                  });
              }}
            >
              <FormContext.Consumer>
                {value => (
                  <FormContext.Provider
                    value={{
                      ...value,
                      errors: {
                        ...value.errors,
                        ...errors,
                      },
                    }}
                  >
                    <Field label="Old password" required>
                      <Input name="old" type="password" />
                    </Field>
                  </FormContext.Provider>
                )}
              </FormContext.Consumer>
              <Field label="New password" required>
                <Input name="new" type="password" />
              </Field>
              <Field label="Confirm new password" required>
                <Input name="confirm" type="password" />
              </Field>
              <ActionBar layout="center">
                <Button
                  type="submit"
                  layout="full-width"
                  label="Change Password"
                  style="secondary"
                />
              </ActionBar>
            </Form>
          </div>
        </Modal>
      </>
    );
  }
}

const ModalPassword = connect(
  null,
  mapOperationsToDispatchProps(operations),
)(PureModalPassword);

export { ModalPassword };
export default ModalPassword;
