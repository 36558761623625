import * as React from "react";

import {
  ActionBar,
  Button,
  Loading,
  More,
  Table,
  Tag,
} from "@ribit/components";
import {
  Operations,
  operations,
} from "@app/state/ducks/manage/invites/operations";

import { EmptyTableView } from "@app/components/empty-table-view";
import Job from "@app/models/job";
import Pagination from "@app/models/pagination";
import { ProfilePhoto } from "@app/components/profile-photo";
import Skill from "@app/models/skill";
import { Student } from "@app/models/student";
import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";
import styles from "./styles.modules.css";

type OwnProps = {
  students: Pagination<Student>;
  job: Job;
  onSelect: (student: Student) => void;
  loading: boolean;
};

type DispatchProps = {
  operations?: Operations;
};

type SuggestedProps = DispatchProps & OwnProps;

class PureSuggested extends React.Component<SuggestedProps> {
  private _renderName = (student: Student) => {
    const { onSelect } = this.props;
    return (
      <ProfilePhoto
        name={student.fullName}
        url={student.profile.photo}
        action={() => {
          onSelect(student);
        }}
        subtitle={
          student.profile.institution
            ? student.profile.institution.name
            : "Unknown"
        }
        style="square"
        size="small"
      />
    );
  };

  private _renderActions = (student: Student) => {
    const { onSelect } = this.props;
    return (
      <Button
        label="View profile"
        style="tertiary"
        size="small"
        action={() => {
          onSelect(student);
        }}
      />
    );
  };

  private _renderSkills(student: Student) {
    const skills: Skill[] = student.profile.skills;
    return (
      <div>
        {skills.map(skill => (
          <Tag label={skill.name} key={skill.id} />
        ))}
      </div>
    );
  }

  private _renderLoadMore(): React.ReactElement<any> {
    const { students, operations, loading, job } = this.props;
    if (!students.results.length || !students.next) {
      return null;
    }

    return (
      <ActionBar layout="center">
        {loading && students.results.length ? (
          <Loading style="inverted" />
        ) : (
          <More
            label="View more"
            action={() => {
              operations.fetch(job.uuid, students.results.length);
            }}
          />
        )}
      </ActionBar>
    );
  }

  render(): React.ReactElement<any> {
    const { students, loading } = this.props;
    return (
      <>
        <Table
          className={styles.table}
          cellClassName={styles.cell}
          showColumns={false}
          columns={[
            { key: "uuid", renderer: this._renderName, width: "35%" },
            { key: "skills", renderer: this._renderSkills },
            { key: "actions", renderer: this._renderActions, width: "15%" },
          ]}
          data={students.results}
          loading={loading && !students.results.length}
          defaultLoadingLabel="Retrieving applicants for this job..."
          emptyView={
            <EmptyTableView
              span={3}
              title="Matched students"
              message="No direct matches for this job right now, check back later or use the search function to find suitable candidates."
              action="/students/search"
              actionLabel="Find more students"
            />
          }
        />
        {this._renderLoadMore()}
      </>
    );
  }
}

const Suggested = connect(
  null,
  mapOperationsToDispatchProps(operations),
)(PureSuggested);

export { Suggested };
export default Suggested;
