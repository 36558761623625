import { alertsAdd, alertsClear } from "./actions";

import { Dispatch } from "redux";

const addAlert = (
  message: any,
  action: string,
  actionLabel: string,
  type = "error",
): any => {
  return (dispatch: Dispatch<any>) => {
    return dispatch(alertsAdd(message, action, actionLabel, type));
  };
};

const clearAlerts = (): any => {
  return (dispatch: Dispatch<any>) => {
    return dispatch(alertsClear());
  };
};

type Operations = {
  addAlert: (
    message: any,
    action: string,
    actionLabel: string,
    type: string,
  ) => any;
  clearAlerts: () => any;
};

const operations: Operations = {
  addAlert,
  clearAlerts,
};

export { addAlert, clearAlerts, operations, Operations };
