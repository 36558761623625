import Industry from "./industry";
import { hydrate } from "@ribit/lib";

class Size {
  id: number;
  name: string;
}

class Company {
  uuid: string;
  name: string;
  description?: string;
  linkedin?: string;
  values?: string;
  vision?: string;
  studentAttributes?: string;
  url?: string;
  abn?: string;
  abnData?: string;
  logo?: string;
  phoneNumber?: string;
  @hydrate
  industryCode?: Industry;
  @hydrate
  size?: Size;
  email?: string;
  approved?: boolean;
  readonly postCount: number;

  get fullLinkedInUrl(): string {
    return `https://linkedin.com/company/${this.linkedin}`;
  }
}

export { Company, Size };

export default Company;
