import * as React from "react";
import * as commonStyles from "../styles.modules.css";
import * as yup from "yup";

import {
  ActionBar,
  Button,
  Field,
  Form,
  Input,
  Text,
  Select,
} from "@ribit/components";
import { Option } from "@ribit/components/src/components/select";
import { lookup } from "@app/helpers/api";
import { Size as CompanySize } from "@app/models/company";
import { Employer as EmployerType } from "@app/models/employer";
import Industry from "@app/models/industry";

import { Employer } from "@app/models/employer";
import Layout from "../layout";
import { PhoneNumberInput } from "@app/components/phone-number-input";
import Title from "../title";
import { extractNonNull } from "@app/helpers/data";
import { prependHttpToUrl } from "@app/helpers/urls";

type OwnProps = {
  step: number;
  user: Employer;
  stepTotal: number;
  onSubmit: (values: any) => void;
};

const extractSizeOption = (user: EmployerType): Option[] => {
  if (user.company.size) {
    const { size } = user.company;
    return [{ label: size.name, value: size.id }];
  }
  return [];
};

const extractIndustryCodeOption = (user: EmployerType): Option[] => {
  if (user.company.industryCode) {
    const { industryCode } = user.company;
    return [{ label: industryCode.name, value: industryCode.code }];
  }
  return [];
};

const ABitAboutYourself: React.FunctionComponent<OwnProps> = (
  props: OwnProps,
) => {
  const { onSubmit, step, stepTotal, user } = props;
  const initialData: object = extractNonNull(user.company);
  const sizeOptions: Option[] = extractSizeOption(user);
  const industryCodeOptions: Option[] = extractIndustryCodeOption(user);

  return (
    <Layout>
      <Form
        initial={initialData}
        validateSchema={yup.object().shape({
          url: yup
            .string()
            .url("Website must be a valid URL")
            .required("Website is a required field"),
          description: yup
            .string()
            .required("Business Description is a required field"),
          phoneNumber: yup
            .string()
            .phoneNumber("Must enter a valid phone number"),
          industryCode: yup
            .string()
            .nullable()
            .required("Industry code is a required field"),
          size: yup
            .string()
            .nullable()
            .required("Company size is a required field"),
        })}
        onFilterValues={values => {
          values.url = prependHttpToUrl(values.url);
          return values;
        }}
        onSubmit={values => {
          return onSubmit(values);
        }}
      >
        <Title text="About your business" step={`${step}/${stepTotal}`} />
        <Text className={commonStyles.content}>
          Your business description and website url will appear with your job
          post as additional information to applicants. Your phone number is not
          displayed to applicants.
        </Text>
        <Field required label="Website">
          <Input name="url" placeholder="https://yourcompany.com" />
        </Field>
        <Field required>
          <PhoneNumberInput name="phoneNumber" />
        </Field>
        <Field label="Industry" required>
          <Select
            name="industryCode"
            options={industryCodeOptions}
            async={{
              loadingText: "Retrieving industries...",
              threshold: 3,
              callback: lookup(
                Industry,
                "/industries",
                (industries: Industry[]) => {
                  return industries.map(industry => {
                    return {
                      label: industry.name,
                      value: industry.code,
                    };
                  });
                },
              ),
            }}
            placeholder="Select an industry..."
          />
        </Field>
        <Field label="Business size" required>
          <Select
            name="size"
            options={sizeOptions}
            async={{
              loadingText: "Retrieving sizes...",
              threshold: 3,
              callback: lookup(
                CompanySize,
                "/companies/sizes",
                (sizes: CompanySize[]) => {
                  return sizes.map(size => {
                    return {
                      label: size.name,
                      value: size.id,
                    };
                  });
                },
              ),
            }}
            placeholder="Select a size..."
          />
        </Field>
        <Field required label="Business Description">
          <Input
            name="description"
            lines={4}
            placeholder="We're an amazing company that..."
          />
        </Field>
        <ActionBar layout="center" className={commonStyles.actionBar}>
          <Button label="Next" type="submit" style="secondary" />
        </ActionBar>
      </Form>
    </Layout>
  );
};

export { ABitAboutYourself };
export default ABitAboutYourself;
