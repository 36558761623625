import * as React from "react";
import styles from "./styles.modules.css";

import { Tag as BaseTag } from "@ribit/components";
import { FunctionComponent } from "react";

interface Props {
  solid: boolean;
}

const Tag: FunctionComponent<Parameters<typeof BaseTag>[0] & Props> = ({
  solid,
  ...rest
}) => <BaseTag className={solid ? styles.solid : undefined} {...rest} />;

export { Tag };
export default Tag;
