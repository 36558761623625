import User, { isEmployer, isStudent } from "@app/models/user";
import { alertsAdd, alertsClear } from "../site-alerts/actions";
import {
  changePasswordAttempt,
  changePasswordSuccess,
  fetchProfileError,
  fetchProfileSuccess,
  initiatePasswordReset as initiatePasswordResetAction,
  initiatePasswordResetError,
  initiatePasswordResetSuccess,
  loginAttempt,
  loginError,
  loginSuccess,
  logoutSuccess,
  registerError,
  registerKioskError,
  registerKioskSuccess,
  updateProfileAttempt,
  updateProfileError,
  updateProfileSuccess,
  uploadFilesAttempt,
  uploadFilesError,
  uploadFilesSuccess,
  uploadProfilePhotoAttempt,
  uploadProfilePhotoError,
} from "./actions";
import { del, fetchNewAccessToken, get, post, put } from "@app/helpers/api";
import {
  makeEmployerSignUpSuccessAction,
  makeStudentSignUpSuccessAction,
} from "../analytics/actions";

import { Action } from "@app/state/types";
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import FileUpload from "@app/models/file-upload";
import { Payload } from "./types";
import Student from "@app/models/student";
import Token from "@app/models/token";
import { deserialize } from "@ribit/lib";
import marked from "marked";
import { push } from "redux-first-history";
import { storage } from "@ribit/lib";
import { upload } from "@app/helpers/upload";
import { userTypeFromJson } from "./reducers";

const clearStorage = () => {
  storage.remove("user");
  storage.remove("token");
  storage.remove("activity");
};

const mapDataToUser = (data: any) => {
  const UserType: any = userTypeFromJson(data);
  const user: User = deserialize(UserType, data);
  storage.to("user", user);
  return user;
};

const login = (username: string, password: string): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(alertsClear());
    dispatch(loginAttempt(username));
    return post("/auth/token", { email: username, password: password }, Token)
      .then((token: Token) => {
        storage.to("token", token);
        return get("/users/profile", (data: any) => {
          const user: any = mapDataToUser(data);
          dispatch(loginSuccess(user));
          return user;
        });
      })
      .catch((error: AxiosError) => {
        clearStorage();
        dispatch(alertsAdd(error.response.data.message, null, "Close"));
        dispatch(loginError());
        throw error.response;
      });
  };
};

const logout = (): any => {
  return (dispatch: Dispatch<any>) => {
    clearStorage();
    const out: Action<Payload> = logoutSuccess();
    dispatch(alertsAdd(out.payload.message, null, "Close", "success"));
    dispatch(out);
    return dispatch(push("/"));
  };
};

const closeAccount = (): any => {
  return (dispatch: Dispatch<any>) => {
    del("/users/close")
      .then(() => {
        clearStorage();
        dispatch(
          alertsAdd(
            "Closed your account successfully.",
            null,
            "Close",
            "success",
          ),
        );
        dispatch(logoutSuccess());
        return dispatch(push("/"));
      })
      .catch(() => {
        dispatch(
          alertsAdd(
            "Unable to close your account at this time.",
            null,
            "Close",
            "error",
          ),
        );
      });
  };
};

const sso = (token: string): any => {
  return async (dispatch: Dispatch<any>) => {
    const tempToken: Token = new Token();
    tempToken.refresh = token;
    storage.to("token", tempToken);
    fetchNewAccessToken()
      .then(() => {
        return get("/users/profile", (data: any) => {
          const user: any = mapDataToUser(data);
          dispatch(loginSuccess(user));
          dispatch(push("/dashboard"));
        });
      })
      .catch(() => {
        clearStorage();
        return dispatch(push("/"));
      });
  };
};

const register = (data: object): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(alertsClear());
    return post("/users", data)
      .then((user: User) => {
        login(data["email"], data["password"])(dispatch);

        if (data["company"] !== undefined) {
          dispatch(makeEmployerSignUpSuccessAction(user));
        } else {
          dispatch(makeStudentSignUpSuccessAction(user));
        }

        return user;
      })
      .catch((error: AxiosError) => {
        const failure: Action<Payload> = registerError();
        dispatch(alertsAdd(marked(failure.payload.message), null, "Close"));
        dispatch(failure);
        throw error.response;
      });
  };
};

const registerKiosk = (data: object): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(alertsClear());
    return post("/users/kiosk", data)
      .then((user: User) => {
        const success: Action<Payload> = registerKioskSuccess(user);
        dispatch(success);
        dispatch(alertsAdd(success.payload.message, null, "Close", "success"));
        return user;
      })
      .catch((error: AxiosError) => {
        const failure: Action<Payload> = registerKioskError();
        dispatch(alertsAdd(marked(failure.payload.message), null, "Close"));
        dispatch(failure);
        throw error.response;
      });
  };
};

const updateProfile = (data: { [s: string]: any }): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(updateProfileAttempt());
    if (data.tenant) {
      data.tenant = data.tenant.uuid;
    }
    return put("/users/profile", data, (data: any) => {
      const UserType: any = userTypeFromJson(data);
      const user: User = deserialize(UserType, data);
      storage.to("user", user);
      dispatch(alertsClear());
      dispatch(
        alertsAdd("Profile updated successfully.", null, "Close", "success"),
      );
      dispatch(updateProfileSuccess(user));
      return user;
    }).catch((error: AxiosError) => {
      const failure: Action<Payload> = updateProfileError();
      dispatch(alertsAdd(marked(failure.payload.message), null, "Close"));
      throw error.response;
    });
  };
};

const fetchProfile = (): any => {
  return (dispatch: Dispatch<any>) => {
    return get("/users/profile", (data: any) => {
      const UserType: any = userTypeFromJson(data);
      const user: User = deserialize(UserType, data);
      storage.to("user", user);
      dispatch(fetchProfileSuccess(user));
      return user;
    }).catch(() => {
      dispatch(
        alertsAdd(
          "Session has expired, please login again.",
          null,
          "Close",
          "error",
        ),
      );
      clearStorage();
      dispatch(fetchProfileError());
      dispatch(push("/auth/logout"));
    });
  };
};

const uploadProfilePhoto = (data: File): any => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(uploadProfilePhotoAttempt());
    if (!data) {
      return dispatch(
        uploadProfilePhotoError("No photo provided to be uploaded."),
      );
    }
    const files = await upload([data]);
    return put("/users/profile/photo", files[0].data, null, {
      headers: {
        "Content-Type": data.type,
        "Content-Disposition": `attachment; filename=${data.name}`,
      },
    })
      .then((response: { url: string }) => {
        const storedUser: any = storage.from("user");
        const url: string =
          response.url + "?" + Math.round(new Date().getTime() / 1000);
        const user: User = deserialize(
          userTypeFromJson(storedUser),
          storedUser,
        );
        if (isEmployer(user)) {
          user.company.logo = url;
        } else if (isStudent(user)) {
          user.profile.photo = url;
        }
        storage.to("user", user);
        dispatch(fetchProfileSuccess(user));
        return url;
      })
      .catch(() => {
        dispatch(uploadProfilePhotoError("Photo could not be uploaded."));
      });
  };
};

const uploadFiles = (data: File[]): any => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(uploadFilesAttempt());
    const files = await upload(data);
    return Promise.all(
      files.map(file => {
        const browserFile: File = file.file;
        return post("/users/upload/files", file.data, FileUpload, {
          headers: {
            "Content-Type": browserFile.type,
            "Content-Disposition": `attachment; filename=${browserFile.name}`,
          },
        });
      }),
    )
      .then((uploadedFiles: FileUpload[]) => {
        const storedUser: any = storage.from("user");
        const user: Student = deserialize(
          userTypeFromJson(storedUser),
          storedUser,
        );
        const existingFileUploads: FileUpload[] = user.profile.fileUploads;
        user.profile.fileUploads = existingFileUploads.concat(uploadedFiles);
        storage.to("user", user);
        dispatch(uploadFilesSuccess(uploadedFiles));
        return uploadedFiles;
      })
      .catch(() => {
        dispatch(uploadFilesError("Unable to upload files."));
      });
  };
};

const changePassword = (data: { [s: string]: any }): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(changePasswordAttempt());
    return post("/users/update-password", data, () => {
      dispatch(alertsClear());
      dispatch(
        alertsAdd("Password updated successfully.", null, "Close", "success"),
      );
      dispatch(changePasswordSuccess());
    }).catch((error: AxiosError) => {
      throw error.response;
    });
  };
};

const initiatePasswordReset = (email: string): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(initiatePasswordResetAction());
    return post("/users/reset-password", { email: email }, () => {
      dispatch(initiatePasswordResetSuccess(email));
    }).catch((error: AxiosError) => {
      dispatch(initiatePasswordResetError());
      throw error.response;
    });
  };
};

const resendVerification = (): any => () => {
  get("/users/resend-verification").catch(err => console.error(err));
};

const confirmAccount = (): any => (dispatch: Dispatch<any>) => {
  return get("/users/confirm", (data: any) => {
    const UserType: any = userTypeFromJson(data);
    const user: User = deserialize(UserType, data);
    storage.to("user", user);
    dispatch(fetchProfileSuccess(user));
    return user;
  }).catch(() => {
    dispatch(
      alertsAdd(
        "Unable to confirm your account, please try again.",
        null,
        "Close",
        "error",
      ),
    );
    dispatch(fetchProfileError());
  });
};

type Operations = {
  login: (username: string, password: string) => any;
  sso: (token: string) => any;
  logout: () => any;
  register: (data: object) => any;
  registerKiosk: (data: object) => any;
  updateProfile: (data: object) => any;
  fetchProfile: () => any;
  uploadProfilePhoto: (data: any) => any;
  uploadFiles: (data: any[]) => any;
  changePassword: (data: object) => any;
  initiatePasswordReset: (email: string) => any;
  closeAccount: () => any;
  resendVerification: () => any;
  confirmAccount: () => any;
};

const operations: Operations = {
  login,
  sso,
  logout,
  register,
  updateProfile,
  fetchProfile,
  uploadProfilePhoto,
  uploadFiles,
  changePassword,
  initiatePasswordReset,
  registerKiosk,
  closeAccount,
  resendVerification,
  confirmAccount,
};

export {
  login,
  sso,
  logout,
  register,
  registerKiosk,
  updateProfile,
  fetchProfile,
  uploadProfilePhoto,
  initiatePasswordReset,
  uploadFiles,
  closeAccount,
  operations,
  Operations,
};
