import * as React from "react";
import classNames from "classnames/bind";
import styles from "./styles.modules.css";

import { Icon, Icons, Photo } from "@ribit/components";

import { Badge } from "../badge";
import Employer from "@app/models/employer";
import { Message as MessageType } from "@app/models/messages";
import Student from "@app/models/student";
import { isStudent } from "@app/models/user";
import markdown from "@app/helpers/markdown";

const cx = classNames.bind(styles);

type OwnProps = {
  message: MessageType;
  mine?: boolean;
};

const Message: React.FunctionComponent<OwnProps> = (props: OwnProps) => {
  const { message, mine } = props;
  const badge: any = !message.read ? (
    <Badge label="New" />
  ) : (
    <Icon icon={Icons.Check} />
  );
  const className: string = cx({
    outer: true,
    mine: mine,
  });
  const { fromUser } = message;
  let url: string = null;
  if (isStudent(fromUser)) {
    url = (fromUser as Student).profile.photo;
  } else {
    url = (fromUser as Employer).company.logo;
  }

  return (
    <div className={className}>
      <div className={styles.inner}>
        <Photo
          name={fromUser.fullName}
          size="small"
          url={url}
          className={styles.photo}
        />
        <div className={styles.bodyWrapper}>
          {markdown(message.body, styles.body)}
          <div className={styles.info}>
            {badge}{" "}
            <span className={styles.time}>
              Sent {message.formattedCreatedAt}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Message };
export default Message;
