import { Action, ApiData } from "@app/state/types";
import Types, { Payload } from "./types";

import Message from "@app/models/messages";

const initialState: ApiData<Message[]> = {
  loading: null,
  data: [],
};

const reducer = (
  state = initialState,
  action: Action<Payload>,
): ApiData<Message[]> => {
  const { type } = action;
  const payload: Payload = action.payload;
  switch (type) {
    case Types.FETCH_SUCCESS:
      return payload;
    case Types.FETCHING:
      return {
        loading: true,
        data: [],
      };
    case Types.CREATE:
      return {
        loading: true,
        data: state.data,
      };
    case Types.CLEAR:
      return payload;
    case Types.CREATE_SUCCESS:
      return {
        loading: false,
        data: [...state.data, ...payload.data],
      };
    default:
      return state;
  }
};

export { reducer };

export default reducer;
