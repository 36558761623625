import * as React from "react";
import styles from "./styles.modules.css";

import { Container } from "@ribit/components";
import Job from "./job";
import { Job as JobType } from "@app/models/job";

type OwnProps = {
  items: JobType[];
};

const Jobs: React.FunctionComponent<OwnProps> = (props: OwnProps) => {
  let { items } = props;
  if (!items.length) {
    return null;
  }
  items = items.slice(0, 3);

  const gridType = items.length >= 3 ? "three" : "two";
  const gridCssClass = items.length >= 3 ? styles.list : styles.listSmall;

  return (
    <Container grid={gridType} className={gridCssClass}>
      {items.map(job => (
        <Job job={job} key={job.uuid} />
      ))}
    </Container>
  );
};

export { Jobs };
export default Jobs;
