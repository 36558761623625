import * as React from "react";
import styles from "./styles.modules.css";

import { CountryCode, parsePhoneNumberFromString } from "libphonenumber-js";
import { Input, Select } from "@ribit/components";

type Country = {
  code: CountryCode;
  prefix: number;
};

type OwnProps = {
  name: string;
  value?: any;
  placeholder?: any;
  countries?: Country[];
  initialCountryCode?: string;
  onChange?: (name: string, value: string) => void;
};

type OwnState = {
  prefix: Country;
};

const AUSTRALIA: Country = { code: "AU", prefix: 61 };

const defaultProps = {
  countries: [AUSTRALIA],
  initialCountryCode: "AU",
};
type DefaultProps = Readonly<typeof defaultProps>;

class PhoneNumberInput extends React.Component<OwnProps, OwnState> {
  static defaultProps: DefaultProps = defaultProps;
  constructor(props: OwnProps) {
    super(props);

    let initialPrefix: Country[] = props.countries.filter(
      country => country.code === props.initialCountryCode,
    );
    if (!initialPrefix.length) {
      initialPrefix = [AUSTRALIA];
    }

    this.state = {
      prefix: initialPrefix[0],
    };
  }

  private changePrefix = (name: string, value: any) => {
    this.setState({ prefix: value });
  };

  onChange = (inputName: string, inputValue: string) => {
    const { name, onChange } = this.props;
    onChange(name, this.formatNumber(inputValue, false));
  };

  private stripPrefix = (number: string): string => {
    if (!number) {
      number = "";
    }
    const parsedNumber = parsePhoneNumberFromString(number);
    if (parsedNumber) {
      return parsedNumber.nationalNumber.toString();
    }
    return number.replace("+", "");
  };

  private formatNumber = (value: string, strip = true): string => {
    const { prefix } = this.state;
    const plusPrefix = `+${prefix.prefix} `;
    const combinedValue = `${plusPrefix}${this.stripPrefix(value)}`;
    const parsedNumber = parsePhoneNumberFromString(combinedValue, prefix.code);
    let formattedNumber: string = value;
    if (parsedNumber) {
      formattedNumber = parsedNumber.formatInternational();
    }
    if (strip && formattedNumber) {
      return formattedNumber.replace(plusPrefix, "");
    }
    return formattedNumber;
  };

  componentDidUpdate(prevProps: OwnProps) {
    const { name, onChange } = this.props;
    let { value } = this.props;
    value = this.formatNumber(value, false);
    if (value !== prevProps.value) {
      onChange(name, value);
    }
  }

  render(): React.ReactElement<any> {
    const { name, placeholder, countries } = this.props;
    let { value } = this.props;

    const countryOptions = countries.map(country => {
      return { label: `+${country.prefix} (${country.code})`, value: country };
    });
    value = this.formatNumber(value);

    return (
      <Input
        className={styles.input}
        name={name}
        placeholder={placeholder}
        onChange={this.onChange}
        value={value}
        prefixClassName={styles.prefix}
        prefix={
          <Select
            name="prefix"
            value={this.state.prefix}
            options={countryOptions}
            className={styles.select}
            onChange={this.changePrefix}
          />
        }
      />
    );
  }
}

export { PhoneNumberInput };
export default PhoneNumberInput;
