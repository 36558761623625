import * as duckActions from "./actions";
import * as duckOperations from "./operations";
import * as duckSelectors from "./selectors";
import * as duckType from "./types";

import reducer from "./reducers";

export { duckOperations, duckActions, duckSelectors, duckType };

export default reducer;
