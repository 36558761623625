import { AnyAction } from "redux";
import { Types as UserTypes } from "@app/state/ducks/user/types";
import {
  identifyUser,
  resetIdentification,
  trackEvent,
} from "@app/analytics/segment";
import * as R from "ramda";
import { ActionObserverFunc } from "../types";

export const observeUserLogin: ActionObserverFunc = (action, _state) => {
  if (action.type !== UserTypes.LOGIN_SUCCESS) return;
  const uuid: string = action.payload.user.uuid;
  const tenantUuid: string = R.view(
    R.lensPath(["payload", "user", "tenant", "uuid"]),
    action,
  );
  const userTypeId: number = R.view(
    R.lensPath(["payload", "user", "userType"]),
    action,
  );
  const userType = userTypeId === 1 ? "STUDENT" : "EMPLOYER";
  identifyUser(uuid, userType, tenantUuid);
  trackEvent("USER_LOGIN", { userUuid: uuid, type: userType, tenantUuid });
};

export const observeUserLogout = (action: AnyAction) => {
  if (action.type !== UserTypes.LOGOUT) return;
  resetIdentification();
};
