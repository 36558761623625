import * as React from "react";
import * as selectApplication from "@app/state/ducks/selected-application/operations";
import styles from "./styles.modules.css";

import { FunctionComponent, useEffect, useState } from "react";
import { Icon, Icons, Modal } from "@ribit/components";

import Application from "@app/models/application";
import { CloseJob } from "@app/components/close-job/close-job";
import Job from "@app/models/job";
import { LoadingType } from "@app/state/ducks/selected-application/types";
import { SelectedApplicationStore } from "@app/state/ducks/selected-application/reducers";
import { State } from "@app/state";
import { ViewApplicationDecline } from "@app/components/view-application/steps/decline";
import { ViewApplicationHome } from "@app/components/view-application/steps/home";
import { connect } from "react-redux";
import { operations as jobOperations } from "@app/state/ducks/job/operations";
import {
  operations as threadOperations,
  Operations as ThreadOperations,
} from "@app/state/ducks/messages/threads/operations";
import { mapOperationsToDispatchProps } from "@ribit/lib";
import User from "@app/models/user";

interface OwnProps {
  job: Job;
  dismissed: (dirty?: boolean) => void;
}

type DispatchProps = {
  operations: {
    select: typeof selectApplication;
    job: typeof jobOperations;
    threads: ThreadOperations;
  };
};

enum ViewState {
  HOME = 1,
  DECLINE,
  HIRE,
}

interface StateProps {
  selected?: SelectedApplicationStore;
  applications?: Application[];
  user?: User;
}

export const PureViewApplication: FunctionComponent<OwnProps & StateProps> = ({
  selected: { loading, shouldClose, application: propsApplication },
  job,
  applications,
  user,
  dismissed,
  ...rest
}) => {
  const { operations } = (rest as unknown) as DispatchProps;

  // eslint-disable-next-line
  let [application, setApplication] = useState(propsApplication);
  const [isDirty, setDirty] = useState(false);
  const [viewState, setViewState] = useState(ViewState.HOME);

  const viewStateFactory = (state: ViewState) => () => setViewState(state);

  const closeButtonClicked = () => {
    setViewState(ViewState.HOME);
    dismissed(isDirty);
    setDirty(false);
  };

  const sendMessage = () => {
    if (application) {
      operations.threads.create(job, application.student, user);
    }
  };

  const decline = () => {
    if (!loading.includes(LoadingType.DECLINE)) {
      operations.select.declineApplication(job.uuid, application.uuid);
      setDirty(true);
    }
  };

  const hireAndClose = (a?: Application, reason?: number) => {
    if (!loading.includes(LoadingType.HIRE)) {
      operations.select.acceptApplication(
        job.uuid,
        (a || application).uuid,
        reason,
      );
      operations.job.close(job.uuid, reason);
      setDirty(true);
    }
  };

  useEffect(() => {
    if (shouldClose) {
      closeButtonClicked();
    }
  }, [shouldClose]);

  useEffect(() => {
    if (propsApplication) {
      // This is like debouncing.
      // In the future, if the dialog animates open and
      // closed, the previous version of the application
      // has to be visible during the exit animation.
      setApplication(propsApplication);
      setViewState(ViewState.HOME);
    }
  }, [propsApplication]);

  let content = undefined;
  application = propsApplication || application;
  if (application) {
    const shortlisted = application.status !== "applied";
    const shortlistLoading =
      loading.includes(LoadingType.SHORTLIST) ||
      loading.includes(LoadingType.UN_SHORTLIST);

    const toggleShortlist = () => {
      if (shortlistLoading) {
        return;
      }

      setDirty(true);

      if (shortlisted) {
        operations.select.unShortlistApplication(job.uuid, application.uuid);
      } else {
        operations.select.shortlistApplication(job.uuid, application.uuid);
      }
    };

    switch (viewState) {
      case ViewState.HIRE:
        content = (
          <CloseJob
            onClose={hireAndClose}
            selected={application.student}
            applications={applications}
            onBack={viewStateFactory(ViewState.HOME)}
            loading={loading.includes(LoadingType.HIRE)}
          />
        );
        break;
      case ViewState.DECLINE:
        content = (
          <ViewApplicationDecline
            loading={loading.includes(LoadingType.DECLINE)}
            onDecline={decline}
            onHome={viewStateFactory(ViewState.HOME)}
          />
        );
        break;
      default:
        content = (
          <ViewApplicationHome
            job={job}
            application={application}
            onDecline={viewStateFactory(ViewState.DECLINE)}
            onHire={viewStateFactory(ViewState.HIRE)}
            onSendMessage={sendMessage}
            onToggleShortlist={toggleShortlist}
            shortlistLoading={shortlistLoading}
            shortlisted={shortlisted}
          />
        );
    }
  }

  return (
    <Modal isOpen={!!propsApplication} onClose={closeButtonClicked}>
      <div className={styles.content}>
        <button onClick={closeButtonClicked} className={styles.close}>
          <Icon icon={Icons.Cross} />
        </button>
        {content}
      </div>
    </Modal>
  );
};

const mapToStateProps = (state: State): StateProps => ({
  selected: state.selectedApplication,
  applications: state.manage.applications.data.results.concat(
    state.manage.shortlist.data.results,
  ),
  user: state.user,
});

export const ViewApplication = connect(
  mapToStateProps,
  mapOperationsToDispatchProps({
    select: selectApplication,
    job: jobOperations,
    threads: threadOperations,
  }),
)(PureViewApplication);
