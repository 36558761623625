import React, { FunctionComponent, useState, useEffect } from "react";

import {
  Attribute,
  AttributeList,
  Heading,
  Icon,
  Icons,
  Modal,
  Tag,
  Size,
  CheckboxGroup,
  CheckboxOption,
} from "@ribit/components";

import Pagination from "@app/models/pagination";
import { Job, Course } from "@app/models/job";
import styles from "./styles.modules.css";
import { ModalContent } from "@app/components/modal-content";
import { JobHeader } from "@app/components/job-header";
import { humanNameForResidencyRequirement } from "@app/pages/manage-jobs/models";
import markdown from "@app/helpers/markdown";
import { Button } from "@app/components/button";

interface OwnProps {
  job?: Job;
  courses?: Pagination<Course>;
  isOpen?: boolean;
  approved?(job?: Job, courses?: string[]): void;
  rejected?(job?: Job): void;
  dismissed?: (dirty?: boolean) => void;
}

type CheckboxGroupOption = { [s: string]: CheckboxOption[] };

export const PureViewJob: FunctionComponent<OwnProps> = ({
  job,
  courses,
  isOpen,
  approved,
  rejected,
  dismissed,
}) => {
  const [step, setStep] = useState<number>(1);
  const [courseOptions, setCourseOptions] = useState<CheckboxGroupOption>();
  const [coursesSelected, setCoursesSelected] = useState<string[]>();

  const getCourseOptions = () =>
    [
      ...Array.from(new Set(courses.results.map(course => course.facultyName))),
    ].reduce(
      (acc, heading) => ({
        ...acc,
        [heading]: courses.results
          .filter(course => course.facultyName === heading)
          .map(course => ({
            label: `${course.code} ${course.name}`,
            value: course.id,
          })),
      }),
      {},
    );

  useEffect(() => {
    setStep(1);

    if (job && job.jobReview && job.jobReview.suitableCourses) {
      // existing review - set selected courses
      setCoursesSelected(job.jobReview.suitableCourses);
    } else {
      // no review yet - make sure no course is selected by default
      setCoursesSelected([]);
    }
  }, [job]);

  useEffect(() => {
    courses && courses.results && setCourseOptions(getCourseOptions());
  }, [courses]);

  const closeButtonClicked = () => {
    dismissed();
  };

  const toggleCourseSelection = (value: string) => {
    if (coursesSelected === undefined) {
      // couldn't reproduce locally but did happen in Cypress test (partner-approve-job.js)
      setCoursesSelected([value]);
    } else {
      const index = coursesSelected.indexOf(value);
      if (index === -1) {
        setCoursesSelected([value, ...coursesSelected]);
      } else {
        const newSelection = coursesSelected.filter((course, i) => {
          return index !== i;
        });
        setCoursesSelected(newSelection);
      }
    }
  };

  const courseContent = (
    <ModalContent
      content={
        <>
          <Heading
            size={Size.Medium}
            text="Which course(s) is this role suitable for?"
          />
          {courses && courses.results && (
            <div data-cy="checkbox_group__courses">
              <CheckboxGroup
                onChange={(name, courseId) => toggleCourseSelection(courseId)}
                name="courses"
                layout="horizontal"
                options={courseOptions}
                value={coursesSelected}
                className={styles.options}
              />
            </div>
          )}
        </>
      }
      actions={
        <>
          <Button
            action={() => approved(job, coursesSelected)}
            label="Add Courses"
            style="secondary"
            size="small"
            layout="full-width"
            dataCy="button__confirm-approve-job"
            disabled={!(coursesSelected && coursesSelected.length)}
          />
          <Button
            action={() => setStep(1)}
            label="Back"
            style="tertiary"
            size="small"
            layout="full-width"
          />
        </>
      }
    />
  );

  const jobContent = job && (
    <ModalContent
      dataCy="popup__job-details"
      content={
        <>
          <JobHeader job={job} />
          <div className={styles.tags}>
            {job.skills &&
              job.skills.map((item, i) => {
                return <Tag key={i} label={item.name} />;
              })}
          </div>
          <Attribute
            name="Employer contact details"
            value={
              <>
                {job.owner.company.phoneNumber}
                <br />
                <a href={`mailto:${job.owner.email}`}>{job.owner.email}</a>
              </>
            }
          />
          <AttributeList
            className={styles.attributeList}
            items={[
              {
                name: "Category",
                value: job.category,
              },
              {
                name: "No. of placements available",
                value: job.numberOfStudents,
              },
              { name: "Days per week", value: job.days },
              { name: "Duration", value: job.duration },
            ]}
          />
          <Attribute
            name="Role description"
            value={
              <div className={styles.description}>
                {markdown(job.description)}
              </div>
            }
          />
        </>
      }
      actions={
        <>
          {job.jobReview && <Attribute name={job.jobReview.status} value="" />}
          <Button
            action={() => setStep(2)}
            label="Approve"
            style="secondary"
            size="small"
            layout="full-width"
            dataCy="button__approve-job"
          />
          <Button
            action={() => rejected(job)}
            label="Reject"
            style="tertiary"
            size="small"
            layout="full-width"
            dataCy="button__reject-job"
          />
        </>
      }
      sidebarContent={
        <>
          <Attribute
            name="Supervisor contact details"
            value={
              <>
                {job.supervisorName}
                <br />
                {job.supervisorJobTitle}
                <br />
                {job.supervisorPhoneNumber}
                <br />
                <a href={`mailto:${job.supervisorEmail}`}>
                  {job.supervisorEmail}
                </a>
              </>
            }
          />
          <Attribute name="Workplace details" value={job.workplaceAddress} />
          <Attribute name="Allowance/Stipend" value={job.budget} />
          <Attribute
            name="Residency requirements"
            value={humanNameForResidencyRequirement(job.residencyRequirement)}
          />
        </>
      }
    />
  );

  return (
    <Modal isOpen={isOpen} onClose={closeButtonClicked}>
      <div className={styles.content}>
        <button
          type="button"
          onClick={closeButtonClicked}
          className={styles.close}
          data-cy="button__close-popup"
        >
          <Icon icon={Icons.Cross} />
        </button>
        {step === 2 ? courseContent : jobContent}
      </div>
    </Modal>
  );
};
