import * as React from "react";
import * as queryString from "query-string";
import styles from "./styles.modules.css";

import {
  ActionBar,
  Button,
  Container,
  Content,
  Field,
  Form,
  Icons,
  Input,
  PageBar,
  Panel,
  Select,
  Separator,
  Text,
} from "@ribit/components";
import {
  Operations as FilterOperations,
  operations as filterOperations,
} from "@app/state/ducks/find-jobs-filters/operations";
import Job, { Type } from "@app/models/job";
import {
  Operations as JobOperations,
  operations as jobOperations,
} from "@app/state/ducks/jobs/operations";
import { RouteComponentProps } from "@app/types";
import { withRouter } from "@app/helpers/hooks";
import SearchAlert, { Criteria, Interval } from "@app/models/search-alert";

import Category from "@app/models/category";
import { Jobs } from "./components";
import Location from "@app/models/location";
import { ModalSelect } from "@app/components/modal-select";
import Pagination from "@app/models/pagination";
import { SearchAlert as SearchAlertModal } from "@app/containers/search-alert";
import { SearchAlerts } from "./containers";
import Skill from "@app/models/skill";
import { State } from "@app/state";
import { Title } from "@app/components/title";
import { connect } from "react-redux";
import { lookup } from "@app/helpers/api";
import { mapOperationsToDispatchProps } from "@ribit/lib";

type OwnProps = Record<string, never>;
type OwnState = {
  shouldReset: boolean;
  searchAlert?: SearchAlert | null;
};
type DispatchProps = {
  operations: {
    jobs: JobOperations;
    filters: FilterOperations;
  };
};
type StateProps = {
  jobs?: Pagination<Job>;
  loading?: boolean;
  filters: Criteria;
  filtersSet?: boolean;
  searchAlerts?: Pagination<SearchAlert> | null;
};
type FindJobsProps = StateProps &
  DispatchProps &
  OwnProps &
  RouteComponentProps;

class PureFindJobs extends React.Component<FindJobsProps, OwnState> {
  state: OwnState = {
    shouldReset: false,
    searchAlert: null,
  };

  fetch = () => {
    const { operations, jobs, filters } = this.props;
    operations.jobs.retrieve(
      jobs ? jobs.results.length : 0,
      10,
      filters.munge(),
    );
  };

  clear = () => {
    const { operations } = this.props;
    if (operations === undefined || operations.jobs === undefined) {
      return;
    }
    operations.jobs.clear();
  };

  private get alertUuid(): string {
    const params = queryString.parse(this.props.match.location.search);
    return params["filter"] as any;
  }

  componentDidMount() {
    this.fetch();
  }

  componentWillUnmount() {
    this.clear();
  }

  componentDidUpdate(prevProps: FindJobsProps) {
    if (!prevProps.searchAlerts) {
      return;
    }

    if (
      this.props.searchAlerts &&
      !prevProps.searchAlerts.count &&
      this.alertUuid
    ) {
      const alert = this.props.searchAlerts.results.find(
        alert => alert.uuid === this.alertUuid,
      );
      if (alert) {
        this.props.operations.filters.set(alert.criteria);
      }
    }
  }

  reset() {
    this.props.operations.filters.clear();
    this.setState({ shouldReset: true });
  }

  saveSearchAlert = (alert?: SearchAlert) => {
    let searchAlert = alert;
    if (!alert) {
      searchAlert = new SearchAlert();
      searchAlert.interval = Interval.WEEKLY;
      searchAlert.criteria = this.props.filters;
    }
    this.setState({ searchAlert: searchAlert });
  };

  render(): React.ReactElement<any> {
    const {
      jobs,
      loading,
      filters,
      operations: { filters: filtersOperations },
    } = this.props;

    return (
      <>
        <Form
          initial={filters}
          onSubmit={values => {
            const filterValues = Criteria.fromFilters(values);
            this.clear();
            filtersOperations.set(filterValues);
            this.fetch();
          }}
        >
          {props => {
            const { reset, submit, values, valueChanged } = props;

            if (this.state.shouldReset) {
              reset(filters);
              submit();
              this.setState({ shouldReset: false });
            }

            return (
              <>
                <PageBar
                  grid="span-small"
                  left={
                    <Field className={styles.keywords} label={undefined}>
                      <Input
                        icon={Icons.Search}
                        name="keywords"
                        placeholder="Search for a job..."
                        style="transparent"
                      />
                    </Field>
                  }
                  right={<Text>{jobs ? jobs.count : 0} results found</Text>}
                />
                <Content>
                  <Title label="Find jobs" />
                  <Container grid="medium-span">
                    <div>
                      <Panel title="Filter jobs" icon={Icons.Sliders}>
                        <Field layout="horizontal" className={styles.field}>
                          <Select
                            loadImmediate={true}
                            name="locations"
                            async={{
                              callback: lookup(
                                Location,
                                "/locations",
                                (locations: Location[]) => {
                                  return locations.map(location => {
                                    return {
                                      label: location.name,
                                      value: location,
                                    };
                                  });
                                },
                              ),
                            }}
                            multiple
                            placeholder="Select locations..."
                          />
                        </Field>
                        <ModalSelect
                          itemsName="Skills"
                          value={values.skills}
                          onChange={valueChanged}
                          name="skills"
                          modalTitle="Select some skills..."
                          layout="horizontal"
                          options={[]}
                          loadImmediate={true}
                          modalCallback={lookup(
                            Skill,
                            "/skills",
                            (skills: Skill[]) => {
                              return skills.map(skill => {
                                return {
                                  label: skill.name,
                                  value: skill,
                                };
                              });
                            },
                            0,
                            100,
                          )}
                          async={{
                            loadingText: "Retrieving skills...",
                            callback: lookup(
                              Skill,
                              "/skills",
                              (skills: Skill[]) => {
                                return skills.map(skill => {
                                  return {
                                    label: skill.name,
                                    value: skill,
                                  };
                                });
                              },
                            ),
                          }}
                          placeholder="Select some skills..."
                          actionLabel="View all"
                        />
                        <Field layout="horizontal" className={styles.field}>
                          <Select
                            loadImmediate={true}
                            name="types"
                            multiple
                            async={{
                              callback: lookup(
                                Type,
                                "/jobs/types",
                                (types: Type[]) => {
                                  return types.map(type => {
                                    return {
                                      label: type.name,
                                      value: type,
                                    };
                                  });
                                },
                              ),
                            }}
                            placeholder="Select some types..."
                          />
                        </Field>
                        <Field
                          layout="horizontal"
                          label="Area"
                          className={styles.field}
                        >
                          <Select
                            loadImmediate={true}
                            name="category"
                            multiple
                            async={{
                              callback: lookup(
                                Category,
                                "/categories",
                                (categories: Category[]) => {
                                  return categories.map(category => {
                                    return {
                                      label: category.name,
                                      value: category,
                                    };
                                  });
                                },
                              ),
                            }}
                            placeholder="Select area..."
                          />
                        </Field>
                        <Field layout="horizontal" className={styles.field}>
                          <Select
                            loadImmediate={true}
                            name="sort"
                            options={[
                              { label: "Date Created", value: "created_at" },
                              { label: "Alphabetical", value: "alphabetical" },
                            ]}
                            placeholder="Select a sort method"
                          />
                        </Field>
                        <ActionBar layout="center" className={styles.actions}>
                          <Button
                            type="submit"
                            label="Filter jobs"
                            style="tertiary"
                            layout="full-width"
                          />
                          <Button
                            label="Save as Search Alert"
                            style="tertiary"
                            layout="full-width"
                            className={styles.searchAlert}
                            action={() => this.saveSearchAlert()}
                            disabled={!this.props.filtersSet}
                          />
                          <Separator text="or" />
                          <Button
                            label="Reset"
                            type="text"
                            layout="full-width"
                            action={() => this.reset()}
                          />
                        </ActionBar>
                      </Panel>
                      <Panel title="Search Alerts" icon={Icons.Mail}>
                        <SearchAlerts
                          onEdit={alert =>
                            this.setState({ searchAlert: alert })
                          }
                        />
                      </Panel>
                    </div>
                    <div>
                      <Panel>
                        <Jobs
                          data={jobs}
                          loading={loading === true}
                          searchAction={() => {
                            this.clear();
                            this.fetch();
                          }}
                          viewMoreAction={this.fetch}
                        />
                      </Panel>
                    </div>
                  </Container>
                </Content>
              </>
            );
          }}
        </Form>
        {this.state.searchAlert && (
          <SearchAlertModal
            visible={!!this.state.searchAlert}
            close={() => this.setState({ searchAlert: null })}
            searchAlert={this.state.searchAlert}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    jobs: state.jobs.data,
    loading: state.jobs.loading,
    filters: state.findJobFilters.criteria,
    filtersSet: state.findJobFilters.filtered,
    searchAlerts: state.searchAlerts.data,
  };
};

const ConnectedFindJobs = connect(
  mapStateToProps,
  mapOperationsToDispatchProps({
    jobs: jobOperations,
    filters: filterOperations,
  }),
)(PureFindJobs);
const FindJobs = withRouter(ConnectedFindJobs);

export { FindJobs };
export default FindJobs;
