import * as React from "react";
import * as yup from "yup";

import {
  ActionBar,
  Button,
  CheckboxGroup,
  CheckboxOption,
  Container,
  Field,
  Form,
  Heading,
  Icons,
  Input,
  Loading,
  Panel,
  RadioGroup,
  Select,
  Size,
  StickyFooterBar,
  Text,
  Upload,
} from "@ribit/components";
import {
  Munger,
  Schema,
  clone,
  deepMerge,
  isString,
  mapOperationsToDispatchProps,
} from "@ribit/lib";
import { Operations, operations } from "@app/state/ducks/user/operations";
import { del, get, lookup } from "@app/helpers/api";

import AfqLevel from "@app/models/afq-level";
import ArrayCount from "@app/components/array-count/array-count";
import Category from "@app/models/category";
import Degree from "@app/models/degree";
import { DeleteAccount } from "@app/components/delete-account";
import FileUpload from "@app/models/file-upload";
import { InputList } from "@app/components/input-list";
import Institution from "@app/models/institution";
import Language from "@app/models/language";
import Location from "@app/models/location";
import { ModalPassword } from "@app/components/modal-password";
import { ModalSelect } from "@app/components/modal-select";
import { Option } from "@ribit/components/src/components/select";
import Pagination from "@app/models/pagination";
import { PanelArrow } from "@app/components/panel-arrow";
import { PhoneNumberInput } from "@app/components/phone-number-input";
import { PhotoUpload } from "@app/components/photo-upload";
import Skill from "@app/models/skill";
import { Student as StudentType } from "@app/models/student";
import { Type } from "@app/models/job";
import { WorkExample } from "./components";
import { connect } from "react-redux";
import { prependHttpToUrl } from "@app/helpers/urls";
import styles from "./styles.modules.css";

type OwnProps = {
  user: StudentType;
};
type DispatchProps = {
  operations?: Operations;
};
type StudentProps = OwnProps & DispatchProps;
type OwnState = {
  jobTypes: CheckboxOption[];
  uploadCompleted: boolean;
  saving: boolean;
  passwordModalIsOpen: boolean;
  deleteAccountModalIsOpen: boolean;
};

const userSchema: Schema = {
  paths: {
    firstName: "",
    lastName: "",
    category: "profile.category",
    available: "profile.available",
    phoneNumber: "profile.mobilePhoneNumber",
    gender: "profile.gender",
    postcode: "profile.postcode",
    facebook: "profile.facebook",
    twitter: "profile.twitter",
    linkedin: "profile.linkedin",
    bio: "profile.biography",
    photo: "profile.photo",
    institution: "profile.institution.id|profile.institution",
    afqLevel: "profile.afqLevel.id|profile.afqLevel",
    degree: "profile.courseDegree.id|profile.courseDegree",
    graduatingYear: "profile.graduateYear",
    international: "profile.internationalStudent",
    aboriginal: "profile.aboriginal",
    locations: "profile.locations",
    languages: "profile.languages",
    skills: "profile.skills",
    workEligibility: "profile.workEligibility",
    portfolios: "profile.portfolios.link",
    workTypes: "profile.workTypes",
  },
};

const extractIntitutionOption = (user: StudentType): Option[] => {
  if (user.profile.institution) {
    const { institution } = user.profile;
    return [{ label: institution.name, value: institution.id }];
  }
  return [];
};

const extractAfqLevelOption = (user: StudentType): Option[] => {
  if (user.profile.afqLevel) {
    const { afqLevel } = user.profile;
    return [{ label: afqLevel.name, value: afqLevel.id }];
  }
  return [];
};

const extractDegreeOption = (user: StudentType): Option[] => {
  if (user.profile.courseDegree) {
    const { courseDegree } = user.profile;
    return [{ label: courseDegree.name, value: courseDegree.id }];
  }
  return [];
};

const extractSkillOption = (user: StudentType): Option[] => {
  if (user.profile.skills) {
    const { skills } = user.profile;
    return skills.map(skill => {
      return { label: skill.name, value: skill };
    });
  }
  return [];
};

const extractLanguageOption = (user: StudentType): Option[] => {
  if (user.profile.languages) {
    const { languages } = user.profile;
    return languages.map(language => {
      return { label: language.name, value: language };
    });
  }
  return [];
};

const extractCategoryOption = (user: StudentType): Option[] => {
  if (user.profile.category) {
    return [
      {
        label: user.profile.category,
        value: user.profile.category,
      },
    ];
  }
  return [];
};

const extractLocationOption = (user: StudentType): Option[] => {
  if (user.profile.locations) {
    const { locations } = user.profile;
    return locations.map(location => {
      return { label: location.name, value: location };
    });
  }
  return [];
};

const replaceObjectWithValues = (
  obj: { [s: string]: any[] },
  key: string,
  value = "id",
) => {
  obj[key] = obj[key].map(o => o[value]);
};

class PureStudent extends React.Component<StudentProps, OwnState> {
  state: OwnState = {
    jobTypes: [],
    uploadCompleted: false,
    saving: false,
    passwordModalIsOpen: false,
    deleteAccountModalIsOpen: false,
  };
  private formRef = React.createRef<any>();

  componentDidMount() {
    get("/jobs/types", Type).then((types: Pagination<Type>) => {
      this.setState({
        jobTypes: types.results.map(type => {
          return {
            label: type.name,
            value: type,
          };
        }),
      });
    });
  }

  private _removeWorkExample = (fileUpload: FileUpload) => {
    const { user } = this.props;
    del(`/users/upload/files/${fileUpload.uuid}`).then(() => {
      const index: number = user.profile.fileUploads.findIndex(
        file => file.uuid === fileUpload.uuid,
      );
      user.profile.fileUploads.splice(index, 1);
      this.forceUpdate();
    });
  };

  triggerModalPassword = () => {
    this.setState({ passwordModalIsOpen: !this.state.passwordModalIsOpen });
  };

  triggerDeleteAccount = () => {
    this.setState({
      deleteAccountModalIsOpen: !this.state.deleteAccountModalIsOpen,
    });
  };

  render(): React.ReactElement<any> {
    const { user, operations } = this.props;
    const { jobTypes } = this.state;
    const munger: Munger = new Munger(userSchema);
    const initial: any = munger.flatten(user);
    initial.canWork = [];
    if (user.profile.availabilityAnywhere) {
      initial.canWork.push("move");
    }
    if (user.profile.availabilityRemote) {
      initial.canWork.push("remote");
    }

    const afqLevelOptions: Option[] = extractAfqLevelOption(user);
    const institutionOptions: Option[] = extractIntitutionOption(user);
    const degreeOptions: Option[] = extractDegreeOption(user);
    const categoryOptions: Option[] = extractCategoryOption(user);
    const skillOptions: Option[] = extractSkillOption(user);
    const languageOptions: Option[] = extractLanguageOption(user);
    const locationOptions: Option[] = extractLocationOption(user);
    const { uploadCompleted, saving } = this.state;

    return (
      <>
        <Form
          ref={this.formRef}
          initial={initial}
          validateSchema={yup.object().shape({
            firstName: yup
              .string()
              .nullable()
              .required("First Name is a required field"),
            lastName: yup
              .string()
              .nullable()
              .required("Last Name is a required field"),
            phoneNumber: yup
              .string()
              .nullable()
              .phoneNumber("Must enter a valid phone number"),
            skills: yup
              .array()
              .required("You must select some skills, but no more than 6")
              .min(1, "Must select at least one skill")
              .max(6, "Must not select more than 6 skills"),
            portfolios: yup
              .array()
              .of(yup.string().url("Portfolio links must be a valid urls")),
            graduatingYear: yup
              .number()
              .moreThan(2015, "Set a valid year")
              .lessThan(2100, "Set a valid year")
              .typeError("Graduating Year is a required field")
              .required("Graduating Year is a required field"),
          })}
          onFilterValues={values => {
            const portfolios = values.portfolios || [];
            values.portfolios = portfolios.map(url => prependHttpToUrl(url));
            return values;
          }}
          onError={() => window.scrollTo(0, 100)}
          onSubmit={values => {
            if (saving) {
              return;
            }
            this.setState({ saving: true });
            const temporaryUser: object = clone(user);
            const updatedData: { [s: string]: any } = deepMerge(
              temporaryUser,
              munger.expand<any>(values),
            );
            replaceObjectWithValues(updatedData.profile, "skills");
            replaceObjectWithValues(updatedData.profile, "locations");
            replaceObjectWithValues(updatedData.profile, "languages");
            replaceObjectWithValues(updatedData.profile, "workTypes");
            updatedData.profile.availabilityAnywhere =
              values.canWork.indexOf("move") > -1;
            updatedData.profile.availabilityRemote =
              values.canWork.indexOf("remote") > -1;
            updatedData.profile.portfolios = values.portfolios.filter(
              portfolio => portfolio.length > 0,
            );
            const promises: Promise<any>[] = [
              operations.uploadFiles(values.examples || []),
            ];
            if (!isString(values.photo)) {
              promises.push(operations.uploadProfilePhoto(values.photo));
            }
            return Promise.all(promises).then(() => {
              this.setState({ uploadCompleted: true });
              operations
                .updateProfile(updatedData)
                .then(() => {
                  this.formRef.current.valueChanged("examples", []);
                  this.setState({ saving: false, uploadCompleted: false });
                })
                .catch(() => {
                  this.setState({ saving: false });
                });
            });
          }}
        >
          {props => {
            const { values, valueChanged, isDirty } = props;
            return (
              <>
                <Container grid="medium-span-small">
                  <div>
                    <Field label={null}>
                      <PhotoUpload name="photo" />
                    </Field>
                    <Panel title="Account" icon={Icons.Shield}>
                      <Field required>
                        <Input name="firstName" />
                      </Field>
                      <Field required>
                        <Input name="lastName" />
                      </Field>
                      <ActionBar className={styles.actions} layout="center">
                        <Button
                          style="tertiary"
                          layout="full-width"
                          label="Change password"
                          action={this.triggerModalPassword}
                        />
                        <Button
                          style="secondary"
                          layout="full-width"
                          label="Deactivate Account"
                          action={this.triggerDeleteAccount}
                        />
                      </ActionBar>
                    </Panel>
                    <Panel title="Private Information" icon={Icons.Person}>
                      <Field>
                        <PhoneNumberInput name="phoneNumber" />
                      </Field>
                      <Field>
                        <Input
                          name="postcode"
                          placeholder="2000"
                          format={{
                            str: "XXXX",
                            separator: " ",
                            characters: "^[0-9]$",
                          }}
                        />
                      </Field>
                      <Field>
                        <Select
                          name="gender"
                          options={[
                            { label: "Male", value: "Male" },
                            { label: "Female", value: "Female" },
                            { label: "I'd rather not say", value: "N" },
                          ]}
                        />
                      </Field>
                    </Panel>
                    <Panel title="Social Accounts" icon={Icons.Social}>
                      <Field label={null}>
                        <Input
                          name="facebook"
                          prefix="facebook.com/"
                          prefixClassName={styles.social}
                        />
                      </Field>
                      <Field label={null}>
                        <Input
                          name="linkedin"
                          prefix="linkedin.com/in/"
                          prefixClassName={styles.social}
                        />
                      </Field>
                      <Field label={null}>
                        <Input
                          name="twitter"
                          prefix="twitter.com/"
                          prefixClassName={styles.social}
                        />
                      </Field>
                    </Panel>
                  </div>
                  <div>
                    <Panel title="Study details" icon={Icons.User}>
                      <Field label="University/College/Institution">
                        <Select
                          name="institution"
                          options={institutionOptions}
                          async={{
                            loadingText: "Retrieving institutions...",
                            callback: lookup(
                              Institution,
                              "/institutions",
                              (institutions: Institution[]) => {
                                return institutions.map(institution => {
                                  return {
                                    label: institution.name,
                                    value: institution.id,
                                  };
                                });
                              },
                            ),
                          }}
                          placeholder="Start typing to search..."
                        />
                      </Field>
                      <Field label="I'm studying a...">
                        <Select
                          name="afqLevel"
                          options={afqLevelOptions}
                          async={{
                            loadingText: "Retrieving study levels...",
                            callback: lookup(
                              AfqLevel,
                              "/afq-levels",
                              (afqLevels: AfqLevel[]) => {
                                return afqLevels.map(afqLevel => {
                                  return {
                                    label: afqLevel.name,
                                    value: afqLevel.id,
                                  };
                                });
                              },
                            ),
                          }}
                          placeholder="Select a study level..."
                        />
                      </Field>
                      <Field label={null}>
                        <Select
                          name="degree"
                          options={degreeOptions}
                          async={{
                            loadingText: "Retrieving degrees...",
                            callback: lookup(
                              Degree,
                              "/degrees",
                              (degrees: Degree[]) => {
                                return degrees.map(degree => {
                                  return {
                                    label: degree.name,
                                    value: degree.id,
                                  };
                                });
                              },
                            ),
                          }}
                          placeholder="Type your qualification name..."
                        />
                      </Field>
                      <Field label="I'm graduating in..." required>
                        <Input
                          name="graduatingYear"
                          placeholder="Select a graduating year..."
                          format={{
                            str: "XXXX",
                            separator: " ",
                            characters: "^[0-9]$",
                          }}
                        />
                      </Field>
                      <ModalSelect
                        itemsName="Skills"
                        value={values.skills}
                        onChange={valueChanged}
                        name="skills"
                        modalTitle="Select the things you're good at..."
                        label="I'm good at..."
                        required
                        help={<ArrayCount array={values.skills} max={6} />}
                        options={skillOptions}
                        modalCallback={lookup(
                          Skill,
                          "/skills",
                          (skills: Skill[]) => {
                            return skills.map(skill => {
                              return {
                                label: skill.name,
                                value: skill,
                              };
                            });
                          },
                          0,
                          100,
                        )}
                        async={{
                          loadingText: "Retrieving skills...",
                          callback: lookup(
                            Skill,
                            "/skills",
                            (skills: Skill[]) => {
                              return skills.map(skill => {
                                return {
                                  label: skill.name,
                                  value: skill,
                                };
                              });
                            },
                          ),
                        }}
                        placeholder="Select some skills..."
                        actionLabel="View all"
                      />
                      <Field label={"My area of interest"}>
                        <Select
                          name="category"
                          options={categoryOptions}
                          async={{
                            loadingText: "Retrieving areas of interest...",
                            callback: lookup(
                              Category,
                              "/categories",
                              (categories: Category[]) => {
                                return categories.map(category => {
                                  return {
                                    label: category.name,
                                    value: category.name,
                                  };
                                });
                              },
                            ),
                          }}
                          placeholder="Start typing to search..."
                        />
                      </Field>
                      <Field label="What makes me awesome">
                        <Input
                          lines={6}
                          name="bio"
                          placeholder="Add a brief overview of the things that interest or motivate you, your hobbies, and any experience or achievements you wish to share. Also add here any additional qualifications. courses, micro credentials etc."
                        />
                      </Field>
                      <Field label="Are you a domestic or international student?">
                        <RadioGroup
                          name="international"
                          options={[
                            { label: "Domestic", value: false },
                            { label: "International", value: true },
                          ]}
                        />
                      </Field>
                      <Field label="Are you of Aboriginal and/or Torres Strait Islander origin?">
                        <RadioGroup
                          name="aboriginal"
                          layout="horizontal"
                          options={[
                            { label: "No", value: false },
                            { label: "Yes", value: true },
                            { label: "Prefer not to say", value: null },
                          ]}
                        />
                      </Field>
                      <Field label="Do you speak any additional languages?">
                        <Select
                          name="languages"
                          options={languageOptions}
                          async={{
                            loadingText: "Retrieving languages...",
                            callback: lookup(
                              Language,
                              "/languages",
                              (languages: Language[]) => {
                                return languages.map(language => {
                                  return {
                                    label: language.name,
                                    value: language,
                                  };
                                });
                              },
                            ),
                          }}
                          multiple
                          placeholder="Select some languages..."
                        />
                      </Field>
                    </Panel>
                    <Panel title="Work details">
                      <Field
                        label="Are you currently looking for work?"
                        className={styles.available}
                      >
                        <RadioGroup
                          name="available"
                          options={[
                            { label: "Yes", value: true },
                            { label: "No", value: false },
                          ]}
                        />
                      </Field>
                      <Text className={styles.availableLabel}>
                        If you select No, you will not appear in employer
                        searches
                      </Text>
                      <Field label="My work status">
                        <Select
                          name="workEligibility"
                          options={[
                            {
                              value: "permanent_resident",
                              label: "Australian citizen or permanent resident",
                            },
                            {
                              value: "working_visa",
                              label: "Valid working visa",
                            },
                            {
                              value: "sponsorship",
                              label: "I'm looking for Visa sponsorship",
                            },
                          ]}
                          placeholder="Select a work eligibility status..."
                        />
                      </Field>
                      <Field label="I'm interested in...">
                        <CheckboxGroup
                          name="workTypes"
                          layout="horizontal"
                          options={jobTypes}
                        />
                      </Field>
                      <Field label="Locations you can work from?">
                        <Select
                          name="locations"
                          options={locationOptions}
                          async={{
                            loadingText: "Retrieving locations...",
                            callback: lookup(
                              Location,
                              "/locations",
                              (locations: Skill[]) => {
                                return locations.map(location => {
                                  return {
                                    label: location.name,
                                    value: location,
                                  };
                                });
                              },
                            ),
                          }}
                          multiple
                          placeholder="Select some locations..."
                        />
                      </Field>
                      <Field label={null}>
                        <CheckboxGroup
                          name="canWork"
                          layout="horizontal"
                          options={[
                            { value: "remote", label: "I can work remotely" },
                            { value: "move", label: "Happy to work anywhere" },
                          ]}
                        />
                      </Field>
                      <Field label="Upload documents" className={styles.upload}>
                        <Upload
                          uploadComplete={uploadCompleted}
                          multiple
                          message="Click or drag-and-drop your files to upload (pdf, jpeg or png)"
                          showPreview={false}
                          showFileList
                          acceptedTypes="image/jpeg, image/png, application/pdf"
                          name="examples"
                          icon={Icons.Upload}
                        />
                      </Field>
                      {user.profile.fileUploads.length > 0 && (
                        <Heading
                          text="Existing uploaded work examples"
                          size={Size.Smaller}
                          className={styles.upload}
                        />
                      )}
                      {user.profile.fileUploads.length > 0 && (
                        <div className={styles.fileUploads}>
                          {user.profile.fileUploads.map(upload => (
                            <WorkExample
                              fileUpload={upload}
                              key={upload.uuid}
                              onRemove={this._removeWorkExample}
                            />
                          ))}
                        </div>
                      )}
                      <Field label="Links to your online portfolio">
                        <InputList
                          name="portfolios"
                          addLabel="+ add another link..."
                          placeholder="e.g. YouTube, Github, Dribbble"
                        />
                      </Field>
                    </Panel>
                  </div>
                  <div className={styles.helper}>
                    <Panel>
                      <PanelArrow />
                      <Heading size={Size.Small}>Your profile</Heading>
                      <Text>
                        Your profile will appear with your job applications to
                        provide additional information about you to prospective
                        employers.
                      </Text>
                      <Text>
                        Your postcode, gender and social profiles won’t be
                        displayed.
                      </Text>
                      <Text>
                        You’re more likely to stand out from the crowd and get
                        hired when you complete your profile with a photo of you
                        smiling, details about what makes you awesome, your
                        skills and examples of your work/resume.
                      </Text>
                    </Panel>
                  </div>
                </Container>
                <StickyFooterBar className={styles.update}>
                  <Button
                    type="submit"
                    label={saving ? <Loading /> : "Update"}
                    style="secondary"
                    className={styles.updateButton}
                    disabled={!isDirty}
                  />
                </StickyFooterBar>
              </>
            );
          }}
        </Form>
        <DeleteAccount
          toggleModal={this.triggerDeleteAccount}
          modalIsOpen={this.state.deleteAccountModalIsOpen}
        />
        <ModalPassword
          name="change-password"
          modalTitle="Change Password"
          toggleModal={this.triggerModalPassword}
          modalIsOpen={this.state.passwordModalIsOpen}
        />
      </>
    );
  }
}

const Student = connect(
  null,
  mapOperationsToDispatchProps(operations),
)(PureStudent);

export { Student };
export default Student;
