import * as React from "react";

type OwnProps = {
  label: string[] | string;
  suffix?: string;
  separator?: string;
};

const defaultProps = {
  suffix: "Ribit",
  separator: " - ",
};
type DefaultProps = Readonly<typeof defaultProps>;

const Title: React.FunctionComponent<OwnProps> = (props: OwnProps) => {
  const { label, suffix, separator } = props;
  let parts: string[] = [];
  if (Array.isArray(label)) {
    parts = parts.concat(label);
  } else {
    parts.push(label);
  }
  parts.push(suffix);

  document.title = parts.join(separator);
  return <></>;
};

(Title.defaultProps as DefaultProps) = defaultProps;

export { Title };
export default Title;
