import * as React from "react";
import * as commonStyles from "../styles.modules.css";
import * as yup from "yup";

import {
  ActionBar,
  Button,
  Field,
  Form,
  Input,
  Select,
  Text,
} from "@ribit/components";

import AfqLevel from "@app/models/afq-level";
import Degree from "@app/models/degree";
import Institution from "@app/models/institution";
import Layout from "../layout";
import Title from "../title";
import { lookup } from "@app/helpers/api";

type OwnProps = {
  step: number;
  stepTotal: number;
  onSubmit: (values: any) => void;
};

const Studying: React.FunctionComponent<OwnProps> = (props: OwnProps) => {
  const { onSubmit, step, stepTotal } = props;
  return (
    <Layout>
      <Title text="Your area of study" step={`${step}/${stepTotal}`} />
      <Text className={commonStyles.content}>
        Please tell us about your education. Ribit uses this along with other
        information you provide to match you to the right jobs and introduce you
        to employers.
      </Text>
      <Form
        validateSchema={yup.object().shape({
          institution: yup.number().required("Institution is a required field"),
          afqLevel: yup
            .number()
            .required("Your level of study is a required field"),
          courseDegree: yup
            .number()
            .required("What you're studying is a required field"),
          graduateYear: yup
            .number()
            .moreThan(2015, "Set a valid year")
            .lessThan(2100, "Set a valid year")
            .typeError("Graduating Year is a required field")
            .required("Graduating Year is a required field"),
        })}
        onSubmit={values => {
          return onSubmit(values);
        }}
      >
        <Field label={"University/College/Institution"} required>
          <Select
            name="institution"
            placeholder="Select your university/college/institution"
            async={{
              loadingText: "Retrieving institutions...",
              callback: lookup(
                Institution,
                "/institutions",
                (institutions: Institution[]) => {
                  return institutions.map(institution => {
                    return {
                      label: institution.name,
                      value: institution.id,
                    };
                  });
                },
              ),
            }}
          />
        </Field>
        <Field label="I'm studying a..." required>
          <Select
            name="afqLevel"
            placeholder="Select your level of study (eg Bachelor/Masters)"
            async={{
              loadingText: "Retrieving study levels...",
              callback: lookup(
                AfqLevel,
                "/afq-levels",
                (afqLevels: AfqLevel[]) => {
                  return afqLevels.map(afqLevel => {
                    return {
                      label: afqLevel.name,
                      value: afqLevel.id,
                    };
                  });
                },
              ),
            }}
          />
        </Field>
        <Field label={null} required>
          <Select
            name="courseDegree"
            placeholder="Select your degree name"
            async={{
              loadingText: "Retrieving degrees...",
              callback: lookup(Degree, "/degrees", (degrees: Degree[]) => {
                return degrees.map(degree => {
                  return {
                    label: degree.name,
                    value: degree.id,
                  };
                });
              }),
            }}
          />
        </Field>
        <Field label="Year of completion" required>
          <Input
            type="text"
            name="graduateYear"
            placeholder="XXXX"
            format={{ str: "XXXX", separator: " ", characters: "^[0-9]$" }}
          />
        </Field>
        <ActionBar layout="center" className={commonStyles.actionBar}>
          <Button label="Next" type="submit" style="secondary" />
        </ActionBar>
      </Form>
    </Layout>
  );
};

export { Studying };
export default Studying;
