import * as React from "react";
import * as selectApplication from "@app/state/ducks/selected-application/operations";
import styles from "./styles.modules.css";

import { Icon, Icons, Modal } from "@ribit/components";

import Application from "@app/models/application";
import { CloseJob } from "./close-job";
import { FunctionComponent } from "react";
import Job from "@app/models/job";
import { connect } from "react-redux";
import { operations as jobOperations } from "@app/state/ducks/job/operations";
import { mapOperationsToDispatchProps } from "@ribit/lib";

interface Props {
  onClose: (dirty?: boolean) => void;
  job: Job;
  applications: Application[];
  isOpen: boolean;
}

interface DispatchProps {
  operations: {
    select: typeof selectApplication;
    job: typeof jobOperations;
  };
}

const PureCloseJobDialog: FunctionComponent<Props> = ({
  job,
  applications,
  onClose,
  isOpen,
  ...rest
}) => {
  const { operations } = (rest as unknown) as DispatchProps;

  const closeButtonClicked = () => {
    onClose();
  };

  const jobClosed = (application?: Application, reason?: number) => {
    if (application) {
      operations.select.acceptApplication(job.uuid, application.uuid, reason);
    }
    operations.job.close(job.uuid, reason);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.content}>
        <button onClick={closeButtonClicked} className={styles.close}>
          <Icon icon={Icons.Cross} />
        </button>
        <CloseJob
          onClose={jobClosed}
          onBack={closeButtonClicked}
          applications={applications}
          loading={false}
        />
      </div>
    </Modal>
  );
};

export const CloseJobDialog = connect(
  null,
  mapOperationsToDispatchProps({
    select: selectApplication,
    job: jobOperations,
  }),
)(PureCloseJobDialog);
