import { alertsAdd, alertsClear } from "../site-alerts/actions";
import {
  create as createAction,
  createError,
  createSuccess,
  fetchError,
  fetchSuccess,
  fetching,
  update as updateAction,
  updateError,
  updateSuccess,
} from "./actions";
import { del, get, post, put } from "@app/helpers/api";
import { fetch as fetchInvites } from "@app/state/ducks/manage/invites/operations";

import { Dispatch } from "redux";
import Job from "@app/models/job";
import { history } from "@app/state/store";

const _retrieveJob = (url: string): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(fetching());
    return get(url, Job)
      .then((job: Job) => {
        dispatch(fetchSuccess(job));
        return job;
      })
      .catch(() => {
        dispatch(fetchError());
      });
  };
};

const retrieveJobByUuid = (uuid: string): any => {
  return _retrieveJob(`/jobs/manage/${uuid}`);
};

const retrieveJobBySlug = (slug: string): any => {
  return _retrieveJob(`/jobs/${slug}`);
};

const create = (data: object): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(alertsClear());
    dispatch(createAction());
    return post("/jobs/manage", data, Job)
      .then((job: Job) => {
        dispatch(
          alertsAdd(
            "Successfully created your new job.",
            null,
            "Close",
            "success",
          ),
        );
        dispatch(createSuccess(job));
        fetchInvites(job.uuid)(dispatch);
        return job;
      })
      .catch(() => {
        dispatch(
          alertsAdd(
            "Failed to create the job, please correct the errors and try again.",
            null,
            "Close",
            "error",
          ),
        );
        dispatch(createError());
      });
  };
};

const close = (uuid: string, reason?: number) => {
  return (dispatch: Dispatch<any>) => {
    return del(`/jobs/manage/${uuid}`, null, { data: { reason } })
      .then(job => {
        dispatch(fetchSuccess(job));
      })
      .catch(() => {
        dispatch(alertsAdd("Failed to close job.", null, "Close", "error"));
        fetchError();
      })
      .then(() => {
        history.push("/dashboard");
      });
  };
};

const sendInvitation = (uuid: string, student: string) => {
  return (dispatch: Dispatch<any>) => {
    return post(`/jobs/manage/${uuid}/invitations`, { student })
      .then(job => {
        dispatch(
          alertsAdd(
            "Successfully sent job invitation.",
            null,
            "Close",
            "success",
          ),
        );
        dispatch(fetchSuccess(job));
      })
      .catch(e => {
        const data = e.response.data;
        let message = "Failed to send job invitation.";
        let shouldThrow = true;

        if (data.errors && data.errors.nonFieldErrors) {
          message = data.errors.nonFieldErrors[0] || message;
          shouldThrow = false;
        }

        dispatch(alertsAdd(message, null, "Close", "error"));
        fetchError();

        if (shouldThrow) {
          throw e;
        }
      });
  };
};

const update = (uuid: string, data: object): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(alertsClear());
    dispatch(updateAction());
    return put(`/jobs/manage/${uuid}`, data, Job)
      .then((job: Job) => {
        dispatch(
          alertsAdd("Successfully updated your job.", null, "Close", "success"),
        );
        dispatch(updateSuccess(job));
        return job;
      })
      .catch(() => {
        dispatch(
          alertsAdd(
            "Failed to updated the job, please correct the errors and try again.",
            null,
            "Close",
            "error",
          ),
        );
        dispatch(updateError());
      });
  };
};

type Operations = {
  retrieveJobByUuid: (uuid: string) => any;
  retrieveJobBySlug: (slug: string) => any;
  create: (data: object) => any;
  update: (uuid: string, data: object) => any;
  sendInvitation: (uuid: string, student: string) => any;
  close: (uuid: string, reason: number) => any;
};

const operations: Operations = {
  retrieveJobByUuid,
  retrieveJobBySlug,
  create,
  sendInvitation,
  update,
  close,
};

export { operations, Operations };
