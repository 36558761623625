import { hydrate } from "@ribit/lib";
import { User } from "@app/models/user";
import { Job } from "@app/models/job";

class CourseCreditApprovalRequest {
  readonly uuid: string;
  supervisorEmail: string;
  message: string;
  university: string;
  @hydrate
  job: Job;
  @hydrate
  requestedBy: User;
  @hydrate
  readonly createdAt: Date;
  readonly humanCreatedAt: string;
  // application for request user "created at" date if any
  readonly humanApplicationCreatedAt: string;
}

export default CourseCreditApprovalRequest;
