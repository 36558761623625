import { Action, ApiData } from "@app/state/types";
import { Payload, ResetAttempt, ResetRequest, Types } from "./types";

const initialState: ApiData<ResetRequest | ResetAttempt> = {
  loading: null,
  data: {
    name: null,
    email: null,
    valid: false,
    success: null,
  },
};

const reducer = (state = initialState, action: Action<Payload>) => {
  const { type } = action;
  const payload: Payload = action.payload;
  switch (type) {
    case Types.FETCH_SUCCESS:
      return {
        loading: false,
        data: payload.data,
      };
    case Types.FETCHING:
      return {
        loading: true,
        data: payload.data,
      };
    case Types.FETCH_ERROR:
      return {
        loading: false,
        data: payload.data,
      };
    case Types.RESET_ATTEMPT:
      return {
        loading: true,
        data: {
          valid: true,
          success: null,
        },
      };
    case Types.RESET_SUCCESS:
      return {
        loading: false,
        data: {
          valid: true,
          success: true,
        },
      };
    case Types.RESET_ERROR:
      return {
        loading: false,
        data: {
          valid: true,
          success: false,
        },
      };
    default:
      return state;
  }
};

export { reducer };

export default reducer;
