import {
  clear as clearActive,
  fetchError,
  fetchSuccess,
  fetching,
} from "./actions";

import { Dispatch } from "redux";
import Job from "@app/models/job";
import Pagination from "@app/models/pagination";
import { get } from "@app/helpers/api";

const fetch = (offset = 0, limit = 10): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(fetching());
    get(`/jobs/manage?offset=${offset}&limit=${limit}&is_closed=True`, Job)
      .then((data: Pagination<Job>) => {
        dispatch(fetchSuccess(data));
      })
      .catch(() => {
        dispatch(fetchError());
      });
  };
};

const clear = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch(clearActive());
  };
};

type Operations = {
  fetch: (offset?: number, limit?: number) => any;
  clear: () => void;
};

const operations: Operations = {
  fetch,
  clear,
};

export { fetch, operations, Operations };
