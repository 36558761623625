import Application from "@app/models/application";
import Pagination from "@app/models/pagination";

const Types = {
  FETCHING: "@@manage/shortlist/FETCHING",
  FETCH_SUCCESS: "@@manage/shortlist/FETCH_SUCCESS",
  FETCH_ERROR: "@@manage/shortlist/FETCH_ERROR",
  CLEAR: "@@manage/shortlist/CLEAR",
};

type Payload = {
  loading: boolean;
  data: Pagination<Application>;
};

export { Payload, Types };

export default Types;
