import * as React from "react";

import { Loading } from "@ribit/components";
import { Navigate } from "react-router-dom";
import { State } from "@app/state";
import { Tenant } from "./containers";
import { Tenant as TenantType } from "@app/models/tenant";
import { Title } from "@app/components/title";
import User from "@app/models/user";
import { connect } from "react-redux";

type OwnProps = Record<string, never>;
type OwnState = Record<string, never>;
type DispatchProps = Record<string, never>;
type StateProps = {
  user: User;
  tenant: TenantType;
};

type HomeProps = StateProps & DispatchProps & OwnProps;

class PureHome extends React.Component<HomeProps, OwnState> {
  renderContent(): React.ReactElement<any> {
    const { user, tenant } = this.props;
    if (!tenant || (tenant && !tenant.uuid)) {
      return <Loading style="inverted" />;
    }
    if (tenant.isPrimary && !user.isAuthenticated) {
      return <Navigate to="/auth/login" />;
    } else if (
      user.isAuthenticated &&
      (tenant.uuid === user.tenant.uuid || tenant.isPrimary)
    ) {
      return <Navigate to="/dashboard" />;
    }
    return <Tenant tenant={tenant} />;
  }

  render(): React.ReactElement<any> {
    let label = "Home";
    const { tenant } = this.props;
    if (tenant && tenant.name && !tenant.isPrimary) {
      label = tenant.name;
    }
    return (
      <>
        <Title label={label} />
        {this.renderContent()}
      </>
    );
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    user: state.user,
    tenant: state.tenant,
  };
};

const Home = connect(mapStateToProps)(PureHome);

export { Home };
export default Home;
