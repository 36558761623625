import * as React from "react";

import { Operations, operations } from "@app/state/ducks/user/operations";

import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";

type OwnProps = Record<string, never>;
type DispatchProps = {
  operations?: Operations;
};
type LogoutProps = DispatchProps & OwnProps;

class PureLogout extends React.Component<LogoutProps> {
  _onSubmit = values => {
    this.props.operations.login(values.emailAddress, values.password);
  };

  componentDidMount() {
    this.props.operations.logout();
  }

  render(): React.ReactElement<any> {
    return <></>;
  }
}

const mapDispatchToProps = mapOperationsToDispatchProps(operations);

const Logout = connect(null, mapDispatchToProps)(PureLogout);

export { Logout };
export default Logout;
