import { AnyAction } from "redux";
import State from "@app/state/types";
import { Types } from "@app/state/ducks/analytics/actions";
import { User } from "@app/models/user";
import { Types as UserTypes } from "@app/state/ducks/user/types";

export const observeEmployerSignUp = (action: AnyAction) => {
  const { type } = action;
  if (
    type !== Types.EMPLOYER_SIGN_UP_SUCCESS &&
    type !== UserTypes.REGISTER_KIOSK_SUCCESS
  )
    return;
  const { user } = action.payload;
  if (user.company === undefined && user.userType !== 2) return;

  window.analytics.track("EMPLOYER_SIGNED_UP", {
    uuid: user.uuid,
    tenantUuid: user.tenant.uuid,
    tenantName: user.tenant.name,
    source:
      action.type === UserTypes.REGISTER_KIOSK_SUCCESS
        ? "KIOSK_SIGN_UP_PAGE"
        : "SIGN_UP_PAGE",
  });

  window.analytics.identify(user.uuid, {
    userType: "EMPLOYER",
    tenantUuid: (user.tenant || user.company).uuid,
  });
};

export const observeStudentSignUp = (action: AnyAction) => {
  const { type } = action;
  if (
    type !== Types.STUDENT_SIGN_UP_SUCCESS &&
    type !== UserTypes.REGISTER_KIOSK_SUCCESS
  )
    return;
  const { user } = action.payload;
  if (user.company !== undefined && user.userType !== 1) return;

  window.analytics.track("STUDENT_SIGNED_UP", {
    uuid: user.uuid,
    source:
      action.type === UserTypes.REGISTER_KIOSK_SUCCESS
        ? "KIOSK_SIGN_UP_PAGE"
        : "SIGN_UP_PAGE",
    tenantUuid: user.tenant.uuid,
    tenantName: user.tenant.name,
  });

  window.analytics.identify(user.uuid, {
    userType: "STUDENT",
    tenantUuid: user.tenant.uuid,
  });
};

export const observeProfileUpdate = (action: AnyAction, state: State) => {
  if (action.type !== UserTypes.UPDATE_PROFILE_SUCCESS) return;
  const profileContext = window.location.pathname.startsWith("/dashboard")
    ? "FINISHED_CREATING_PROFILE"
    : "UPDATED_PROFILE";
  const userType = state.user.userType === 1 ? "STUDENT" : "EMPLOYER";
  const eventName = `${userType}_${profileContext}`;

  const percentageComplete = (state.user as User).percentageComplete;

  window.analytics.track(eventName, {
    userId: state.user.uuid,
    percentageComplete,
  });
};
