import * as React from "react";
import styles from "./styles.modules.css";

import { Heading, Panel, Size, Text } from "@ribit/components";

import { Job as JobType } from "@app/models/job";

type OwnProps = {
  job: JobType;
};

const Job: React.FunctionComponent<OwnProps> = (props: OwnProps) => {
  const { job } = props;
  const description = job.description.substr(0, 200) + "...";
  return (
    <Panel className={styles.outer}>
      <div className={styles.highlight}></div>
      <Heading size={Size.Small} text={job.title} className={styles.title} />
      <Text className={styles.company}>
        {job.owner.company.name}, {job.location}
      </Text>
      <Text className={styles.description}>{description}</Text>
      <Text className={styles.other}>
        {job.type.name} for {job.budget}
      </Text>
      <div className={styles.other}>Located in {job.location} area</div>
    </Panel>
  );
};

export { Job };
export default Job;
