import Types, { Payload } from "./types";

import { Action } from "@app/state/types";
import Message from "@app/models/messages";

const fetchSuccess = (data: Message[]): Action<Payload> => {
  return {
    type: Types.FETCH_SUCCESS,
    payload: {
      loading: false,
      data: data,
    },
  };
};

const fetchError = (): Action<Payload> => {
  return {
    type: Types.FETCH_ERROR,
    payload: {
      loading: false,
      data: null,
    },
  };
};

const fetching = (): Action<Payload> => {
  return {
    type: Types.FETCHING,
    payload: {
      loading: true,
      data: null,
    },
  };
};

const clear = (): Action<Payload> => {
  return {
    type: Types.CLEAR,
    payload: {
      loading: false,
      data: [],
    },
  };
};

const creating = (): Action<Payload> => {
  return {
    type: Types.CREATE,
    payload: {
      loading: true,
      data: [],
    },
  };
};

const createSuccess = (data: Message): Action<Payload> => {
  return {
    type: Types.CREATE_SUCCESS,
    payload: {
      loading: false,
      data: [data],
    },
  };
};

const createError = (): Action<Payload> => {
  return {
    type: Types.CREATE_ERROR,
    payload: {
      loading: false,
      data: [],
    },
  };
};

export {
  fetchSuccess,
  fetchError,
  fetching,
  clear,
  creating,
  createError,
  createSuccess,
};
