import * as React from "react";
import * as commonStyles from "../styles.modules.css";
import * as yup from "yup";

import {
  ActionBar,
  Button,
  Field,
  Form,
  Input,
  Text,
  Upload,
} from "@ribit/components";
import { Operations, operations } from "@app/state/ducks/user/operations";

import Layout from "../layout";
import Title from "../title";
import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";
import { prependHttpToUrl } from "@app/helpers/urls";

type OwnProps = {
  step: number;
  stepTotal: number;
  onSubmit: (values: any, completed: boolean) => void;
};

type DispatchProps = {
  operations: Operations;
};

type WorkProps = OwnProps & DispatchProps;

const PureWork: React.FunctionComponent<WorkProps> = (props: WorkProps) => {
  const { onSubmit, step, stepTotal, operations } = props;
  return (
    <Layout>
      <Title text="Add examples of your work" step={`${step}/${stepTotal}`} />
      <Text className={commonStyles.content}>
        We encourage you to upload and share your resume, examples of projects
        you've worked on, any assessment reports such as personality or skills
        validation, and anything else that represents your best self. Acceptable
        file types include jpeg, png, pdf.
      </Text>
      <Text className={commonStyles.content}>
        You can also add links to videos or online portfolios that highlight
        your work.
      </Text>
      <Form
        validateSchema={yup.object().shape({
          portfolio: yup.string().url("Portfolio must be a valid URL."),
        })}
        onFilterValues={values => {
          values.portfolio = prependHttpToUrl(values.portfolio);
          return values;
        }}
        onSubmit={values => {
          const dataToSubmit: { portfolios?: string[] } = {};
          if (values.portfolio) {
            dataToSubmit.portfolios = [values.portfolio];
          }
          if (!values.fileUpload) {
            return onSubmit(dataToSubmit, false);
          }
          return operations.uploadFiles(values.fileUpload).then(() => {
            onSubmit(dataToSubmit, false);
          });
        }}
      >
        <Field label="Upload examples of your work/resume">
          <Upload
            name="fileUpload"
            message="Click or drag-and-drop your files to upload (pdf, jpeg or png)"
            multiple
            showPreview={false}
            showFileList
            acceptedTypes="image/jpeg, image/png, application/pdf"
          />
        </Field>
        <Field label="Add a link to your online work (you can add more later)">
          <Input
            name="portfolio"
            placeholder="e.g. Youtube, Github, Dribbble etc"
          />
        </Field>
        <ActionBar layout="center" className={commonStyles.actionBar}>
          <Button
            label="Skip"
            action={() => onSubmit(null, false)}
            style="tertiary"
          />
          <Button label="Next" type="submit" style="secondary" />
        </ActionBar>
      </Form>
    </Layout>
  );
};

const Work = connect(null, mapOperationsToDispatchProps(operations))(PureWork);

export { Work };
export default Work;
