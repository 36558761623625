import * as React from "react";

import {
  Operations,
  operations,
} from "@app/state/ducks/course-credit-approval-requests/operations";

import CourseCreditApprovalRequest from "@app/models/course-credit-approval-request";
import { History } from "history";
import Pagination from "@app/models/pagination";
import { State } from "@app/state";
import { Student } from "@app/models/student";
import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";
import styles from "@app/pages/jobs/components/styles.modules.css";
import { Icons, Panel, Table, Text } from "@ribit/components";
import { EmptyTableView } from "@app/components/empty-table-view";
import { ProfilePhoto } from "@app/components/profile-photo";
import { Button } from "@app/components/button";

type OwnProps = {
  data?: Pagination<CourseCreditApprovalRequest>;
};
type OwnState = {
  offset: number;
};
type DispatchProps = {
  operations?: Operations;
};
type StateProps = {
  loading?: boolean;
  user?: Student;
  history: History;
};
type CourseCreditRolesProps = StateProps & DispatchProps & OwnProps;

class PureCourseCreditRoles extends React.Component<
  CourseCreditRolesProps,
  OwnState
> {
  state: OwnState = {
    offset: 0,
  };

  fetch() {
    const { operations } = this.props;
    operations.clearCourseCreditApprovalRequests();
    operations.retrieveCourseCreditApprovalRequests();
  }

  componentDidMount() {
    this.fetch();
  }

  componentWillUnmount() {
    this.props.operations.clearCourseCreditApprovalRequests();
  }

  render(): React.ReactElement<any> {
    const { data, loading } = this.props;

    return (
      <div data-cy="course-credit-approval-requests">
        <Panel title="Course Credit Roles" icon={Icons.Suitcase}>
          <Table
            className={styles.table}
            cellClassName={styles.cell}
            showColumns={true}
            columns={[
              // job picture + title + company name
              {
                key: "Role Title",
                renderer: this._renderJobTitle,
                width: "35%",
              },
              {
                key: "Request submitted",
                renderer: this._renderCreatedAt,
                width: "25%",
              },
              {
                key: "Application status",
                renderer: this._renderApplicationStatus,
                width: "25%",
              },
              { key: "", renderer: this._renderActions, width: "15%" },
            ]}
            data={data.results}
            loading={loading && !data.results.length}
            defaultLoadingLabel="Retrieving roles..."
            emptyView={this._renderEmptyTableView()}
            data-cy="job-listing"
          />
        </Panel>
      </div>
    );

    // return <h3>Course Credit Roles</h3>;
  }

  private _renderJobTitle(request: CourseCreditApprovalRequest) {
    const job = request.job;
    return (
      <ProfilePhoto
        className={styles.logo}
        url={job.owner.company.logo}
        altName={job.owner.company.name}
        name={job.title}
        action={`/jobs/${job.slug}`}
        subtitle={job.owner.company.name}
        size="small"
        style="square"
      />
    );
  }

  private _renderCreatedAt(request: CourseCreditApprovalRequest) {
    return (
      <Text className={styles.count}>Submitted {request.humanCreatedAt}</Text>
    );
  }

  private _renderApplicationStatus(request: CourseCreditApprovalRequest) {
    return (
      <Text className={styles.count}>
        {request.humanApplicationCreatedAt
          ? `Submitted ${request.humanApplicationCreatedAt}`
          : "Not applied yet"}
      </Text>
    );
  }

  private _renderActions(request: CourseCreditApprovalRequest) {
    return (
      <div>
        <Button
          label="View job"
          size="small"
          style="secondary"
          action={`/jobs/${request.job.slug}`}
          dataCy="button__view-job"
        />
      </div>
    );
  }

  private _renderEmptyTableView = (): React.ReactElement<any> => {
    return (
      <EmptyTableView
        span={5}
        title="No roles here yet"
        message="You haven't interacted with a course credit role yet. When you do, your approval request and/or application will be visible here."
      />
    );
  };
}

const mapStateToProps = (state: State): StateProps => {
  return {
    loading: state.courseCreditApprovalRequests.loading,
    // lastUpdatedApplication: state.jobApplication.data,
    history: state.history,
  };
};

const CourseCreditRoles = connect(
  mapStateToProps,
  mapOperationsToDispatchProps(operations),
)(PureCourseCreditRoles);

export { CourseCreditRoles };
export default CourseCreditRoles;
