import * as React from "react";

type OwnProps = Record<string, never>;

const Unauthorized: React.FunctionComponent<OwnProps> = () => {
  return <div>Unauthorized</div>;
};

export { Unauthorized };
export default Unauthorized;
