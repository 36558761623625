import CourseCreditApprovalRequest from "@app/models/course-credit-approval-request";

const Types = {
  FETCHING: "@@courseCreditApprovalRequest/FETCHING",
  FETCH_SUCCESS: "@@courseCreditApprovalRequest/FETCH_SUCCESS",
  FETCH_ERROR: "@@courseCreditApprovalRequest/FETCH_ERROR",
  CREATE: "@@courseCreditApprovalRequest/CREATE",
  CREATE_ERROR: "@@courseCreditApprovalRequest/CREATE_ERROR",
  CREATE_SUCCESS: "@@courseCreditApprovalRequest/CREATE_SUCCESS",
  UPDATE: "@@courseCreditApprovalRequest/UPDATE",
  UPDATE_ERROR: "@@courseCreditApprovalRequest/UPDATE_ERROR",
  UPDATE_SUCCESS: "@@courseCreditApprovalRequest/UPDATE_SUCCESS",
};

type Payload = {
  loading: boolean;
  data: CourseCreditApprovalRequest;
};

export { Payload, Types };

export default Types;
