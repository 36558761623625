import * as queryString from "query-string";

import {
  clear as clearAction,
  fetchError,
  fetchSuccess,
  fetching,
} from "./actions";

import { Dispatch } from "redux";
import Job from "@app/models/job";
import Pagination from "@app/models/pagination";
import { QueryStringFilter } from "@app/types";
import { get } from "@app/helpers/api";

const retrieve = (
  offset = 0,
  limit = 10,
  values: QueryStringFilter = {},
): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(fetching());
    get(
      `/jobs?${queryString.stringify(values, {
        arrayFormat: "comma",
      })}&offset=${offset}&limit=${limit}`,
      Job,
    )
      .then((data: Pagination<Job>) => {
        dispatch(fetchSuccess(data));
      })
      .catch(() => {
        dispatch(fetchError());
      });
  };
};

const recent = (): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(fetching());
    get("/jobs?recent", Job)
      .then((data: Pagination<Job>) => {
        dispatch(fetchSuccess(data));
      })
      .catch(() => {
        dispatch(fetchError());
      });
  };
};

const clear = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch(clearAction());
  };
};

type Operations = {
  retrieve: (
    offset?: number,
    limit?: number,
    values?: QueryStringFilter,
  ) => any;
  clear: () => void;
  recent: () => void;
};

const operations: Operations = {
  retrieve,
  recent,
  clear,
};

export { retrieve, operations, Operations };
