import { fetchedSuccess, fetching } from "./actions";

import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import Tenant from "@app/models/tenant";
import { deserialize } from "@ribit/lib";
import { get } from "@app/helpers/api";
import { storage } from "@ribit/lib";

const retrieve = (): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(fetching());
    const hostname: string = window.location.hostname.replace(/\./g, "_");
    get(`/tenants/${hostname}?expand=theme,content,links,features`)
      .then((response: AxiosResponse) => {
        const tenant: Tenant = deserialize(Tenant, response);
        if (response["customUi"]) {
          tenant.customUi = response["customUi"];
        }
        if (tenant.segmentKey !== null && window.analytics.load !== undefined) {
          window.analytics.load(tenant.segmentKey);
        }

        storage.to("tenant", tenant);
        dispatch(fetchedSuccess(tenant));
      })
      .catch(() => {
        // Shouldn't error as we've CNAME'd the tenants.
      });
  };
};

type Operations = {
  retrieve: () => any;
};

const operations: Operations = {
  retrieve,
};

export { retrieve, operations, Operations };
