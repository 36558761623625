import * as React from "react";
import styles from "./styles.modules.css";

import {
  Attribute,
  AttributeList,
  AttributeType,
  Button,
  Container,
} from "@ribit/components";
import { Header, Tag } from "./components";

import { FunctionComponent } from "react";
import Skill from "@app/models/skill";
import Student from "@app/models/student";
import WorkExample from "@app/pages/account/student/components/work-example";

interface Props {
  student: Student;
  matchSkills?: Skill[];
  applicationDetails?: AttributeType[];
  sidebarContent?: React.ReactElement;
  blur?: boolean;
}

const StudentProfile: FunctionComponent<Props> = ({
  student,
  matchSkills,
  applicationDetails,
  sidebarContent,
  blur,
}) => {
  const { profile } = student;
  const {
    fileUploads,
    graduateYear,
    internationalStudent,
    portfolios,
  } = profile;

  const details = [];
  const portfolioItems = portfolios.map(portfolio => (
    <div key={portfolio.link}>
      <Button type="text" action={portfolio.link} label={portfolio.link} />
    </div>
  ));
  if (portfolioItems.length) {
    details.push({
      name: "Portfolio",
      value: portfolioItems,
    });
  }
  const uploads = fileUploads.map(upload => (
    <WorkExample fileUpload={upload} onRemove={null} key={upload.uuid} />
  ));
  if (uploads.length) {
    details.push({
      name: "Attachments",
      value: uploads,
    });
  }
  return (
    <Container grid="span-medium" className={styles.container}>
      <div>
        <Header student={student} blur={blur} />
        <div className={styles.tags}>
          {student.profile.skills.map(skill => (
            <Tag
              solid={!!(matchSkills || []).find(s => s.id === skill.id)}
              key={skill.id}
              label={skill.name}
            />
          ))}
        </div>
        {applicationDetails && applicationDetails.length > 0 && (
          <AttributeList
            className={styles.application}
            items={applicationDetails}
          />
        )}
        <AttributeList
          className={styles.attributes}
          items={[
            {
              name: "Graduation year",
              value: `${graduateYear || "N/A"} (${
                internationalStudent
                  ? "International Student"
                  : "Domestic Student"
              })`,
            },
            { name: "Interested in", value: profile.humanWorkTypes },
            { name: "Available to work in", value: profile.humanLocations },
            { name: "Work eligibility", value: profile.humanWorkEligibility },
          ]}
        />
        <Attribute
          name="About me"
          value={profile.biography}
          className={styles.biography}
        />
        <AttributeList items={details} className={styles.detailsMobile} />
      </div>
      <div>
        <div className={styles.sidebar}>{sidebarContent}</div>
        <AttributeList items={details} className={styles.details} />
      </div>
    </Container>
  );
};

export { StudentProfile };
export default StudentProfile;
