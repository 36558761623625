import * as React from "react";

import { Loading, Separator } from "@ribit/components";
import {
  Operations,
  operations,
} from "@app/state/ducks/messages/threads/operations";
import { RouteComponentProps } from "@app/types";
import { withRouter } from "@app/helpers/hooks";

import { GroupedPayload } from "@app/state/ducks/messages/threads/types";
import { State } from "@app/state";
import { Threads as ThreadList } from "../components";
import { Thread as ThreadType } from "@app/models/messages";
import User from "@app/models/user";
import { connect } from "react-redux";
import { history } from "@app/state/store";
import { mapOperationsToDispatchProps } from "@ribit/lib";

type OwnProps = Record<string, never>;
type DispatchProps = {
  operations?: Operations;
};
type StateProps = {
  threads?: GroupedPayload;
  loading?: boolean;
  user?: User;
};
type MessageProps = OwnProps & DispatchProps & StateProps & RouteComponentProps;

class PureThreads extends React.Component<MessageProps> {
  private get userUuid(): string {
    return this.props.match.params["useruuid"];
  }

  private get jobUuid(): string {
    return this.props.match.params["jobuuid"];
  }

  private get isEmpty(): boolean {
    const { threads, loading } = this.props;
    if (!threads) {
      return false;
    }
    return loading === false && !Object.keys(threads).length;
  }

  componentDidMount() {
    this.refresh();
  }

  componentDidUpdate() {
    const { threads, user, loading } = this.props;
    const threadKeys: string[] = Object.keys(threads);
    if (threadKeys.length >= 1 && !this.userUuid) {
      const thread: ThreadType = threads[threadKeys[0]][0];
      const recipient: User = thread.recipient(user);
      history.push(`/messages/${thread.job.uuid}/${recipient.uuid}`);
    }
    if (threadKeys.length === 0 && this.userUuid && loading === false) {
      history.push("/messages");
    }
  }

  componentWillUnmount() {
    const { operations } = this.props;
    operations.clear();
  }

  refresh = () => {
    const { operations } = this.props;
    operations.fetch();
  };

  renderEmpty = (): React.ReactElement<any> => {
    if (!this.isEmpty || this.props.loading) {
      return null;
    }

    return <Separator text="No messages" />;
  };

  renderLoading = (): React.ReactElement<any> => {
    const { loading } = this.props;
    if (!loading) {
      return this.renderEmpty();
    }

    return (
      <>
        <Separator text="Threads" />
        <Loading style="inverted" label="Retrieving message threads..." />
      </>
    );
  };

  render(): React.ReactElement<any> {
    const { threads, user } = this.props;
    return (
      <>
        {this.renderLoading()}
        <ThreadList
          threads={threads}
          user={user}
          activeUuid={this.userUuid}
          activeJobUuid={this.jobUuid}
        />
      </>
    );
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    threads: state.messages.threads.data,
    loading: state.messages.threads.loading,
    user: state.user,
  };
};

const ConnectedThreads = connect(
  mapStateToProps,
  mapOperationsToDispatchProps(operations),
)(PureThreads);
const Threads = withRouter(ConnectedThreads);

export { Threads };
export default Threads;
