import * as React from "react";
import styles from "./styles.modules.css";

import {
  Container,
  Content,
  Heading,
  NumberedList,
  Tabs,
  Text,
} from "@ribit/components";
import { Employer, Student } from "./types";
import { Route, Routes } from "react-router-dom";

type OwnProps = Record<string, never>;

const Kiosk: React.FunctionComponent<OwnProps> = () => {
  return (
    <Content className={styles.outer}>
      <Container grid="span-medium">
        <div className={styles.copy}>
          <Routes>
            <Route
              path="student"
              element={
                <>
                  <Heading
                    text="Sign up as a student"
                    className={styles.pageTitle}
                  />
                  <Text className={styles.reasons}>
                    Find work with Australia’s most innovative companies.
                  </Text>
                  <NumberedList
                    className={styles.steps}
                    items={[
                      "Showcase your skills, education and experience on an easy to create profile.",
                      "Get matched! Apply for jobs, internships and projects recommended for you.",
                      "Connect with prospective employers with direct messaging.",
                    ]}
                  />
                  <Text className={styles.reasons}>Hop on - it’s free.</Text>
                </>
              }
            />
            <Route
              path="employer"
              element={
                <>
                  <Heading
                    text="Sign up as an employer"
                    className={styles.pageTitle}
                  />
                  <Text className={styles.reasons}>
                    Post a job and get instantly matched to talented students in
                    three easy steps.
                  </Text>
                  <NumberedList
                    className={styles.steps}
                    items={[
                      "Create an account and post a role.",
                      "Search the database and invite potentially suitable students to apply.",
                      "Shortlist candidates and direct message for interview.",
                    ]}
                  />
                  <Text className={styles.reasons}>Hop on - it’s free.</Text>
                </>
              }
            />
          </Routes>
        </div>
        <div>
          <Tabs
            items={[
              { name: "I'm a student", link: "/kiosk/student" },
              { name: "I'm an employer", link: "/kiosk/employer" },
            ]}
          >
            <Routes>
              <Route path="student" element={<Student />} />
              <Route path="employer" element={<Employer />} />
            </Routes>
          </Tabs>
        </div>
      </Container>
    </Content>
  );
};

export { Kiosk };
export default Kiosk;
