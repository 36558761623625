import * as yup from "yup";

import { Location } from "./google";
import { get } from "./api";
import { parsePhoneNumberFromString } from "libphonenumber-js";

declare module "yup" {
  interface StringSchema {
    abn(message: string): StringSchema;
    lengthOrNull(length: number, message: string): StringSchema;
    location(message: string, callback?: any): StringSchema;
    phoneNumber(message: string): StringSchema;
  }

  interface MixedSchema {
    keyValue(key: string, message: string): MixedSchema;
  }
}

yup.addMethod<yup.StringSchema>(yup.string, "abn", function(message: string) {
  return this.test("test-name", message, function(value: string): boolean {
    if (!value) {
      return true;
    }
    const actualValue: string = value.replace(/ /g, "");
    if (actualValue.length !== 11) {
      return false;
    }
    const weights: number[] = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
    const sum: number = weights.reduce(
      (previous: number, current: number, currentIndex: number) => {
        const digit: number =
          parseInt(actualValue[currentIndex]) - (currentIndex ? 0 : 1);
        return previous + current * digit;
      },
      0,
    );
    return sum % 89 === 0;
  });
});

yup.addMethod<yup.StringSchema>(yup.string, "lengthOrNull", function(
  length: number,
  message: string,
) {
  return this.test("test-name", message, function(value: string): boolean {
    if (!value) {
      return true;
    }
    if (value.length !== length) {
      return false;
    }
    return true;
  });
});

yup.addMethod<yup.StringSchema>(yup.string, "phoneNumber", function(
  message: string,
) {
  return this.test("test-name", message, function(value: string): boolean {
    if (!value) {
      return true;
    }
    const parsedNumber = parsePhoneNumberFromString(value);
    if (parsedNumber && parsedNumber.isValid()) {
      return true;
    }
    return false;
  });
});

yup.addMethod<yup.MixedSchema>(yup.mixed, "keyValue", function(
  key: number,
  message: string,
) {
  return this.test("test-name", message, function(value: object): boolean {
    if (!value[key]) {
      return false;
    }
    return true;
  });
});

yup.addMethod<yup.StringSchema>(yup.string, "location", function(
  message: string,
  callback: any = null,
) {
  return this.test("test-name", message, async function(
    value: string,
  ): Promise<any> {
    if (!value) {
      return false;
    }
    const response: Location[] = await get(
      `/address?q=${encodeURI(value)}&match_region=true`,
    )
      .then(data => {
        return data;
      })
      .catch(error => {
        return error;
      });

    if (response.length === 0) {
      return false;
    }
    if (callback) {
      callback(response);
    }
    return true;
  });
});
