import * as React from "react";

import { Loading, Text } from "@ribit/components";

import Alert from "./alert";
import Pagination from "@app/models/pagination";
import SearchAlert from "@app/models/search-alert";

type OwnProps = {
  alerts: Pagination<SearchAlert>;
  loading: boolean;
  onSelect: (searchAlert: SearchAlert) => void;
  onEdit: (searchAlert: SearchAlert) => void;
};

const List: React.FunctionComponent<OwnProps> = (props: OwnProps) => {
  const { loading, alerts, onSelect, onEdit } = props;
  if (loading) {
    return <Loading style="inverted" />;
  }
  if (!alerts || (alerts && alerts.count === 0)) {
    return (
      <Text>
        Creating a search alert will allow you to receive notifications when new
        jobs that match your profile are posted.
      </Text>
    );
  }
  return (
    <>
      {alerts.results.map(alert => (
        <Alert
          key={alert.uuid}
          searchAlert={alert}
          onSelect={onSelect}
          onEdit={onEdit}
        />
      ))}
    </>
  );
};

export { List };
export default List;
