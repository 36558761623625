import * as React from "react";

import {
  ActionBar,
  Button,
  Container,
  Content,
  Field,
  Form,
  Heading,
  Icons,
  Input,
  Panel,
  Text,
  Tooltip,
} from "@ribit/components";
import { Operations, operations } from "@app/state/ducks/user/operations";

import { Title } from "@app/components/title";
import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";
import styles from "./styles.modules.css";

type OwnProps = Record<string, never>;
type DispatchProps = {
  operations?: Operations;
};
type LoginProps = DispatchProps & OwnProps;

class PureLogin extends React.Component<LoginProps> {
  _onSubmit = values => {
    return this.props.operations.login(values.emailAddress, values.password);
  };

  render(): React.ReactElement<any> {
    const url = new URL(window.location.href);
    const type = url.searchParams.get("type");
    const signupUrl = type && type === "student" ? `/${type}` : "";

    return (
      <Content className={styles.outer}>
        <Title label="Login" />
        <Container>
          <div className={styles.login}>
            <Panel>
              <Heading className={styles.title} text="Log in to Ribit" />
              <Form onSubmit={this._onSubmit}>
                <Field required>
                  <Input
                    type="email"
                    name="emailAddress"
                    placeholder="janesmith@example.com"
                  />
                </Field>
                <Field
                  required
                  help={
                    <Button
                      type="text"
                      label="Forgotten password"
                      action="/auth/forgotten-password"
                    />
                  }
                >
                  <Input
                    type="password"
                    name="password"
                    suffix={
                      <Tooltip
                        icon={Icons.Question}
                        label="Must be at least 8 characters"
                        arrowDirection="right"
                      />
                    }
                  />
                </Field>
                <ActionBar>
                  <Button
                    type="submit"
                    label="Login"
                    style="secondary"
                    layout="full-width"
                  />
                </ActionBar>
              </Form>
            </Panel>
            <div className={styles.altAction}>
              <Text>Don't have an account yet?</Text>
              <Button
                action={`/auth/signup${signupUrl}`}
                label="Signup"
                size="small"
              />
            </div>
          </div>
        </Container>
      </Content>
    );
  }
}

const mapDispatchToProps = mapOperationsToDispatchProps(operations);

const Login = connect(null, mapDispatchToProps)(PureLogin);

export { Login };
export default Login;
