import * as React from "react";
import styles from "./styles.modules.css";

import { Button, Icon, Icons } from "@ribit/components";

import FileUpload from "@app/models/file-upload";

type OwnProps = {
  fileUpload: FileUpload;
  onRemove: (fileUpload: FileUpload) => void;
};

const WorkExample: React.FunctionComponent<OwnProps> = (props: OwnProps) => {
  const { fileUpload, onRemove } = props;
  return (
    <div className={styles.attachment}>
      <Icon icon={Icons.Attachment} />
      <Button
        type="text"
        className={styles.filename}
        label={fileUpload.name}
        action={fileUpload.url}
      />
      {onRemove && (
        <Button
          className={styles.remove}
          action={() => {
            onRemove(fileUpload);
          }}
          label={<Icon icon={Icons.Cross} />}
        />
      )}
    </div>
  );
};

export { WorkExample };
export default WorkExample;
