import React, { useState } from "react";
import styles from "../employer/styles.modules.css";
import { Container, Icons, Panel } from "@ribit/components";
import {
  Operations as JobOperations,
  operations as jobOperations,
} from "@app/state/ducks/manage/ccRoles/operations";
import {
  Operations as CourseOperations,
  operations as courseOperations,
} from "@app/state/ducks/manage/courses/operations";
import {
  Operations as ClosedJobOperations,
  operations as closedJobOperations,
} from "@app/state/ducks/manage/closedCCRoles/operations";
import { CCRoles, ClosedCCRoles } from "./containers";
import { Partner as PartnerType } from "@app/models/partner";
import { PureViewJob } from "@app/pages/dashboard/partner/containers/view-job";
import { Job, Course } from "@app/models/job";
import Pagination from "@app/models/pagination";
import { State } from "@app/state";
import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";

type OwnProps = Record<string, never>;

type DispatchProps = {
  operations: {
    jobOperations: JobOperations;
    closedJobOperations: ClosedJobOperations;
    courseOperations: CourseOperations;
  };
};

type StateProps = {
  user?: PartnerType;
  ccRoles?: Pagination<Job>;
  closedCCRoles?: Pagination<Job>;
  courses?: Pagination<Course>;
};

type PartnerProps = StateProps & DispatchProps & OwnProps;

const PurePartner: React.FunctionComponent<PartnerProps> = (
  props: PartnerProps,
) => {
  const { ccRoles, closedCCRoles, courses, operations } = props;
  const [activeJob, setActiveJob] = useState<Job>(null);

  React.useEffect(() => {
    operations.jobOperations.fetch();
    operations.closedJobOperations.fetch();
    operations.courseOperations.fetch();
    return () => {
      operations ? operations.jobOperations.clear() : null;
      operations ? operations.closedJobOperations.clear() : null;
      operations ? operations.courseOperations.clear() : null;
    };
  }, []);

  const handleJobOpen = (job: Job) => {
    operations.jobOperations.seen(job.uuid);
    setActiveJob(job);
  };

  const handleJobClose = () => {
    setActiveJob(null);
  };

  const handleJobApproved = (job: Job, coursesSelected: string[]) => {
    operations.jobOperations.approve(job.uuid, coursesSelected);
    handleJobClose();
  };

  const handleJobRejected = (job: Job) => {
    operations.jobOperations.reject(job.uuid);
    handleJobClose();
  };

  return (
    <>
      <Container>
        <div className={styles.jobs}>
          <Panel title="Course Credit Roles" icon={Icons.Suitcase}>
            <CCRoles data={ccRoles} onJobClick={job => handleJobOpen(job)} />
          </Panel>
        </div>
      </Container>
      <Container>
        <div className={styles.jobs}>
          <Panel title="Closed Course Credit Roles" icon={Icons.Suitcase}>
            <ClosedCCRoles data={closedCCRoles} />
          </Panel>
        </div>
      </Container>
      <PureViewJob
        job={activeJob}
        courses={courses}
        isOpen={!!activeJob}
        approved={(job, coursesSelected) =>
          handleJobApproved(job, coursesSelected)
        }
        rejected={job => handleJobRejected(job)}
        dismissed={() => handleJobClose()}
      />
    </>
  );
};

const mapStateToProps = (state: State): StateProps => {
  return {
    user: state.user as PartnerType,
    ccRoles: state.manage.ccRoles.data,
    closedCCRoles: state.manage.closedCCRoles.data,
    courses: state.manage.courses.data,
  };
};

const Partner = connect(
  mapStateToProps,
  mapOperationsToDispatchProps({
    jobOperations,
    closedJobOperations,
    courseOperations,
  }),
)(PurePartner);

export { Partner };
export default Partner;
