import * as React from "react";
import * as selectApplication from "@app/state/ducks/selected-application/operations";
import * as styles from "../styles.modules.css";

import {
  ActionBar,
  Container,
  Field,
  Form,
  Heading,
  Input,
  Loading,
  Panel,
  Separator,
  Text,
} from "@ribit/components";
import { Applicants, Shortlist, Suggested } from "../containers";
import {
  Operations as ApplicationOperations,
  operations as applicationOperations,
} from "@app/state/ducks/manage/applications/operations";
import {
  Operations as InviteOperations,
  operations as inviteOperations,
} from "@app/state/ducks/manage/invites/operations";
import {
  Operations as ShortlistOperations,
  operations as shortlistOperations,
} from "@app/state/ducks/manage/shortlist/operations";

import Application from "@app/models/application";
import { CloseJobDialog } from "@app/components/close-job/close-job-dialog";
import Job from "@app/models/job";
import Pagination from "@app/models/pagination";
import { State } from "@app/state";
import { Student } from "@app/models/student";
import Tenant from "@app/models/tenant";
import { ViewApplication } from "@app/containers/view-application/view-application";
import { ViewStudent } from "@app/containers/view-student";
import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";
import { PendingCompanyPanel } from "@app/components/pending-company-panel/pending-company-panel";
import Employer from "@app/models/employer";
import { Button } from "@app/components/button";

type OwnProps = {
  job: Job;
};

type DispatchProps = {
  operations: {
    shortlist: ShortlistOperations;
    applications: ApplicationOperations;
    invites: InviteOperations;
    select: typeof selectApplication;
  };
};

type StateProps = {
  tenant: Tenant;
  applicantsLoading: boolean;
  applicants: Pagination<Application>;
  shortlistLoading: boolean;
  shortlist: Pagination<Application>;
  invites: Pagination<Student>;
  invitesLoading: boolean;
  currentEmployer?: Employer;
};

type ViewProps = OwnProps & DispatchProps & StateProps;

class PureView extends React.Component<
  ViewProps,
  { closeDialogOpen: boolean; selectedStudent?: Student }
> {
  constructor(props: ViewProps) {
    super(props);
    this.state = { closeDialogOpen: false };
    if (props.job) {
      this.fetchPanels(props.job);
    }
  }

  private selectApplication = (application?: Application) => {
    if (application) {
      this.props.operations.select.selectApplication(application);
    } else {
      this.props.operations.select.deselectApplication();
    }
  };

  private selectStudent = (selectedStudent?: Student) => {
    this.setState({ ...this.state, selectedStudent });
  };

  private deselectStudent = () => {
    this.selectStudent(undefined);
  };

  private showCloseJobDialog = () => {
    this.setState({ ...this.state, closeDialogOpen: true });
  };

  private dismissModal = (dirty?: boolean) => {
    let newState = { ...this.state, closeDialogOpen: false };
    if (dirty) {
      newState = { ...newState };
      const { job } = this.props;
      if (job) {
        this.fetchPanels(job);
      }
    }
    this.setState(newState);
    this.selectApplication(undefined);
  };

  private fetchPanels = (job: Job) => {
    const { applications, shortlist, invites } = this.props.operations;

    applications.clear();
    shortlist.clear();
    invites.clear();

    applications.fetch(job.uuid);
    shortlist.fetch(job.uuid);
    invites.fetch(job.uuid);
  };

  componentWillUnmount() {
    const { applications, shortlist, invites } = this.props.operations;
    this.dismissModal();
    applications.clear();
    shortlist.clear();
    invites.clear();
  }

  componentDidUpdate(prevProps: ViewProps) {
    const { job } = this.props;
    if (!prevProps.job && job) {
      this.fetchPanels(job);
    }
  }

  private _renderStudentProfile(): React.ReactElement {
    return (
      <ViewStudent
        job={this.props.job}
        student={this.state.selectedStudent}
        dismissed={this.deselectStudent}
        currentEmployer={this.props.currentEmployer}
      />
    );
  }

  private _renderInvite(): React.ReactElement<any> {
    const { invitesLoading, invites, job } = this.props;
    return (
      <Panel
        title="Suggested / recommended students"
        help={
          invites.count > 0 && (
            <div className={styles.help}>
              {invites.results.length}/{invites.count}
            </div>
          )
        }
      >
        <Suggested
          job={job}
          students={invites}
          onSelect={this.selectStudent}
          loading={invitesLoading}
        />
        {this._renderStudentProfile()}
      </Panel>
    );
  }

  private _renderShortlist(): React.ReactElement<any> {
    const {
      applicantsLoading,
      shortlistLoading,
      applicants,
      shortlist,
      job,
    } = this.props;
    return (
      <>
        <Panel
          title="Shortlist"
          help={
            shortlist.count > 0 && (
              <div className={styles.help}>
                {shortlist.results.length}/{shortlist.count}
              </div>
            )
          }
        >
          <Shortlist
            job={job}
            applications={shortlist}
            loading={shortlistLoading}
            onSelect={this.selectApplication}
          />
        </Panel>
        <Panel
          title="Job applicants"
          help={
            applicants.count > 0 && (
              <div className={styles.help}>
                {applicants.results.length}/{applicants.count}
              </div>
            )
          }
        >
          <Applicants
            job={job}
            applications={applicants}
            loading={applicantsLoading}
            onSelect={this.selectApplication}
          />
        </Panel>
      </>
    );
  }

  private _renderApplications(): React.ReactElement<any> {
    const { applicants, shortlist } = this.props;
    if (applicants.count > 0 || shortlist.count > 0) {
      return (
        <>
          {this._renderShortlist()}
          {this._renderInvite()}
        </>
      );
    } else {
      return (
        <>
          {this._renderInvite()}
          {this._renderShortlist()}
        </>
      );
    }
  }

  private _renderViewApplication(): React.ReactElement {
    return (
      <ViewApplication dismissed={this.dismissModal} job={this.props.job} />
    );
  }

  render() {
    const { job, tenant, shortlist, applicants } = this.props;
    if (!job || !tenant) {
      return <Loading style="inverted" label="Retrieving job..." />;
    }

    const jobUrl = `https://${tenant.domain}/jobs/${job.slug}`;
    const isCompanyApproved = job.owner.company.approved;

    return (
      <Container>
        <PendingCompanyPanel companyApproved={isCompanyApproved} />
        <ActionBar className={styles.actionBar}>
          <div className={styles.jobTitleContainer}>
            <Heading className={styles.jobTitle}>
              <span>Your job post "</span>
              <span className={styles.shrink}>{job.title}</span>
              <span>" is {isCompanyApproved ? "live!" : "complete!"}</span>
            </Heading>
            <Text>
              Great work - your job post{" "}
              {isCompanyApproved ? "is being" : "will soon be"} seen by students
              all over our platform.
            </Text>
          </div>
          <div className={styles.buttons}>
            <Button
              action={`/manage/jobs/${job.uuid}/edit`}
              label="Edit job post"
              style="tertiary"
              dataCy="button__edit-job"
            />
            <Button
              action={this.showCloseJobDialog}
              label="Hire or Close Job"
              style="secondary"
            />
          </div>
        </ActionBar>
        <CloseJobDialog
          onClose={this.dismissModal}
          applications={shortlist.results.concat(applicants.results)}
          job={job}
          isOpen={this.state.closeDialogOpen}
        />
        <Container grid="small-span" className={styles.container}>
          <div>
            <Panel title="Share job">
              {!isCompanyApproved && (
                <div className={styles.pendingApprovalText}>
                  You will be able to share this job once your account has been
                  reviewed
                </div>
              )}
              {isCompanyApproved && (
                <div>
                  <Text className={styles.share}>
                    Reach a larger audience, share your job on social media!{" "}
                  </Text>
                  <Form
                    initial={{
                      url: jobUrl,
                    }}
                  >
                    <Field label={null}>
                      <Input name="url" readonly />
                    </Field>
                    <Button
                      label="Copy share link"
                      action={() => {
                        const el: HTMLInputElement = document.querySelector(
                          "input[name='url']",
                        );
                        el.select();
                        document.execCommand("copy");
                        el.blur();
                      }}
                      style="tertiary"
                      layout="full-width"
                    />
                    <Separator text="OR" />
                    <Button
                      label="Share on Facebook"
                      action={`https://www.facebook.com/sharer.php?u=${jobUrl}`}
                      layout="full-width"
                      size="small"
                      className={styles.facebook}
                    />
                    <Button
                      label="Share on Twitter"
                      action={`https://twitter.com/intent/tweet?text=${jobUrl}`}
                      layout="full-width"
                      size="small"
                      className={styles.twitter}
                    />
                    <Button
                      label="Share on LinkedIn"
                      action={`https://www.linkedin.com/shareArticle?url=${jobUrl}`}
                      layout="full-width"
                      size="small"
                      className={styles.linkedin}
                    />
                    <Separator text="OR" />
                    <Button
                      label="View job post"
                      style="tertiary"
                      layout="full-width"
                      action={`/jobs/${job.slug}`}
                    />
                  </Form>
                </div>
              )}
            </Panel>
          </div>
          <div>
            {this._renderApplications()}
            {this._renderViewApplication()}
          </div>
        </Container>
      </Container>
    );
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    tenant: state.tenant,
    shortlist: state.manage.shortlist.data,
    applicants: state.manage.applications.data,
    shortlistLoading: state.manage.shortlist.loading,
    applicantsLoading: state.manage.applications.loading,
    invites: state.manage.invites.data,
    invitesLoading: state.manage.invites.loading,
    currentEmployer: state.user as Employer,
  };
};

const View = connect(
  mapStateToProps,
  mapOperationsToDispatchProps({
    shortlist: shortlistOperations,
    applications: applicationOperations,
    select: selectApplication,
    invites: inviteOperations,
  }),
)(PureView);

export { View, PureView };
export default View;
