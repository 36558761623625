import * as React from "react";
import styles from "./styles.modules.css";
import * as yup from "yup";

import {
  ActionBar,
  AttributeList,
  Button,
  Field,
  Form,
  Heading,
  Input,
  RadioGroup,
  Size,
  Tag,
  Text,
} from "@ribit/components";

import SearchAlert from "@app/models/search-alert";

type OwnProps = {
  onSuccess: (searchAlert: SearchAlert) => any;
  onCancel: () => any;
  searchAlert: SearchAlert;
};

const Alert: React.FunctionComponent<OwnProps> = (props: OwnProps) => {
  const { onSuccess, onCancel, searchAlert } = props;
  const { locations, skills, types, category } = searchAlert.criteria;
  const criterias: any[] = [];
  if (locations) {
    criterias.push({
      name: "Locations",
      value: locations.map(location => (
        <Tag key={location.id} label={location.name} />
      )),
    });
  }
  if (skills) {
    criterias.push({
      name: "Skills",
      value: skills.map(skill => <Tag key={skill.id} label={skill.name} />),
    });
  }
  if (types) {
    criterias.push({
      name: "Types",
      value: types.map(type => <Tag key={type.id} label={type.name} />),
    });
  }
  if (category) {
    criterias.push({
      name: "Category",
      value: category.map(cat => <Tag key={cat.id} label={cat.name} />),
    });
  }

  return (
    <div className={styles.outer}>
      <Heading
        text={(searchAlert.uuid ? "Update" : "Save") + " Search Alert"}
      />
      <Text>
        {searchAlert.uuid
          ? "This search alert will be updated with your current filtered search criteria."
          : "This search alert will be saved with your current filtered search criteria."}
      </Text>
      <Form
        className={styles.form}
        initial={searchAlert}
        validateSchema={yup.object().shape({
          name: yup.string().required("You must enter a name."),
          interval: yup.number().required("You must select an interval."),
        })}
        onSubmit={values => {
          searchAlert.name = values.name;
          searchAlert.interval = values.interval;
          onSuccess(searchAlert);
        }}
      >
        <>
          <Field>
            <Input
              name="name"
              placeholder="A memorable name for your search alert"
            />
          </Field>
          <Field label="Notification frequency">
            <RadioGroup
              name="interval"
              options={[
                { label: "Never", value: 0 },
                { label: "Daily", value: 1 },
                { label: "Weekly", value: 2 },
              ]}
            />
          </Field>
          <Heading text="Criteria" size={Size.Small} />
          <AttributeList items={criterias} />
          <ActionBar layout="center" className={styles.actions}>
            <Button
              action={onCancel}
              label="Delete"
              style="tertiary"
              disabled={!searchAlert.uuid}
            />
            <Button label="Save" style="secondary" type="submit" />
          </ActionBar>
        </>
      </Form>
    </div>
  );
};

export { Alert };
export default Alert;
