import { Action, ApiData } from "@app/state/types";
import Types, { GroupedPayload, Payload } from "./types";

import Job from "@app/models/job";
import { Thread } from "@app/models/messages";
import { threadExists } from "./selectors";

const initialState: ApiData<GroupedPayload> = {
  loading: null,
  data: {},
};

const reducer = (
  state = initialState,
  action: Action<Payload>,
): ApiData<GroupedPayload> => {
  const { type } = action;
  const payload: Payload = action.payload;
  switch (type) {
    case Types.FETCH_SUCCESS:
      return {
        loading: false,
        data: payload.data.length
          ? payload.data.reduce((previous: GroupedPayload, message: Thread) => {
              if (!threadExists(previous, message.job)) {
                previous[message.job.title] = [];
              }
              previous[message.job.title].push(message);
              return previous;
            }, state.data)
          : state.data,
      };
    case Types.FETCHING:
      return {
        loading: true,
        data: state.data,
      };
    case Types.CREATE_SUCCESS: {
      const job: Job = payload.data[0].job;
      if (threadExists(state.data, job)) {
        state.data[job.title] = [...state.data[job.title], ...payload.data];
      } else {
        state.data[job.title] = payload.data;
      }
      return {
        loading: false,
        data: state.data,
      };
    }
    case Types.CLEAR:
      return {
        loading: false,
        data: {},
      };
    default:
      return state;
  }
};

export { reducer };

export default reducer;
