import { clear as clearAction, fetchSuccess, fetching } from "./actions";

import { Dispatch } from "redux";
import Pagination from "@app/models/pagination";
import { Student } from "@app/models/student";
import { get } from "@app/helpers/api";

const fetch = (offset = 0, limit = 10): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(fetching());
    return get(`/students?offset=${offset}&limit=${limit}`, Student).then(
      (data: Pagination<Student>) => {
        dispatch(fetchSuccess(data));
        return data;
      },
    );
  };
};

const clear = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch(clearAction());
  };
};

type Operations = {
  fetch: (offset?: number, limit?: number) => any;
  clear: () => void;
};

const operations: Operations = {
  fetch,
  clear,
};

export { fetch, clear, operations, Operations };
