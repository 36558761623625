import * as React from "react";
import classNames from "classnames/bind";
import styles from "./styles.modules.css";

import {
  ActionBar,
  Button,
  Loading,
  More,
  Table,
  Text,
} from "@ribit/components";
import {
  Operations,
  operations,
} from "@app/state/ducks/job-invitations/operations";

import { EmptyTableView } from "@app/components/empty-table-view";
import Invitation from "@app/models/invitation";
import Pagination from "@app/models/pagination";
import { ProfilePhoto } from "@app/components/profile-photo";
import { State } from "@app/state";
import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";

const cx = classNames.bind(styles);

type OwnProps = {
  data?: Pagination<Invitation>;
};
type OwnState = {
  offset: number;
};
type DispatchProps = {
  operations?: Operations;
};
type StateProps = {
  loading?: boolean;
};
type JobInvitationProps = StateProps & DispatchProps & OwnProps;

class PureJobInvitations extends React.Component<JobInvitationProps, OwnState> {
  state: OwnState = {
    offset: 0,
  };

  componentDidMount() {
    this.props.operations.retrieveJobInvitations();
  }

  componentWillUnmount() {
    this.props.operations.clearJobInvitations();
  }

  private _renderTitle(invitation: Invitation) {
    const viewUrl = `/jobs/${invitation.job.slug}`;
    return (
      <ProfilePhoto
        style="square"
        size="small"
        name={invitation.job.title}
        action={viewUrl}
        altName={invitation.job.owner.company.name}
        subtitle={invitation.job.owner.company.name}
        url={invitation.job.owner.company.logo}
      />
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private _renderSkills(invitation: Invitation) {
    return <Text className={styles.count}>-</Text>;
  }

  private _renderPay(invitation: Invitation) {
    if (invitation.job.type.isCourseCredit) {
      // hide allowance for Course Credit jobs
      return null;
    } else {
      return <Text className={styles.count}>{invitation.job.budget}</Text>;
    }
  }

  private _renderLoadMore(): React.ReactElement<any> {
    const { data, operations, loading } = this.props;
    if (!data.results.length || !data.next) {
      return null;
    }

    return (
      <ActionBar layout="center">
        {loading && data.results.length ? (
          <Loading style="inverted" />
        ) : (
          <More
            label="View more"
            action={() => {
              operations.retrieveJobInvitations(data.results.length);
            }}
          />
        )}
      </ActionBar>
    );
  }

  private _renderActions = (invitation: Invitation) => {
    const { operations } = this.props;
    return (
      <Button
        label="Dismiss"
        size="small"
        style="tertiary"
        action={() => {
          operations.dismiss(invitation.id);
        }}
      />
    );
  };

  render(): React.ReactElement<any> {
    const { data, loading } = this.props;
    return (
      <>
        <Table
          className={cx({ table: true, invitations: true })}
          cellClassName={styles.cell}
          showColumns={false}
          showSeparator={false}
          columns={[
            { key: "title", renderer: this._renderTitle, width: "44%" },
            { key: "skills", renderer: this._renderSkills },
            { key: "pay", renderer: this._renderPay },
            { key: "actions", renderer: this._renderActions },
          ]}
          data={data.results}
          loading={loading && !data.results.length}
          defaultLoadingLabel="Retrieving jobs..."
          emptyView={
            <EmptyTableView
              span={5}
              title="No job invitations yet"
              actionLabel="Complete my profile"
              message="Start filling in more of your profile so we can start matching you with jobs you'd be great at!"
              action="/account"
            />
          }
        />
        {this._renderLoadMore()}
      </>
    );
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    loading: state.jobInvitations.loading,
  };
};

const JobInvitations = connect(
  mapStateToProps,
  mapOperationsToDispatchProps(operations),
)(PureJobInvitations);

export { JobInvitations };
export default JobInvitations;
