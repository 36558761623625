import * as React from "react";
import styles from "./styles.modules.css";

import {
  ActionBar,
  Attribute,
  Button,
  Loading,
  More,
  Table,
  Text,
} from "@ribit/components";
import {
  Operations,
  operations,
} from "@app/state/ducks/manage/closed/operations";

import { EmptyTableView } from "@app/components/empty-table-view";
import Job from "@app/models/job";
import Pagination from "@app/models/pagination";
import { State } from "@app/state";
import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";

type OwnProps = {
  data?: Pagination<Job>;
};
type DispatchProps = {
  operations?: Operations;
};
type StateProps = {
  loading?: boolean;
};
type ClosedJobsProps = StateProps & DispatchProps & OwnProps;

class PureClosedJobs extends React.Component<ClosedJobsProps> {
  componentWillUnmount() {
    this.props.operations.clear();
  }

  private _renderTitle(job: Job) {
    return <Attribute name={job.title} value={job.posted} />;
  }

  private _renderActions(job: Job) {
    const manageUrl = `/manage/jobs/${job.uuid}/repost`;
    const viewUrl = `/jobs/${job.slug}`;
    return (
      <div className={styles.actions}>
        {!job.canRepost && (
          <Button label="View" style="tertiary" size="small" action={viewUrl} />
        )}
        {job.canRepost && (
          <Button
            label="Repost"
            style="tertiary"
            size="small"
            action={manageUrl}
          />
        )}
      </div>
    );
  }

  private _renderInvites(job: Job) {
    return <Text className={styles.count}>{job.invites} invites</Text>;
  }

  private _renderApplicants(job: Job) {
    return <Text className={styles.count}>{job.applicants} applicants</Text>;
  }

  private _renderViews(job: Job) {
    return <Text className={styles.count}>{job.views} views</Text>;
  }

  private _renderLoadMore(): React.ReactElement<any> {
    const { data, operations, loading } = this.props;
    if (!data.results.length || !data.next) {
      return null;
    }

    return (
      <ActionBar layout="center">
        {loading && data.results.length ? (
          <Loading style="inverted" />
        ) : (
          <More
            label="View more"
            action={() => {
              operations.fetch(data.results.length);
            }}
          />
        )}
      </ActionBar>
    );
  }

  render(): React.ReactElement<any> {
    const { data, loading } = this.props;
    return (
      <>
        <Table
          className={styles.table}
          cellClassName={styles.cell}
          showColumns={false}
          showSeparator={false}
          columns={[
            { key: "title", renderer: this._renderTitle, width: "55%" },
            { key: "invites", renderer: this._renderInvites },
            { key: "views", renderer: this._renderViews },
            { key: "applicants", renderer: this._renderApplicants },
            { key: "actions", renderer: this._renderActions, width: "15%" },
          ]}
          data={data.results}
          loading={loading && !data.results.length}
          defaultLoadingLabel="Retrieving active jobs..."
          emptyView={
            <EmptyTableView
              span={5}
              title="Closed jobs"
              actionLabel="Post a job"
              message="You have no closed jobs, make sure you've got some jobs posted on the platform."
              action="/manage/jobs/create"
            />
          }
        />
        {this._renderLoadMore()}
      </>
    );
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    loading: state.manage.closed.loading,
  };
};

const ClosedJobs = connect(
  mapStateToProps,
  mapOperationsToDispatchProps(operations),
)(PureClosedJobs);

export { ClosedJobs };
export default ClosedJobs;
