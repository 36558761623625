import * as React from "react";
import * as commonStyles from "../styles.modules.css";

import { ActionBar, Button, Heading, Text } from "@ribit/components";

import Layout from "../layout";

type OwnProps = {
  onSubmit: () => void;
};

const Setup: React.FunctionComponent<OwnProps> = (props: OwnProps) => {
  const { onSubmit } = props;
  return (
    <Layout className={commonStyles.centered}>
      <Heading text="Setup your profile" />
      <Text className={commonStyles.content}>
        Before you post your first job we need a bit more information about your
        business. We’ll guide you through the process in 3 short steps.
      </Text>
      <ActionBar layout="center" className={commonStyles.actionBar}>
        <Button
          label="Next"
          action={() => {
            onSubmit();
          }}
          style="secondary"
        />
      </ActionBar>
    </Layout>
  );
};

export { Setup };
export default Setup;
