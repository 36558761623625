import { Action } from "@app/state/types";
import { Criteria } from "@app/models/search-alert";
import { Types } from "./types";

type State = {
  criteria: Criteria;
  filtered: boolean;
};

const initialState: State = {
  filtered: false,
  criteria: new Criteria(),
};

const reducer = (state = initialState, action: Action<Criteria>): State => {
  const { type, payload } = action;
  switch (type) {
    case Types.CLEAR:
      return initialState;
    case Types.SET:
      return {
        filtered: true,
        criteria: payload,
      };
    default:
      return state;
  }
};

export { reducer };
export default reducer;
