import * as React from "react";

import {
  ActionBar,
  Button,
  Field,
  Form,
  Heading,
  Input,
} from "@ribit/components";
import { Operations, operations } from "@app/state/ducks/user/operations";

import { State } from "@app/state";
import Tenant from "@app/models/tenant";
import { Title } from "@app/components/title";
import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";
import styles from "./styles.modules.css";

type OwnProps = Record<string, never>;
type DispatchProps = {
  operations?: Operations;
};
type StateProps = {
  tenant?: Tenant;
};
type EmployerProps = StateProps & DispatchProps & OwnProps;

const PureEmployer: React.FunctionComponent<EmployerProps> = (
  props: EmployerProps,
) => {
  const { operations, tenant } = props;
  return (
    <>
      <Title label="Employer Registration" />
      <Heading className={styles.title} text="Get started - it's free" />
      <Form
        onSubmit={(values, internals) => {
          const mungedData: object = {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            company: {
              name: values.companyName,
            },
            tenant: tenant.uuid,
          };
          const response: Promise<any> = operations.registerKiosk(mungedData);
          response.then(() => {
            internals.reset();
          });
          return response;
        }}
      >
        <Field required>
          <Input name="firstName" placeholder="Jane" />
        </Field>
        <Field required>
          <Input name="lastName" placeholder="Smith" />
        </Field>
        <Field required>
          <Input name="companyName" placeholder="Acme, Inc." />
        </Field>
        <Field required>
          <Input
            type="email"
            name="email"
            placeholder="janesmith@example.com"
          />
        </Field>
        <ActionBar>
          <Button
            type="submit"
            label="Sign up as an Employer"
            style="secondary"
            layout="full-width"
          />
        </ActionBar>
      </Form>
    </>
  );
};

const mapDispatchToProps = mapOperationsToDispatchProps(operations);
const mapStateToProps = (state: State) => {
  return {
    tenant: state.tenant,
  };
};

const Employer = connect(mapStateToProps, mapDispatchToProps)(PureEmployer);

export { Employer };
export default Employer;
