import { clear, fetchError, fetchSuccess, fetching } from "./actions";
import { del, get } from "@app/helpers/api";

import { Dispatch } from "redux";
import Invitation from "@app/models/invitation";
import Pagination from "@app/models/pagination";

const retrieveJobInvitations = (offset = 0, limit = 10): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(fetching());
    get(`/jobs/invitations?offset=${offset}&limit=${limit}`, Invitation)
      .then((data: Pagination<Invitation>) => {
        dispatch(fetchSuccess(data));
      })
      .catch(() => {
        dispatch(fetchError());
      });
  };
};

const clearJobInvitations = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch(clear());
  };
};

const dismiss = (id: number) => {
  return (dispatch: Dispatch<any>) => {
    del(`/jobs/invitations/${id}`).then(() => {
      dispatch(clear());
      retrieveJobInvitations()(dispatch);
    });
  };
};

type Operations = {
  retrieveJobInvitations: (offset?: number, limit?: number) => any;
  clearJobInvitations: () => void;
  dismiss: (id: number) => void;
};

const operations: Operations = {
  retrieveJobInvitations,
  clearJobInvitations,
  dismiss,
};

export {
  retrieveJobInvitations,
  clearJobInvitations,
  dismiss,
  operations,
  Operations,
};
