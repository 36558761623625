import * as React from "react";
import styles from "./styles.modules.css";
import * as yup from "yup";

import {
  ActionBar,
  Button,
  Container,
  Content,
  Field,
  Form,
  Heading,
  Input,
  NumberedList,
  Panel,
  Text,
} from "@ribit/components";
import { Operations, operations } from "@app/state/ducks/user/operations";

import { State } from "@app/state";
import { Title } from "@app/components/title";
import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";

type OwnProps = Record<string, never>;
type DispatchProps = {
  operations?: Operations;
};
type StateProps = {
  initiated?: boolean;
};
type ForgottenPasswordProps = DispatchProps & StateProps & OwnProps;

class PureForgottenPassword extends React.Component<ForgottenPasswordProps> {
  renderForm = (): React.ReactElement<any> => {
    const { operations } = this.props;
    return (
      <>
        <Form
          validateSchema={yup.object().shape({
            email: yup.string().email("Must enter a valid email address"),
          })}
          onSubmit={values => {
            return operations.initiatePasswordReset(values.email);
          }}
        >
          <Field required>
            <Input
              type="email"
              name="email"
              placeholder="janesmith@example.com"
            />
          </Field>
          <ActionBar>
            <Button
              type="submit"
              label="Reset your password"
              style="secondary"
              layout="full-width"
            />
          </ActionBar>
        </Form>
      </>
    );
  };

  renderInitiatedMessage() {
    return (
      <>
        <Text>
          Your password reset request has been made, please check your email for
          a reset password link.
        </Text>
      </>
    );
  }

  render(): React.ReactElement<any> {
    const { initiated } = this.props;
    return (
      <Content className={styles.outer}>
        <Title label="Forgotten Password" />
        <Container grid="span-medium">
          <div className={styles.copy}>
            <Heading text="Forgotten Password" className={styles.pageTitle} />
            <Text className={styles.reasons}>
              It happens to the best of us, luckily you can reset your password
              if you've forgotten it. You'll receive a link in your email which
              will allow you to go through an reset it.
            </Text>
            <NumberedList
              className={styles.steps}
              items={[
                "Request reset of your password.",
                "Check your email for the reset password link.",
                "Set the password to something more memorable.",
              ]}
            />
          </div>
          <div>
            <Panel>
              <Heading className={styles.title} text="Reset password" />
              {initiated ? this.renderInitiatedMessage() : this.renderForm()}
            </Panel>
            <div className={styles.altAction}>
              <Text>Remembered your password?</Text>
              <Button action="/auth/login" label="Login" size="small" />
            </div>
          </div>
        </Container>
      </Content>
    );
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    initiated: !!state.user.email,
  };
};

const ForgottenPassword = connect(
  mapStateToProps,
  mapOperationsToDispatchProps(operations),
)(PureForgottenPassword);
export { ForgottenPassword };
export default ForgottenPassword;
