import { alertsAdd, alertsClear } from "../site-alerts/actions";
import {
  create,
  createSuccess,
  fetchError,
  fetchSuccess,
  fetching,
  update,
  updateError,
  updateSuccess,
} from "./actions";
import { get, post, put } from "@app/helpers/api";

import Application from "@app/models/application";
import { Dispatch } from "redux";
import { createError } from "../messages/conversation/actions";

const fetch = (uuid: string): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(fetching());
    return get(`/jobs/applications/${uuid}`, Application)
      .then(application => {
        dispatch(fetchSuccess(application));
      })
      .catch(() => {
        dispatch(fetchError());
      });
  };
};

type ApplicationPayload = {
  job?: string;
  coverLetter: string;
  interviewAnswer: string;
  skills: number[];
};

const apply = (data: ApplicationPayload): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(create());
    dispatch(alertsClear());
    return post("/jobs/applications", data, Application)
      .then(application => {
        dispatch(
          alertsAdd(
            "Successfully applied for the role.",
            null,
            "Close",
            "success",
          ),
        );
        dispatch(createSuccess(application));
      })
      .then(() => {
        dispatch(createError());
      });
  };
};

const edit = (application: Application, data: ApplicationPayload): any => {
  return (dispatch: Dispatch<any>) => {
    dispatch(update());
    dispatch(alertsClear());
    return put(`/jobs/applications/${application.job.uuid}`, data, Application)
      .then((app: Application) => {
        application.coverLetter = app.coverLetter;
        application.interviewAnswer = app.interviewAnswer;
        application.skills = app.skills;
        dispatch(
          alertsAdd(
            "Successfully updated your application.",
            null,
            "Close",
            "success",
          ),
        );
        dispatch(updateSuccess(app));
      })
      .catch(() => {
        dispatch(updateError());
      });
  };
};

type Operations = {
  fetch(uuid: string): void;
  apply(data: ApplicationPayload): void;
  edit(application: Application, data: ApplicationPayload): void;
};

const operations: Operations = {
  fetch,
  apply,
  edit,
};

export { operations, Operations };
