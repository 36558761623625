import Pagination from "@app/models/pagination";
import SearchAlert from "@app/models/search-alert";

type Payload = {
  loading: boolean;
  data: Pagination<SearchAlert>;
};

const Types = {
  FETCHING: "@@search-alert/FETCHING",
  FETCH_SUCCESS: "@@search-alert/FETCH_SUCCESS",
  FETCH_ERROR: "@@search-alert/FETCH_ERROR",
  CREATE: "@@search-alert/CREATE",
  CREATE_SUCCESS: "@@search-alert/CREATE_SUCCESS",
  CREATE_ERROR: "@@search-alert/CREATE_ERROR",
  UPDATE: "@@search-alert/UPDATE",
  UPDATE_SUCCESS: "@@search-alert/UPDATE_SUCCESS",
  UPDATE_ERROR: "@@search-alert/UPDATE_ERROR",
  DELETE: "@@search-alert/DELETE",
  DELETE_SUCCESS: "@@search-alert/DELETE_SUCCESS",
  DELETE_ERROR: "@@search-alert/DELETE_ERROR",
};

export { Payload, Types };

export default Types;
